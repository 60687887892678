import { Controller, useFormContext, useWatch } from "react-hook-form";
import Select from "react-select";
import { components } from "react-select";
import { capFirstLetter } from "../Onboarding";

const options = [
  {
    value: "sat",
    label: "Sat",
  },
  {
    value: "sun",
    label: "Sun",
  },
  {
    value: "mon",
    label: "Mon",
  },
  {
    value: "tue",
    label: "Tue",
  },
  {
    value: "wed",
    label: "Wed",
  },
  {
    value: "thu",
    label: "Thu",
  },
  {
    value: "fri",
    label: "Fri",
  },
];
export function TicketAvailability() {
  const { control } = useFormContext();
  const selected = useWatch({
    control: control,
    name: `availability`,
  });
  return (
    <div className="flex flex-wrap gap-2.5">
      {(selected?.length ?? 0) > 0 && <div className="flex items-center justify-center gap-2.5 flex-wrap">
        {selected?.map((val: any) => (
          <div className="h-[31px] rounded-full px-2.5 bg-[#F1F1F1] text-sm font-normal flex items-center justify-center">
            {val?.label || capFirstLetter(val)}
          </div>
        ))}
      </div>}

      <Controller
        control={control}
        name={`availability`}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <div>
            <Select
              isMulti={true}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              onChange={(v) => onChange(v.map(o => o.value))}
              options={options}
              value={options.filter((o) => {
                return Array.isArray(value) ? (value ?? [])?.includes(o.value) : []
              })}
              placeholder={"Select Days"}
              components={{
                Option: MultiSelectOptions,
                IndicatorSeparator: () => <></>,
                ClearIndicator: (props) => <></>,
                DropdownIndicator: () => null,
                MultiValueContainer: () => <>Select Days</>,
              }}
              controlShouldRenderValue={false}
              styles={{
                multiValue: (styles) => ({
                  ...styles,
                  backgroundColor: "#F1F1F1",
                  borderRadius: 8,
                  height: 31,
                  display: "flex",
                  alignItems: "center",
                }),
                multiValueRemove: () => ({
                  display: "none",
                }),
                multiValueLabel: (styles) => ({
                  ...styles,
                  color: "#000",
                  fontSize: 14,
                  fontWeight: 400,
                  display: "block",
                  paddingLeft: 10,
                  paddingRight: 10,
                }),
                menu: (styles) => ({
                  ...styles,
                  margin: 0,
                  paddingLeft: 5,
                  border: "1px solid #6836D1",
                }),
                menuList: (base) => ({
                  ...base,
                  marginTop: 0,
                  marginBottom: 0,

                  "::-webkit-scrollbar": {
                    width: 14,
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#c2c2c2",
                    borderRadius: 10,
                    border: "4px solid white",
                  },
                }),
                noOptionsMessage: (base) => ({
                  ...base,
                  color: "#212121",
                }),
                control: (styles, state) => ({
                  ...styles,
                  cursor: "pointer",
                  outline: "none",
                  border: "none",
                  boxShadow: "none",
                  paddingTop: 0,
                  paddingRight: 2,
                  paddingLeft: 2,
                  paddingBottom: 0,
                  minHeight: 31,
                  width: 114,
                  borderRadius: 999,
                  backgroundColor: "#F1F1F1",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#212121",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  color: "#212121",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                background: "none",
                colors: {
                  ...theme.colors,
                  neutral80: "#6836D1",
                },
              })}
            />
          </div>
        )}
      />
    </div>
  );
}

const MultiSelectOptions: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  value,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    color: "#555",
    display: "flex",
    fontSize: 14,
    fontWeight: 400,
    height: 0,
    padding: 15,
    margin: 0,
  };

  const props = {
    ...innerProps,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <label className="checkbox-item">
        <input
          className="!w-40 !h-3 !opacity-0"
          type="checkbox"
          checked={isSelected || value.includes("true") ? true : false}
          readOnly
        />
        <span className="checkmark"></span>
      </label>
      {children}
    </components.Option>
  );
};
