export const smallChartSample = [
  {
    data: [
      {
        key: "2023-05-01T00:00:00.000Z",
        label: "May 2023",
        value: 201,
      },
      {
        key: "2023-06-01T00:00:00.000Z",
        label: "June 2023",
        value: 40,
      },
      {
        key: "2023-07-01T00:00:00.000Z",
        label: "July 2023",
        value: 0,
      },
      {
        key: "2023-08-01T00:00:00.000Z",
        label: "August 2023",
        value: 0,
      },
      {
        key: "2023-09-01T00:00:00.000Z",
        label: "September 2023",
        value: 0,
      },
      {
        key: "2023-10-01T00:00:00.000Z",
        label: "October 2023",
        value: 0,
      },
      {
        key: "2023-11-01T00:00:00.000Z",
        label: "November 2023",
        value: 0,
      },
      {
        key: "2023-12-01T00:00:00.000Z",
        label: "December 2023",
        value: 0,
      },
      {
        key: "2024-01-01T00:00:00.000Z",
        label: "January 2024",
        value: 0,
      },
      {
        key: "2024-02-01T00:00:00.000Z",
        label: "February 2024",
        value: 0,
      },
      {
        key: "2024-03-01T00:00:00.000Z",
        label: "March 2024",
        value: 0,
      },
      {
        key: "2024-04-01T00:00:00.000Z",
        label: "April 2024",
        value: 0,
      },
    ],
    data_previous: [
      {
        key: "2022-05-01T00:00:00.000Z",
        label: "May 2022",
        value: 0,
      },
      {
        key: "2022-06-01T00:00:00.000Z",
        label: "June 2022",
        value: 0,
      },
      {
        key: "2022-07-01T00:00:00.000Z",
        label: "July 2022",
        value: 0,
      },
      {
        key: "2022-08-01T00:00:00.000Z",
        label: "August 2022",
        value: 0,
      },
      {
        key: "2022-09-01T00:00:00.000Z",
        label: "September 2022",
        value: 0,
      },
      {
        key: "2022-10-01T00:00:00.000Z",
        label: "October 2022",
        value: 93,
      },
      {
        key: "2022-11-01T00:00:00.000Z",
        label: "November 2022",
        value: 175,
      },
      {
        key: "2022-12-01T00:00:00.000Z",
        label: "December 2022",
        value: 191,
      },
      {
        key: "2023-01-01T00:00:00.000Z",
        label: "January 2023",
        value: 305,
      },
      {
        key: "2023-02-01T00:00:00.000Z",
        label: "February 2023",
        value: 240,
      },
      {
        key: "2023-03-01T00:00:00.000Z",
        label: "March 2023",
        value: 274,
      },
      {
        key: "2023-04-01T00:00:00.000Z",
        label: "April 2023",
        value: 371,
      },
    ],
    data_type: "IMPRESSIONS",
    start_date: new Date("2023-05-01T00:00:00.000Z"),
    count: 0,
    count_previous: 0,
  },
  {
    data: [
      {
        key: "2023-05-01T00:00:00.000Z",
        label: "May 2023",
        value: 201,
      },
      {
        key: "2023-06-01T00:00:00.000Z",
        label: "June 2023",
        value: 40,
      },
      {
        key: "2023-07-01T00:00:00.000Z",
        label: "July 2023",
        value: 0,
      },
      {
        key: "2023-08-01T00:00:00.000Z",
        label: "August 2023",
        value: 0,
      },
      {
        key: "2023-09-01T00:00:00.000Z",
        label: "September 2023",
        value: 0,
      },
      {
        key: "2023-10-01T00:00:00.000Z",
        label: "October 2023",
        value: 0,
      },
      {
        key: "2023-11-01T00:00:00.000Z",
        label: "November 2023",
        value: 0,
      },
      {
        key: "2023-12-01T00:00:00.000Z",
        label: "December 2023",
        value: 0,
      },
      {
        key: "2024-01-01T00:00:00.000Z",
        label: "January 2024",
        value: 0,
      },
      {
        key: "2024-02-01T00:00:00.000Z",
        label: "February 2024",
        value: 0,
      },
      {
        key: "2024-03-01T00:00:00.000Z",
        label: "March 2024",
        value: 0,
      },
      {
        key: "2024-04-01T00:00:00.000Z",
        label: "April 2024",
        value: 0,
      },
    ],
    data_previous: [
      {
        key: "2022-05-01T00:00:00.000Z",
        label: "May 2022",
        value: 0,
      },
      {
        key: "2022-06-01T00:00:00.000Z",
        label: "June 2022",
        value: 0,
      },
      {
        key: "2022-07-01T00:00:00.000Z",
        label: "July 2022",
        value: 0,
      },
      {
        key: "2022-08-01T00:00:00.000Z",
        label: "August 2022",
        value: 0,
      },
      {
        key: "2022-09-01T00:00:00.000Z",
        label: "September 2022",
        value: 0,
      },
      {
        key: "2022-10-01T00:00:00.000Z",
        label: "October 2022",
        value: 93,
      },
      {
        key: "2022-11-01T00:00:00.000Z",
        label: "November 2022",
        value: 175,
      },
      {
        key: "2022-12-01T00:00:00.000Z",
        label: "December 2022",
        value: 191,
      },
      {
        key: "2023-01-01T00:00:00.000Z",
        label: "January 2023",
        value: 305,
      },
      {
        key: "2023-02-01T00:00:00.000Z",
        label: "February 2023",
        value: 240,
      },
      {
        key: "2023-03-01T00:00:00.000Z",
        label: "March 2023",
        value: 274,
      },
      {
        key: "2023-04-01T00:00:00.000Z",
        label: "April 2023",
        value: 371,
      },
    ],
    data_type: "OUTBOUND_CLICKS",
    start_date: new Date("2023-05-01T00:00:00.000Z"),
    count: 0,
    count_previous: 0,
  },
  {
    data: [
      {
        key: "2023-05-01T00:00:00.000Z",
        label: "May 2023",
        value: 201,
      },
      {
        key: "2023-06-01T00:00:00.000Z",
        label: "June 2023",
        value: 40,
      },
      {
        key: "2023-07-01T00:00:00.000Z",
        label: "July 2023",
        value: 0,
      },
      {
        key: "2023-08-01T00:00:00.000Z",
        label: "August 2023",
        value: 0,
      },
      {
        key: "2023-09-01T00:00:00.000Z",
        label: "September 2023",
        value: 0,
      },
      {
        key: "2023-10-01T00:00:00.000Z",
        label: "October 2023",
        value: 0,
      },
      {
        key: "2023-11-01T00:00:00.000Z",
        label: "November 2023",
        value: 0,
      },
      {
        key: "2023-12-01T00:00:00.000Z",
        label: "December 2023",
        value: 0,
      },
      {
        key: "2024-01-01T00:00:00.000Z",
        label: "January 2024",
        value: 0,
      },
      {
        key: "2024-02-01T00:00:00.000Z",
        label: "February 2024",
        value: 0,
      },
      {
        key: "2024-03-01T00:00:00.000Z",
        label: "March 2024",
        value: 0,
      },
      {
        key: "2024-04-01T00:00:00.000Z",
        label: "April 2024",
        value: 0,
      },
    ],
    data_previous: [
      {
        key: "2022-05-01T00:00:00.000Z",
        label: "May 2022",
        value: 0,
      },
      {
        key: "2022-06-01T00:00:00.000Z",
        label: "June 2022",
        value: 0,
      },
      {
        key: "2022-07-01T00:00:00.000Z",
        label: "July 2022",
        value: 0,
      },
      {
        key: "2022-08-01T00:00:00.000Z",
        label: "August 2022",
        value: 0,
      },
      {
        key: "2022-09-01T00:00:00.000Z",
        label: "September 2022",
        value: 0,
      },
      {
        key: "2022-10-01T00:00:00.000Z",
        label: "October 2022",
        value: 93,
      },
      {
        key: "2022-11-01T00:00:00.000Z",
        label: "November 2022",
        value: 175,
      },
      {
        key: "2022-12-01T00:00:00.000Z",
        label: "December 2022",
        value: 191,
      },
      {
        key: "2023-01-01T00:00:00.000Z",
        label: "January 2023",
        value: 305,
      },
      {
        key: "2023-02-01T00:00:00.000Z",
        label: "February 2023",
        value: 240,
      },
      {
        key: "2023-03-01T00:00:00.000Z",
        label: "March 2023",
        value: 274,
      },
      {
        key: "2023-04-01T00:00:00.000Z",
        label: "April 2023",
        value: 371,
      },
    ],
    data_type: "PAGE_VIEWS",
    start_date: new Date("2023-05-01T00:00:00.000Z"),
    count: 0,
    count_previous: 0,
  },
] satisfies AttractionsDataResponse;
