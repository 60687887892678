import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from "zod";
import { PrimaryButton } from '../../../../components/global/PrimaryButton';
import { apiSlice } from '../../../../store/apiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardContainer from '../../../../components/amp/DashboardContainer';
import DashboardHeaderContainer from '../../../../components/amp/DashboardHeaderContainer';
import { AvailabilityPeriodName } from './AvailabilityPeriodName';
import { AvailabilityPeriodDates } from './AvailabilityPeriodDates';
import { TicketingProduct } from '../CreateTicket/TicketingProduct';

const CreateAvailabilityPeriod: React.FC = () => {
  const navigate = useNavigate();
  const { availabilityPeriodId } = useParams<{ availabilityPeriodId: string }>();
  const { attractionId } = useParams();
  const isEditing = !!availabilityPeriodId
  const [products, setProducts] = useState<DOWTKTicketingProduct[]>([]);
  const [createAvailabilityPeriod] = apiSlice.useCreateAvailabilityPeriodMutation()
  const [updateAvailabilityPeriod] = apiSlice.useUpdateAvailabilityPeriodMutation()
  const { data: availabilityQuery } = apiSlice.useGetAvailabilityPeriodQuery(availabilityPeriodId || '', { skip: !isEditing })
  const { data: productsQuery, isFetching } = apiSlice.useGetTicketingProductListQuery({
    venueShortId: attractionId!
  });
  const formMethods = useForm<AvailabilityPeriodFormType>({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (!productsQuery) return
    setProducts(productsQuery.results)
  }, [productsQuery])

  useEffect(() => {
    if (!availabilityQuery) return
    const availabilityQueryCopy = { ...availabilityQuery }
    //format date to be compatible with input type="date"
    const startDate = availabilityQuery.start_date?.split('T')[0]
    const endDate = availabilityQuery.end_date?.split('T')[0]

    availabilityQueryCopy.start_date = startDate
    availabilityQueryCopy.end_date = endDate

    formMethods.reset(availabilityQueryCopy)
  }, [availabilityQuery])


  const onSubmit = () => {
    const fn: SubmitHandler<AvailabilityPeriodFormType> = async (e) => {

      if (isEditing) {
        await updateAvailabilityPeriod(e as any).unwrap().then((res) => {
          navigate(-1);
        })
      } else {
        await createAvailabilityPeriod(e as any).unwrap().then((res) => {
          navigate(-1);
        })
      }
    };
    return fn;
  }

  return (
    <>
      <div className="flex flex-col flex-1 bg-cs-off-white">
        <DashboardHeaderContainer>
          <h1 className="font-extrabold text-xl text-black font-roboto-flex ">Availability Period</h1>
        </DashboardHeaderContainer>
        <DashboardContainer>
          <section className={`flex flex-col px-6 pt-4 pb-8 bg-white xl:pt-6 xl:rounded-lg xl:mx-[70px]`}>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit())}>
                <legend className="mb-3 text-lg font-bold text-cs-gray">
                  {isEditing ? 'Edit Period' : 'Create Period'}
                </legend>
                <AvailabilityPeriodName />
                <TicketingProduct products={products} />
                <AvailabilityPeriodDates />
                <div className="flex items-center gap-5 max-w-[350px] mt-5">
                  <PrimaryButton scale="sm" type="submit">
                    {isEditing ? 'Update Period' : 'Create Period'}
                  </PrimaryButton>
                </div>
              </form>
            </FormProvider>
          </section>
        </DashboardContainer>
      </div>

    </>
  )
}

export default CreateAvailabilityPeriod;

export type AvailabilityPeriodFormType = z.infer<typeof schema>;
const schema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: "Please enter a name for this period." }),
  start_date: z.string().nonempty({ message: "Please enter a start date for this period." }),
  end_date: z.string().optional(),
  product_id: z.string(),
});
