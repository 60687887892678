import {
  ChartData,
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend,
  Filler,
  BarElement,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";

interface IChart {
  data: {
    label: string,
    data: number,
    secondaryData?: number
  }[];
  activePeriod: number;
  hasFilters?: boolean;
  customTooltipLabel?: string
}

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  TimeScale,
  Tooltip,
  Legend,
  Filler
);

const ReportChart: React.FC<IChart> = ({
  data,
  activePeriod,
  hasFilters = true,
  customTooltipLabel
}) => {
  const labels = data.map(d => d.label)
  const values = data.map(d => d.data)
  const secondaryValues = data.map(d => d.secondaryData || 0)

  const [getTotalVisibility, setCurrentVisibility] = useState<boolean>(false);
  const [getCommissionVisibility, setPreviousVisibility] =
    useState<boolean>(false);

  const chartData: ChartData<"bar"> = {
    datasets: [
      {
        label: customTooltipLabel ? customTooltipLabel : "Total Commission",
        data: values,
        backgroundColor: "#81DE4A",
        borderColor: "transparent",
        stack: "Stack 0",
        maxBarThickness: 65,
        hidden: getCommissionVisibility,
      },
      {
        label: customTooltipLabel ? customTooltipLabel : "Total Attraction Revenue",
        data: secondaryValues,
        backgroundColor: "#0052FF",
        stack: "Stack 0",
        maxBarThickness: 65,
        hidden: getTotalVisibility || secondaryValues.length === 0,
      },
    ],
  };

  const toggleData: any = (value: any) => {
    if (value === 0) {
      setCurrentVisibility((c) => !c);
    } else {
      setPreviousVisibility((c) => !c);
    }
  };

  useEffect(() => {}, [getTotalVisibility, getCommissionVisibility]);

  return (
    <>
      {hasFilters ? (
        <div className="flex items-center gap-x-5 my-3">
          <button
            className={`flex gap-[6px] border border-[#CFDBD5] h-[26px] py-[5px] px-3 rounded-full items-center ${
              getTotalVisibility && "opacity-50"
            }`}
            onClick={() => toggleData(0)}
          >
            <span
              className={`bg-[#0052FF] w-[9px] h-[9px] rounded-full`}
            ></span>
            <div className="flex gap-1 items-center text-left text-xs font-bold text-black font-['Inter_var']">
              <span>Total Attraction Revenue</span>
            </div>
          </button>
          <button
            className={`flex gap-[6px] border border-[#CFDBD5] h-[26px] py-[5px] px-3 rounded-full items-center ${
              getCommissionVisibility && "opacity-50"
            }`}
            onClick={() => toggleData(1)}
          >
            <span
              className={`bg-[#81DE4A] w-[9px] h-[9px] rounded-full`}
            ></span>
            <div className="flex gap-1 items-center text-left text-xs font-bold text-black font-['Inter_var']">
              <span>Total Commission</span>
            </div>
          </button>
        </div>
      ) : (
        <div className="my-5"></div>
      )}
      <div className="relative h-full min-w-[400px] max-w-[calc(100vw-500px)]">
        <Bar
          data={chartData}
          options={{
            animation: {
              duration: 300,
            },
            onHover: (event, activeElements) => {
              (event?.native?.target as HTMLElement).style.cursor =
                activeElements?.length > 0 ? "none" : "auto";
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                labels: labels,
                grid: {
                  display: false,
                },
                ticks: {
                  callback: function (context) {
                    return this.getLabelForValue(Number(context));
                  },
                  font: {
                    size: 14,
                  },
                  color: "black",
                },
                border: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  stepSize: activePeriod != 3 ? 50 : 500,
                  callback: function (value, index) {
                    if (value !== 0) {
                      if (typeof value === "string") {
                        return value;
                      }
                      return value.toLocaleString();
                    }
                  },
                  color: "black",
                  font: {
                    size: 12,
                  },
                },
                grid: {
                  display: true,
                  color: "#CFDBD5",
                },
                border: {
                  display: false,
                  dash: [2, 4],
                },
              },
            },
            plugins: {
              legend: {
                display: false,

                labels: {
                  color: "#555",
                  font: {
                    family: "Montserrat",
                    size: 13,
                  },
                  boxHeight: 0,
                  padding: 0,
                },
                align: "start",
                onHover: (event, activeElements) => {
                  (event?.native?.target as HTMLElement).style.cursor =
                    "pointer";
                },
              },
              tooltip: undefined,
            },
          }}
        />
      </div>
    </>
  );
};

export default ReportChart;
