import { components } from "react-select";
import { XIcon } from "../../components/misc/Icons";

const Options: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  innerRef,
  ref,
  value,
  defaultValue,
  selectProps,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    color: "#555",
    display: "flex",
    fontSize: 14,
    fontWeight: 400,
    height: 40,
    padding: 0,
    flexWrap: "wrap",
    width: "auto",
    borderRadius: 40
  };

  const props = {
    ...innerProps,
    style
  };

  const handleClearOption = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    const { onChange } = selectProps;
    if (onChange) {
        if (selectProps.isMulti) {
            const newValue = selectProps.value.filter((option: { label: string, value: string }) => option.value !== value);
            setTimeout(() =>{
              onChange(newValue);
            }, 50)
        } else {
          setTimeout(() =>{
            onChange(null);
          }, 50)
        }
    }
  };

  return (
    <components.Option
    {...rest}
    isDisabled={isDisabled}
    isFocused={isFocused}
    isSelected={isSelected}
    getStyles={getStyles}
    innerProps={props}
    innerRef={innerRef}
    >
      <label className={`checkbox-item white-checkmark w-full h-10 rounded-full px-5 text-sm font-normal flex items-center border ${isSelected || value == true || value && value.includes("true") ? "text-cs-pink border-cs-pink" : "border-[#CFDBD5] text-cs-gray"} ${isDisabled ? 'bg-gray-100 opacity-80' : ''}`}>
        <input className={`${ selectProps.customDefaultValue && selectProps.customDefaultValue.filter((option: any) => option?.value == value).length > 0 ? "default-selected" : ""} !w-full !h-10 `} type="checkbox" checked={isSelected || value == true ? true : false || value && value.includes("true") ? true : false} readOnly/>
        <span className={`${(isSelected || value == true || value && value.includes("true")) ? "pr-3" : ""}`}>
          {children}
        </span>
        {
          (isSelected || value == true || value && value.includes("true")) &&
          <button className="absolute right-2.5 z-50 mr-1" onClick={handleClearOption} type="button"><XIcon className="w-2 h-2" svgPathClassName="fill-cs-pink"/></button>
        }
      </label>
    </components.Option>
  );
};

export default Options;