import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { apiSlice } from "../../../store/apiSlice";
import AttractionTickets from "../attractions/AttractionTickets";
import Onboarding from "./Onboarding";
import { useEffect, useState } from "react";

export function DashboardTicket() {
  const { user } = useAppSelector((state) => state.global);
  const { attractionId } = useParams();

  const {
    data: getAttraction,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");

  const navigate = useNavigate()
  useEffect(() => {
    if (getAttraction?.result?.listing_package === 'BASIC') {
      navigate("/forbidden");

    }
  }, [getAttraction, navigate])

  useEffect(() => {
    if (user?.role === 'ATTRACTION_MANAGER') {
      navigate(`/attractions/${attractionId}/ticketing/scan`)
    }
  }, [user?.role, attractionId, navigate])

  return !!user?.ticketOnboardingUser ? <AttractionTickets /> : <Onboarding />;
}
