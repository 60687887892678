import Header from "../../components/global/amp/Header";
import Footer from "../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import { apiSlice } from "../../store/apiSlice";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import type { AmpUserDetail } from "../../types/user";
import { useNavigate, useParams } from "react-router-dom";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { ErrorMessage } from "../../components/global/ErrorMessage";
import Select from "react-select";
import { restartAnimation } from "../../components/global/amp/ToastNotification";
import MultiSelectCategoryOptionsSm from "../../lib/react-select/MultiSelectCategoryOptionsSm";
import RichTextEditor from "../../components/form/RichTextEditor";
import { textareaClassName } from "./attractions/AttractionFAQs";

export type CreateBrandPayload = Omit<Brands, "id"> & {
  id?: string | undefined;
};
const AddOrEditBrand: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const {
    data: getQuery,
    isSuccess: isQuerySuccess,
    isFetching,
    refetch,
  } = apiSlice.useGetBrandQuery({ id: id ?? "" }, { skip: !isEdit || !id });

  const [addBrand, { isLoading, isError, isSuccess, error }] =
    apiSlice.useAddBrandMutation();

  const schema = z.object({
    id: z.string().optional(),
    name: z.string().min(1, { message: "Required" }),
    website: z.string().url(),
    listing_ad_placement: z.enum(["DETAILED", "FLAG"]).nullable(),
    public_offer_html: z.string().optional(),
    public_offer_meta_json: z.object({
      page_title: z.string(),
      meta_description: z
        .string()
        .max(160, { message: "Maximum of 160 characters is allowed" }),
      discount_text: z
        .string()
        .max(20, { message: "Maximum of 20 characters is allowed" }),
      hero: z.object({
        subtitle: z.string(),
        title: z.string(),
        content: z.string(),
        background_image: z.object({
          url: z.string(),
          alt_text: z.string(),
        }),
      }),
      faqs: z
        .object({
          title: z.string(),
          content: z.string(),
        })
        .array(),
      offer_card: z.object({
        age_range_text: z.string(),
        image: z.object({
          url: z.string(),
          alt_text: z.string(),
        }),
      }),
    }),
    slug: z
      .string()
      .min(1, { message: "Required" })
      .regex(/^[a-z]+(-[a-z]+)*$/, {
        message: "Slug can only contain lowercase characters and -",
      }),

    link: z.string(),
  });

  type AddBrandForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    control,
    getValues,
    reset,
  } = useForm<AddBrandForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      listing_ad_placement: null,
      public_offer_meta_json: {
        faqs: [
          {
            title: "",
            content: "",
          },
        ],
      },
    },
  });

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const {
    fields: faqs,
    append: addFaq,
    remove: removeFaq,
  } = useFieldArray({
    control,
    name: "public_offer_meta_json.faqs",
  });

  useEffect(() => {
    document.title = "Add Brand | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Successful",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot add changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !(user as AmpUserDetail)?.email_verified &&
      (user as AmpUserDetail).role !== "ADMIN"
    ) {
      navigate("/dashboard");
    }
  }, []);

  async function onSubmit() {
    const payload: DeepPartial<CreateBrandPayload> = {
      id: isEdit ? id : undefined,
      link: getValues("link") || "",
      slug: getValues("slug") || "",
      name: getValues("name") || "",
      website: getValues("website") || "",
      listing_ad_placement: getValues("listing_ad_placement") || null,
      public_offer_html: getValues('public_offer_html') || "",
      public_offer_meta_json: {
        discount_text: getValues("public_offer_meta_json.discount_text"),
        page_title: getValues("public_offer_meta_json.page_title"),
        meta_description: getValues("public_offer_meta_json.meta_description"),
        offer_card: {
          age_range_text: getValues(
            "public_offer_meta_json.offer_card.age_range_text"
          ),
          image: {
            url: getValues("public_offer_meta_json.offer_card.image.url"),
            alt_text: getValues(
              "public_offer_meta_json.offer_card.image.alt_text"
            ),
          },
        },
        faqs: getValues("public_offer_meta_json.faqs").map((f) => ({
          title: f.title,
          content: f.content,
        })),
        hero: {
          content: getValues("public_offer_meta_json.hero.content"),
          title: getValues("public_offer_meta_json.hero.title"),
          subtitle: getValues("public_offer_meta_json.hero.subtitle"),
          background_image: {
            url: getValues("public_offer_meta_json.hero.background_image.url"),
            alt_text: getValues(
              "public_offer_meta_json.hero.background_image.alt_text"
            ),
          },
        },
      },
    };

    const results = await addBrand(payload);

    if ("data" in results && !isEdit) {
      navigate(`/brands/${results.data.id}`);
    }

    await refetch();
    restartAnimation();
  }

  useEffect(() => {
    if (getQuery && isQuerySuccess && !isFetching) {
      reset(
        {
          id: getQuery.id || "",
          link: getQuery.link || "",
          slug: getQuery.slug || "",
          name: getQuery.name || "",
          website: getQuery.website || "",
          listing_ad_placement: getQuery.listing_ad_placement || null,
          public_offer_html: getQuery.public_offer_html || "",
          public_offer_meta_json: {
            discount_text: getQuery.public_offer_meta_json?.discount_text || "",
            page_title: getQuery.public_offer_meta_json?.page_title || "",
            meta_description:
              getQuery.public_offer_meta_json?.meta_description || "",
            offer_card: {
              age_range_text:
                getQuery.public_offer_meta_json?.offer_card?.age_range_text ||
                "",
              image: {
                url:
                  getQuery.public_offer_meta_json?.offer_card?.image?.url || "",
                alt_text:
                  getQuery.public_offer_meta_json?.offer_card?.image
                    ?.alt_text || "",
              },
            },
            faqs: getQuery.public_offer_meta_json?.faqs || [
              {
                title: "",
                content: "",
              },
            ],
            hero: {
              content: getQuery.public_offer_meta_json?.hero?.content || "",
              title: getQuery.public_offer_meta_json?.hero?.title || "",
              subtitle: getQuery.public_offer_meta_json?.hero?.subtitle || "",
              background_image: {
                url:
                  getQuery.public_offer_meta_json?.hero?.background_image
                    ?.url || "",
                alt_text:
                  getQuery.public_offer_meta_json?.hero?.background_image
                    ?.alt_text || "",
              },
            },
          },
        },
        {
          keepDirty: false,
        }
      );
    }
  }, [getQuery, isFetching]);

  const watchedDiscountText = useWatch({
    control,
    name: "public_offer_meta_json.discount_text",
  });
  const watchedDiscountTextCharCount = watchedDiscountText?.length ?? 0;

  const listingAdPlacementOptions = [
    {
      value: null,
      label: "None",
    },
    {
      value: "DETAILED",
      label: "Detailed",
    },
    {
      value: "FLAG",
      label: "Flag",
    },
  ];
  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA]">
      <Header title={!isEdit ? "Add Brand" : "Edit Brand"} showBack />

      <SingleAttractionTemplate name={"Brand"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 xl:w-2/5 gap-y-5">
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"name"}
              label="Name"
              required
              inputProps={{ ...register("name") }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"website"}
              label="Website"
              required
              inputProps={{ ...register("website") }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"slug"}
              label="Slug"
              required
              inputProps={{ ...register("slug") }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"link"}
              label="Link"
              required
              readonly
              inputProps={{ ...register("link") }}
            />

            <div>
              <div className="flex items-center font-bold text-sm mb-2">
                Listing Ad Placement
              </div>
              <Controller
                control={control}
                name="listing_ad_placement"
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      onChange={(value, meta) => {
                        onChange(value?.value ?? null);
                      }}
                      value={listingAdPlacementOptions.find(
                        (v) => v.value === value
                      )}
                      defaultValue={null}
                      isClearable={false}
                      closeMenuOnSelect={false}
                      menuIsOpen={true}
                      hideSelectedOptions={false}
                      options={listingAdPlacementOptions}
                      components={{ Option: MultiSelectCategoryOptionsSm }}
                      placeholder={""}
                      styles={{
                        multiValue: (styles) => ({
                          ...styles,
                          backgroundColor: "#6836D1",
                          borderRadius: 20,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                        }),
                        multiValueRemove: () => ({
                          display: "none",
                        }),
                        multiValueLabel: (styles) => ({
                          ...styles,
                          color: "#FFF",
                          paddingLeft: 10,
                          paddingRight: 10,
                          fontSize: 14,
                          fontWeight: 400,
                        }),
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          paddingLeft: 5,
                          border: "0",
                          overflow: "hidden",
                          boxShadow: "none",
                          display: "flex",
                          width: "100%",
                          position: "relative",
                        }),
                        menuList: (base) => ({
                          ...base,
                          marginBottom: 0,
                          overflow: "hidden",
                          display: "flex",
                          gap: 12,
                        }),
                        option: (base) => ({
                          ...base,
                          background: "red",
                          padding: "0",
                        }),
                        noOptionsMessage: (base) => ({
                          ...base,
                          display: "none",
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          display: "none",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        background: "none",
                        colors: {
                          ...theme.colors,
                          neutral80: "#555",
                        },
                      })}
                    />
                    {error && <ErrorMessage>{error.message}</ErrorMessage>}
                  </>
                )}
              />
            </div>
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.page_title"}
              label="Page Title"
              required
              inputProps={{
                ...register("public_offer_meta_json.page_title"),
              }}
            />

            <div>
              <h4 className="text-black font-bold text-sm flex items-center justify-between gap-1 mb-2">
                Discount Text{" "}
                <span
                  className={`text-sm font-bold ${
                    watchedDiscountTextCharCount > 20
                      ? "text-red-500"
                      : "text-cs-gray"
                  }`}
                >
                  {watchedDiscountTextCharCount}/ Max 20
                </span>
              </h4>

              <TextInput
                className="group"
                labelClassName="group-focus-within:text-cs-pink font-bold"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
                name={"public_offer_meta_json.discount_text"}
                label=""
                required
                inputProps={{
                  ...register("public_offer_meta_json.discount_text"),
                }}
              />
            </div>

            <Controller
              control={control}
              name={"public_offer_meta_json.meta_description"}
              render={({ field: { ref, value, onChange } }) => (
                <>
                  <div className="flex items-center font-bold text-sm">
                    Meta Description
                  </div>
                  <textarea
                    value={value ?? ""}
                    onChange={onChange}
                    placeholder=""
                    className="text-cs-gray placeholder:text-sm text-md p-3 w-full border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40"
                  ></textarea>
                  <p className="text-[#5F646D] text-sm text-right w-full mt-2">
                    {(value?.length ?? 0) + " / 160"}
                  </p>
                  {errors["public_offer_meta_json"]?.["meta_description"] && (
                    <ErrorMessage>
                      {
                        errors["public_offer_meta_json"]["meta_description"]
                          .message
                      }
                    </ErrorMessage>
                  )}
                </>
              )}
            />

            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.offer_card.age_range_text"}
              label="Offer Card Age Range Text"
              required
              inputProps={{
                ...register("public_offer_meta_json.offer_card.age_range_text"),
              }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.offer_card.image.url"}
              label="Offer Card Image"
              required
              inputProps={{
                ...register("public_offer_meta_json.offer_card.image.url"),
              }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.offer_card.image.alt_text"}
              label="Offer Card Image Alt"
              required
              inputProps={{
                ...register("public_offer_meta_json.offer_card.image.alt_text"),
              }}
            />

            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.hero.title"}
              label="Hero Title"
              required
              inputProps={{
                ...register("public_offer_meta_json.hero.title"),
              }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.hero.subtitle"}
              label="Hero Subtitle"
              required
              inputProps={{
                ...register("public_offer_meta_json.hero.subtitle"),
              }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.hero.content"}
              label="Hero Content"
              required
              inputProps={{
                ...register("public_offer_meta_json.hero.content"),
              }}
            />

            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.hero.background_image.url"}
              label="Hero Image URL"
              required
              inputProps={{
                ...register("public_offer_meta_json.hero.background_image.url"),
              }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"public_offer_meta_json.hero.background_image.alt_text"}
              label="Hero Image Alt Text"
              required
              inputProps={{
                ...register(
                  "public_offer_meta_json.hero.background_image.alt_text"
                ),
              }}
            />

            <div>
              <div className="flex items-center font-bold text-sm">
                Public Offer HTML
              </div>

              <div className="relative w-full ">
                <textarea
                  {...control.register("public_offer_html")}
                  placeholder=""
                  className={`${textareaClassName} ${
                    errors["public_offer_html"] ? "border-cs-red" : ""
                  }`}
                ></textarea>

                {errors["public_offer_html"] && (
                  <ErrorMessage>
                    {errors["public_offer_html"].message}
                  </ErrorMessage>
                )}
              </div>
            </div>

            <div className="flex items-center font-bold text-sm">FAQs</div>
            {faqs.map((f, i) => (
              <div
                key={JSON.stringify(f) + i.toString()}
                className="relative inline-flex gap-10 mb-5"
              >
                <div className="grid grid-cols-1 min-w-[300px] w-[500px] max-w-full gap-2.5">
                  <TextInput
                    className={`group`}
                    labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
                    inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                    control={control}
                    name={`public_offer_meta_json.faqs.${i}.title`}
                    label={"Title"}
                    inputProps={{
                      ...register(`public_offer_meta_json.faqs.${i}.title`),
                      placeholder: "",
                      type: "string",
                    }}
                    hideLabelMargin={true}
                  />

                  <Controller
                    control={control}
                    name={`public_offer_meta_json.faqs.${i}.content`}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <RichTextEditor
                        label="Content"
                        charLabel={""}
                        initialValue={value || ""}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>

                {i === 0 && (
                  <button
                    type="button"
                    className="flex-grow self-center flex items-center justify-start"
                    onClick={() =>
                      addFaq({
                        title: "",
                        content: "",
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      className="mr-2 mt-6"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
                      />
                    </svg>
                  </button>
                )}

                {i !== 0 && (
                  <button
                    type="button"
                    className="flex-grow self-center flex items-center justify-start"
                    onClick={() => removeFaq(i)}
                  >
                    <svg
                      className="mr-2 mt-6"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5z"
                      ></path>
                    </svg>
                  </button>
                )}
              </div>
            ))}
          </fieldset>

          <PrimaryButton type="submit" loading={isLoading} scale="sm">
            {isLoading ? (
              <>
                <span className="flex justify-center">
                  <svg
                    className="w-5 h-5 mr-3 animate-spin text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Uploading
                </span>
              </>
            ) : !isEdit ? (
              "Add Brand"
            ) : (
              "Save Changes"
            )}
          </PrimaryButton>

          {error && "data" in error && (
            <ErrorMessage>
              {error?.data?.message?.replaceAll('"', "") ?? ""}
            </ErrorMessage>
          )}
        </form>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AddOrEditBrand;
