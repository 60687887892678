import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "../../../form/TextInput";
import { z } from "zod";
import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiSlice } from "../../../../store/apiSlice";
import { Description, Heading } from "../../TwoColTemplate";
import { PrimaryButton } from "../../PrimaryButton";
import { ErrorMessage } from "../../ErrorMessage";

interface ForgottenPasswordForm {
  email: string;
}

type Props = {
  setSubmittedModal: (showModal: boolean) => void;
};

export default function ForgottenPasswordModal({ setSubmittedModal }: Props) {
  const schema = z.object({
    email: z
      .string()
      .min(1, "Email is required")
      .email("Enter a valid email address"),
  });

  const [requestPasswordReset, requestPasswordResetQueryData] =
    apiSlice.usePasswordResetRequestMutation();
  const { isError, isSuccess, isLoading } = requestPasswordResetQueryData;

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isSubmitted },
    control,
  } = useForm<ForgottenPasswordForm>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<ForgottenPasswordForm> = (e) => {
    requestPasswordReset(e.email);
  };

  useEffect(() => {
    if (isSuccess) {
      setSubmittedModal(true);
    }
  }, [isError, isSuccess]);

  return (
    <div className="flex flex-col w-full">
      <Heading>Forgot your password?</Heading>
      <Description>
        Don't worry! Tell us the email address linked to your account, and we'll
        send you instructions on how to reset your password.
      </Description>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-5">
          <TextInput
            className="group"
            labelClassName=""
            inputClassName="border text-black rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
            control={control}
            name={"email"}
            required
            label="Email address"
            inputProps={{ ...register("email") }}
          />
        </div>

        <PrimaryButton type="submit" loading={isLoading}>
          Reset password
        </PrimaryButton>

        {isSubmitted && !isValid && (
          <ErrorMessage className="mt-3">
            Please fix the errors indicated above to continue
          </ErrorMessage>
        )}
      </form>
    </div>
  );
}
