import React, { useEffect, useRef, useState } from "react";
import { CellProps, Column, TableInstance, useGlobalFilter, usePagination, UsePaginationInstanceProps, UsePaginationState, useSortBy, UseSortByInstanceProps, useTable } from "react-table";
import { AmpUserDetail } from "../../types/user";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../lib/react-table/Table";
import { TableTextNormal } from "../../components/global/TableTextNormal";
import moment from "moment";
import ContextMenu from "../../components/global/ContextMenu";
import { apiSlice } from "../../store/apiSlice";
import DashboardHeaderContainer from "../../components/amp/DashboardHeaderContainer";
import { SearchField } from "../../components/global/SearchField";
import DashboardContainer from "../../components/amp/DashboardContainer";
import Footer from "../../components/global/amp/Footer";
import { AmpBlogDetail } from "../../../../client-core/src/types/blog";

interface IDiaries{
  activeTab: string;
  setActiveTab: (val: string) => void
}

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
};

const returnBlogStatus = (status: string) => {
  return(
    <div 
      className="rounded-lg px-3 py-1.5 text-black text-sm flex justify-center items-center whitespace-pre-line"
      style={{ background: 
        status === "Incomplete" || status === "Pending" ? "#F1F1F1" : status === "Published" ? "#C9F9AD" :
        status === "Declined" ? "#FFA1A3" : ""

      }}
    >
      {
        status === "Incomplete" ? "Incomplete" : status === "Pending" ? "Awaiting \n Approval" : status === "Published" ? "Approved" :
        status === "Declined" ? "Declined" : ""
      }
    </div>
  )
}

const Diaries: React.FC<IDiaries> = ({ activeTab, setActiveTab }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
    const [getQueryPage, setQueryPage] = useState<string>(
      searchParams.get("page")
        ? parseInt(searchParams?.get("page") ?? "0").toString()
        : "0"
    );
    const [getQuerySortBy, setQuerySortBy] = useState<string>("first_name");
    const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
    const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");
    const [featured, setShowFeatured] = useState<string>("false");

    const prevQuerySearch = useRef(getQuerySearchQuery);

    const { data: tableData, isFetching } = apiSlice.useAdminContentBlogsQuery({
      pageSize: getQueryPageSize,
      page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
      sortBy: getQuerySortBy,
      query: getQuerySearchQuery,
      sortDesc: getQuerySortDesc,
      isFeatured: featured
    });

    const columns = React.useMemo<Column<AmpBlogDetail>[]>(
        () =>[
              {
                  Header: "Diary",
                  accessor: "title",
                  width: "auto",
                  Cell: ({ row: { original } }: CellProps<AmpBlogDetail>) => {
                  return (
                      <>
                      <div>
                          <TableTextNormal className="w-full">
                          {original.last_modified_content?.title}
                          </TableTextNormal>
                      </div>
                      </>
                  );
                  },
              },
              {
                  Header: "Submitted",
                  accessor: "date_submitted",
                  width: "15%",
                  Cell: ({ row: { original } }: CellProps<AmpBlogDetail>) => {
                  return (
                      <>
                        <div className="w-[8rem]">
                          <TableTextNormal className="w-full text-center">
                              {original.last_modified_content?.date_submitted
                              ? moment(original.last_modified_content?.date_submitted).format(
                                  "DD MMM YYYY"
                                  )
                              : "--"}
                          </TableTextNormal>
                          </div>
                      </>
                  );
                  },
              },
              {
                Header: "Attraction",
                accessor: "attraction_name",
                width: "15%",
                Cell: ({ row: { original } }: CellProps<AmpBlogDetail>) => {
                  return(
                    <>
                    <div className="w-[7rem]">
                          <TableTextNormal className="w-full text-center">
                          {original.attraction_name}
                          </TableTextNormal>
                      </div>
                    </>
                  )
                }
              },
              {
                  Header: "First Name",
                  accessor: "first_name",
                  width: "15%",
                  Cell: ({ row: { original } }: CellProps<AmpBlogDetail>) => {
                  return (
                      <>
                      <div className="w-[7rem]">
                          <TableTextNormal className="w-full text-center">
                          {original.created_by_user?.first_name}
                          </TableTextNormal>
                      </div>
                      </>
                  );
                  },
              },
              {
                  Header: "Last Name",
                  accessor: "last_name",
                  width: "15%",
                  Cell: ({ row: { original } }: CellProps<AmpBlogDetail>) => {
                  return (
                      <>
                      <div className="w-[7rem]">
                          <TableTextNormal className="w-full text-center">
                          {original.created_by_user?.last_name}
                          </TableTextNormal>
                      </div>
                      </>
                  );
                  },
              },
              {
                  Header: "Status",
                  accessor: "status",
                  width: "15%",
                  Cell: ({ row: { original } }: CellProps<AmpBlogDetail>) => {
                  return (
                      <>
                        <div className="w-[7rem] text-center">
                          {returnBlogStatus(original?.last_modified_content?.content_status ?? "")}
                        </div>
                      </>
                  );
                  },
              },
              {
                  Header: "",
                  accessor: "id",
                  disableSortBy: true,
                  Cell: ({ row: { original } }: CellProps<AmpBlogDetail>) => {
                    return (
                      <ContextMenu
                          options={[
                            {
                              name: "View / Edit",
                              linkProps: {
                                to: {
                                  pathname: `/blogs/${original.blog_id}`
                                },
                              },
                            },
                          ]}
                        />
                    );
                  },
                  disableGlobalFilter: true,
                  width: "7.35%",
              },
            ]
        ,[]
    );
    
    const data = React.useMemo<AmpBlogDetail[]>(
      () => 
        tableData?.results 
        ? tableData.results.map(result => ({
            ...result,
            id: Math.random(),
            title: result.last_modified_content?.title,
            date_submitted: result.last_modified_content?.date_submitted,
            first_name: result.created_by_user?.first_name,
            last_name: result.created_by_user?.last_name,
            status: result.last_modified_content?.content_status,
          }))
        : ([] as any),
      [tableData]
    );

    const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      setGlobalFilter(value);
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      setGlobalFilter,
      pageOptions,
      gotoPage,
      setPageSize,
      setSortBy,
      state: { pageSize, pageIndex, sortBy, globalFilter },
    } = useTable<any>(
      {
        columns,
        data,
        autoResetPage: false,
        manualPagination: true,
        manualSortBy: true,
        pageCount: tableData?.total,
        disableSortRemove: true,
        initialState: {
          hiddenColumns: [],
          pageSize:
            parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
          pageIndex: parseInt(getQueryPage),
          globalFilter: searchParams.get("query") || getQuerySearchQuery,
          sortBy: [
            {
              id: searchParams.get("sortBy") || getQuerySortBy,
              desc:
                searchParams.get("sortDesc") === "true" ||
                getQuerySortDesc !== "false",
            },
          ],
        },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
  ) as TableInstanceWithHooks<any>;

    useEffect(() => {
      if (!isFetching) {
        setSearchParams(
          {
            pageSize: pageSize.toString(),
            page: pageIndex.toString(),
            query: globalFilter ? globalFilter : "",
            sortBy: sortBy[0].id.toLowerCase(),
            sortDesc: sortBy[0].desc!.toString(),
            isFeatured: featured
          },
          { replace: JSON.stringify(searchParams) === "{}" }
        );
  
        setQueryPageSize(pageSize.toString());
        setQueryPage(pageIndex.toString());
        setQuerySearchQuery(globalFilter ? globalFilter : "");
        setQuerySortBy(sortBy[0].id.toLowerCase());
        setQuerySortDesc(sortBy[0].desc!.toString());
      }
    }, [
      pageSize,
      pageIndex,
      sortBy[0].id,
      globalFilter,
      sortBy[0].desc,
      isFetching,
      featured
    ]);

    const navigate = useNavigate();

    const handleRowClick = (row: AmpBlogDetail) => {
      navigate(`/blogs/${row.blog_id}`);
    };

    return(
      <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
        <DashboardHeaderContainer slim={true}>
            <div className="flex items-center justify-between flex-grow">
                <h1 className="text-xl font-extrabold text-black">Family Creator Network</h1>
                <div className="relative w-full max-w-cs-320">
                    <SearchField
                        parentClassName="max-w-full w-[437px] ml-auto"
                        defaultValue={searchParams.get("query") ?? ""}
                        onChange={handleFilterInputChange}
                        placeholder={`Search for a diary`}
                    />
                </div>
            </div>
        </DashboardHeaderContainer>
        <DashboardContainer>
            <div className="flex gap-x-3 px-5 xl:px-[20px]">
                <button 
                    className={`border border-black rounded-lg px-4 py-2 text-sm font-semibold ${activeTab === "Diaries" ? "bg-cs-pink border-cs-pink text-white" : ""}`}
                    onClick={() => { setActiveTab("Diaries"); setSearchParams("")}}
                >
                    All Diaries
                </button>
                <button 
                    className={`border border-black rounded-lg px-4 py-2 text-sm font-semibold ${activeTab === "Creators" ? "bg-cs-pink border-cs-pink text-white" : ""}`}
                    onClick={() => {setActiveTab("Creators"); setSearchParams("")}}
                >
                    All Creators
                </button>

                <label className="ml-auto flex items-center justify-center py-1.5 text-sm font-bold bg-transparent border border-black rounded-lg w-cs-145">                    
                    <input
                        className="mt-0.5 mr-2"
                        checked={featured == "true"}
                        onChange={(e) => {
                            setShowFeatured(e.target.checked ? "true" : "false");
                        }}
                        type="checkbox"
                    />
                    <span>Featured</span>                    
                </label>
            </div>
          <section className="flex flex-col pt-4 px-5 xl:px-[20px]">
              <div id="diariesTable" className="max-w-[calc(100vw-3rem)]">
                  <Table<AmpBlogDetail>
                  // <table id>
                  tableId="diariesTable"
                  // <table className>
                  tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
                  // <thead className>
                  tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl [&:nth-last-child(2)]:rounded-r-xl"
                  // <tbody className>
                  tableTbodyClassNames={`relative ${
                      page.length === 0 ? "h-[60px]" : ""
                  }`}
                  // Mobile <tbody className>
                  tableTbodyMobileClassNames="block"
                  // <tbody><tr className>
                  tableTbodyTrClassNames="h-1 last-of-type:hidden cursor-pointer"
                  // Empty <tbody><tr className>
                  tableTbodyTrEmptyMobileClassNames="border-none"
                  // Mobile <tbody><tr className>
                  tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative border-2 border-cs-off-white font-sm font-normal text-black rounded-xl p-3 bg-white"
                  // <tbody><tr><td className>
                  tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
                  // Empty <tbody><tr><td className>
                  tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
                  // Mobile <tbody><tr><td className>
                  tableTbodyTrEmptyTdMobileClassNames="h-2"
                  // Hide Columns On Mobile
                  hideColumnOnMobile={[]}
                  fullWidthMobileTd={[]}
                  spacedMobileTd={[]}
                  // react-table props
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  headerGroups={headerGroups}
                  page={page}
                  pages={tableData?.total}
                  prepareRow={prepareRow}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  pageOptions={pageOptions}
                  gotoPage={gotoPage}
                  setPageSize={setPageSize}
                  isFetching={isFetching}
                  rowClick={handleRowClick}
                  />
              </div>
          </section>
        </DashboardContainer>
        <Footer />
      </div>
    )
}

export default Diaries;