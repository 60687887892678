import { useEffect, type ReactNode } from "react";
import DashboardContainer from "../amp/DashboardContainer";
import { useDispatch } from "react-redux";
import { walkthroughActions } from "../../store/walkthroughSlice";
import {
  clearNewAttractionPermission,
  getNewAttractionPermissionFromStorage,
  initiateNewAttractionPermission,
} from "../../helpers/newAttractionsTabPermission";
import { useAppSelector } from "../../store";
import { apiSlice } from "../../store/apiSlice";

export const className = "px-6 pt-8 pb-8 bg-white xl:pt-6 xl:rounded-lg";

const containerClassName = className;
export function SingleAttractionTemplate({
  children,
  name,
  className = "",
  isAttraction = false,
  doneWalkthrough = undefined,
  result = undefined,
  attractionId = "",
  unstyled = false,
  forceShowTitle = false,
  beforeContainer,
}: {
  children: ReactNode;
  name: string;
  className?: string;
  isAttraction?: boolean;
  doneWalkthrough?: boolean | null;
  result?: AttractionDetails;
  attractionId?: string;
  unstyled?: boolean;
  forceShowTitle?: boolean;
  beforeContainer?: ReactNode;
}) {
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.global);

  const {
    data: getAttraction,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  useEffect(() => {
    if (!getAttraction) return;
    if (doneWalkthrough !== true && isAttraction && user?.role !== "ADMIN") {
      const isClaim = getAttraction?.result?.ownership_claim?.created_by_user?.email !== getAttraction?.result?.creation_claim?.created_by_user?.email;
      dispatch(walkthroughActions.setWalkthroughType(isClaim ? "newClaimedAttraction" : "newAttraction"));
      dispatch(walkthroughActions.setWalkthroughStatus("startModal"));
    }
  }, [doneWalkthrough, isAttraction, dispatch, user?.role, getAttraction]);

  // const isNew = !result?.published_at;
  const isNew = !getAttraction?.result?.finished_onboarding;
  useEffect(() => {
    if (!isAttraction || !result || !attractionId) return;

    const permissionData = getNewAttractionPermissionFromStorage(attractionId);

    if (!result.short_id) return;

    if (!permissionData && isNew) {
      initiateNewAttractionPermission(attractionId);
    }

    if (result?.short_id && !isNew) {
      clearNewAttractionPermission(attractionId);
    }
  }, [result, attractionId, isAttraction, isNew]);
  return (
    <DashboardContainer>
      <div className={`${className ? className : "xl:mt-24 xl:mb-24"}`}>
        {beforeContainer}
        <section
          id={isAttraction ? "editAttractionContainer" : undefined}
          className={`flex flex-col ${
            unstyled ? "" : containerClassName
          } xl:mx-[70px]`}
        >
          {((name && !isNew) || forceShowTitle) && (
            <h2 className={`text-[22px] font-extrabold text-black ${name ? 'mb-4' : ''}`}>
              {name}
            </h2>
          )}

          {children}
        </section>
      </div>
    </DashboardContainer>
  );
}
