import { useCallback, useEffect, useRef, useState } from "react";
import {
  NavLink,
  useLocation,
  useMatches,
  useNavigate,
  useParams,
} from "react-router-dom";
import { attractionRoutes } from "../../../App";
import Footer from "../../../components/global/amp/Footer";
import Header from "../../../components/global/amp/Header";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { useAppSelector } from "../../../store";
import { isTabActive } from "../../../helpers/newAttractionsTabPermission";
import { LockIcon, icons } from "../../../components/WithNavigationBar";
import { apiSlice } from "../../../store/apiSlice";
import {
  clearNewAttractionLeftOverTab,
  getNewAttractionWhichTabLeftOver,
} from "../../../helpers/newAttractionsFinishLater";

export default function AttractionMain() {
  const location = useLocation();
  const navigate = useNavigate();
  const windowSize = useWindowResize();
  const state = useAppSelector((state) => state);
  const { user } = useAppSelector((state) => state.global);
  const matches = useMatches();
  const parentRoute = matches[matches.length > 1 ? matches.length - 2 : 0];
  const fromPage = location.state?.from as string | undefined;
  const claimData = location?.state?.claim ?? undefined;
  const isTicketOnboarding = !!location.state?.isTicketing;
  const { attractionId } = useParams();
  const isLoggedIn = !!user;

  const TicketingNav = useCallback(() => {
    const isLocked = !user?.ticketOnboardingUser;
    return (
      <>
        {isLoggedIn && (
          <NavLink
            to={`/attractions/${attractionId}`}
            state={{ isTicketing: false }}
            className="relative flex items-center py-[11px] px-[13px] text-black bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
            Back to Overview
          </NavLink>
        )}
        {isLoggedIn ? (
          <>
            {user.role !== "ATTRACTION_MANAGER" && (
              <>
                <NavLink
                  end
                  className={({ isActive }) =>

                    `relative flex items-center gap-3 py-[11px] px-[14px] ${
                      isActive
                        ? "bg-[#E9E9F2] before:h-[38px] before:w-[1px] before:bg-[#FA2080] before:absolute before:-right-6 before:border before:border-[#FA2080]"
                        : "bg-transparent"
                    } hover:bg-[#E9E9F2] text-black rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px]`
                  }
                  to={`/attractions/${attractionId}/ticketing`}
                >
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.421143 1.44737C0.421143 1.19611 0.520954 0.955144 0.69862 0.777478C0.876286 0.599812 1.11725 0.5 1.36851 0.5H10.8422C11.0935 0.5 11.3344 0.599812 11.5121 0.777478C11.6898 0.955144 11.7896 1.19611 11.7896 1.44737C11.7896 1.69863 11.6898 1.93959 11.5121 2.11726C11.3344 2.29493 11.0935 2.39474 10.8422 2.39474H1.36851C1.11725 2.39474 0.876286 2.29493 0.69862 2.11726C0.520954 1.93959 0.421143 1.69863 0.421143 1.44737ZM0.421143 11.5526C0.421143 11.3014 0.520954 11.0604 0.69862 10.8827C0.876286 10.7051 1.11725 10.6053 1.36851 10.6053H9.57904C9.8303 10.6053 10.0713 10.7051 10.2489 10.8827C10.4266 11.0604 10.5264 11.3014 10.5264 11.5526C10.5264 11.8039 10.4266 12.0449 10.2489 12.2225C10.0713 12.4002 9.8303 12.5 9.57904 12.5H1.36851C1.11725 12.5 0.876286 12.4002 0.69862 12.2225C0.520954 12.0449 0.421143 11.8039 0.421143 11.5526ZM1.36851 5.55263C1.11725 5.55263 0.876286 5.65244 0.69862 5.83011C0.520954 6.00778 0.421143 6.24874 0.421143 6.5C0.421143 6.75126 0.520954 6.99222 0.69862 7.16989C0.876286 7.34756 1.11725 7.44737 1.36851 7.44737H14.6317C14.8829 7.44737 15.1239 7.34756 15.3016 7.16989C15.4792 6.99222 15.579 6.75126 15.579 6.5C15.579 6.24874 15.4792 6.00778 15.3016 5.83011C15.1239 5.65244 14.8829 5.55263 14.6317 5.55263H1.36851Z"
                      fill="#212121"
                    />
                  </svg>
                  Ticket Dashboard
                </NavLink>

                <NavLink
                  end
                  state={{ from: location.pathname }}
                  className={({ isActive }) =>
                    `relative flex items-center gap-3 py-[11px] px-[13px] bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px] ${
                      isLocked
                        ? "text-[#979797] pointer-events-none cursor-not-allowed"
                        : "text-black"
                    }`
                  }
                  to={`/attractions/${attractionId}/ticketing/manage`}
                >
                  {isLocked ? (
                    <LockIcon />
                  ) : (
                    <svg
                      width="16"
                      height="13"
                      viewBox="0 0 16 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.421143 1.44737C0.421143 1.19611 0.520954 0.955144 0.69862 0.777478C0.876286 0.599812 1.11725 0.5 1.36851 0.5H10.8422C11.0935 0.5 11.3344 0.599812 11.5121 0.777478C11.6898 0.955144 11.7896 1.19611 11.7896 1.44737C11.7896 1.69863 11.6898 1.93959 11.5121 2.11726C11.3344 2.29493 11.0935 2.39474 10.8422 2.39474H1.36851C1.11725 2.39474 0.876286 2.29493 0.69862 2.11726C0.520954 1.93959 0.421143 1.69863 0.421143 1.44737ZM0.421143 11.5526C0.421143 11.3014 0.520954 11.0604 0.69862 10.8827C0.876286 10.7051 1.11725 10.6053 1.36851 10.6053H9.57904C9.8303 10.6053 10.0713 10.7051 10.2489 10.8827C10.4266 11.0604 10.5264 11.3014 10.5264 11.5526C10.5264 11.8039 10.4266 12.0449 10.2489 12.2225C10.0713 12.4002 9.8303 12.5 9.57904 12.5H1.36851C1.11725 12.5 0.876286 12.4002 0.69862 12.2225C0.520954 12.0449 0.421143 11.8039 0.421143 11.5526ZM1.36851 5.55263C1.11725 5.55263 0.876286 5.65244 0.69862 5.83011C0.520954 6.00778 0.421143 6.24874 0.421143 6.5C0.421143 6.75126 0.520954 6.99222 0.69862 7.16989C0.876286 7.34756 1.11725 7.44737 1.36851 7.44737H14.6317C14.8829 7.44737 15.1239 7.34756 15.3016 7.16989C15.4792 6.99222 15.579 6.75126 15.579 6.5C15.579 6.24874 15.4792 6.00778 15.3016 5.83011C15.1239 5.65244 14.8829 5.55263 14.6317 5.55263H1.36851Z"
                        fill="#212121"
                      />
                    </svg>
                  )}
                  Add / Manage Tickets
                </NavLink>
              </>
            )}

            <NavLink
              end
              state={{ from: location.pathname }}
              className={({ isActive }) =>
                `relative flex items-center gap-3 py-[11px] px-[13px] bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px] ${
                  isLocked
                    ? "text-[#979797] pointer-events-none cursor-not-allowed"
                    : "text-black"
                }`
              }
              to={`/attractions/${attractionId}/ticketing/scan`}
            >
              {isLocked ? (
                <LockIcon />
              ) : (
                <svg
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.421143 1.44737C0.421143 1.19611 0.520954 0.955144 0.69862 0.777478C0.876286 0.599812 1.11725 0.5 1.36851 0.5H10.8422C11.0935 0.5 11.3344 0.599812 11.5121 0.777478C11.6898 0.955144 11.7896 1.19611 11.7896 1.44737C11.7896 1.69863 11.6898 1.93959 11.5121 2.11726C11.3344 2.29493 11.0935 2.39474 10.8422 2.39474H1.36851C1.11725 2.39474 0.876286 2.29493 0.69862 2.11726C0.520954 1.93959 0.421143 1.69863 0.421143 1.44737ZM0.421143 11.5526C0.421143 11.3014 0.520954 11.0604 0.69862 10.8827C0.876286 10.7051 1.11725 10.6053 1.36851 10.6053H9.57904C9.8303 10.6053 10.0713 10.7051 10.2489 10.8827C10.4266 11.0604 10.5264 11.3014 10.5264 11.5526C10.5264 11.8039 10.4266 12.0449 10.2489 12.2225C10.0713 12.4002 9.8303 12.5 9.57904 12.5H1.36851C1.11725 12.5 0.876286 12.4002 0.69862 12.2225C0.520954 12.0449 0.421143 11.8039 0.421143 11.5526ZM1.36851 5.55263C1.11725 5.55263 0.876286 5.65244 0.69862 5.83011C0.520954 6.00778 0.421143 6.24874 0.421143 6.5C0.421143 6.75126 0.520954 6.99222 0.69862 7.16989C0.876286 7.34756 1.11725 7.44737 1.36851 7.44737H14.6317C14.8829 7.44737 15.1239 7.34756 15.3016 7.16989C15.4792 6.99222 15.579 6.75126 15.579 6.5C15.579 6.24874 15.4792 6.00778 15.3016 5.83011C15.1239 5.65244 14.8829 5.55263 14.6317 5.55263H1.36851Z"
                    fill="#212121"
                  />
                </svg>
              )}
              Scan / Redeem Ticket
            </NavLink>

            <NavLink
              end
              state={{ from: location.pathname }}
              className={({ isActive }) =>
                `relative flex items-center gap-3 py-[11px] px-[13px] bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px] ${
                  isLocked
                    ? "text-[#979797] pointer-events-none cursor-not-allowed"
                    : "text-black"
                }`
              }
              to={`/attractions/${attractionId}/ticketing/reporting`}
            >
              {isLocked ? (
                <LockIcon />
              ) : (
                <svg
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.421143 1.44737C0.421143 1.19611 0.520954 0.955144 0.69862 0.777478C0.876286 0.599812 1.11725 0.5 1.36851 0.5H10.8422C11.0935 0.5 11.3344 0.599812 11.5121 0.777478C11.6898 0.955144 11.7896 1.19611 11.7896 1.44737C11.7896 1.69863 11.6898 1.93959 11.5121 2.11726C11.3344 2.29493 11.0935 2.39474 10.8422 2.39474H1.36851C1.11725 2.39474 0.876286 2.29493 0.69862 2.11726C0.520954 1.93959 0.421143 1.69863 0.421143 1.44737ZM0.421143 11.5526C0.421143 11.3014 0.520954 11.0604 0.69862 10.8827C0.876286 10.7051 1.11725 10.6053 1.36851 10.6053H9.57904C9.8303 10.6053 10.0713 10.7051 10.2489 10.8827C10.4266 11.0604 10.5264 11.3014 10.5264 11.5526C10.5264 11.8039 10.4266 12.0449 10.2489 12.2225C10.0713 12.4002 9.8303 12.5 9.57904 12.5H1.36851C1.11725 12.5 0.876286 12.4002 0.69862 12.2225C0.520954 12.0449 0.421143 11.8039 0.421143 11.5526ZM1.36851 5.55263C1.11725 5.55263 0.876286 5.65244 0.69862 5.83011C0.520954 6.00778 0.421143 6.24874 0.421143 6.5C0.421143 6.75126 0.520954 6.99222 0.69862 7.16989C0.876286 7.34756 1.11725 7.44737 1.36851 7.44737H14.6317C14.8829 7.44737 15.1239 7.34756 15.3016 7.16989C15.4792 6.99222 15.579 6.75126 15.579 6.5C15.579 6.24874 15.4792 6.00778 15.3016 5.83011C15.1239 5.65244 14.8829 5.55263 14.6317 5.55263H1.36851Z"
                    fill="#212121"
                  />
                </svg>
              )}
              Ticket Reports
            </NavLink>
          </>
        ) : (
          <NavLink
            end
            state={{ from: location.pathname }}
            className={({ isActive }) =>
              `relative flex items-center gap-3 py-[11px] px-[13px] bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px] ${
                isLocked
                  ? "text-[#979797] pointer-events-none cursor-not-allowed"
                  : "text-black"
              }`
            }
            to={`/ticketing/scan`}
          >
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.421143 1.44737C0.421143 1.19611 0.520954 0.955144 0.69862 0.777478C0.876286 0.599812 1.11725 0.5 1.36851 0.5H10.8422C11.0935 0.5 11.3344 0.599812 11.5121 0.777478C11.6898 0.955144 11.7896 1.19611 11.7896 1.44737C11.7896 1.69863 11.6898 1.93959 11.5121 2.11726C11.3344 2.29493 11.0935 2.39474 10.8422 2.39474H1.36851C1.11725 2.39474 0.876286 2.29493 0.69862 2.11726C0.520954 1.93959 0.421143 1.69863 0.421143 1.44737ZM0.421143 11.5526C0.421143 11.3014 0.520954 11.0604 0.69862 10.8827C0.876286 10.7051 1.11725 10.6053 1.36851 10.6053H9.57904C9.8303 10.6053 10.0713 10.7051 10.2489 10.8827C10.4266 11.0604 10.5264 11.3014 10.5264 11.5526C10.5264 11.8039 10.4266 12.0449 10.2489 12.2225C10.0713 12.4002 9.8303 12.5 9.57904 12.5H1.36851C1.11725 12.5 0.876286 12.4002 0.69862 12.2225C0.520954 12.0449 0.421143 11.8039 0.421143 11.5526ZM1.36851 5.55263C1.11725 5.55263 0.876286 5.65244 0.69862 5.83011C0.520954 6.00778 0.421143 6.24874 0.421143 6.5C0.421143 6.75126 0.520954 6.99222 0.69862 7.16989C0.876286 7.34756 1.11725 7.44737 1.36851 7.44737H14.6317C14.8829 7.44737 15.1239 7.34756 15.3016 7.16989C15.4792 6.99222 15.579 6.75126 15.579 6.5C15.579 6.24874 15.4792 6.00778 15.3016 5.83011C15.1239 5.65244 14.8829 5.55263 14.6317 5.55263H1.36851Z"
                fill="#212121"
              />
            </svg>
            Scan / Redeem Ticket
          </NavLink>
        )}
      </>
    );
  }, [attractionId, user, isLoggedIn, location.pathname]);

  const { data: getAttraction } = apiSlice.useGetAttractionQuery(
    attractionId ?? ""
  );
  const isStepperActive =
    user?.role !== "ADMIN" && !getAttraction?.result?.finished_onboarding;

  const [prevRoute, setPrevRoute] = useState(location.state);
  useEffect(() => {
    setPrevRoute(location.state);
  }, [attractionId, location.pathname]);

  useEffect(() => {
    if (windowSize?.width! >= 1280) {
      navigate("overview", {
        state: {
          from: fromPage ?? undefined,
          claim: claimData ?? undefined,
        },
        replace: true,
      });
    }
  }, []);

  let timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    const shouldGoToLeftOverTab = getNewAttractionWhichTabLeftOver(
      attractionId ?? ""
    );
    if (shouldGoToLeftOverTab) {
      navigate(
        `/attractions/${attractionId}/${
          attractionRoutes.find((r) => r.key === shouldGoToLeftOverTab)
            ?.routeObject.path
        }`,
        {
          state: {
            from: fromPage ?? undefined,
            claim: claimData ?? undefined,
          },
          replace: true,
        }
      );

      timeout.current = setTimeout(() => {
        clearNewAttractionLeftOverTab(attractionId ?? "");
      }, 500);
    }

    return () => {
      // clearTimeout(timeout.current)
    };
  }, [navigate, attractionId]);

  const hasPaid =
    getAttraction?.result?.published_version?.has_paid_to_publish ||
    getAttraction?.result?.published_version?.skip_payment;

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header />
      <div
        className={`flex flex-col bg-white align-center h-full w-full overflow-y-scroll xl:m-0 xl:overflow-y-hidden xl:left-0 xl:max-w-cs-230 xl:px-0 mt-[85px]`}
      >
        <div className="text-lg text-white px-8 py-3 bg-cs-gray xl:text-base xl:font-normal text-center">
          Choose a section
        </div>

        <div className="p-5">
          {isTicketOnboarding ? (
            <TicketingNav />
          ) : (
            <>
              <NavLink
                to={prevRoute?.from ?? "/dashboard"}
                className="relative flex items-center py-[11px] px-[13px] text-black bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
                Back to dashboard
              </NavLink>
              {attractionRoutes &&
                attractionRoutes
                  .filter((route) => {
                    if (
                      user?.role === "ATTRACTION_MANAGER" &&
                      !user?.ticketOnboardingUser &&
                      route.key === "ATTRACTION_TICKETS"
                    )
                      return false;

                    const isClaim =
                      getAttraction?.result?.ownership_claim?.created_by_user
                        ?.email !==
                      getAttraction?.result?.creation_claim?.created_by_user
                        ?.email;
                    if (route.key === "ATTRACTION_PAY_PUBLISH" && isClaim)
                      return false;

                    if (
                      user?.role !== "ADMIN" &&
                      route.key === "ATTRACTION_PREMIUM_ENHANCED"
                    ) {
                      return false;
                    }
                    if (
                      user?.role === "ADMIN" &&
                      route.key === "VENUE_OPT_SCORE"
                    )
                      return false;
                    if (
                      user?.role === "ADMIN" &&
                      route.key === "ATTRACTION_VENUE_OPT_SCORE"
                    )
                      return true;
                    if (
                      !window.connectFeatureFlags?.venueOptScore &&
                      [
                        "VENUE_OPT_SCORE",
                        "ATTRACTION_VENUE_OPT_SCORE",
                      ].includes(route.key!)
                    )
                      return false;
                    if (
                      "ATTRACTION_VENUE_OPT_SCORE" === route.key &&
                      !["STANDARD", "ENHANCED", "PREMIUM"].includes(
                        getAttraction?.result?.listing_package || "BASIC"
                      )
                    )
                      return false;

                    const attraction =
                      state.global.user?.attractions_operator?.find(
                        (a) => a.short_id === attractionId
                      );

                    // hiding "subscription tab" for attractions that haven't had their publishing fee paid yet
                    if (
                      !attraction?.show_subscription_options &&
                      route.key === "ATTRACTION_UPGRADE_PLAN"
                    ) {
                      return false;
                    }

                    if (hasPaid && route.key === "ATTRACTION_PAY_PUBLISH") {
                      return false;
                    }

                    const listing_package =
                      getAttraction?.result?.listing_package;
                    if (
                      listing_package !== undefined &&
                      listing_package === "BASIC" &&
                      [
                        "ATTRACTION_TICKETS_OLD",
                        "TICKETING",
                        "ATTRACTION_TICKETS",
                      ].includes(route.key || "")
                    ) {
                      return false;
                    }
                    // if (isStepperActive && r.key === 'ATTRACTION_UPGRADE_PLAN') {
                    //   return false
                    // }
                    return true;
                  })
                  .map((route) => {
                    const key = route.key;
                    const isLocked =
                      isTabActive(attractionId ?? "", key ?? "") === "LOCKED" &&
                      user?.role !== "ADMIN" &&
                      isStepperActive &&
                      !["ATTRACTION_CHANGE_LOG"].includes(route.key ?? "");
                    return (
                      <NavLink
                        end
                        key={route.key}
                        state={{ from: location.pathname }}
                        className={({ isActive }) =>
                          `relative flex items-center gap-3 py-[11px] px-[13px] bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px] ${
                            isLocked
                              ? "text-[#979797] pointer-events-none cursor-not-allowed"
                              : "text-black"
                          }`
                        }
                        to={`${parentRoute.pathname}${
                          route.routeObject.path
                            ? `/${route.routeObject.path}`
                            : ""
                        }`}
                      >
                        {isLocked ? <LockIcon /> : icons[route.key!] ?? ""}
                        {typeof route.title == "function"
                          ? route.title(state)
                          : route.title}
                      </NavLink>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
