import { ReactNode } from "react";

export function VenueOptScoreProgressCircular({
  score = 0,
  maxScore = 100,
  size = 64,
  stroke = 6,
  children,
  color = 'stroke-[#6837D1]'
}: {
  score: number;
  maxScore?: number,
  size?: number;
  stroke?: number,
  children?: ReactNode;
  color?: string
}) {
  const strokeSize = stroke;
  const radius = (size - strokeSize) / 2;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * (score ?? 0)) / maxScore;

  return (
    <div
      className="relative"
      style={{
        width: size,
        height: size,
      }}
    >
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          className="fill-none stroke-[#D0E2FF]"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeSize}px`}
        />
        <circle
          className={`fill-none ${color}`}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeSize}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </svg>

      <div className="text-[#212121] font-bold text-xl absolute inset-x-0 mx-auto block text-center top-1/2 -translate-y-1/2">
        {children ? (
          children
        ) : (
          <>
            {score && score > 0 ? (
              score
            ) : (
              <>
                <svg
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  className="mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.125 17.5312C1.67812 17.5312 1.29571 17.3723 0.97775 17.0543C0.659791 16.7364 0.500542 16.3537 0.5 15.9062V7.78125C0.5 7.33437 0.65925 6.95196 0.97775 6.634C1.29625 6.31604 1.67867 6.15679 2.125 6.15625H2.9375V4.53125C2.9375 3.40729 3.33373 2.44935 4.12619 1.65744C4.91865 0.865521 5.87658 0.469292 7 0.46875C8.12396 0.46875 9.08217 0.864979 9.87462 1.65744C10.6671 2.4499 11.063 3.40783 11.0625 4.53125V6.15625H11.875C12.3219 6.15625 12.7046 6.3155 13.0231 6.634C13.3416 6.9525 13.5005 7.33492 13.5 7.78125V15.9062C13.5 16.3531 13.341 16.7358 13.0231 17.0543C12.7051 17.3728 12.3224 17.5318 11.875 17.5312H2.125ZM7 13.4687C7.44687 13.4687 7.82956 13.3098 8.14806 12.9918C8.46656 12.6739 8.62554 12.2912 8.625 11.8437C8.625 11.3969 8.46602 11.0145 8.14806 10.6965C7.8301 10.3785 7.44742 10.2193 7 10.2187C6.55312 10.2187 6.17071 10.378 5.85275 10.6965C5.53479 11.015 5.37554 11.3974 5.375 11.8437C5.375 12.2906 5.53425 12.6733 5.85275 12.9918C6.17125 13.3103 6.55367 13.4693 7 13.4687ZM4.5625 6.15625H9.4375V4.53125C9.4375 3.85417 9.20052 3.27865 8.72656 2.80469C8.2526 2.33073 7.67708 2.09375 7 2.09375C6.32292 2.09375 5.7474 2.33073 5.27344 2.80469C4.79948 3.27865 4.5625 3.85417 4.5625 4.53125V6.15625Z"
                    fill="#979797"
                  />
                </svg>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
