import React, { useEffect, useState } from "react";
import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import ClaimsTable from "../../../components/amp/ClaimsTable";
import NewAttractionsTable from "../../../components/amp/NewAttractionsTable";
import { apiSlice } from "../../../store/apiSlice";
import DeniedAttractionsTable from "../../../components/amp/DeniedAttractionsTable";
import UpdateQueueAttractionsTable from "../../../components/amp/UpdateQueueAttractionsTable";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { Badge } from "../../../components/global/Badge";
import { Button } from "../../../components/global/Button";
import EventAttractionsTable from "../../../components/amp/EventAttractionsTable";

const PendingApproval: React.FC = () => {
  const { data: pendingAttractions } =
    apiSlice.useGetPendingApprovalAttractionsQuery(undefined, {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    });
  const { data: pendingNewAttractions } =
    apiSlice.useGetNewApprovalAttractionsQuery(undefined, {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    });

  const [getTotalPendingApprovals, setTotalPendingApprovals] =
    useState<number>(0);

  useEffect(() => {
    document.title = "Pending Approval | Day Out With The Kids";
  }, []);

  useEffect(() => {
    setTotalPendingApprovals(
      (pendingAttractions?.total ?? 0) + (pendingNewAttractions?.total ?? 0)
    );
  }, [pendingAttractions?.total, pendingNewAttractions?.total]);

  const [getFilter, setFilter] = useState<
    "claims" | "new"  | "event" | "denied" | "queue"
  >("claims");

  const { data: claimsTableData} = apiSlice.useGetPendingApprovalAttractionsQuery();
  const { data: newTableData} = apiSlice.useGetNewApprovalAttractionsQuery();
  const { data: eventTableData} = apiSlice.useGetEventApprovalAttractionsQuery();
  const { data: deniedTableData } = apiSlice.useGetDeniedApprovalAttractionsQuery();
  const { data: queueTableData } = apiSlice.useGetPublishQueueListQuery()

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <DashboardHeaderContainer slim={true}>
        <div className="flex items-center">
        <h1 className="font-extrabold text-xl text-black">
          Pending Approval
        </h1>

        <Badge color="primary" className="ml-7">
          {getTotalPendingApprovals > 99 ? "99+" : getTotalPendingApprovals}
        </Badge>
        </div>
      </DashboardHeaderContainer>

      <DashboardContainer>
        <div className="px-5 xl:px-[20px] flex">
          <div className="flex flex-wrap gap-2.5">
            <Button
              color={getFilter === "claims" ? "primary" : "outlineGray"}
              scale={"md"}
              className="flex gap-2"
              onClick={() => setFilter("claims")}
            >
              Claims <Badge color={getFilter === 'claims' ? 'white' : 'black'}>{claimsTableData?.total ?? 0}</Badge>
            </Button>
            <Button
              color={getFilter === "new" ? "primary" : "outlineGray"}
              scale={"md"}
              className="flex gap-2"
              onClick={() => setFilter("new")}
            >
              New Attractions <Badge color={getFilter === 'new' ? 'white' : 'black'}>{newTableData?.total ?? 0}</Badge>
            </Button>
            <Button
              color={getFilter === "event" ? "primary" : "outlineGray"}
              scale={"md"}
              className="flex gap-2"
              onClick={() => setFilter("event")}
            >
              Event Attractions <Badge color={getFilter === 'event' ? 'white' : 'black'}>{eventTableData?.total ?? 0}</Badge>
            </Button>
            <Button
              color={getFilter === "denied" ? "primary" : "outlineGray"}
              scale={"md"}
              className="flex gap-2"
              onClick={() => setFilter("denied")}
            >
              Denied claims <Badge color={getFilter === 'denied' ? 'white' : 'black'}>{deniedTableData?.total ?? 0}</Badge>
            </Button>
            <Button
              color={getFilter === "queue" ? "primary" : "outlineGray"}
              scale={"md"}
              className="flex gap-2"
              onClick={() => setFilter("queue")}
            >
              Update Queue <Badge color={getFilter === 'queue' ? 'white' : 'black'}>{queueTableData?.length ?? 0}</Badge>
            </Button>
          </div>
        </div>
        <section className="flex flex-col px-5 xl:px-[20px]">
          <div className="my-5">
            {getFilter === 'claims' && <ClaimsTable />}
            {getFilter === 'new' && <NewAttractionsTable />}
            {getFilter === 'event' && <EventAttractionsTable />}
            {getFilter === 'denied' && <DeniedAttractionsTable />}
            {getFilter === 'queue' && <UpdateQueueAttractionsTable />}
          </div>
        </section>
      </DashboardContainer>

      <Footer />
    </div>
  );
};

export default PendingApproval;
