import React, { useState } from 'react';

interface AccordionItem {
  title: string
  content: string
}

interface AccordionProps {
  items: AccordionItem[]
  innerClassNames: string
  outerClassNames: string
  contentClassNames: string
}

const Accordion: React.FC<AccordionProps> = ({ items, innerClassNames, outerClassNames, contentClassNames}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <section>
      {items.map((item, index) => (
        <div className={outerClassNames} key={index}>
          <button className={innerClassNames} onClick={() => handleClick(index)}>
            {item.title}
            <span className={`transition-all duration-500 leading-none ${activeIndex === index ? 'rotate-45' : 'rotate-0'} text-cs-gray text-4xl`}>+</span>
          </button>
          <div
            className={`transition-max-height overflow-hidden my-2 mx-4 ${
              activeIndex === index ? 'max-h-[2000px] ease-in duration-700' : 'max-h-0 ease-out duration-500'
            } ${contentClassNames}`}
          >
            <div dangerouslySetInnerHTML={{__html: item.content }} />
          </div>
        </div>
      ))}
    </section>
  );
};

export default Accordion;