import { Navigate } from "react-router-dom";
import { useAuthUser, useLogout } from "../../hooks/useAuth";
import { useAppSelector } from "../../store";
import { Link, useNavigate, useSearchParams, useLocation} from "react-router-dom";
import { PrimaryButton } from "./PrimaryButton";

interface IProtectedRoute extends ComponentWithChildren {
    loggedOutOnly?: boolean
}

/*
    TODO - Extend this component to contain role/permission logic
        Grab the user from state and check their permissions against the required permission passed through props
        Specify an optional "redirectTo" prop and default to "/login" if logged out or "/".
*/

const ProtectedRoute: React.FC<IProtectedRoute> = ({ children, loggedOutOnly }) => {

    const { isLoggedIn } = useAuthUser();
    const [searchParams] = useSearchParams()


    if(loggedOutOnly){
        return isLoggedIn ? <Navigate to={"/"} replace /> : <>{children}</>
    } else {
        return isLoggedIn ? isLoggedIn.role == "CONTENT_CREATOR" ? <UnauthorizedPage/> : <>{children}</> : <Navigate to={"/login"} replace />
    }
}

const UnauthorizedPage = () => {

    const [logout] = useLogout();

    return(
        <div className="flex items-center justify-center flex-1">
            <div className="flex flex-col">
                You do not have permission to access this site as a content creator. Please visit Day Out Diaries or logout.
                <div className="flex justify-center mt-2">
                    <PrimaryButton scale="sm" onClick={logout}>Logout</PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default ProtectedRoute;