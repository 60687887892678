import React from "react";
import { Link } from "react-router-dom";
import LoggeedoutFooter from "../www/Footer";
import LoggeedoutHeader from "../www/Header";
import LoggedinFooter from "./Footer";
import LoggedinHeader from "./Header";
import { useAppSelector } from "../../../store";
import { useAuthUser } from "../../../hooks/useAuth";
import DashboardContainer from "../../amp/DashboardContainer";

type Permissions = "NOT_FOUND" | "FORBIDDEN" | "UNKNOWN"
export const PermissionError: React.FC<{ type: Permissions, homeLink?: string }> = ({ type, homeLink = "/dashboard" }) => {
    const types = {
        NOT_FOUND: {
            title: "We can't find that page...",
            description: "Don't worry, we'll find it somewhere. In the meantime, please go to the home page",
            icon: "/assets/amp/unknown-error.svg"
        },
        FORBIDDEN: {
            title: "You don't have permission to view this page",
            description: "If you think this is in error, then please contact us.",
            icon: "/assets/amp/forbidden.svg"
        },
        UNKNOWN: {
            title: "Something has gone wrong...",
            description: "We'll work on it. In the meantime, please refresh the page or go to the home page",
            icon: "/assets/amp/unknown-error.svg"
        }
    } satisfies Record<Permissions, {
        title: string,
        description: string,
        icon: string
    }>
    const error = types[type]
    return (
        <div className="py-10">
            <img className="w-24 mx-auto" src={error.icon} alt="forbidden" />
            <h2 className="text-xl font-bold text-cs-gray flex items-center justify-center mt-4 text-center">{error.title}</h2>
            <p className="mt-6 font-normal text-cs-gray text-center text-sm">{error.description}</p>

            <div className="flex">
                <Link to={homeLink} className={`rounded-full px-10 py-3 mt-8 mx-auto inline-block w-auto text-white text-base border-2 font-bold shadow-cs-button-backdrop hover:text-cs-pink hover:outline-cs-pink bg-cs-pink border-cs-pink hover:bg-white`}>
                    Go to home page
                </Link>
            </div>
        </div>
    )
}

export const WithPermission: React.FC<{ 
    type: Permissions, 
    allowedUsers: ("OPERATOR_OWNER" | "ADMIN" | "ATTRACTION_MANAGER")[], 
    children: ReturnType<React.FC> 
}> = ({ type, allowedUsers, children }) => {
    const { user } = useAppSelector(state => state.global)

    if (!(allowedUsers as string[]).includes(user?.role ?? '')) {
        return (
            <div className="flex flex-col flex-1 bg-[#F5F5FA]">
                <LoggedinHeader />
        
                <DashboardContainer>
                    <section className="flex flex-col pt-6 pb-8 px-6 bg-white xl:shadow-cs-admin-section xl:rounded-lg xl:pt-6 xl:pb-10 xl:m-10">
                        <PermissionError type={type} />
                    </section>
                </DashboardContainer>
        
                <LoggedinFooter />
            </div>
        );
    }

    return children
}

export const ErrorPage: React.FC<{ 
    type: Permissions, 
}> = ({ type}) => {
    const { isLoggedIn } = useAuthUser();

    return (
        <div className="flex flex-col flex-1 bg-[#F5F5FA]">
            {isLoggedIn ? <LoggedinHeader full={true} /> : <LoggeedoutHeader />}
    
            <main className="mt-20 bg-cs-off-white xl:mt-20">
                <section className="flex flex-col pt-6 pb-8 px-6 bg-white xl:shadow-cs-admin-section xl:rounded-lg xl:pt-6 xl:pb-10 xl:m-10">
                    <PermissionError type={type} homeLink={isLoggedIn ? "/dashboard" : "/"} />
                </section>
            </main>
    
            {isLoggedIn ? <LoggedinFooter full={true} /> : <LoggeedoutFooter />}
        </div>
    );
}