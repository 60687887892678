import { useEffect } from "react";
import { TwoColTemplate } from "../../components/global/TwoColTemplate";
import LoginModal from "../../components/global/www/modals/LoginModal";
import { useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const { user } = useAppSelector(state => state.global);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      navigate('/dashboard');
    }
  }, [user])

  return (
    <>
      <TwoColTemplate
        sidebarText="Showcase the best of your brand to our family audience... with over 35 million annual visitors, searching for things to do."
        sidebarImage="/assets/www/login-image-2.png"
      >
        <LoginModal />
      </TwoColTemplate>
    </>
  );
};

export default Login;
