export const visitorType = [
    { value: "Mum", label: "Mum" },
    { value: "Dad", label: "Dad" },
    { value: "Daughter", label: "Daughter" },
    { value: "Son", label: "Son" },
    { value: "Friend", label: "Friend" },
    { value: "Nan", label: "Nan" },
    { value: "Grandad", label: "Grandad" },
    { value: "Aunt", label: "Aunt" },
    { value: "Uncle", label: "Uncle" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
    { value: "Cousin", label: "Cousin" },
    { value: "Partner", label: "Partner" },
    { value: "Babysitter", label: "Babysitter" },
]

const generateAgesArray = (): { value: string, label: string }[] => {
    const agesArray = [{ value: "under_1", label: "Under 1 year" }];

    for (let i = 1; i <= 75; i++) {
        agesArray.push({ value: i.toString(), label: i.toString() });
    }

    agesArray.push({ value: "75+", label: "75+" });

    return agesArray;
};

export const ages = generateAgesArray();

export const dayOutCategories = [
    {"label": "Activity Centres", "value": "activity_centres"},
    {"label": "Aquariums", "value": "aquariums"},
    {"label": "Arcades & Gaming Cafés", "value": "arcades_and_gaming_cafes"},
    {"label": "Arts & Crafts", "value": "arts_and_crafts"},
    {"label": "Beaches", "value": "beaches"},
    {"label": "Castles and Palaces", "value": "castles_and_palaces"},
    {"label": "Caves and Mines", "value": "caves_and_mines"},
    {"label": "Children’s Farms", "value": "children_farms"},
    {"label": "Cinemas & Theatres", "value": "cinemas_and_theatres"},
    {"label": "Climbing", "value": "climbing"},
    {"label": "Concerts & Events", "value": "concerts_and_events"},
    {"label": "Crazy Golf", "value": "crazy_golf"},
    {"label": "Cycling", "value": "cycling"},
    {"label": "Escape Rooms", "value": "escape_rooms"},
    {"label": "FootGolf", "value": "footgolf"},
    {"label": "Go Karting & Driving", "value": "go_karting_and_driving"},
    {"label": "High Ropes Courses", "value": "high_ropes_courses"},
]

export const months = [
    {"label": "January", "value": "01"},
    {"label": "February", "value": "02"},
    {"label": "March", "value": "03"},
    {"label": "April", "value": "04"},
    {"label": "May", "value": "05"},
    {"label": "June", "value": "06"},
    {"label": "July", "value": "07"},
    {"label": "August", "value": "08"},
    {"label": "September", "value": "09"},
    {"label": "October", "value": "10"},
    {"label": "November", "value": "11"},
    {"label": "December", "value": "12"},
]

export const years = [
    {"label": "2024", "value": "2024"},
    {"label": "2023", "value": "2023"},
    {"label": "2022", "value": "2022"},
    {"label": "2021", "value": "2021"},
    {"label": "2020", "value": "2020"},
    {"label": "2019", "value": "2019"},
    {"label": "2018", "value": "2018"},
    {"label": "2017", "value": "2017"},
    {"label": "2016", "value": "2016"},
    {"label": "2015", "value": "2015"},
]

export const dayOutEssentials = [
    {"label": "Tickets", "value": "tickets"},
    {"label": "Hand Sanitiser", "value": "hand_sanitiser"},
    {"label": "Comfortable Shoes", "value": "comfortable_shoes"},
    {"label": "Sunscreen", "value": "sunscreen"},
    {"label": "Swimming Costumes", "value": "swimming_costumes"},
    {"label": "Reusable Water Bottles", "value": "reusable_water_bottles"},
    {"label": "Snacks", "value": "snacks"},
    {"label": "A Lunchbox", "value": "lunchbox"},
    {"label": "Baby Food & Bottles", "value": "baby_food_and_bottles"},
    {"label": "Power Bank for Phone", "value": "power_bank_for_phone"},
    {"label": "Camera", "value": "camera"},
    {"label": "Sunglasses", "value": "sunglasses"},
    {"label": "Hats", "value": "hats"},
    {"label": "Wet Wipes", "value": "wet_wipes"},
    {"label": "A Change of Clothes", "value": "a_change_of_clothes"},
    {"label": "Towel", "value": "towel"},
]

export const travelTimes = [
    {"label": "Up to 30 mins", "value": "up_to_30mins"},
    {"label": "30-60 mins", "value": "30_to_60mins"},
    {"label": "1-2 hours", "value": "1_to_2hours"},
    {"label": "2-3 hours", "value": "2_to_3hours"},
    {"label": "3-4 hours", "value": "3_4hours"},
    {"label": "4+ hours", "value": "4hours"},
]

export const travelMethods = [
    {"label": "Car", "value": "car"},
    {"label": "Public transport", "value": "public_transport"},
    {"label": "Walk", "value": "walk"},
    {"label": "Cycle", "value": "cycle"},
]

export const queueTime = [
    {"label": "No queue", "value": "no_queue"},
    {"label": "Up to 30 mins", "value": "up_to_30mins"},
    {"label": "30-60 mins", "value": "30_to_60mins"},
    {"label": "1-2 hours", "value": "1_to_2hours"},
    {"label": "2+ hours", "value": "2hours"},
]

export const parkingPrice = [
    {"label": "Free Parking", "value": null},
]