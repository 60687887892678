import React, { useEffect, useState } from "react";
import { z } from "zod";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { useParams } from "react-router-dom";
import { apiSlice } from "../../../store/apiSlice";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ImageUpload from "../../../components/form/ImageUpload";
import { useApiError } from "../../../hooks/useApiError";
import { base64ToFile, FcnBlogContentRequestInterfaceKeys, fetchFileData, fileToDataString, filterProperties } from "../../../helpers/media";
import DiaryDetailsHeader from "./DiaryDetailsHeader";
import { FcnBlogContentRequest } from "../../../../../client-core/src/types/blog";

export const mediaJsonSchema = {
    data: z.string().min(1, "Image data is required"),
    id: z.string().optional(),
    caption: z.string().optional()
}

export const optionalMediaJsonSchema = {
    data: z.string().optional(),
    id: z.string().optional(),
    caption: z.string().optional()
}

interface MediaDbObject {
    id: string
    public_id: string
    url: string
    cloudinary_url: string
    created_at: string
    alt_text: string
}

export type MediaArrayItem = Partial<MediaDbObject> & {
    data?: string // base64 of new image
    caption?: string | null
}


const schema = z.object({
    best_moments_1: z.string().min(1, "Best bits is required"),
    best_moments_2: z.string().min(1, "Best bits is required"),
    best_moments_3: z.string().min(1, "Best bits is required"),
    worst_moments_1: z.string().min(1, "Worst bits is required"),
    worst_moments_2: z.string().min(1, "Worst bits is required"),
    worst_moments_3: z.string().min(1, "Worst bits is required"),
    overall_summary: z.string().optional(),
    mini_summary: z.string().optional(),
    final_images_json: z.object(optionalMediaJsonSchema).array().min(3, { message: "At least 3 images are required" })
    .superRefine((images, context) => {
      const validImagesCount = images.filter(image => image.data && image.data.trim() !== "").length;
  
      if (validImagesCount < 3) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "At least 3 images must have valid data",
        });
      }
    }),
    final_image_1_id: z.string().optional(),
    final_image_caption_1: z.string().optional(),
    final_image_2_id: z.string().optional(),
    final_image_caption_2: z.string().optional(),
    final_image_3_id: z.string().optional(),
    final_image_caption_3: z.string().optional(),
    final_image_4_id: z.string().optional(),
    final_image_caption_4: z.string().optional(),
});

type DiaryForm = z.infer<typeof schema>;

const DiaryFinalThoughts: React.FC = () => {
    const { blogId } = useParams();
    const { data, isLoading } = apiSlice.useGetBlogDetailsQuery(blogId ?? "");
    const [uploadContent, { isLoading: isLoadingContent, error }] = apiSlice.useContentMutation();
    const [uploadMedia, {isLoading: isLoadingUploadMedia}] = apiSlice.useUploadImageMutation();
    const [deleteMedia] = apiSlice.useLazyDeleteImageQuery();
    const [resolveContent, {isLoading: isLoadingResolve, error: resolveError}] = apiSlice.useResolveContentMutation();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [defaultValuesData, setDefaultValuesData] = useState<any>();
    const contentError = useApiError(error);
    const [resolveType, setResolveType] = useState<string>("");
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { isValid, isSubmitted, errors, isDirty },
        control,
        setValue,
        getValues,
        reset,
        trigger: triggerValidation,
        clearErrors,
        watch
    } = useForm<DiaryForm>({
        resolver: async (data, context, options) => {
        return zodResolver(schema)(data, context, options);
        },
        defaultValues: {}
    });

    const overallSummary = watch('overall_summary', '') ?? "";
    const miniSummary = watch('mini_summary', '') ?? "";

    const endOfDayCaption1 = watch('final_images_json.0.caption', '') ?? "";
    const endOfDayCaption2 = watch('final_images_json.1.caption', '') ?? "";
    const endOfDayCaption3 = watch('final_images_json.2.caption', '') ?? "";
    const endOfDayCaption4 = watch('final_images_json.3.caption', '') ?? "";

    const onSaveMedia = async (key: string, max: number, media: MediaArrayItem[], oldMedia?: MediaArrayItem[]) => {
        // example implementation of images in AttractionImages.tsx

        let form: { [key: string]: string | undefined | null } = {};

        // set all media & caption fields null to be re-set using media array
        for(let i = 0; i < max; i++){
            form[`${key}_${i + 1}_id`] = null;
            form[`${key}_caption_${i + 1}`] = null;
        }

        // loop through media array, upload if needed and set new positions
        for(let i = 0; i < media.length; i++){
            const mediaObject = media[i];
            let id = mediaObject.id;
            if(mediaObject.data && !id){
                const file = await base64ToFile(mediaObject.data);
                const formData = new FormData();
                formData.append("file", file);
                let mediaResponse = await uploadMedia(formData).unwrap();
                if(mediaResponse?.id){
                    if(key === "favourite_image"){
                        form[`favourite_image_id`] = mediaResponse?.id;
                    }else if(key === "family_creator_image"){
                        form[`family_creator_image_id`] = mediaResponse?.id;
                    }else{
                        form[`${key}_${i + 1}_id`] = mediaResponse?.id;
                    }
                }
            }else{
                if(key === "favourite_image"){
                    form[`favourite_image_id`] = id;
                }else if(key === "family_creator_image"){
                    form[`family_creator_image_id`] = id;
                }else{
                    form[`${key}_${i + 1}_id`] = id;
                }
            }
            form[`${key}_caption_${i + 1}`] = mediaObject.caption;
        }

        // find media which has been deleted and call the delete API
        if(oldMedia){
            const deletedMedia = oldMedia.filter((v) => !media.some((m) => m.id == v.id));
            for(let i = 0; i < deletedMedia.length; i++){
                const deletedMediaObject = deletedMedia[i];
                if(deletedMediaObject?.id){
                    await deleteMedia(deletedMediaObject?.id ?? "")
                }
            }
        }
        return form;
    }

    const clearErrorsOnChange = (fieldName: string) => {
		clearErrors(fieldName as any);
	};

    const onSubmit: SubmitHandler<DiaryForm> = async () => {
        const formValues = { 
            ...getValues()
        };
        setIsDisabled(true);
        const contentUpdateRequest: any = {
            ...formValues,
            ...(await onSaveMedia("final_image", 4, formValues.final_images_json, defaultValuesData?.final_images_json)),
        }  
        const filteredPayload = filterProperties<FcnBlogContentRequest>(contentUpdateRequest, FcnBlogContentRequestInterfaceKeys);
   
        await uploadContent({
            ...filteredPayload,
            blog_id: data?.blog_id,
            content_id: data?.last_modified_content?.content_id,
        });
        setIsDisabled(false);
    };

    const countWords = (text: string) => {
        if(!text){
            return 0;
        }
        return text.trim().split(/\s+/).length;
    };

    const handleResolveContent = async (type: string) => {
        setResolveType(type);
        if(type === "approve"){
            await resolveContent({
                approve: true,
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else if(type === "deny"){
            await resolveContent({
                approve: false,
                reason: "",
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else{
            console.log("No resolve type selected")
        }
    }

    const handlePreviewContent = async () => {
        setIsLoadingPreview(true);
        try {
          const response = await fetch(`/api/amp/v1/fcn/blogs/content/${data?.last_modified_content?.content_id}/preview`);
          const url = await response.text();
          if(url){
            window.open(url, '_blank', 'noopener,noreferrer');
          }
        } catch (err) {
            console.log("Preview Error: ", err)
        } finally{
            setIsLoadingPreview(false);
        }
    };
    
    useEffect(() => {
        async function fetchBlogDetails(){
            if(data){
              const { final_image_1, final_image_2, final_image_3, final_image_4,
                final_image_caption_1, final_image_caption_2, final_image_caption_3, final_image_caption_4, 
                } = data?.last_modified_content ?? {};

                if(final_image_1){
                    const data = await fetchFileData(final_image_1.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("final_images_json.0.data", urlString);
                    setValue("final_images_json.0.id", final_image_1?.id);
                    setValue("final_images_json.0.caption", final_image_caption_1 ?? "");
                    setValue("final_image_1_id", final_image_1?.id);
                }
                if(final_image_2){
                    const data = await fetchFileData(final_image_2.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("final_images_json.1.data", urlString);
                    setValue("final_images_json.1.id", final_image_2?.id);
                    setValue("final_images_json.1.caption", final_image_caption_2 ?? "");
                    setValue("final_image_2_id", final_image_2?.id);
                }
                if(final_image_3){
                    const data = await fetchFileData(final_image_3.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("final_images_json.2.data", urlString);
                    setValue("final_images_json.2.id", final_image_3?.id);
                    setValue("final_images_json.2.caption", final_image_caption_3 ?? "");
                    setValue("final_image_3_id", final_image_3?.id);
                }
                if(final_image_4){
                    const data = await fetchFileData(final_image_4.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("final_images_json.3.data", urlString);
                    setValue("final_images_json.3.id", final_image_4?.id);
                    setValue("final_images_json.3.caption", final_image_caption_4 ?? "");
                    setValue("final_image_4_id", final_image_4?.id);
                }

                setValue("best_moments_1", data?.last_modified_content?.best_moments_1 ?? "");
                setValue("best_moments_2", data?.last_modified_content?.best_moments_2 ?? "");
                setValue("best_moments_3", data?.last_modified_content?.best_moments_3 ?? "");
                setValue("worst_moments_1", data?.last_modified_content?.worst_moments_1 ?? "");
                setValue("worst_moments_2", data?.last_modified_content?.worst_moments_2 ?? "");
                setValue("worst_moments_3", data?.last_modified_content?.worst_moments_3 ?? "");
                setValue("overall_summary", data?.last_modified_content?.overall_summary ?? "");
                setValue("mini_summary", data?.last_modified_content?.mini_summary ?? "");
    
                const formValues = getValues();
                const clonedFormValues = JSON.parse(JSON.stringify(formValues));
                setDefaultValuesData(clonedFormValues);
            }
        }   
        fetchBlogDetails();
    }, [data]);

    return(
        <div className={`flex flex-col flex-1 bg-[#F5F5FA] doodle-bg ${isLoading && "blur-sm"}`}>
            <DashboardHeaderContainer slim={true}>
            {
                blogId &&
                <DiaryDetailsHeader
                    blogId={blogId}
                />
            }
            </DashboardHeaderContainer>
            <DashboardContainer>
                <form className={`z-10 ${false ? "blur-sm" : ""}`} onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-x-3 px-5 xl:px-[20px] w-full">
                        <div className="w-full p-6 mb-6 bg-white rounded-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/final.png" alt="final"/>
                                {"Final thoughts"}
                            </h1>
                            <div className="mt-2 mb-8 text-sm font-normal text-cs-gray">Time to wrap up your Day Out Diary!</div>
                            <div className="flex flex-wrap gap-8">
                                <div className="max-w-[60%] w-full">
                                    <label className="block mb-4 text-sm font-bold group-focus-within:text-cs-pink">
                                        Sum up your best bits
                                        <span className="text-cs-pink"> *</span>
                                    </label>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className={`w-7 h-7 min-w-7 rounded-full border-2 ${errors?.best_moments_1 ? "border-cs-red text-cs-red" : "border-[#979797] text-[#979797]"}  flex items-center justify-center text-sm`}>1</div>
                                        <div className="w-full">
                                            <Controller
                                                name="best_moments_1"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="best_moments_1"
                                                        rows={2}
                                                        className={`border-cs-1 ${errors?.best_moments_1 ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-24`}
                                                        placeholder="Share one moment that made your day out unforgettable."
                                                        onChange={(e) => {
                                                            setValue('best_moments_1', e?.target?.value);
                                                            clearErrorsOnChange('best_moments_1');
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className={`w-7 h-7 min-w-7 rounded-full border-2 ${errors?.best_moments_2 ? "border-cs-red text-cs-red" : "border-[#979797] text-[#979797]"}  flex items-center justify-center text-sm`}>2</div>
                                        <div className="w-full">
                                            <Controller
                                                name="best_moments_2"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="best_moments_2"
                                                        rows={2}
                                                        className={`border-cs-1 ${errors?.best_moments_2 ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-24`}
                                                        placeholder="What was the most exciting part of the day out?"
                                                        onChange={(e) => {
                                                            setValue('best_moments_2', e?.target?.value);
                                                            clearErrorsOnChange('best_moments_2');
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className={`w-7 h-7 min-w-7 rounded-full border-2 ${errors?.best_moments_3 ? "border-cs-red text-cs-red" : "border-[#979797] text-[#979797]"}  flex items-center justify-center text-sm`}>3</div>
                                        <div className="w-full">
                                            <Controller
                                                name="best_moments_3"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="best_moments_3"
                                                        rows={2}
                                                        className={`border-cs-1 ${errors?.best_moments_3 ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-24`}
                                                        placeholder="What made this attraction different to others?"
                                                        onChange={(e) => {
                                                            setValue('best_moments_3', e?.target?.value);
                                                            clearErrorsOnChange('best_moments_3');
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap gap-8">
                                <div className="max-w-[60%] w-full">
                                    <label className="block mb-4 text-sm font-bold group-focus-within:text-cs-pink">
                                        Tell us your worst bits
                                        <span className="text-cs-pink"> *</span>
                                    </label>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className={`w-7 h-7 min-w-7 rounded-full border-2 ${errors?.worst_moments_1 ? "border-cs-red text-cs-red" : "border-[#979797] text-[#979797]"}  flex items-center justify-center text-sm`}>1</div>
                                        <div className="w-full">
                                                <Controller
                                                    name="worst_moments_1"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <textarea
                                                            {...field}
                                                            id="worst_moments_1"
                                                            rows={2}
                                                            className={`border-cs-1 ${errors?.worst_moments_1 ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-24`}
                                                            placeholder="Was there anything that didn’t meet your expectations?"
                                                            onChange={(e) => {
                                                                setValue('worst_moments_1', e?.target?.value);
                                                                clearErrorsOnChange('worst_moments_1');
                                                            }}
                                                        />
                                                    )}
                                                />
                                        </div>
                                    </div>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className={`w-7 h-7 min-w-7 rounded-full border-2 ${errors?.worst_moments_2 ? "border-cs-red text-cs-red" : "border-[#979797] text-[#979797]"}  flex items-center justify-center text-sm`}>2</div>
                                        <div className="w-full">
                                            <Controller
                                                name="worst_moments_2"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="worst_moments_2"
                                                        rows={2}
                                                        className={`border-cs-1 ${errors?.worst_moments_2 ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-24`}
                                                        placeholder="Was there anything that didn’t meet your expectations?"
                                                        onChange={(e) => {
                                                            setValue('worst_moments_2', e?.target?.value);
                                                            clearErrorsOnChange('worst_moments_2');
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className={`w-7 h-7 min-w-7 rounded-full border-2 ${errors?.worst_moments_3 ? "border-cs-red text-cs-red" : "border-[#979797] text-[#979797]"}  flex items-center justify-center text-sm`}>3</div>
                                        <div className="w-full">
                                            <Controller
                                                name="worst_moments_3"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="worst_moments_3"
                                                        rows={2}
                                                        className={`border-cs-1 ${errors?.worst_moments_3 ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-24`}
                                                        placeholder="Was there anything that didn’t meet your expectations?"
                                                        onChange={(e) => {
                                                            setValue('worst_moments_3', e?.target?.value);
                                                            clearErrorsOnChange('worst_moments_3');
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                    Reflect on your day out. Would you visit again? Share your honest thoughts on the day out.
                                </label>
                                <Controller
                                    name="overall_summary"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 200 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="overall_summary"
                                            rows={2}
                                            className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                            placeholder="For example: to sum up, the theme park was a hit with most of the family! The rides were amazing, some of the best in the UK. The only downside was the wait time and the. food options. I’d recommend taking your own. snacks with you and stopping by at a cafe on the way to save some money."
                                            maxLength={200}
                                        />
                                    )}
                                />
                                <p className="text-sm text-cs-gray text-end">{countWords(overallSummary)} / 200 words</p>
                            </div>
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                    In one sentence, how would you sum up your families day out?
                                </label>
                                <Controller
                                    name="mini_summary"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 45 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="mini_summary"
                                            rows={2}
                                            className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] font-love text-2xl placeholder:text-2xl rounded-md pt-2 px-5 flex flex-col w-full focus:outline-cs-pink h-12"
                                            placeholder="“A must-visit for families who...”"
                                            maxLength={45}
                                        />
                                    )}
                                />
                                <p className="text-sm text-cs-gray text-end">{miniSummary.length} / 45 characters</p>
                            </div>
                            <div>
                                <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.final_images_json ? "text-cs-red" : ""}`}>
                                    Share some photos of your family travelling to the attraction!
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <div className="text-sm font-normal text-cs-gray my-1.5">Add a minimum of 3 photos.</div>
                                <div className="flex flex-wrap gap-3 mt-2">
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="final_images_json.0.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.final_images_json && errors?.final_images_json[0]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaText="Drop image here"
                                            dropAreaSubText="Upload image"
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="final_images_json.0.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="final_images_json.0.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{endOfDayCaption1.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="final_images_json.1.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.final_images_json && errors?.final_images_json[1]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="final_images_json.1.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="final_images_json.1.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{endOfDayCaption2.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="final_images_json.2.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.final_images_json && errors?.final_images_json[2]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="final_images_json.2.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="final_images_json.2.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{endOfDayCaption3.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="final_images_json.3.data"
                                            setValue={setValue}
                                            className="dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted border-black text-xs"
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="final_images_json.3.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="final_images_json.3.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{endOfDayCaption4.length} / 35 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button 
                                className={`px-4 mt-4 py-2 text-sm font-bold text-white rounded-lg bg-cs-pink ${isDisabled ? "opacity-50" : "opacity-100"}`} 
                                type="submit"
                                disabled={isDisabled}
                            >
                                Save Changes
                            </button>
                            {
                                contentError &&
                                <p className="mt-2 text-sm text-cs-red">{contentError}</p>
                            }
                        </div>
                    </div>
                </form>
            </DashboardContainer>
        </div>
    )
}

export default DiaryFinalThoughts;