import { useEffect, useState } from "react";
import { apiSlice } from "../../store/apiSlice";

export function CampaignInterest() {
  const { data, isFetching, isSuccess, refetch } =
    apiSlice.useGetUserCampaignInterestsQuery();
  const [fetchedData, setFetchedData] = useState<{
    christmas_campaign_interest: boolean | null;
    all_campaigns_interest: boolean | null;
  } | null>(null);

  const [settingChristmasInterest, setSettingChristmasInterest] =
    useState(false);
  const [christmasCampaignInterest] =
    apiSlice.useChristmasCampaignInterestMutation();

  const [settingAllInterest, setSettingAllInterest] = useState(false);
  const [allCampaignInterest] = apiSlice.useAllCampaignInterestMutation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [hideSections, setHideSections] = useState(true);

  useEffect(() => {
    if (data && !isFetching && isSuccess) {
      if (
        isFirstLoad &&
        data.all_campaigns_interest === true &&
        data.christmas_campaign_interest === true
      ) {
        setHideSections(true);
      } else {
        setHideSections(false);
      }

      setFetchedData(data);
      setIsFirstLoad(false);
    }
  }, [data, isFetching, isSuccess]);

  return !hideSections ? (
    <div className="mb-5 flex flex-wrap lg:flex-nowrap gap-5 xl:mx-[70px]">
      {!fetchedData?.christmas_campaign_interest ? (
        <div className="relative bg-[#FCF0E3] rounded-lg p-6 border border-[#F6CDA1] w-[490px] max-w-full lg:max-w-[50%]">
          <div className="font-extrabold text-base leading-[19px]">
            Get additional exposure to your Christmas Events across our website!
          </div>

          <p className="mt-2 font-normal text-xs leading-5 text-[#212121]">
            The festive season is just around the corner, and families across
            the UK are already planning their magical days out! So, now is the
            perfect time to showcase your event - whether it’s Santa’s Grottos,
            festive light trails or Christmas markets! 
          </p>
          <button
            type="button"
            className={`bg-[#0F402C] w-[155px] h-[36px] flex justify-center items-center rounded-full text-white font-bold text-xs mt-2 ${
              settingChristmasInterest ? "opacity-50 pointer-events-none" : ""
            }`}
            onClick={async (event) => {
              event.preventDefault();
              if (fetchedData?.christmas_campaign_interest) return;
              setSettingChristmasInterest(true);

              const result = await christmasCampaignInterest();

              if ("data" in result) {
                refetch();
              }

              setSettingChristmasInterest(false);
            }}
          >
            Sign Up Now
          </button>

          <img
            src={"/assets/amp/christmas-tree.png"}
            className="not-sr-only absolute bottom-0 right-5 select-none pointer-events-none w-[53px]"
            alt=""
          />
        </div>
      ) : (
        <div className="relative bg-[#FCF0E3] rounded-lg p-6 border border-[#F6CDA1] w-[490px] max-w-full lg:max-w-[50%]">
          <div className="font-extrabold text-base leading-[19px]">
            You’ve opted in to our Christmas Campaign!
          </div>

          <p className="mt-2 font-normal text-xs leading-5 text-[#212121]">
            Thanks for opting in! A member of our team will contact you closer
            to the festive season to discuss the possibilities on offer for your
            attraction.
          </p>
        </div>
      )}

      {!fetchedData?.all_campaigns_interest ? (
        <div className="relative bg-white rounded-lg p-6 border border-[#EEEEEE] w-[490px] max-w-full lg:max-w-[50%]">
          <div className="font-extrabold text-base leading-[19px]">
            Boost Your Attraction's Visibility with Year-Round Exposure on Our
            Website in 2025
          </div>

          <p className="mt-2 font-normal text-xs leading-5 text-[#212121] pr-32">
            Plan ahead and ensure your attraction gets maximum exposure
            throughout the year.
          </p>

          <button
            type="button"
            className={`bg-transparent border border-cs-pink w-[178px] h-[36px] flex justify-center items-center rounded-full text-cs-pink font-bold text-xs mt-[12px] ${
              fetchedData?.all_campaigns_interest || settingAllInterest
                ? "pointer-events-none opacity-50"
                : ""
            }`}
            onClick={async (event) => {
              event.preventDefault();
              if (fetchedData?.all_campaigns_interest) return;
              setSettingAllInterest(true);

              const result = await allCampaignInterest();

              if ("data" in result) {
                refetch();
              }

              setSettingAllInterest(false);
            }}
          >
            Register Interest
          </button>
        </div>
      ) : (
        <div className="relative bg-white rounded-lg p-6 border border-[#EEEEEE] w-[490px] max-w-full lg:max-w-[50%]">
          <div className="font-extrabold text-base leading-[19px]">
            Thanks for showing interest!
          </div>

          <p className="mt-2 font-normal text-xs leading-5 text-[#212121] pr-32">
            A member of our team will contact you to discuss the possibilities
            on offer for your attraction.
          </p>
        </div>
      )}
    </div>
  ) : null;
}
