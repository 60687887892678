import React, { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { useParams } from "react-router-dom";
import { apiSlice } from "../../../store/apiSlice";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ImageUpload from "../../../components/form/ImageUpload";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import ButtonMultiSelect from "../../../components/form/ButtonMultiSelect";
import { ages, dayOutCategories, months, visitorType, years } from "../../../helpers/constants";
import RatingInput from "../../../components/form/RatingInput";
import TextInput from "../../../components/form/TextInput";
import MultipleFieldArray from "../../../components/form/MultipleFieldArray";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import Select from "react-select";
import dayjs from "dayjs";
import { base64ToFile, FcnBlogContentRequestInterfaceKeys, fetchFileData, fileToDataString, filterProperties } from "../../../helpers/media";
import { useApiError } from "../../../hooks/useApiError";
import DiaryDetailsHeader from "./DiaryDetailsHeader";
import { FcnBlogContentRequest } from "../../../../../client-core/src/types/blog";

export const mediaJsonSchema = {
    data: z.string().min(1, "Image data is required"),
    id: z.string().optional(),
    caption: z.string().optional()
}

interface MediaDbObject {
    id: string
    public_id: string
    url: string
    cloudinary_url: string
    created_at: string
    alt_text: string
}

export type MediaArrayItem = Partial<MediaDbObject> & {
    data?: string // base64 of new image
    caption?: string | null
}

const schema = z.object({
    favourite_image_json: z.object(mediaJsonSchema).array().min(1, { message: "At least 1 image is required"}),
    favourite_image_id: z.string().optional(),
    title: z.string().min(1, "Day Out Diary Title is required"),
    overview: z.string().min(1, "Short Overview is required"),
    category_name: z.string().min(1, "Day Out Category is required"),
    safety_cleanliness_rating: z.number().optional(),
    safety_cleanliness_comment: z.string().optional(),
    rides_entertainment_rating: z.number().optional(),
    rides_entertainment_comment: z.string().optional(),
    food_drink_rating: z.number().optional(),
    food_drink_comment: z.string().optional(),
    queues_crowds_rating: z.number().optional(),
    queues_crowds_comment: z.string().optional(),
    fun_delight_rating: z.number().optional(),
    fun_delight_comment: z.string().optional(),
    value_for_money_rating: z.number().optional(),
    value_for_money_comment: z.string().optional(),
    family_creator_image_json: z.object(mediaJsonSchema).array().min(1, { message: "At least 1 image is required"}),
    family_creator_image: z.string().optional(),
    family_creator_name: z.string().min(1, "Family Creator Name"),
    visit_month: z.string().optional(),
    visit_year: z.string().optional(),
    visit_reason: z.string().min(1, "Visit reason is required"),
    familyVisitors: z.object({
        visitor: z.string().optional(),
        age: z.string().optional()
    }).array()
    .optional(),
});

type DiaryForm = z.infer<typeof schema>;

const DiaryOverview: React.FC = () => {
    const { blogId } = useParams();
    const { data, isLoading } = apiSlice.useGetBlogDetailsQuery(blogId ?? "");
    const [uploadContent, { isLoading: isLoadingContent, error }] = apiSlice.useContentMutation();
    const [uploadMedia, {isLoading: isLoadingUploadMedia}] = apiSlice.useUploadImageMutation();
    const [deleteMedia] = apiSlice.useLazyDeleteImageQuery();
    
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [defaultValuesData, setDefaultValuesData] = useState<any>();
    const contentError = useApiError(error);
    

    const {
        register,
        handleSubmit,
        formState: { isValid, isSubmitted, errors, isDirty },
        control,
        setValue,
        getValues,
        reset,
        trigger: triggerValidation,
        clearErrors,
        watch
    } = useForm<DiaryForm>({
        resolver: async (data, context, options) => {
        return zodResolver(schema)(data, context, options);
        },
        defaultValues: {
            visit_year: "2024",
            familyVisitors: [
              { visitor: 'Mum', age: '40' }
            ],
        },
    });

    const description = watch('title', '');
    const overview = watch('overview', '');
    const visitReason = watch('visit_reason', '');
    // Ratings
    const safety_cleanliness_rating = watch('safety_cleanliness_rating', 0);
    const rides_entertainment_rating = watch('rides_entertainment_rating', 0);
    const food_drink_rating = watch('food_drink_rating', 0);
    const queues_crowds_rating = watch('queues_crowds_rating', 0);
    const fun_delight_rating = watch('fun_delight_rating', 0);
    const value_for_money_rating = watch('value_for_money_rating', 0);

    const onSaveMedia = async (key: string, max: number, media: MediaArrayItem[], oldMedia?: MediaArrayItem[]) => {
        // example implementation of images in AttractionImages.tsx

        let form: { [key: string]: string | undefined | null } = {};

        // set all media & caption fields null to be re-set using media array
        for(let i = 0; i < max; i++){
            form[`${key}_${i + 1}_id`] = null;
            form[`${key}_caption_${i + 1}`] = null;
        }

        // loop through media array, upload if needed and set new positions
        for(let i = 0; i < media.length; i++){
            const mediaObject = media[i];
            let id = mediaObject.id;
            if(mediaObject.data && !id){
                const file = await base64ToFile(mediaObject.data);
                const formData = new FormData();
                formData.append("file", file);
                let mediaResponse = await uploadMedia(formData).unwrap();
                if(mediaResponse?.id){
                    if(key === "favourite_image"){
                        form[`favourite_image_id`] = mediaResponse?.id;
                    }else if(key === "family_creator_image"){
                        form[`family_creator_image_id`] = mediaResponse?.id;
                    }else{
                        form[`${key}_${i + 1}_id`] = mediaResponse?.id;
                    }
                }
            }else{
                if(key === "favourite_image"){
                    form[`favourite_image_id`] = id;
                }else if(key === "family_creator_image"){
                    form[`family_creator_image_id`] = id;
                }else{
                    form[`${key}_${i + 1}_id`] = id;
                }
            }
            form[`${key}_caption_${i + 1}`] = mediaObject.caption;
        }

        // find media which has been deleted and call the delete API
        if(oldMedia){
            const deletedMedia = oldMedia.filter((v) => !media.some((m) => m.id == v.id));
            for(let i = 0; i < deletedMedia.length; i++){
                const deletedMediaObject = deletedMedia[i];
                if(deletedMediaObject?.id){
                    await deleteMedia(deletedMediaObject?.id ?? "")
                }
            }
        }
        return form;
    }

    const clearErrorsOnChange = (fieldName: string) => {
		clearErrors(fieldName as any);
	};

    const onSubmit: SubmitHandler<DiaryForm> = async () => {
        const formValues = {
            ...getValues()
        }
        setIsDisabled(true);


        const contentUpdateRequest: any = {
            ...formValues,
            ...(await onSaveMedia("favourite_image", 1, formValues.favourite_image_json, defaultValuesData?.favourite_image_json)),
            ...(await onSaveMedia("family_creator_image", 1, formValues.family_creator_image_json, defaultValuesData?.family_creator_image_json))
        }  

        const filteredPayload = filterProperties<FcnBlogContentRequest>(contentUpdateRequest, FcnBlogContentRequestInterfaceKeys);
        let visitors = getValues("familyVisitors") ? getValues("familyVisitors")?.filter((row) => row?.visitor !== "" && row?.age !== "") : []
        await uploadContent({
            ...filteredPayload,
            blog_id: data?.blog_id,
            content_id: data?.last_modified_content?.content_id,
            visit_date: `${getValues("visit_month") ? `01-${getValues("visit_month")}-${getValues("visit_year")}` : `01-01-${dayjs().format("YYYY")}`}`,
            family_json: JSON.stringify(visitors)
        });
        setIsDisabled(false);
        
    };

    const sumOfRatings = useMemo(() => {
        let total = 0;
        if(safety_cleanliness_rating){
            total += safety_cleanliness_rating
        }
        if(rides_entertainment_rating){
            total += rides_entertainment_rating
        }
        if(food_drink_rating){
            total += food_drink_rating
        }
        if(queues_crowds_rating){
            total += queues_crowds_rating
        }
        if(fun_delight_rating){
            total += fun_delight_rating
        }
        if(value_for_money_rating){
            total += value_for_money_rating
        }

        // A*: 27.6 - 30.0
        // A: 24.6 - 27.5
        // B: 21.6 - 24.5
        // C: 18.6 - 21.5

        if(total >= 27.6 && total <= 30){
            return "A+"
        }else if(total >= 24.6 && total <= 27.5){
            return "A"
        }else if(total >= 21.6 && total <= 24.5){
            return "B"
        }else if(total >= 18.6 && total <= 21.5){
            return "C"
        }else{
            return ""
        }
    }, [safety_cleanliness_rating, rides_entertainment_rating, food_drink_rating, queues_crowds_rating, fun_delight_rating, value_for_money_rating])

    useEffect(() => {
        async function fetchBlogDetails(){
            if(data){
                const { favourite_image, family_creator_image } = data?.last_modified_content ?? {};
                if(favourite_image){
                    const mediaData = await fetchFileData(favourite_image.url);
                    let  urlString = await fileToDataString(mediaData.data);
                    setValue("favourite_image_json.0.data", urlString);
                    setValue("favourite_image_json.0.id", favourite_image?.id);
                    setValue("favourite_image_id", data?.last_modified_content?.favourite_image_id ?? "");
                }
                if(family_creator_image){
                    const mediaData = await fetchFileData(family_creator_image.url);
                    let  urlString = await fileToDataString(mediaData.data);
                    setValue("family_creator_image_json.0.data", urlString);
                    setValue("family_creator_image_json.0.id", family_creator_image?.id);
                    setValue("family_creator_image", data?.last_modified_content?.family_creator_image_id ?? "");
                }
    
                setValue("title", data?.last_modified_content?.title ?? "");
                setValue("overview", data?.last_modified_content?.overview ?? "");
                setValue("category_name", data?.last_modified_content?.category_name ?? "");
                setValue("safety_cleanliness_comment", data?.last_modified_content?.safety_cleanliness_comment ?? "");
                setValue("safety_cleanliness_rating", data?.last_modified_content?.safety_cleanliness_rating ?? 0);
                setValue("rides_entertainment_rating", data?.last_modified_content?.rides_entertainment_rating ?? 0);
                setValue("rides_entertainment_comment", data?.last_modified_content?.rides_entertainment_comment ?? "");
                setValue("food_drink_rating", data?.last_modified_content?.food_drink_rating ?? 0);
                setValue("food_drink_comment", data?.last_modified_content?.food_drink_comment ?? "");
                setValue("queues_crowds_rating", data?.last_modified_content?.queues_crowds_rating ?? 0);
                setValue("queues_crowds_comment", data?.last_modified_content?.queues_crowds_comment ?? "");
                setValue("fun_delight_rating", data?.last_modified_content?.fun_delight_rating ?? 0);
                setValue("fun_delight_comment", data?.last_modified_content?.fun_delight_comment ?? "");
                setValue("value_for_money_rating", data?.last_modified_content?.fun_delight_rating ?? 0);
                setValue("value_for_money_comment", data?.last_modified_content?.value_for_money_comment ?? "");
                if(data?.last_modified_content?.family_json){
                    setValue("familyVisitors", data?.last_modified_content?.family_json ?? [])
                }
                setValue("family_creator_name", data?.last_modified_content?.family_creator_name ?? "");
                if(data?.last_modified_content?.visit_date){
                    setValue("visit_month", dayjs(data?.last_modified_content?.visit_date).format("MM"))
                    setValue("visit_year", dayjs(data?.last_modified_content?.visit_date).format("YYYY"))
                }
                setValue("visit_reason", data?.last_modified_content?.visit_reason ?? "");

                const formValues = getValues();
                const clonedFormValues = JSON.parse(JSON.stringify(formValues));
                setDefaultValuesData(clonedFormValues);
            }
        }
        fetchBlogDetails();
    }, [data]);

    return(
        <div className={`flex flex-col flex-1 bg-[#F5F5FA] doodle-bg ${isLoading && "blur-sm"}`}>
            <DashboardHeaderContainer slim={true}>
            {
                blogId &&
                <DiaryDetailsHeader
                    blogId={blogId}
                />
            }
            </DashboardHeaderContainer>
            <DashboardContainer>
                <form className={`z-10 ${false ? "blur-sm" : ""}`} onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-x-3 px-5 xl:px-[20px] w-full">
                        <div className="w-full p-6 mb-6 bg-white rounded-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/star.png" alt="star"/>
                                {"Your Diary Overview"}
                            </h1>
                            <div className="mt-2 text-sm font-normal text-cs-gray">Before we go into the details, give us a little overview of your family adventure to entice families exploring our website.</div>
                            <div className="flex flex-wrap gap-4 mt-4">
                                <div className="max-w-[60%] w-full">
                                    <label className={`group-focus-within:text-cs-pink text-sm font-bold ${errors?.favourite_image_json && errors?.favourite_image_json[0]?.data ? "text-cs-red" : ""}`}>
                                        Favourite Image
                                        <span className="text-cs-pink"> *</span>
                                    </label>
                                    <div className="text-sm font-normal text-cs-gray my-1.5">Share a photo that captures the best moment of your visit and highlights the attraction. This will be the ‘hero image’ for your day out diary. Aim for a picture featuring your whole family or the entrance to make it extra special!</div>
                                    <ImageUpload 
                                        control={control} 
                                        clearErrors={clearErrorsOnChange} 
                                        name="favourite_image_json.0.data"
                                        setValue={setValue}
                                        className={`dropzone flex items-center justify-center w-full md:w-[279px] h-[179px] border border-dotted ${errors?.favourite_image_json && errors?.favourite_image_json[0]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                        imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                        isSquareCrop={false}
                                    />
                                    <div className="mt-6 space-y-2">
                                        <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.title ? "text-cs-red" : ""}`}>
                                            Day Out Diary Title
                                            <span className="text-cs-pink"> *</span>
                                        </label>
                                        <div className="text-sm font-normal text-cs-gray my-1.5">Create a title that includes the attraction's name and makes it stand out.</div>
                                        <Controller
                                            name="title"
                                            control={control}
                                            defaultValue=""
                                            rules={{ maxLength: 50 }}
                                            render={({ field }) => (
                                                <textarea
                                                    {...field}
                                                    id="title"
                                                    rows={2}
                                                    className={`border-cs-1 ${errors?.title ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10`}
                                                    placeholder="Example: Unforgettable Adventures: Our Day at South Safari Lakes!"
                                                    maxLength={50}
                                                    onChange={(e) => {
                                                        setValue('title', e?.target?.value);
                                                        clearErrorsOnChange('title');
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className="flex justify-between gap-x-2 !mt-0">
                                            {
                                                errors?.title &&
                                                <ErrorMessage>{errors?.title?.message}</ErrorMessage>
                                            }
                                            <p className="mt-3 ml-auto text-sm text-cs-gray text-end">{description.length} / 50 characters</p>
                                        </div>
                                    </div>
                                    <div className="mt-6 space-y-2">
                                        <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.overview ? "text-cs-red" : ""}`}>
                                            Short Overview
                                            <span className="text-cs-pink"> *</span>
                                        </label>
                                        <div className="text-sm font-normal text-cs-gray my-1.5">Include the name of the attraction, what made it memorable, and what readers should expect to find out when reading.</div>
                                        <Controller
                                            name="overview"
                                            control={control}
                                            defaultValue=""
                                            rules={{ maxLength: 50 }}
                                            render={({ field }) => (
                                                <textarea
                                                    {...field}
                                                    id="overview"
                                                    // rows={4}
                                                    className={`border-cs-1 ${errors?.overview ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16`}
                                                    placeholder="For example: Discover why our day at [Attraction Name] was a hit... unexpected thrills, tasty snacks, and family fun. You won't believe what happened!"
                                                    maxLength={70}
                                                    onChange={(e) => {
                                                        setValue('overview', e?.target?.value);
                                                        clearErrorsOnChange('overview');
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className="flex justify-between gap-x-2 !mt-0">
                                            {
                                                errors?.overview &&
                                                <ErrorMessage>{errors?.overview?.message}</ErrorMessage>
                                            }
                                            <p className="mt-3 ml-auto text-sm text-cs-gray text-end">{overview.length} / 70 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ButtonMultiSelect 
                                containerClassName="my-4 w-full max-w-[600px]"
                                control={control}
                                label="Day Out Category"
                                subLabel="Which category does the day out fall under? Please select one category that is best fit."
                                options={dayOutCategories}
                                name="category_name"
                                selectOptionsContainerClassName="overflow-y-auto max-h-[170px]"
                                required
                                maxHeight={170}
                                clearErrors={clearErrorsOnChange} 
                            />  
                        </div>
                        <div className="px-6 py-8 mb-6 bg-white rounded-b-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/star.png" alt="star"/>
                                {"Tried & Tested Score"}
                            </h1>
                            <div className="mt-2 text-sm font-normal text-cs-gray">Rate your day out! Give a real, honest rating from 1 to 5 stars for each category and share why you gave that score</div>
                            <div className="flex flex-wrap items-center justify-center gap-4 px-8 py-3 mx-auto my-6 rounded-lg w-fit shadow-cs-rating">
                                <div className="relative">
                                    <img src={"/assets/www/Tried&Tested.png"} alt="Tried & Tested"/>
                                    <span className="absolute left-1/2 -translate-x-1/2 bg-white text-center font-love block text-cs-46 top-[37px]">{sumOfRatings ?? "A+"}</span>
                                </div>
                                <img src={"/assets/www/add-ratings.png"} alt="Add ratings"/>
                            </div>    
                            <div className="flex flex-wrap justify-around gap-8 mb-6">
                                <div className="w-full max-w-cs-400">
                                    <RatingInput 
                                        label="Safety & Cleanliness"
                                        subLabel="How would you rate the safety and cleanliness of the venue?"
                                        name="safety_cleanliness_rating"
                                        control={control}
                                    />
                                    <Controller
                                        name="safety_cleanliness_comment"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                id="safety_cleanliness_comment"
                                                className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 mt-2 text-sm flex flex-col w-full focus:outline-cs-pink h-28"
                                                placeholder="For example: I’d rate the cleanliness 4/5. The bathrooms were spotless, and the picnic areas were well-maintained. However, we did notice a few littered spots near the playground"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-full max-w-cs-400">
                                    <RatingInput 
                                        label="Rides & Entertainment"
                                        subLabel="How satisfied are you with the rides and entertainment options?"
                                        name="rides_entertainment_rating"
                                        control={control}
                                    />
                                    <Controller
                                        name="rides_entertainment_comment"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                id="rides_entertainment_comment"
                                                className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 mt-2 text-sm flex flex-col w-full focus:outline-cs-pink h-28"
                                                placeholder="For example: We rated it 5/5 for rides and entertainment. There was honestly something for everyone... even my teenager didn’t get bored and that is a first!"
                                            />
                                        )}
                                    />
                                </div>
                            </div>   
                            <div className="flex flex-wrap justify-around gap-8 mb-6">
                                <div className="w-full max-w-cs-400">
                                    <RatingInput 
                                        label="Food & Drink"
                                        subLabel="How do you feel about the quality and variety of food and drink available?"
                                        name="food_drink_rating"
                                        control={control}
                                    />
                                    <Controller
                                        name="food_drink_comment"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                id="food_drink_comment"
                                                className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 mt-2 text-sm flex flex-col w-full focus:outline-cs-pink h-28"
                                                placeholder="For example: We gave the food and drink a 4 rating There was plenty of options including healthy food, vegan options and also gluten free. The only downside was that it was pretty expensive."
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-full max-w-cs-400">
                                    <RatingInput 
                                        label="Queues & Crowds"
                                        subLabel="How well were queues and crowds managed during your visit?"
                                        name="queues_crowds_rating"
                                        control={control}
                                    />
                                    <Controller
                                        name="queues_crowds_comment"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                id="queues_crowds_comment"
                                                className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 mt-2 text-sm flex flex-col w-full focus:outline-cs-pink h-28"
                                                placeholder="For example: We rated a 2/5 for queues and crowds. We had to wait an hour when we arrived, and the kids started to get fed up. There were big queues to get onto most of the rides too. "
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-around gap-8 mb-6">
                                <div className="w-full max-w-cs-400">
                                    <RatingInput 
                                        label="Fun & Delight"
                                        subLabel="How would you rate the overall fun and enjoyment of your visit?"
                                        name="fun_delight_rating"
                                        control={control}
                                    />
                                    <Controller
                                        name="fun_delight_comment"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                id="fun_delight_comment"
                                                className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 mt-2 text-sm flex flex-col w-full focus:outline-cs-pink h-28"
                                                placeholder="For example: We gave a 4 rating for fun and delight. There were plenty of activities for our kids, and the staff was super friendly. The only downside was the lack of shaded areas for resting"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="w-full max-w-cs-400">
                                    <RatingInput 
                                        label="Value For Money"
                                        subLabel="How would you rate the value for money for your family’s visit?"
                                        name="value_for_money_rating"
                                        control={control}
                                    />
                                    <Controller
                                        name="value_for_money_comment"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                id="value_for_money_comment"
                                                className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 mt-2 text-sm flex flex-col w-full focus:outline-cs-pink h-28"
                                                placeholder="For example: We rated 5/5 for value for money. The ticket prices were reasonable, and we felt we got a lot of bang for our buck with all the included activities and attractions"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-6 bg-white rounded-t-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/star.png" alt="star"/>
                                {"Who joined you on the day out?"}
                            </h1>
                            <div className="mt-2 text-sm font-normal text-cs-gray">Tell us who was part of your day out crew!</div>
                            <div className="w-full mt-4 max-w-cs-574">
                                <label className={`group-focus-within:text-cs-pink text-sm font-bold ${errors?.family_creator_image_json && errors?.family_creator_image_json[0]?.data ? "text-cs-red" : ""}`}>
                                    Family Picture
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <div className="text-sm font-normal text-cs-gray my-1.5">Upload a photo that shows everyone who joined you on the trip.</div>
                                <ImageUpload 
                                    control={control} 
                                    clearErrors={clearErrorsOnChange} 
                                    name="family_creator_image_json.0.data"
                                    setValue={setValue}
                                    className={`dropzone flex items-center justify-center w-full md:w-[179px] h-[179px] border border-dotted ${errors?.family_creator_image_json && errors?.family_creator_image_json[0]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                    imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                    isSquareCrop={true}
                                />
                                <TextInput
                                    className="w-full my-4"
                                    labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                                    inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full max-w-[437px] focus:outline-cs-pink h-10"
                                    control={control}
                                    name={"family_creator_name"}
                                    label="Your Full Name"
                                    required
                                    inputProps={{
                                        ...register('family_creator_name'),
                                        onChange: (e) => {
                                            register('family_creator_name').onChange(e);
                                            clearErrorsOnChange('family_creator_name');
                                        },
                                        placeholder: "Family Creator Name"
                                    }}
                                />
                                <MultipleFieldArray
                                    control={control}
                                    name="familyVisitors"
                                    firstSelectName="visitor"
                                    firstSelectOptions={visitorType}
                                    secondSelectName="age"
                                    secondSelectOptions={ages}
                                    watch={watch}
                                    setValue={setValue}
                                />
                                <div>
                                    <label className="group-focus-within:text-cs-pink text-sm font-bold my-1.5 block">When did you visit?</label>
                                    <div className="flex flex-wrap gap-4">
                                        <Controller
                                            name="visit_month"
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return(
                                                    <Select
                                                        className="w-[148px]"
                                                        placeholder="Month"
                                                        components={{ Option: SingleSelectOption, IndicatorSeparator: () => null }}
                                                        options={months}
                                                        value={months.find(option => option.value === value || "")}
                                                        onChange={(selectedOption) => onChange(selectedOption?.value || "")}
                                                        isSearchable={false}
                                                        styles={{
                                                            menu: (styles) => ({
                                                                ...styles,
                                                                margin: 0,
                                                                width: "100%",
                                                                borderRadius: '6px',
                                                            }),
                                                            menuList: (base) => ({
                                                                ...base,
                                                                marginTop: 0,
                                                                marginBottom: 0
                                                            }),
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                borderRadius: '6px'
                                                            }),
                                                            placeholder: (provided) => ({
                                                                ...provided,
                                                                color: '#CFDBD5', 
                                                            }),
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                        <Controller
                                            name="visit_year"
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return(
                                                    <Select
                                                        className="w-92"
                                                        placeholder="Year"
                                                        components={{ Option: SingleSelectOption, IndicatorSeparator: () => null }}
                                                        options={years}
                                                        value={years.find(option => option.value === value || "")}
                                                        onChange={(selectedOption) => onChange(selectedOption?.value || "")}
                                                        isSearchable={false}
                                                        styles={{
                                                            menu: (styles) => ({
                                                                ...styles,
                                                                margin: 0,
                                                                width: "100%",
                                                                borderRadius: '6px'
                                                            }),
                                                            menuList: (base) => ({
                                                                ...base,
                                                                marginTop: 0,
                                                                marginBottom: 0
                                                            }),
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                borderRadius: '6px'
                                                            }),
                                                            placeholder: (provided) => ({
                                                                ...provided,
                                                                color: '#CFDBD5', 
                                                            }),
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-6 space-y-2">
                                    <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.visit_reason ? "text-cs-red" : ""}`}>
                                        How did your family visit come about?
                                        <span className="text-cs-pink"> *</span>
                                    </label>
                                    <div className="text-sm font-normal text-cs-gray my-1.5">Tell us what inspired the day out, and any special reasons for choosing this attraction.</div>
                                    <Controller
                                        name="visit_reason"
                                        control={control}
                                        defaultValue=""
                                        rules={{ maxLength: 200 }}
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                id="visit_reason"
                                                rows={2}
                                                className={`border-cs-1 ${errors?.visit_reason ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36`}
                                                placeholder="For example: We decided to visit the zoo because our kids love animals and it was a sunny weekend. We found a great deal on tickets online and planned our visit for weeks, marking the date on our family calendar. It was a special trip to celebrate our daughter's birthday."
                                                maxLength={200}
                                                onChange={(e) => {
                                                    setValue('visit_reason', e?.target?.value);
                                                    clearErrorsOnChange('visit_reason');
                                                }}
                                            />
                                        )}
                                    />
                                    <div className="flex justify-between gap-x-2 !mt-0">
                                        {
                                            errors?.visit_reason &&
                                            <ErrorMessage>{errors?.visit_reason?.message}</ErrorMessage>
                                        }
                                        <p className="mt-3 ml-auto text-sm text-cs-gray text-end">{visitReason.length} / 200</p>
                                    </div>
                                </div>
                            </div>
                            <button 
                                className={`px-4 py-2 text-sm font-bold text-white rounded-lg bg-cs-pink ${isDisabled ? "opacity-50" : "opacity-100"}`} 
                                type="submit"
                                disabled={isDisabled}
                            >
                                Save Changes
                            </button>
                            {
                                contentError &&
                                <p className="mt-2 text-sm text-cs-red">{contentError}</p>
                            }
                        </div>
                    </div>
                </form>
            </DashboardContainer>
        </div>
    )
}

export default DiaryOverview;

