import { components } from "react-select";

const SingleSelectOption: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: 16,
    fontWeight: 400,
    height: 0,
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    margin: 0
  };

  const props = {
    ...innerProps,
    style
  };

  return (
    <components.Option
    {...rest}
    isFocused={isFocused}
    getStyles={getStyles}
    innerProps={props}
    >
      {children}
    </components.Option>
  );
};

export default SingleSelectOption;