import {
  Controller,
  useFormContext,
} from "react-hook-form";
import Select from "react-select";

export function TicketCategory({
  index,
  update,
}: {
  index: number;
  update: any;
}) {
  const { control } = useFormContext();
  const categories = [
    { value: "adult", label: "Adult" },
    { value: "child", label: "Child" },
    { value: "infant", label: "Infant" },
    { value: "senior", label: "Senior" },
    { value: "student", label: "Student" },
    { value: "military", label: "Military" },
    { value: "family", label: "Family" },
    { value: "group", label: "Group" },
  ];
  return (
    <Controller
      name={`ticketType.${index}.category`}
      control={control}
      render={({ field }) => (
        <Select
          required={true}
          {...field}
          value={categories.find((option: any) => option.value === field.value)}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                className="-translate-x-[14px]"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.05469 0.853516L4.5 4.29883L7.94531 0.853516L9 1.9082L4.5 6.4082L0 1.9082L1.05469 0.853516Z"
                  fill="#212121"
                />
              </svg>
            ),
          }}
          options={categories}
          onChange={(v) => {
            field.onChange((v as any)?.value);

            if (["adult", "child"].includes((v as any)?.value)) {
              update({
                min_age: (v as any)?.value === "adult" ? 15 : 3,
                max_age: (v as any)?.value === "adult" ? 100 : 14,
              });
            }
          }}
          styles={{
            menu: (styles) => ({
              ...styles,
              margin: 0,
              border: "none",
              zIndex: 15,
            }),
            menuList: (base, isSelected) => ({
              ...base,
              paddingTop: 0,
              paddingBottom: 0,
              background: "white",

              "::-webkit-scrollbar": {
                width: 14,
              },
              "::-webkit-scrollbar-thumb": {
                background: "#c2c2c2",
                borderRadius: 10,
                border: "4px solid white",
              },
            }),
            option: (base, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...base,
                background: "white",
                border: "1px solid #c2c2c2",
                color: "#555",
                "&:hover": {
                  backgroundColor: "#6836D1",
                  color: isSelected || isFocused ? "white" : "#555",
                  border: isFocused ? "1px solid white" : "",
                },
                "&:active": {
                  backgroundColor: "#6836D1",
                  color: "white",
                },
              };
            },
            singleValue: (base) => ({
              ...base,
              color: "#212121",
              fontSize: 14,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }),
            control: (styles, state) => ({
              ...styles,
              cursor: "pointer",
              outline: "none",
              backgroundColor: "#F1F1F1",
              border: "none",
              boxShadow: "none",
              paddingTop: 0,
              paddingBottom: 0,
              margin: 0,
              minHeight: 31,
              width: 81,
              height: 31,
              borderRadius: 99,
              paddingLeft: 0,
              color: "#212121",
              fontSize: 14,
              fontWeight: 400,
              display: "flex",
            }),
            valueContainer: (styles, state) => ({
              ...styles,
              width: "100%",
            }),
          }}
        />
      )}
    />
  );
}
