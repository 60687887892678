import { useEffect } from "react"

export const restartAnimation = () => {
    document.getElementById("toastNotification")?.classList.remove("animate-animateInOut")

    setTimeout(() => { 
        document.getElementById("toastNotification")?.classList.add("animate-animateInOut")
    }, 500)
}

const ToastNotification: React.FC<IToastNotification> = ({ pendingType, type, message, attractionApprovalStatus, attractionImage, attractionName, attractionAddress }) => {
    return(
        <div id="toastNotification" className={`flex flex-col px-5 py-3 fixed -right-full  bottom-6 m-auto rounded-lg font-bold z-[2147483639] ${attractionApprovalStatus ? "max-w-cs-540 w-full" : ""} ${type === "SUCCESS" ? attractionApprovalStatus && attractionApprovalStatus == "DENIED" || attractionApprovalStatus == "DENIED_NEW" ? "bg-cs-red text-white" : "bg-cs-blue text-white" :  type === "ERROR" ? "bg-cs-red text-white" : "bg-cs-off-white text-cs-gray"}`}>
           {
            attractionApprovalStatus ?
                attractionApprovalStatus == "APPROVED" || attractionApprovalStatus == "APPROVED_NEW" ?
                    <>
                        <h5 className="font-bold text-white text-lg w-full mb-3 leading-none">{pendingType ? pendingType == "claim" ? "Claim Approved!" : "New Attraction Approved!" : "Claim Approved!"}</h5>
                        <span className={`w-10 h-10 text-sm rounded-full text-white font-bold flex items-center justify-center absolute right-3 top-0 -translate-y-2/4 bg-white border-2 border-cs-success-green`}>
                            <svg version="1.1" width="16px" height="12px" xmlns="http://www.w3.org/2000/svg">
                                <g transform="matrix(1 0 0 1 -1225 -31 )">
                                    <path d="M 15.710967741935484 1.656565656565657  C 15.903655913978497 1.8451178451178447  16.000000000000004 2.074074074074074  16 2.3434343434343434  C 16.000000000000004 2.6127946127946116  15.903655913978497 2.841750841750841  15.710967741935484 3.03030303030303  L 8.23741935483871 10.343434343434343  L 6.833548387096775 11.717171717171716  C 6.640860215053764 11.905723905723907  6.406881720430109 12  6.131612903225807 12  C 5.8563440860215055 12  5.62236559139785 11.905723905723907  5.429677419354839 11.717171717171716  L 4.025806451612903 10.343434343434343  L 0.28903225806451616 6.686868686868688  C 0.09634408602150547 6.498316498316497  0 6.269360269360268  0 6  C 0 5.73063973063973  0.09634408602150547 5.501683501683502  0.28903225806451616 5.313131313131313  L 1.6929032258064516 3.9393939393939394  C 1.8855913978494625 3.7508417508417504  2.1195698924731188 3.6565656565656552  2.3948387096774195 3.6565656565656566  C 2.670107526881721 3.6565656565656552  2.9040860215053765 3.7508417508417504  3.096774193548387 3.9393939393939394  L 6.131612903225807 6.919191919191919  L 12.903225806451612 0.2828282828282829  C 13.095913978494625 0.09427609427609385  13.329892473118282 0  13.605161290322581 0  C 13.880430107526882 0  14.114408602150538 0.09427609427609385  14.307096774193548 0.2828282828282829  L 15.710967741935484 1.656565656565657  Z " fillRule="nonzero" fill="#5aa922" stroke="none" transform="matrix(1 0 0 1 1225 31 )" />
                                </g>
                            </svg>
                        </span>
                        <div className="border-cs-white bg-white flex w-full rounded-lg overflow-hidden">
                            <div className={`w-full max-w-cs-112 bg-contain bg-no-repeat bg-cs-off-white bg-center bg-[url('../public/assets/www/logo.svg')]`}></div>
                            <div className="p-5 w-full max-w-cs-320">
                                <h4 className="font-bold text-cs-pink">{attractionName}</h4>
                                <span className="font-normal text-cs-gray text-xs block">{attractionAddress}</span>
                            </div>
                        </div>
                    </>
                :
                attractionApprovalStatus == "DENIED" || attractionApprovalStatus == "DENIED_NEW" ?
                    <>
                        <h5 className="font-bold text-white text-lg w-full mb-3 leading-none">{pendingType ? pendingType == "claim" ? "Claim Denied!" : "New Attraction Denied!" : "Claim Denied!"}</h5>
                        <span className={`w-10 h-10 text-sm rounded-full text-white font-bold flex items-center justify-center absolute right-3 top-0 -translate-y-2/4 bg-cs-red border-cs-1 border-black`}>
                            X
                        </span>
                        <div className="border-cs-white bg-white flex w-full rounded-lg overflow-hidden">
                            <div className={`w-full max-w-cs-112 bg-contain bg-no-repeat bg-cs-off-white bg-center bg-[url('../public/assets/www/logo.svg')]`}></div>
                            <div className="p-5 w-full max-w-cs-320">
                                <h4 className="font-bold text-cs-pink">{attractionName}</h4>
                                <span className="font-normal text-cs-gray text-xs block">{attractionAddress}</span>
                            </div>
                        </div>
                    </>
                :
                message
            : message
            }
        </div>
    )
}

export default ToastNotification;