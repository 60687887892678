export function filterPayload<T extends object>(
  obj: T,
  keys: (keyof T)[],
  isActive: boolean
): Partial<T> {
  if (!isActive) return obj;
  return keys.reduce((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as Partial<T>);
}
