import { type ReactNode } from "react";

export function TwoColTemplate({
  children,
  sidebarImage,
  sidebarText,
  smallSidebarText = "",
  imageClassName = "",
  leftColMaxWidth = "440px",
}: {
  children: ReactNode;
  sidebarText: string;
  sidebarImage: string | ReactNode;
  smallSidebarText?: string;
  imageClassName?: string;
  leftColMaxWidth?: string;
}) {
  return (
    <section className="bg-white flex">
      <div className="flex flex-grow px-10 py-10">
        <div className="flex flex-col mx-auto">
          <div className="flex items-center gap-2 mb-2">
            <div>
              <img
                src={"/assets/amp/connect-logo-full.png"}
                width={170}
                className="xl:block"
                alt="Connect"
              />
            </div>
          </div>
          <div className={`my-auto max-w-[${leftColMaxWidth}]`}>{children}</div>

          <div className="mt-auto text-black text-sm font-normal">
            Need help?{" "}
            <a
              href="mailto:connect@dayoutwiththekids.co.uk"
              className="font-bold text-sm text-[#6836D1]"
            >
              Contact us
            </a>
          </div>
        </div>
      </div>

      <div
        className="w-[48%] px-16 xl:px-24 hidden lg:flex flex-col justify-center h-screen sticky top-0"
        style={{
          background: "linear-gradient(92deg, #FA2080 26.05%, #1997F4 100.05%)",
        }}
      >
        {typeof sidebarImage === "string" ? (
          <img src={sidebarImage} alt="" className={imageClassName} />
        ) : (
          <>{sidebarImage}</>
        )}

        <div className="text-white font-bold text-2xl mt-12 text-center leading-8">
          {sidebarText}

          {smallSidebarText && (
            <p className="text-sm font-light mt-2.5 text-center">
              {smallSidebarText}
            </p>
          )}
        </div>
      </div>
    </section>
  );
}

export function Heading({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <h1
      className={`text-3xl sm:text-[32px] font-extrabold text-black ${className}`}
    >
      {children}
    </h1>
  );
}

export function Description({
  children,
  margin = true,
}: {
  children: ReactNode;
  margin?: boolean;
}) {
  return (
    <p
      className={`font-normal text-sm text-[#5F646D] ${margin ? "mt-2.5" : ""}`}
    >
      {children}
    </p>
  );
}
