import { useEffect, useState } from "react";
import { apiSlice } from "../../../store/apiSlice";
import moment from "moment";
import { Link } from "react-router-dom";


interface IDiaryDetailsHeader {
    blogId: string
}

const DiaryDetailsHeader: React.FC<IDiaryDetailsHeader> = (props) => {

    const { blogId } = props ?? {};

    const { data, isLoading,  } = apiSlice.useGetBlogDetailsQuery(blogId ?? "");

    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
    const [resolveContent, {isLoading: isLoadingResolve, error: resolveError}] = apiSlice.useResolveContentMutation();
    const [resolveType, setResolveType] = useState<string>("");
    const [isFeatured, setIsFeatured] = useState(false);
    const [uploadContent, { isLoading: isLoadingContent, error }] = apiSlice.useContentMutation();
    const { data: siteConfig } = apiSlice.useGetSiteConfigQuery();

    useEffect(() => {
        if(data){
            setIsFeatured(data.is_featured == null ? false : data.is_featured)
        }
    }, [data])

    const handlePreviewContent = async () => {
        setIsLoadingPreview(true);
        try {
          const response = await fetch(`/api/amp/v1/fcn/blogs/content/${data?.last_modified_content?.content_id}/preview`);
          const url = await response.text();
          if(url){
            window.open(url, '_blank', 'noopener,noreferrer');
          }
        } catch (err) {
            console.log("Preview Error: ", err)
        } finally{
            setIsLoadingPreview(false);
        }
    };

    const handleResolveContent = async (type: string) => {
        setResolveType(type);
        if(type === "approve"){
            await resolveContent({
                approve: true,
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else if(type === "deny"){
            await resolveContent({
                approve: false,
                reason: "",
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else{
            console.log("No resolve type selected")
        }
    }

    const onToggleFeatured = (featured: boolean) => {
        setIsFeatured(featured);
        uploadContent({ 
            is_featured: !isFeatured,
            blog_id: data?.blog_id,
            content_id: data?.last_modified_content?.content_id,
        });
    }

    return(
        <div className="flex items-center justify-between flex-grow gap-x-2">
            <div className="flex flex-col">
                <h1 className="text-xl font-extrabold text-black whitespace-pre">
                    {`${data?.last_modified_content?.title} by ${data?.last_modified_content?.family_creator_name}`}
                </h1>
                {/*{
                    data?.last_modified_content?.content_status == "Declined" &&
                     <div className="text-xs"><span className="">Declined</span> on {moment(data.last_modified_content.date_resolved).format("LLLL")}</div>
                }*/}
            </div>
            <div className="relative w-full flex justify-end items-center flex-wrap gap-x-2.5">
                <label className="flex items-center justify-center py-1.5 text-sm font-bold bg-transparent border border-black rounded-lg w-cs-145">                    
                    <input
                        className="mt-0.5 mr-2"
                        checked={isFeatured}
                        onChange={(e) => {
                            onToggleFeatured(e.target.checked)
                        }}
                        type="checkbox"
                    />
                    <span>Featured</span>                    
                </label>
                {
                    data?.last_modified_content?.content_status != "Published" && 
                    <button 
                        className={`flex items-center justify-center py-1.5 text-sm font-bold bg-transparent border border-black rounded-lg w-cs-145 ${isLoadingPreview ? "opacity-50" : "opacity-100"} hover:opacity-80`} 
                        type="button"
                        disabled={isLoadingPreview}
                        onClick={() => {
                            handlePreviewContent()
                        }}
                    >
                        Preview
                    </button>
                }
                {
                    data?.last_modified_content?.content_status === "Pending" &&
                    <>
                        <button 
                            className={`flex w-cs-145 py-1.5 items-center justify-center bg-[#FF4246] border border-[#FF4246] text-sm font-bold rounded-lg hover:opacity-80 ${resolveType === "deny" && isLoadingResolve ? "opacity-50" : "opacity-100"}`} 
                            type="button"
                            disabled={isLoadingResolve}
                            onClick={() => handleResolveContent("deny")}
                        >
                            Deny
                        </button>
                        <button 
                            className={`flex w-cs-145 py-1.5 items-center justify-center bg-[#B2FF86] border border-[#B2FF86] text-sm font-bold rounded-lg hover:opacity-80 ${resolveType === "approve" && isLoadingResolve ? "opacity-50" : "opacity-100"}`} 
                            type="button"
                            disabled={isLoadingResolve}
                            onClick={() => handleResolveContent("approve")}
                        >
                            Approve
                        </button>
                    </>
                }
                {
                    data?.last_modified_content?.content_status == "Published" &&
                    <>
                        <Link className="text-center w-cs-145 py-1.5 bg-transparent border border-black text-sm font-bold rounded-lg hover:opacity-80" target={"_blank"} to={siteConfig?.siteUrl ? `${siteConfig?.siteUrl}/blogs/${data.slug}` : "#"}>
                            View Diary
                        </Link>
                    </>
                }
            </div>
        </div>
    )
}

export default DiaryDetailsHeader;