import { useEffect, useState } from "react";
import { apiSlice } from "../../../store/apiSlice";

const Footer: React.FC<{ full?: boolean }> = ({ full = false }) => {

    const { data } = apiSlice.useGetVersionNumberQuery();
    const { version } = data ?? {}; 

    return(
        <footer className={`flex flex-col mt-auto bg-[#F5F5FA] ${full ? '' : 'xl:pl-[300px]'}`}>
            <section className={`flex flex-col items-center p-5 text-center text-cs-gray xl:bg-white ${full ? '' : 'xl:mx-10'}`}>
                
                <p className="py-1 text-sm font-normal">{version} | © Copyright Day Out With The Kids Ltd. All rights reserved.</p>
                <p className="py-1 text-sm font-normal">Registered in England and Wales, company number: 05813603</p>
                <p className="py-1 text-sm font-normal">Registered address: Day Out With The Kids, First Floor, 1 The Courtyard, 707 Warwick Road, Solihull, BD1 3DA.</p>
            </section>
        </footer>
    )
}

export default Footer;