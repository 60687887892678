import {
  Link,
  ScrollRestoration,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { ATTRACTION_DATA_LOCAL_STORAGE_KEY, AttractionCard } from "../../components/global/www/modals/CreateClaimModal";
import { useAppSelector } from "../../store";
import { useAuthUser } from "../../hooks/useAuth";
import {
  Description,
  Heading,
  TwoColTemplate,
} from "../../components/global/TwoColTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { EmailVerificationButton } from "../../components/global/www/modals/VerifyEmailModal";

const SkipPayment: React.FC = () => {
  const { user } = useAppSelector((state) => state.global);
  const { isLoggedIn } = useAuthUser();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const attraction = {
    id: searchParams.get("id") ?? "",
    title: searchParams.get("title") ?? "",
    location: searchParams.get("location") ?? "",
    location_name: searchParams.get("location_name") ?? undefined,
    image: searchParams.get("image") ?? "",
    image_alt: searchParams.get("image_alt") ?? "",
  };

  useEffect(() => {
    localStorage.removeItem(ATTRACTION_DATA_LOCAL_STORAGE_KEY)
    if (isLoggedIn && user?.role === "ATTRACTION_MANAGER") {
      navigate("/forbidden");
    }
  }, []);

  return (
    <>
      <ScrollRestoration />

      <TwoColTemplate
        sidebarText={"With over 10 million impressions on Google per month, harness the power of our huge website and unlock new brand visibility."}
        sidebarImage={"/assets/www/login-image-2.png"}
      >
        <div className="flex flex-col my-[74px]">
          <Heading>
            <div className="text-[32px]">Thanks!</div>
            <div className="text-[28px]">
              You’re almost there, simply click "pay and publish" from your dashboard when you’re ready to pay.
            </div>
          </Heading>

          <Description>
          {isLoggedIn ? 
          'We\'ve sent an email to your email address with a reminder to pay for your listing and confirmation of your listing information.' :  
          'We\'ll review your claim as quickly as we can and email you when we\'re done.'}
          </Description>

          {isLoggedIn && <div>
            <EmailVerificationButton
              label="Didn't receive the email?"
              className="text-sm text-[#5F646D] font-normal underline ml-0.5"
              email={user?.email ?? ''}
            />
          </div>}
      
          <div className="w-full mx-auto max-w-cs-616 mt-5">
            <AttractionCard
              className="w-full"
              title={attraction?.title ?? ""}
              location={attraction?.location_name ?? attraction?.location ?? ""}
              image={attraction?.image ?? ""}
              imageAlt={attraction?.image_alt ?? ""}
            />
          </div>
          <div className="mt-6">
            <Heading>What happens now?</Heading>

            <Description>
            Once you've added your payment details to your listing, a member of our team will verify your claim. Feel free to head to the dashboard to upload your content, but you can only publish once payment has been made and your claim is verified.
            <br />
            We verify all attractions to ensure that only the rightful owner is able to manage content, please allow up to 5 working days for your claim to be verified.
            </Description>

            {isLoggedIn && <Link
              to="/dashboard"
              className="font-normal text-sm mt-5 flex items-center gap-2.5"
            >
              Go to your dashboard{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M7.1875 4.875L12.8125 10.5L7.1875 16.125"
                  stroke="black"
                  strokeWidth="1.875"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>}
          </div>
        </div>
      </TwoColTemplate>
    </>
  );
};

export default SkipPayment;
