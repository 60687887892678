import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { PrimaryButton } from "../../../../components/global/PrimaryButton";
import TextInput from "../../../../components/form/TextInput";

const EditPricing = ({ pricing, callback }: {
    pricing: DOWTKAvailabilityPeriodTicketPricing,
    callback: (data?: DOWTKAvailabilityPeriodTicketPricing) => void
}) => {

    const formSchema = z.object({
        id: z.string().optional(),
        price: z.object({
            value: z.string().nonempty({ message: "Please enter a price for this period." }),
        }),
    });

    const formMethods = useForm<DOWTKAvailabilityPeriodTicketPricing>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            id: pricing?.id || undefined,
            price: pricing.price,
            ticket_id: pricing.ticket_id,
            availability_timeslot_id: pricing.availability_timeslot_id,
            period_id: pricing.period_id,
        }
    })

    const onSubmit = () => {
        return () => { callback(formMethods.getValues()) }
    }

    const cancel = () => {
        return () => { callback() }
    }

    return (
        <div className="fixed w-full h-full top-0 left-0 z-[101] flex flex-col before:content-[''] before:bg-opacity-50 before:bg-black before:w-full before:h-full before:fixed">
            <div className="rounded-lg p-6 absolute top-2/4 right-2/4 -translate-y-2/4 translate-x-2/4 flex flex-col min-w-full md:min-w-cs-787 min-h-cs-394 items-center bg-white">
                <h3 className="text-2xl font-bold text-cs-gray">
                    Change Pricing
                </h3>
                <p className="font-normal my-3 text-base text-cs-gray">
                    Choose settings
                </p>
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onSubmit())}>
                        <legend className="mb-3 text-lg font-bold text-cs-gray">
                        </legend>
                        <TextInput
                            name={"price"}
                            className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
                            labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
                            inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink"
                            label="Price"
                            control={formMethods.control}
                            inputProps={{
                                ...formMethods.register('price.value'),
                                type: "float",
                            }} />
                        <div className="flex items-center gap-5 max-w-[350px] mt-5">
                            <PrimaryButton scale="sm" type="submit">
                                OK
                            </PrimaryButton>
                            <PrimaryButton scale="sm" onClick={cancel()}>
                                Cancel
                            </PrimaryButton>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
}

export default EditPricing