import { Link } from "react-router-dom";
import { TinyArrow } from "../../pages/amp/dashboard/Dashboard";
import { VenueOptScoreProgressCircular } from "./VenueOptScoreProgressCircular";
import { apiSlice } from "../../store/apiSlice";

export function SidebarVenueOptScore({
  attractionId,
  plan,
  score,
  maxScore,
  isLocked,
}: {
  attractionId: string;
  plan: AttractionDetails["listing_package"];
  score: number;
  maxScore: number;
  isLocked: boolean;
}) {
  const { data: getAttraction } = apiSlice.useGetAttractionQuery(
    attractionId ?? ""
  );
  const { result } = getAttraction ?? {};
  const attractionName = result?.name ?? "-";
  return (
    <div className="py-[22px] px-[33px] mt-auto">
      <div
        id="attractionOptimizationScoreNavigation"
        className="text-sm bg-white py-[15px] px-2.5 rounded-lg flex items-center gap-2.5"
      >
        {isLocked ? (
          <Locked
            score={score}
            maxScore={maxScore}
            attractionId={attractionId}
            attractionName={attractionName}
          />
        ) : (
          <Normal
            score={score}
            maxScore={maxScore}
            attractionId={attractionId}
            attractionName={attractionName}
          />
        )}
      </div>
    </div>
  );
}

function Normal({
  score,
  maxScore,
  attractionId,
  attractionName,
}: {
  attractionId: string;
  attractionName: string;
  score: number;
  maxScore: number;
}) {
  return (
    <div className="flex flex-col items-center justify-center text-center mx-auto">
      <VenueOptScoreProgressCircular
        color={"stroke-[#68CE2C]"}
        size={80}
        score={score}
        maxScore={maxScore}
        stroke={8}
      >
        <div className="flex flex-col items-center text-lg font-bold">
          <div>{score || 0}</div>
          <div className="h-[1px] bg-black w-8"></div>
          <div>{maxScore || 100}</div>
        </div>
      </VenueOptScoreProgressCircular>

      <div className="mt-2.5">
        <div className="text-xs font-normal text-[#212121] leading-4">
          Venue Optimisation Score
        </div>
        <div className="mt-[5px] font-bold text-base">{attractionName}</div>
        <Link
          to={`/attractions/${attractionId}/venue-optimisation-score`}
          className="mt-[5px] font-normal text-base flex items-center gap-[5px]"
        >
          Improve your score <TinyArrow />
        </Link>
      </div>
    </div>
  );
}

function Locked({
  score,
  maxScore,
  attractionId,
  attractionName,
}: {
  attractionId: string;
  attractionName: string;
  score: number;
  maxScore: number;
}) {
  return (
    <>
      <Link
        to={`/dashboard/venue-optimisation-score?short_id=${attractionId}`}
        className="flex flex-col items-center justify-center w-full"
      >
        <div className="text-[#6836D1] text-lg font-bold mb-2.5 text-center px-2 leading-[20px]">
          View Optimisation Insights
        </div>
        <VenueOptScoreProgressCircular
          size={80}
          maxScore={maxScore}
          stroke={8}
          score={score}
        >
          <div className="flex flex-col items-center text-lg font-bold">
            <div>{score || 0}</div>
            <div className="h-[1px] bg-black w-8"></div>
            <div>{maxScore || 100}</div>
          </div>
        </VenueOptScoreProgressCircular>

        <div className="mt-2.5 text-center">
          <Link
            to="/dashboard/venue-optimisation-score"
            className="text-xs font-normal text-[#212121] leading-4"
          >
            Venue Optimisation Score
          </Link>
          <div className="mt-[5px] font-bold text-base">{attractionName}</div>
        </div>
      </Link>
    </>
  );
}
