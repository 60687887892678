import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AmpUserPartial } from "../types/user"
import { AttractionKeys } from "../helpers/newAttractionsFinishLater"

export interface GlobalState {
    user?: AmpUserPartial
    appLoaded: boolean,
    isNavigationOpen: boolean,
    isClosedAlertOpen: '' | 'open' | 'temporarily_closed' | 'permanently_closed',
    toastNotification: IToastNotification
    isUnsavedModalTempDisabled: boolean
    selectedPlan: SubscriptionPlan | undefined
    isSelectedPlanCurrent: boolean,
    saveCurrentTabStep: {
        attractionId: string, key: AttractionKeys, step?: number, allSteps?: number
    } | undefined
}

const initialState: GlobalState = {
    appLoaded: false,
    isNavigationOpen: false,
    isUnsavedModalTempDisabled: false,
    isClosedAlertOpen: '',
    selectedPlan: undefined,
    isSelectedPlanCurrent: false,
    toastNotification: {
        pendingType: "",
        type: "UNSET",
        message: null,
        attractionApprovalStatus: "",
        attractionImage: "",
        attractionName: "",
        attractionAddress: ""
    },
    saveCurrentTabStep: undefined
}

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<AmpUserPartial | undefined>) => {
            state.user = action.payload;
        },
        setAppLoaded: (state, action: PayloadAction<boolean>) => {
            state.appLoaded = action.payload;
        },
        setIsNavigationOpen: (state, action: PayloadAction<boolean>) => {
            const toggle = (toggleNavigation: boolean) => !toggleNavigation;
            state.isNavigationOpen = toggle(action.payload);
        },
        setIsClosedAlertOpen: (state, action: PayloadAction<GlobalState['isClosedAlertOpen']>) => {
            state.isClosedAlertOpen = action.payload;
        },
        setToastNotifcation: (state, action: PayloadAction<IToastNotification>) => {
            state.toastNotification = action.payload;
        },
        setUnsavedModalTempDisabled: (state, action: PayloadAction<boolean>) => {
            state.isUnsavedModalTempDisabled = action.payload;
        },
        setSelectedPlan: (state, action: PayloadAction<SubscriptionPlan | undefined>) => {
            state.selectedPlan = action.payload
        },
        setIsSelectedPlanCurrent: (state, action: PayloadAction<boolean>) => {
            state.isSelectedPlanCurrent = action.payload
        },
        setSaveCurrentTabStep: (state, action: PayloadAction<GlobalState['saveCurrentTabStep']>) => {
            state.saveCurrentTabStep = action.payload
        }
    }
})

export const globalActions = globalSlice.actions;
export default globalSlice.reducer;