import React, { ReactElement, ReactNode, useState } from "react";
import { PrimaryButton } from "../global/PrimaryButton";
import { OutlineButton } from "../global/OutlineButton";
import { useNavigate } from "react-router-dom";
import { className as containerClassName } from "../global/SingleAttractionTemplate";
import { useDispatch } from "react-redux";
import { globalActions } from "../../store/globalSlice";
import { ErrorMessage } from "../global/ErrorMessage";

function Stepper({
  isActive,
  children,
  title,
  nonStepperSubmit,
  isLoading,
  onStepChange,
  saveFn,
  onNextPage,
  triggerValidation,
  controlledStepping,
  onBeforeSave,
  onBeforeFinishLater,
  onGoPrevPage,
  customSubmitText,
  customLastPageSubmitText
}: {
  isActive: boolean;
  children: ReactNode;
  nonStepperSubmit: ReactNode;
  title: ReactNode;
  isLoading: boolean;
  triggerValidation: () => Promise<boolean>;
  saveFn: (publish?: boolean, isSilent?: boolean) => Function;
  onStepChange: (val: number) => void;
  onNextPage: () => void;
  controlledStepping?: [activeStep: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>],
  onBeforeSave?: (step: number, count: number) => void,
  onBeforeFinishLater: () => void
  onGoPrevPage?: () => void,
  customSubmitText?: string,
  customLastPageSubmitText?: string
}) {
  const localStep = useState(0);
  const [activeStep, setActiveStep] = controlledStepping ? controlledStepping : localStep;

  const steps = React.Children.toArray(children).filter((child) => {
    return (
      React.isValidElement(child) &&
      (child.props).componentType === "Step"
    );
  }) as ReactNode[];

  const groupedData = steps.reduce((acc: ReactNode[][], obj) => {
    const key = (obj as ReactElement).props.grouping;
    const groupIndex = acc.findIndex(
      (group) => (group?.[0] as ReactElement)?.props.grouping === key
    );
    if (groupIndex !== -1) {
      acc[groupIndex].push(obj);
    } else {
      acc.push([obj]);
    }
    return acc;
  }, []);

  const nonSavables = steps.map((obj: ReactNode, index: number) => {
    const key = (obj as ReactElement).props.nonSavable;
    return !!key ? index : undefined
  }).filter(s => typeof s === 'number')
  const hideFinishLaters = steps.map((obj: ReactNode, index: number) => {
    const key = (obj as ReactElement).props.hideFinishLater;
    return !!key ? index : undefined
  }).filter(s => typeof s === 'number')
  const skips = steps.map((obj: ReactNode, index: number) => {
    const key = (obj as ReactElement).props.skipStep;
    return !!key ? index : undefined
  }).filter(s => typeof s === 'number');
  const hideTitle = steps.map((obj: ReactNode, index: number) => {
    const key = (obj as ReactElement).props.hideTitle;
    return !!key ? index : undefined
  }).filter(s => typeof s === 'number');

  const isLastPage = activeStep === steps.length - 1;
  const isHideTitle = hideTitle.includes(activeStep);

  const goToStep = (stepIndex: number, type: 'next' | 'prev'): void => {
    if (skips.includes(stepIndex)) {
      return goToStep(stepIndex + (type === 'next' ? 1 : -1), type);
    }
    if (!steps[stepIndex]) return;
    setActiveStep(stepIndex);
    onStepChange(stepIndex);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function later() {
    onBeforeFinishLater();
    dispatch(globalActions.setUnsavedModalTempDisabled(true));
    setTimeout(() => {
      navigate("/dashboard");
      dispatch(globalActions.setUnsavedModalTempDisabled(false));
    }, 100);
  }
  const [hasErrors, setHasErrors] = useState(false)
  async function save(type: "later" | "next") {
    setHasErrors(false)
    const validated = await triggerValidation();

    if (!validated) {
      if (type === 'later') {
        later();
      }
      setHasErrors(true)
      return;
    };

    onBeforeSave?.(activeStep, steps.length)
    const success = await saveFn()();
    if (!success) return;

    if (type === "next") {
      if (!isLastPage) {
        return goToStep(activeStep + 1, 'next');
      } else {
        return onNextPage();
      }
    }

    later();
  }

  return (
    <div>
      {isActive && (
        <header className="flex gap-4 items-center mb-5">
          {(onGoPrevPage || activeStep !== 0) && <button type="button" onClick={() => {
            if (activeStep === 0) {
              return onGoPrevPage?.()
            }
            goToStep(activeStep - 1, 'prev')
          }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>}

          <Progress total={steps.length} current={activeStep + 1} />
        </header>
      )}

      {!isHideTitle && isActive && title}

      {isActive && (
        <>
          <div>
            {steps.map((step, index) =>
              index === activeStep ? <div key={index}>{step}</div> : null
            )}
          </div>
        </>
      )}

      {!isActive &&
        groupedData.map((group, index) => (
          <div key={index} className={`${containerClassName} mb-5`}>
            {index === 0 && title}
            <div className="flex flex-col gap-y-5">
              {group.map((step, index) => (
                <div key={index}>{step}</div>
              ))}
            </div>
            {index === groupedData.length - 1 && nonStepperSubmit}
          </div>
        ))}

      {isActive && (
        <>
          <div className="h-[1px] bg-[#D9D9D9] w-full my-5"></div>
          <div className="flex items-center gap-2.5 justify-end w-full">
            {!hideFinishLaters.includes(activeStep) && <OutlineButton
              className="h-9 text-sm px-2.5 leading-[16px]"
              scale="none"
              type="button"
              disabled={isLoading}
              onClick={() => save("later")}
            >
              Save & Exit
            </OutlineButton>}
            <PrimaryButton
              scale="sm"
              type="button"
              className={(customSubmitText || (customLastPageSubmitText && isLastPage))  ? 'px-4' : `w-[145px]`}
              loading={isLoading}
              onClick={() => save("next")}
            >
              {customSubmitText ? customSubmitText : nonSavables.includes(activeStep) ? 'Continue' : 
              (isLastPage && customLastPageSubmitText) ?  customLastPageSubmitText : 'Next'}
            </PrimaryButton>
          </div>
          {hasErrors && <ErrorMessage>You have some errors! Scroll up to view & fix</ErrorMessage>}
        </>
      )}
    </div>
  );
}

function Step({
  children,
  grouping = "default",
  nonSavable = false,
  hideFinishLater = false,
  skipStep = false,
  componentType = 'Step',
  hideTitle= false
}: {
  children: ReactNode;
  grouping?: string;
  nonSavable?: boolean,
  hideFinishLater?: boolean,
  skipStep?: boolean,
  componentType?: string,
  hideTitle?: boolean
}) {
  return <>{children}</>;
}
Stepper.Step = Step;
export default Stepper;

function Progress({ current, total }: { current: number; total: number }) {
  const percentage = Math.floor((current / total) * 100);
  return (
    <>
      <div className="flex gap-4 items-center flex-grow">
        <div className="relative h-[9px] rounded-full w-full bg-[#D9D9D9] overflow-hidden">
          <div
            className="h-full rounded-full top-0 left-0 bg-[#6836D1] transition-all"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <div className="text-[#6836D1] font-bold text-sm">
          {current}/{total}
        </div>
      </div>
    </>
  );
}
