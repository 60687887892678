import ReactRouterPrompt from "react-router-prompt";
import ModalWrapper from "../ModalWrapper";
import { useAppSelector } from "../../../store";

export function UnsavedModal({ isDirty }: { isDirty: boolean }) {
    const { isUnsavedModalTempDisabled } = useAppSelector(
        (state) => state.global
      );
    return (
        <ReactRouterPrompt when={isDirty && !isUnsavedModalTempDisabled}>
            {({ isActive, onConfirm, onCancel }) => (
                <UnsavedModalInternal isActive={isActive} onConfirm={onConfirm} onCancel={onCancel} />
            )}
        </ReactRouterPrompt>
    )
}

export function UnsavedModalInternal({ isActive, onConfirm, onCancel }: {isActive: boolean, onConfirm: () => void, onCancel: () => void}) {
    return (
        <ModalWrapper className="w-[500px] px-10 xl:px-0" open={isActive} setOpen={(open) => open ? onConfirm() : onCancel()}>
            <h3 className="text-2xl font-bold text-cs-gray">Leave Page?</h3>
            <p className="text-base font-normal my-7 text-cs-gray text-center">Changes you have made have not been saved.</p>

            <div className="flex flex-row mt-10">
                <button type="button" className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink" onClick={onCancel}>
                Cancel
                </button>

                <button type="button" className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline" onClick={onConfirm}>
                Leave without saving
                </button>
            </div>
        </ModalWrapper>
    )
}