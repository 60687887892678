import { Controller, FieldValues, Control, useController } from "react-hook-form";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { ErrorMessage } from "../global/ErrorMessage";
import MultiSelectCategoryOptionsMd from "../../lib/react-select/MultiSelectCategoryOptionsMd";

interface IButtonMultiSelect {
    control: Control<any>
    options: OptionsOrGroups<any, GroupBase<any>>
    name: string
    label: string
    subLabel?: string
    containerClassName?: string
    selectOptionsContainerClassName?: string;
    isMulti?: boolean;
    required?: boolean;
    maxHeight?: number;
    clearErrors?: (val: string) => void;
}

const ButtonMultiSelect = (props: IButtonMultiSelect) => {

    const { control, options, name, label, containerClassName, subLabel, selectOptionsContainerClassName, isMulti, required, maxHeight, clearErrors } = props;

    const {
        fieldState: { error },
      } = useController({
        name,
        control,
    });

    return(
        <div className={`${containerClassName ?? ""}`}>
            <div className={`text-sm font-bold group-focus-within:text-cs-pink ${error ? "text-cs-red" : ""}`}>{label} {required && <span className="text-cs-pink"> *</span>}</div>
            { subLabel && <div className="my-2 text-sm font-normal text-cs-gray">{subLabel}</div>}
            <Controller
                control={control}
                name={name}
                render={({
                    field: { ref, value, onChange },
                    fieldState: { error },
                }) => (
                    <>
                        <div className={`${selectOptionsContainerClassName ?? ""}`}>
                            <Select
                                isMulti={isMulti}
                                menuPlacement="auto" 
                                onChange={(selectedOptions) => {
                                    if (isMulti) {
                                      onChange(selectedOptions.map((option: {label: string; value: string}) => option.value));
                                    } else {
                                      onChange(selectedOptions ? selectedOptions.value : null);
                                    }
                                    clearErrors && clearErrors(name);
                                }}
                                value={isMulti 
                                    ? options.filter((option) => value && value.includes(option.value)) 
                                    : options.find((option) => option.value === value)}
                                defaultValue={null}
                                isClearable={undefined}
                                closeMenuOnSelect={false}
                                menuIsOpen={true}
                                hideSelectedOptions={false}
                                options={options}
                                components={{ Option: MultiSelectCategoryOptionsMd }}
                                placeholder={""}
                                captureMenuScroll={false}
                                styles={{
                                    multiValue: (styles) => ({
                                        ...styles,
                                        backgroundColor: "#6836D1",
                                        borderRadius: 20,
                                        height: 40,
                                        display: "flex",
                                        alignItems: "center",
                                    }),
                                    multiValueRemove: () => ({
                                        display: "none",
                                    }),
                                    multiValueLabel: (styles) => ({
                                        ...styles,
                                        color: "#FFF",
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        fontSize: 14,
                                        fontWeight: 400,
                                    }),
                                    menu: (styles) => ({
                                        ...styles,
                                        margin: 0,
                                        paddingLeft: 5,
                                        border: "0",
                                        position: "relative",
                                        overflow: "hidden",
                                        boxShadow: "none",
                                        display: "flex",
                                        width: "100%",
                                    }),
                                    menuList: (base) => ({
                                        ...base,
                                        marginTop: 0,
                                        marginBottom: 0,
                                        maxHeight: maxHeight ?? 300,
                                        overflow: "auto",
                                        width: "100%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        margin: "0 -10px",
                                    }),
                                    option: (base) => ({
                                        ...base,
                                        margin: "5px",
                                    }),
                                    noOptionsMessage: (base) => ({
                                        ...base,
                                        color: "#555",
                                    }),
                                    control: (styles, state) => ({
                                        ...styles,
                                        display: "none",
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                background: "none",
                                colors: {
                                    ...theme.colors,
                                    neutral80: "#555",
                                },
                                })}
                            />
                        </div>
                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                    </>
                    
                )}
            />
        </div>
    )
}

export default ButtonMultiSelect;