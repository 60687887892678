import { useEffect, useState } from 'react'
import { apiSlice } from '../../../../store/apiSlice';
import { useParams } from 'react-router-dom';
import DashboardContainer from '../../../../components/amp/DashboardContainer';
import DashboardHeaderContainer from '../../../../components/amp/DashboardHeaderContainer';
import Select from "react-select";
import EditPricing from './EditPricing';

const AvailabilityPeriodPricing: React.FC = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const { attractionId } = useParams();
  const isEditing = !!ticketId

  const [tableData, setTableData] = useState<any[]>([])
  const [availabilityPeriodTicketPricing, setAvailabilityPeriodTicketPricing] = useState<DOWTKAvailabilityPeriodTicketPricing[] | null>(null)
  const [availabilityPeriods, setAvailabilityPeriods] = useState<DOWTKTicketingAvailabilityPeriod[] | undefined>();
  const [selectedAvailabilityPeriod, setSelectedAvailabilityPeriod] = useState<DOWTKTicketingAvailabilityPeriod | null>(null)

  const { data: availabilityPeriodListQuery, } = apiSlice.useGetAvailabilityPeriodListQuery({
    venueShortId: attractionId!
  })

  const [createAvailabilityPeriodTicketPricing] = apiSlice.useCreateAvailabilityPeriodTicketPricingMutation()
  const [updateAvailabilityPeriodTicketPricing] = apiSlice.useUpdateAvailabilityPeriodTicketPricingMutation()
  const [getAvailabilityPeriodTicketPricing] = apiSlice.useGetAvailabilityPeriodTicketPricingMutation()
  const { data: ticketQuery } = apiSlice.useGetTicketQuery(ticketId || '', { skip: !isEditing })
  const [ticketData, setTicketData] = useState<DOWTKTicket | undefined>()
  const [selectedPricing, setSelectedPricing] = useState<DOWTKAvailabilityPeriodTicketPricing | null>(null)
  const [selectedDay, setSelectedDay] = useState<string | null>(null)
  const [selectedTime, setSelectedTime] = useState<string | null>(null)
  const [showEditPricing, setShowEditPricing] = useState(false)

  useEffect(() => {
    setTicketData(ticketQuery)
    setAvailabilityPeriods(availabilityPeriodListQuery?.results)
  }, [ticketQuery, availabilityPeriodListQuery])

  useEffect(() => {
    if (!availabilityPeriods || !ticketData) return
    setSelectedAvailabilityPeriod(availabilityPeriods[0])
  }, [availabilityPeriods, ticketData])

  useEffect(() => {
    if (!selectedAvailabilityPeriod) return
    getAvailabilityPeriodTicketPricing({ ticketId: ticketData!.id, availabilityPeriodId: selectedAvailabilityPeriod.id }).unwrap().then((res) => {
      setAvailabilityPeriodTicketPricing([...res])
    })
  }, [selectedAvailabilityPeriod])

  useEffect(() => {
    availabilityPeriodTicketPricing && prepareTableData(selectedAvailabilityPeriod!)
  }, [availabilityPeriodTicketPricing])

  const prepareTableData = (availabilityPeriod: DOWTKTicketingAvailabilityPeriod) => {
    if (!availabilityPeriod) return
    if (!ticketData) return
    if (!availabilityPeriodTicketPricing) return

    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    const tableData: any[] = []

    days.forEach(day => {
      const availabilityDay = (availabilityPeriod as any)[day]
      availabilityDay.timeslots.forEach((timeslot: any) => {
        const row = tableData.find(row => row[0] === timeslot.start_time)
        let pricingData = availabilityPeriodTicketPricing.find(pricing => pricing.availability_timeslot_id === timeslot.id)

        if (!pricingData) {
          pricingData = {
            id: `local-${crypto.randomUUID()}`,
            price: { value: ticketData.default_price.toString(), currency: ticketData.default_price_currency },
            availability_timeslot_id: timeslot.id,
            period_id: availabilityPeriod.id,
          } as Partial<DOWTKAvailabilityPeriodTicketPricing> as DOWTKAvailabilityPeriodTicketPricing
          setAvailabilityPeriodTicketPricing([...availabilityPeriodTicketPricing, pricingData])
        }

        if (row) {
          row.push(pricingData)
        } else {
          const newRow = [timeslot.start_time, pricingData]
          tableData.push(newRow)
        }
      })
    })

    tableData.sort((a, b) => {
      const timeA = a[0].split(':').map((part: string) => parseInt(part))
      const timeB = b[0].split(':').map((part: string) => parseInt(part))

      if (timeA[0] > timeB[0]) return 1
      if (timeA[0] < timeB[0]) return -1
      if (timeA[1] > timeB[1]) return 1
      if (timeA[1] < timeB[1]) return -1
      return 0
    })

    setTableData(tableData)
  }

  const editPricing = ({ pricingData, day, time }: { pricingData?: DOWTKAvailabilityPeriodTicketPricing, day?: string, time?: string }) => () => {
    setSelectedDay(day || null)
    setSelectedTime(time || null)

    pricingData = pricingData || {
      id: `local-${crypto.randomUUID()}`,
      price: { value: ticketData?.default_price.toString(), currency: ticketData?.default_price_currency },
      availability_timeslot_id: selectedAvailabilityPeriod!.id,
      period_id: selectedAvailabilityPeriod!.id,
    } as DOWTKAvailabilityPeriodTicketPricing
    setSelectedPricing(pricingData)
    setShowEditPricing(true)
  }

  const pricingUpdated = (pricingData?: DOWTKAvailabilityPeriodTicketPricing) => {
    if (!pricingData) {
      setShowEditPricing(false)
      setSelectedPricing(null)
      return
    }

    if (!availabilityPeriodTicketPricing) {
      return
    }

    setShowEditPricing(false)
    setSelectedPricing(null)

    const entriesToUpdate = []

    if (selectedDay) {
      const dayToIndex: any = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      }

      const timeslots = tableData.map(row => JSON.parse(JSON.stringify(row[dayToIndex[selectedDay]])))
      timeslots.forEach((timeslot: DOWTKAvailabilityPeriodTicketPricing) => {
        if (timeslot) {
          timeslot.price = pricingData.price
          entriesToUpdate.push(timeslot)
        }
      })

      setSelectedDay(null)
    } else if (selectedTime) {

      let timeslotToUpdate = tableData.find(row => row[0] === selectedTime)
      if (!timeslotToUpdate) {
        return
      }
      timeslotToUpdate = JSON.parse(JSON.stringify(timeslotToUpdate))
      timeslotToUpdate.slice(1).forEach((pricing: DOWTKAvailabilityPeriodTicketPricing) => {
        pricing.price = pricingData.price
        entriesToUpdate.push(pricing)
      })

      setSelectedTime(null)
    } else {
      entriesToUpdate.push(pricingData)
    }

    for (const pricingData of entriesToUpdate) {
      if (!pricingData.period_id) pricingData.period_id = selectedAvailabilityPeriod!.id
      if (!pricingData.ticket_id) pricingData.ticket_id = ticketData!.id

      if (pricingData.id && pricingData.id.startsWith('local-') === false) {
        updateAvailabilityPeriodTicketPricing(pricingData).unwrap().then((res) => {
          const pricingToUpdateIndex = availabilityPeriodTicketPricing.findIndex(pricing => pricing.id === pricingData.id)
          if (pricingToUpdateIndex == -1) {
            console.log('pricing not found')
            return
          }

          availabilityPeriodTicketPricing[pricingToUpdateIndex] = res
          setAvailabilityPeriodTicketPricing([...availabilityPeriodTicketPricing])

        })
      } else {
        const pricingDataCopy = JSON.parse(JSON.stringify(pricingData))
        delete pricingDataCopy.id

        createAvailabilityPeriodTicketPricing(pricingDataCopy).unwrap().then((res) => {
          const pricingToUpdateIndex = availabilityPeriodTicketPricing.findIndex(pricing => pricing.id === pricingData.id)
          if (!pricingToUpdateIndex) {
            console.log('pricing not found')
            return
          }
          availabilityPeriodTicketPricing[pricingToUpdateIndex] = res
          setAvailabilityPeriodTicketPricing([...availabilityPeriodTicketPricing])
          prepareTableData(selectedAvailabilityPeriod!)
        })
      }
    }
  }

  return (
    <>
      <div className="flex flex-col flex-1 bg-cs-off-white">
        <DashboardHeaderContainer>
          <h1 className="font-extrabold text-xl text-black font-roboto-flex ">Ticketing</h1>
        </DashboardHeaderContainer>
        <DashboardContainer>
          <section className={`flex flex-col px-6 pt-4 pb-8 bg-white xl:pt-6 xl:rounded-lg xl:mx-[70px]`}>

            <Select
              options={availabilityPeriods?.map(period => ({ label: period.name, value: period.id }))}
              onChange={(selected: any) => {
                if (!selected) return
                const selectedPeriod = availabilityPeriods?.find(period => period.id === selected.value)
                setSelectedAvailabilityPeriod(selectedPeriod || null)
              }}
              value={selectedAvailabilityPeriod ? { label: selectedAvailabilityPeriod?.name, value: selectedAvailabilityPeriod?.id } : null}
              className="w-full mt-2 mb-2 text-sm text-cs-gray"
              aria-label='Availability Period'
            />

            <table className='w-full table-auto mt-4 text-center'>
              <thead>
                <tr>
                  <th>Time</th>
                  <th><button onClick={editPricing({ day: 'monday' })}>Monday</button></th>
                  <th><button onClick={editPricing({ day: 'tuesday' })}>Tuesday</button></th>
                  <th><button onClick={editPricing({ day: 'wednesday' })}>Wednesday</button></th>
                  <th><button onClick={editPricing({ day: 'thursday' })}>Thursday</button></th>
                  <th><button onClick={editPricing({ day: 'friday' })}>Friday</button></th>
                  <th><button onClick={editPricing({ day: 'saturday' })}>Saturday</button></th>
                  <th><button onClick={editPricing({ day: 'sunday' })}>Sunday</button></th>
                </tr>
              </thead>
              <tbody>
                {tableData.map(row => {
                  return (
                    <tr>
                      <td><button onClick={editPricing({ time: row[0] })}>{row[0]}</button></td>
                      {row.slice(1).map((pricingData: DOWTKAvailabilityPeriodTicketPricing) => {
                        return (
                          <td>
                            <button onClick={editPricing({ pricingData })}>  {pricingData.price.value}</button>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {showEditPricing && selectedPricing ? <EditPricing pricing={selectedPricing} callback={(p) => { pricingUpdated(p) }} /> : null}
          </section>
        </DashboardContainer>
      </div>

    </>
  )
}

export default AvailabilityPeriodPricing;