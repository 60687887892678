import { type Control } from "react-hook-form";
import TextInput from "./TextInput";

export function ColorInput({
  control,
  props,
  name,
  label,
  getter,
  setter,
}: {
  name: string;
  label: string;
  control: Control<any, any>;
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  getter: string;
  setter: (value: string) => void;
}) {
  return (
    <TextInput
      className="w-full mt-4 group"
      labelClassName="group-focus-within:text-cs-pink font-bold"
      inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
      control={control}
      name={name}
      label={label}
      inputProps={{
        ...props,
      }}
      endAdornment={
        <div className="w-11 h-10 px-0.5 flex">
          <input
            type="color"
            className="w-full h-full rounded-md border border-[#CFDBD5]"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          />
        </div>
      }
    />
  );
}
