import { type ReactNode } from "react";

export type Colors = "green" | 'lime' | "red" | "blue" | "orange" | "default" | "purple";
export function TableText({
  children,
  color = "default",
  className = "",
}: {
  className?: string;
  children: ReactNode;
  color?: Colors;
}) {
  const colors: Record<Colors, string> = {
    default: "bg-[#F5F5FA] text-[#5F646D]",
    green: "bg-[#58FF81] text-[#5F646D]",
    red: "bg-[#FFB1B1] text-[#5F646D]",
    blue: 'bg-[#ADF1FF] text-black',
    orange: 'bg-[#FFB274] text-black',
    lime: 'bg-[#D3F8B4] text-[#5F646D]',
    purple: 'bg-[#CF93FF] text-black'
  };
  return (
    <div
      className={`min-w-[90px] h-[31px] text-sm font-normal flex items-center justify-center rounded-lg ${colors[color]} ${className}`}
    >
      {children}
    </div>
  );
}
