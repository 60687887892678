import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type WalkthroughStatus = 'none' | 'startModal' | 'tooltips' | 'stopModal'
export type WalkthroughType = 'newAttraction' | 'newClaimedAttraction' | 'newAttractionDone' | 'editAttraction' | 'dashboard'
export interface GlobalState {
    walkthroughStatus: WalkthroughStatus
    walkthroughType: WalkthroughType
    reactJoyrideId: string
    attractionId?: string
    joyrideStepTarget?: string
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }

const initialState: GlobalState = {
    walkthroughStatus: 'none',
    walkthroughType: 'newAttraction',
    reactJoyrideId: uuidv4(),
    attractionId: '',
    joyrideStepTarget: '',
}

export const walkthroughSlice = createSlice({
    name: "walkthrough",
    initialState,
    reducers: {
        setWalkthroughStatus: (state, action: PayloadAction<WalkthroughStatus>) => {
            state.walkthroughStatus = action.payload;

            if (action.payload !== 'tooltips') {
                state.reactJoyrideId = uuidv4();
            }
        },
        refreshJoyrideId: (state) => {
            state.reactJoyrideId = uuidv4();
        },
        setWalkthroughType: (state, action: PayloadAction<WalkthroughType>) => {
            state.walkthroughType = action.payload;
        },
        setAttractionId: (state, action: PayloadAction<string>) => {
            state.attractionId = action.payload;
        },
        setJoyrideStepTarget: (state, action: PayloadAction<string>) => {
            state.joyrideStepTarget = action.payload;
        }
    }
})

export const walkthroughActions = walkthroughSlice.actions;
export default walkthroughSlice.reducer;