import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useWindowResize } from "../../../hooks/useWindowResize"
import { useAuthUser } from "../../../hooks/useAuth";

const Header: React.FC = () => {
    const { isLoggedIn } = useAuthUser();

    // to be deleted and remodified once we know how to get www data in
    const aboveTopNav = [
        ["Blog", "https://www.dayoutwiththekids.co.uk/hub/"],
        ["About Us", "https://www.dayoutwiththekids.co.uk/about-us"],
        ["Contact Us", "https://www.dayoutwiththekids.co.uk/contact-us"],
        ["Work With Us", "https://www.dayoutwiththekids.co.uk/work-with-us"],
        ["Add a Listing", "https://www.dayoutwiththekids.co.uk/listing-options"],
        ["Management Centre", isLoggedIn ? '/dashboard' : '/login']
    ]

    const belowTopNav = [
        ["/assets/www/person.svg", "Trusted by 35 Million Visitors Every Year", "https://www.dayoutwiththekids.co.uk/hub/things-to-do"],
        ["/assets/www/star.svg", "Over 8,000 UK Days Out to Discover", "https://membership.dayoutwiththekids.co.uk/"],
        ["/assets/www/padlock.svg", "Unlock Exclusive Offers & Discounts", "https://www.dayoutwiththekids.co.uk/hub/attraction-tickets"]
    ]

    const mobileNavSocial = [
        ["/assets/www/facebook-dark.svg", "https://facebook.com"],
        ["/assets/www/twitter-dark.svg", "https://facebook.com"],
        ["/assets/www/instagram-dark.svg", "https://facebook.com"]
    ]

    const windowSize = useWindowResize();
    const [isNavOpen, setIsNavOpen] = useState<boolean>(false)
    const [activeItemIndex, setActiveSlide] = useState<number>(0);
    const activeItemRef = useRef<any>(null);

    useEffect(() => {
        const switchActiveItem = setInterval(() => {
            setActiveSlide(activeItemRef.current.nextSibling ? activeItemIndex+1 : 0);
        }, 3000);
        return () => {
            clearInterval(switchActiveItem);
        };
    }, [activeItemIndex]);

    useEffect(() => {
        (windowSize?.width ?? 0) < 1280 && isNavOpen ?
            document.body.classList.add('overflow-hidden')
        :
            document.body.classList.remove('overflow-hidden')
    }, [isNavOpen, windowSize?.width])

    return(
        <header className="flex flex-col">
            <nav className="flex items-center shadow-md py-3 bg-white z-20 xl:h-7 xl:shadow-none xl:bg-cs-off-white xl:py-1">
                <img className="block h-10 w-cs-138 mx-auto w-cs-150 xl:hidden" src="/assets/www/logo.svg" alt="Day Out With The Kids"/>

                <div className="absolute flex right-0 items-center xl:hidden">
                    <button id="hamburger" className={`${isNavOpen ? 'open' : ''}`} onClick={() => setIsNavOpen(prevCheck => !prevCheck)}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>

                <ul className="justify-end mx-auto hidden w-full px-5 xl:flex xl:max-w-cs-1280 2xl:max-w-cs-1536">
                    { 
                        aboveTopNav.map((item, i) => <li key={item[0]+i} className="leading-3 text-cs-gray">
                            <Link className="font-normal text-cs-11 border-r-cs-1 border-cs-lt-gray px-4" to={item[1]}>{item[0]}</Link>
                        </li>) 
                    }
                </ul>
            </nav>
            {
                <div className={`w-full h-full bg-white fixed z-10 top-14 overflow-y-scroll duration-300 transition-transform xl:hidden ${isNavOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                    <section className="pt-8 pb-6 w-4/5 mx-auto overflow-hidden">
                        <Link to={"https://www.dayoutwiththekids.co.uk/"} className="float-left block bg-cs-lt-blue py-3 m-none px-8 text-white border-2 border-cs-lt-blue rounded-full font-bold text-sm hover:bg-white hover:text-cs-pink hover:border-cs-pink xl:block">Return to main site</Link>
                    </section>
                    <section className="pt-6 pb-10 bg-cs-off-white">
                        <nav className="w-4/5 mx-auto">
                            <ul className="justify-end mx-auto w-full">
                                { 
                                    aboveTopNav.map((item, i) => <li key={item[0]+i} className="text-cs-gray">
                                        <Link className="text-sm" to={item[1]}>{item[0]}</Link>
                                    </li>)
                                }
                            </ul>
                        </nav>
                        <nav className="flex flex-cs-full justify-center mt-7">
                            <ul className="flex">
                                { 
                                    mobileNavSocial.map((item, i) => <li key={item[0]+i} className="w-6 mx-2"><Link to={item[1]}><img src={item[0]} /></Link></li>) 
                                }
                            </ul>
                        </nav>
                    </section>
                </div>
            }
            <div className="hidden flex mx-auto justify-between w-full px-5 py-8 items-center xl:flex xl:max-w-cs-1280 xl:justify-between 2xl:max-w-cs-1536">
                <Link to={"/"} className="mr-auto"><img className="hidden w-cs-150 h-cs-46 xl:block" src="/assets/www/logo.svg" alt="Day Out With The Kids"/></Link>
                <Link to={"https://www.dayoutwiththekids.co.uk/"} className="hidden bg-cs-lt-blue py-3 m-none px-8 text-white border-2 border-cs-lt-blue rounded-full font-bold text-sm hover:bg-white hover:text-cs-pink hover:border-cs-pink xl:block">Return to main site</Link>
            </div>
            <div className="bg-cs-dowtk-gradient py-3">
                <nav className="flex justify-center mx-auto px-5 lg:max-w-cs-1024 xl:max-w-cs-1280 2xl:max-w-cs-1536">
                    <ul className="flex justify-between relative h-6 w-full items-end mx-auto xl:max-w-cs-1100 lg:h-auto">
                        { 
                            belowTopNav.map((item, i) => <li key={item[0]+i} className="text-white px-2 absolute top-2/4 right-0 left-0 -translate-y-2/4 lg:relative" ref={i === activeItemIndex ? activeItemRef : null} >
                                <Link to={item[2]} className={`flex justify-center items-center flex text-sm font-semibold duration-500 transition-opacity ${i !== activeItemIndex && 'opacity-0 lg:opacity-100'}`}>
                                    <img className="w-5 h-5 mr-2" src={item[0]} />{item[1]}
                                </Link>
                            </li>) 
                        }
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header;