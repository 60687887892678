import { ReactNode } from "react";

export function PointingArrowHintSm({ className, children, boxClassName }: { className: string, children: ReactNode, boxClassName?: string }) {
  return (
    <div className={`md:absolute flex items-center gap-2.5 ${className}`}>
      <svg
      className="hidden md:block"
        width="48"
        height="14"
        viewBox="0 0 48 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.8091 10.8411C17.2311 10.9986 12.3176 10.1309 7.44838 8.87273C6.97641 8.74949 6.49573 8.64958 6.01099 8.5822C5.89771 8.56524 5.74625 8.72513 5.63926 8.83336C5.5991 8.87105 5.60572 9.01942 5.64267 9.07457C6.06289 9.69499 6.50997 10.2965 6.91231 10.9311C7.27297 11.4967 7.53923 12.1007 7.49063 12.8205C7.44576 13.4847 6.76094 13.9769 6.29395 13.5659C4.22497 11.7764 2.20042 9.93055 0.679923 7.59971C-0.188817 6.27575 -0.270208 4.99634 1.38362 3.646C1.95582 3.18322 2.60533 2.75182 3.28923 2.51481C5.07083 1.88646 6.88557 1.36435 8.6956 0.828392C9.00175 0.735909 9.37177 0.684287 9.66698 0.777548C10.0303 0.888405 10.4355 1.09146 10.6522 1.38534C10.9703 1.82162 10.8881 2.39809 10.5408 2.78346C10.2158 3.14067 9.77676 3.42527 9.34096 3.62166C8.29417 4.09579 7.21018 4.49621 6.14937 4.93806C6.02352 4.99088 5.93042 5.1221 5.81872 5.21648C5.94673 5.31674 6.05759 5.4822 6.19818 5.51269C8.75627 6.07969 11.3029 6.79533 13.8895 7.13922C16.4807 7.48769 19.1332 7.70073 21.7309 7.55266C27.866 7.19682 33.9783 6.51651 39.8593 4.49861C41.8886 3.8018 43.8714 3.0175 45.5793 1.64784C45.7044 1.544 45.857 1.46759 46.0007 1.39597C46.5261 1.13799 47.201 1.54603 47.282 2.16196C47.3631 2.7779 47.2904 3.39138 46.8623 3.81501C46.1668 4.51152 45.449 5.22691 44.629 5.74887C42.5991 7.04885 40.3502 7.87867 38.0533 8.51894C32.8921 9.96062 27.636 10.7587 21.8134 10.8271L21.8091 10.8411Z"
          fill="#212121"
        />
      </svg>

      <div className={`${boxClassName ? boxClassName : 'w-[256px]'} bg-[#EDE8F8] py-2.5 px-5 rounded-lg text-sm font-normal font-fredoka leading-[21px] text-[#6836D1]`}>
        {children}
      </div>
    </div>
  );
}
