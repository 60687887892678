import { useEffect, useState } from "react";
import { apiSlice } from "../../../../store/apiSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { globalActions } from "../../../../store/globalSlice";
import { restartAnimation } from "../../amp/ToastNotification";
import { Description, Heading } from "../../TwoColTemplate";

const VerifyEmailModal: React.FC<{ email: string }> = ({ email }) => {
  useEffect(() => {
    document.title = "Verify Email | Day Out With The Kids";
  }, []);

  return (
      <div className="w-full">
        <Heading>Activate your account to continue</Heading>

        <VerifyEmailBox email={email} />
      </div>
  );
};

export const EmailVerificationButton = ({
  email,
  label,
  className = "",
}: {
  email: string;
  label: string;
  className?: string;
}) => {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [time, setTime] = useState(0);

  const [registerRequest, registerRequestData] = apiSlice.useRegisterMutation();
  const {
    isError: isRegisterError,
    isSuccess: isRegisterSuccess,
    isLoading: isRegisterLoading,
    isUninitialized: isRegisterUninitialized,
  } = registerRequestData;

  const resendVerificationEmail = async () => {
    await registerRequest({
      email: email,
      first_name: "",
      last_name: "",
      operator_name: "",
      password: "",
      phone: "",
    });

    restartCountdown();
    restartAnimation();
  };

  const restartCountdown = () => {
    setTime(30);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    if (time === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    isRegisterSuccess &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Sent the email successfuly",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isRegisterSuccess]);

  useEffect(() => {
    isRegisterError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Could not send the email",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isRegisterError]);

  return (
    <button
      type="button"
      className={`${className} ${
        isRegisterLoading || time > 0 ? "pointer-events-none" : ""
      }`}
      onClick={resendVerificationEmail}
    >
      {label}
      {time > 0 && (
        <span className="text-xs">
          (00:{time < 10 ? "0" : ""}
          {time.toString()})
        </span>
      )}
    </button>
  );
};

export const VerifyEmailBox: React.FC<{ email: string, desc?: string }> = ({ email, desc }) => {
  return (
    <>
      <Description>
        {desc ? desc : "We've sent an email to the address that you provided on the previous step. Please head to your inbox, open the email and follow the instructions to verify your account."}
      </Description>
      <Description>
      The link in the email will expire in 24 hours. 
      </Description>
      <p className="mb-6">
        <EmailVerificationButton
          label="Didn't receive the email? Click here to re-send."
          className="text-sm text-[#5F646D] font-normal underline ml-0.5"
          email={email}
        />
      </p>
    </>
  );
};

export default VerifyEmailModal;
