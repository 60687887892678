import { components } from "react-select";

const Options: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  innerRef,
  ref,
  value,
  defaultValue,
  selectProps,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    color: "#555",
    display: "flex",
    fontSize: 14,
    fontWeight: 400,
    height: 40,
    padding: 0,
    flexWrap: "wrap",
    width: "auto",
    borderRadius: 40,
  };

  const props = {
    ...innerProps,
    style,
  };

  const isDefaultSelected =
    selectProps.customDefaultValue &&
    selectProps.customDefaultValue.filter(
      (option: any) => option?.value == value
    ).length > 0;
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
      innerRef={innerRef}
    >
      <label
        className={`relative checkbox-item white-checkmark w-full h-10 rounded-full px-4 text-sm font-normal flex items-center border ${
          isSelected || value == true || (value && value.includes("true"))
            ? "text-cs-pink border-cs-pink"
            : "border-[#CFDBD5] text-cs-gray"
        } ${isDisabled ? "bg-gray-100 opacity-80" : ""}`}
      >
        {rest.data.isMostPopular && (
          <div className="absolute text-[9px] font-bold text-[#FB7037] bg-[#F6F8FA] w-[81px] h-[20px] rounded-[6px] px-[3px] flex items-center justify-center inset-x-0 mx-auto top-[-25px]">
            MOST POPULAR
          </div>
        )}
        <input
          className={`${
            isDefaultSelected ? "default-selected" : ""
          } !w-full !h-10 `}
          type="checkbox"
          checked={
            isSelected || value == true || (value && value.includes("true"))
          }
          readOnly
        />
        {children}
      </label>
    </components.Option>
  );
};

export default Options;
