import Header, { PublishButtons } from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { useEffect, useState } from "react";
import { apiSlice } from "../../../store/apiSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { SubmitHandler } from "react-hook-form";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { useAppDispatch, useAppSelector } from "../../../store";
import { globalActions } from "../../../store/globalSlice";
import { OutlineButton } from "../../../components/global/OutlineButton";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import { ATTRACTION_DATA_LOCAL_STORAGE_KEY } from "../../../components/global/www/modals/CreateClaimModal";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

const AttractionPayPublish: React.FC<IAttractionDetails> = () => {
  const { attractionId } = useParams();

  const { toastNotification, user } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const { data: getAttraction, error: attractionError } =
    apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  useEffect(() => {
    document.title = "Attraction Pay & Publish | Day Out With The Kids";
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);

  const isClaim = result?.ownership_claim?.created_by_user?.email !== result?.creation_claim?.created_by_user?.email;
  useEffect(() => {
    if (result && isClaim) {
      navigate('/forbidden');
    }
  }, [isClaim, navigate, result])

  const [updateAttraction, { isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();
  const [isPublishing, setIsPublishing] = useState(false);

  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<{}> = async (e) => {
      setIsPublishing(publish);

      const payload = {};

      const results = await updateAttraction({
        id: attractionId,
        publish,
        payload: payload,
      });
      if (!isSilent) {
        restartAnimation();
      }

      return "data" in results;
    };

    return fn;
  };

  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(result?.listing_package ?? "");
  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  function finishOnboarding() {
    navigate(`/attractions/${attractionId}/change-log`, {
      state: {
        onboardingComplete: true,
      },
    });
  }

  const [searchParams] = useSearchParams();
  const cbHostedPageId = searchParams.get("id") || "";
  const isPayment = searchParams.get("payment-success") === "true";
  const [completePayment] = apiSlice.useCompletePaymentMutation();

  useEffect(() => {
    if (!isPayment) return;

    localStorage.removeItem(ATTRACTION_DATA_LOCAL_STORAGE_KEY);

    (async () => {
      const result = await completePayment({
        cbHostedPageId,
      });

      if ("error" in result) {
        dispatch(
          globalActions.setToastNotifcation({
            ...toastNotification,
            type: "ERROR",
            message: "Failed completing the payment.",
          })
        );
      } else {
        dispatch(
          globalActions.setToastNotifcation({
            ...toastNotification,
            type: "SUCCESS",
            message: "Successful payment",
          })
        );
        finishOnboarding();
      }

      restartAnimation();
    })();
  }, []);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        getPayload={() => ({})}
        saveFn={onSubmit}
      />

      <SingleAttractionTemplate
        result={result}
        attractionId={attractionId}
        doneWalkthrough={result ? result?.done_walkthrough ?? false : undefined}
        isAttraction={true}
        name={""}
      >
        <h1 className="text-[22px] text-[#212121] font-extrabold">
          💰 You’re almost there!
        </h1>
        <p className="mt-5 font-bold text-sm">
          To complete submission of your listing all you need to do is make
          payment and preview your listing.
        </p>

        <p className="mt-5 text-sm font-normal text-[#212121]">
          Click “Pay Now” to add your payment details.
        </p>

        <div className="h-[1px] bg-[#D9D9D9] w-full my-5"></div>

        <div className="flex items-center gap-2.5 justify-end w-full">
          <PublishButtons
            attractionId={attractionId ?? ""}
            rawSaveFunction={
              onSubmit as (
                publish: boolean,
                isSilent?: boolean
              ) => () => Promise<EntityDetailsResponse<AttractionDetails>>
            }
            getPayload={() => ({})}
            triggerValidation={undefined}
            previewButton={({ onClick }) => (
              <OutlineButton
                className="h-9 text-sm px-2.5 leading-[16px] w-[145px]"
                scale="none"
                type="button"
                onClick={() => onClick()}
              >
                Preview Listing
              </OutlineButton>
            )}
            publishButton={({ onClick, loading, basicPlanPrice }) => (
              <PrimaryButton
                scale="sm"
                type="button"
                onClick={() => onClick()}
                loading={loading}
              >
                Pay Now £{basicPlanPrice ?? "-"}
              </PrimaryButton>
            )}
            isOnboarding={true}
          />
        </div>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AttractionPayPublish;
