import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { useEffect, useState } from "react";
import { apiSlice } from "../../../store/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { ListingTypeSelect } from "../../../components/form/ListingTypeSelect";
import { SubmitHandler } from "react-hook-form";
import { PrimaryButton } from "../../../components/global/PrimaryButton";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

const AttractionSubscription: React.FC<IAttractionDetails> = () => {
  const { attractionId } = useParams();
  const { data: getAttraction, error: attractionError } =
    apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  useEffect(() => {
    document.title = "Attraction Subscription | Day Out With The Kids";
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError, navigate]);

  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<{}> = async (e) => {
      return true;
    };

    return fn;
  };

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        getPayload={() => ({})}
        saveFn={onSubmit}
      />

      <SingleAttractionTemplate name={""}>
        <SubscriptionSection showBottomButton={true} />
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AttractionSubscription;

export function SubscriptionSection({
  showHeader = true,
  onNext,
  showBottomButton = false,
  isOverviewTab = false,
}: {
  showHeader?: boolean;
  onNext?: () => Promise<void>;
  showBottomButton?: boolean;
  isOverviewTab?: boolean;
}) {
  const { attractionId } = useParams();
  const { data: getAttraction } = apiSlice.useGetAttractionQuery(
    attractionId ?? ""
  );
  const { result: attractionDetails } = getAttraction ?? {};
  const isClaim =
    attractionDetails?.ownership_claim?.created_by_user?.email !==
    attractionDetails?.creation_claim?.created_by_user?.email;
  const venueId = (attractionDetails?.published_version?.id as string) || "";

  let defaultPlan = attractionDetails?.published_version?.subscriptions?.find(s => s.status !== 'cancelled')?.plan_id;

  if (
    !defaultPlan &&
    (attractionDetails?.published_version?.skip_payment || attractionDetails?.published_version?.has_paid_to_publish)
  ) {
    // this is probably a claim - claims don't pay for basic
    defaultPlan = "basic-plan-monthly";
  }

  if (isClaim && !defaultPlan) {
    defaultPlan = "basic-plan-monthly";
  }

  const showStandardTrial = true; // !result?.published_version?.has_paid_to_publish;

  const { data, isLoading } = apiSlice.useGetSubscriptionPlansQuery({
    venueId: venueId || "",
  });
  const [plans, setPlans] = useState<SubscriptionPlan[]>(
    (data as SubscriptionPlan[]) || []
  );
  const standardPlan = plans?.find((plan) => plan.name === "standard");
  useEffect(() => {
    if (isLoading) return;

    const relevantPlans = data?.filter(
      (plan) =>
        plan.billing_frequency === "monthly" ||
        plan.billing_frequency === "one-off"
    );
    setPlans(relevantPlans!);
  }, [data, isLoading]);
  const [subscriptionCheckout] = apiSlice.useSubscriptionCheckoutMutation();
  const createSubscriptionCheckout = async (selectedPlanId: string) => {
    if (!selectedPlanId) return;
    const checkoutPage = await subscriptionCheckout({
      venueId: venueId!,
      planId: selectedPlanId,
    });

    if ("data" in checkoutPage) {
      const paymentUrl = checkoutPage?.data?.sessionUrl;
      if (!paymentUrl) return;
      window.location.href = new URL(paymentUrl).toString();
    }
  };

  const [loadingTrial, setLoadingTrial] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionPlan | undefined
  >();
  async function startTrial() {
    setLoadingTrial(true);
    if (onNext) {
      return onNext();
    }
    const newPlan = plans?.find((r) => r.name === "standard");
    await createSubscriptionCheckout(
      selectedPlan?.id || newPlan?.id || "standard"
    );
    setLoadingTrial(false);
  }
  return (
    <div>
      {showHeader ? (
        <div className="inline-flex flex-col flex-wrap w-full mt-5">
          {!isOverviewTab && (
            <PrimaryButton
              loading={loadingTrial}
              scale="sm"
              className={`${
                selectedPlan?.id?.includes("standard") ? "px-2" : "w-[145px]"
              } ml-auto mb-5 -mt-4`}
              onClick={startTrial}
            >
               {selectedPlan?.id?.includes("standard")
                ? "Continue with Free Trial"
                : "Next"}
            </PrimaryButton>
          )}
          <h1 className="text-[30px] font-extrabold text-black text-center leading-8">
            Supercharge your visibility 🚀
          </h1>
        </div>
      ) : !isOverviewTab ? (
        <div className="flex">
          <PrimaryButton
            loading={loadingTrial}
            scale="sm"
            className="w-[145px] ml-auto mb-5 -mt-4"
            onClick={startTrial}
          >
            Next
          </PrimaryButton>
        </div>
      ) : null}

      <div>
        {showHeader && !defaultPlan?.startsWith("standard") && (
          <p className="w-full text-center text-sm font-semibold text-[#61616D] mt-2.5">
            Upgrade Your Listing from Basic to Standard to Unlock All Features.
            Renews at £{(standardPlan?.price ?? 0) / 100} per month. No Risk.
            Cancel at Any Time.
          </p>
        )}
        <ListingTypeSelect
          defaultValue={defaultPlan}
          showLabel={false}
          venueId={venueId || ""}
          showStandardTrial={showStandardTrial}
          onSelectedPlanChange={(value) => setSelectedPlan(value)}
          isClaim={isClaim}
          enhancedSelector={!isOverviewTab}
          isOverviewTab={isOverviewTab}
          onNext={onNext}
        />

        {showBottomButton && (
          <div className="mt-10 flex justify-end">
            <PrimaryButton
              loading={loadingTrial}
              scale="sm"
              className={`${
                selectedPlan?.id?.includes("standard") ? "px-2" : "w-[145px]"
              } ml-auto`}
              onClick={startTrial}
            >
              {selectedPlan?.id?.includes("standard")
                ? "Continue with Free Trial"
                : "Next"}
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
}
