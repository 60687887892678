import { FcnBlogContentRequest } from "../../../client-core/src/types/blog";


function generateUUIDv4(): string {
    return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
      /[018]/g,
      (c: number) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
  }
  
  export function base64ToFile(base64: string): Promise<File> {
    return new Promise((resolve, reject) => {
      // Extract the MIME type and Base64 data
      const match = base64.match(/^data:(.*?);base64,(.*)$/);
      if (!match) {
        return reject(new Error("Invalid Base64 string"));
      }
  
      const mimeType = match[1];
      const base64Data = match[2];
  
      // Decode Base64 data to binary string
      const binaryString = atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
  
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
  
      // Create a blob from the binary data
      const blob = new Blob([bytes], { type: mimeType });
  
      // Generate a filename based on the MIME type
      const extension = mimeType.split("/")[1];
      const fileName = `file-${generateUUIDv4()}.${extension}`;
  
      // Create a File instance from the blob
      resolve(new File([blob], fileName, { type: mimeType }));
    });
  }
  
  export async function fetchFileData(
    url: string
  ): Promise<{ size: number; data: File }> {
    const response = await fetch(url);
    const contentLength = response.headers.get("Content-Length");
    const fileSize = contentLength
      ? Number.parseInt(contentLength, 10)
      : (await response.body
          ?.getReader()
          ?.read()
          .then((result) =>
            result.done ? result.value?.length || 0 : Infinity
          )) || 0;
  
    if (!response.ok) {
      throw new Error("Failed to fetch the file.");
    }
  
    const blob = await response.blob();
    const file = new File([blob], `file-${generateUUIDv4()}`, {
      type: blob.type,
      lastModified: Date.now(),
    });
  
    return { size: fileSize, data: file };
  }
  
  export function fileToDataString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        resolve(event.target?.result as string);
      };
      reader.onerror = reject;
    });
  }


  export const FcnBlogContentRequestInterfaceKeys: (keyof FcnBlogContentRequest)[] = [
    "blog_id",
    "content_id",
    "favourite_image_id",
    "title",
    "overview",
    "category_name",
    "safety_cleanliness_rating",
    "safety_cleanliness_comment",
    "rides_entertainment_rating",
    "rides_entertainment_comment",
    "food_drink_rating",
    "food_drink_comment",
    "queues_crowds_rating",
    "queues_crowds_comment",
    "fun_delight_rating",
    "fun_delight_comment",
    "value_for_money_rating",
    "value_for_money_comment",
    "family_creator_image_id",
    "family_creator_name",
    "family_json",
    "visit_date",
    "visit_reason",
    "visit_plan",
    "visit_essentials",
    "visit_tip_1",
    "visit_tip_2",
    "visit_tip_3",
    "visit_tip_4",
    "visit_budget",
    "travel_time",
    "travel_method",
    "travel_origin",
    "travel_summary",
    "travel_image_1_id",
    "travel_image_caption_1",
    "travel_image_2_id",
    "travel_image_caption_2",
    "travel_image_3_id",
    "travel_image_caption_3",
    "travel_image_4_id",
    "travel_image_caption_4",
    "parking_price",
    "queue_time",
    "arrival_summary",
    "arrival_image_1_id",
    "arrival_image_caption_1",
    "arrival_image_2_id",
    "arrival_image_caption_2",
    "arrival_image_3_id",
    "arrival_image_caption_3",
    "arrival_image_4_id",
    "arrival_image_caption_4",
    "first_half_summary",
    "first_half_image_1_id",
    "first_half_image_caption_1",
    "first_half_image_2_id",
    "first_half_image_caption_2",
    "first_half_image_3_id",
    "first_half_image_caption_3",
    "first_half_image_4_id",
    "first_half_image_caption_4",
    "second_half_summary",
    "second_half_image_1_id",
    "second_half_image_caption_1",
    "second_half_image_2_id",
    "second_half_image_caption_2",
    "second_half_image_3_id",
    "second_half_image_caption_3",
    "second_half_image_4_id",
    "second_half_image_caption_4",
    "food_drink_summary",
    "food_drink_image_1_id",
    "food_drink_image_caption_1",
    "food_drink_image_2_id",
    "food_drink_image_caption_2",
    "food_drink_image_3_id",
    "food_drink_image_caption_3",
    "food_drink_image_4_id",
    "food_drink_image_caption_4",
    "best_moments_1",
    "best_moments_2",
    "best_moments_3",
    "worst_moments_1",
    "worst_moments_2",
    "worst_moments_3",
    "overall_summary",
    "mini_summary",
    "final_image_1_id",
    "final_image_caption_1",
    "final_image_2_id",
    "final_image_caption_2",
    "final_image_3_id",
    "final_image_caption_3",
    "final_image_4_id",
    "final_image_caption_4"
]

export function filterProperties<T>(obj: any, keys: (keyof T)[]): Partial<T> {
    const result: Partial<T> = {};
  
    keys.forEach(key => {
      if (key in obj) {
        result[key] = obj[key];
      }
    });
  
    return result;
  }