import { useState } from 'react'
import CreateTimeslot from './CreateTimeslot';

const TimeslotConfiguration = ({ availabilityPeriod, timeslot, onUpdate }: {
  availabilityPeriod: DOWTKTicketingAvailabilityPeriod, timeslot: DOWTKTicketingAvailabilityTimeSlot,
  onUpdate: (timeslot: DOWTKTicketingAvailabilityTimeSlot) => void
}) => {

  const [showCreateTimeslot, setShowCreateTimeslot] = useState(false)
  const editTimeslotCapacity = () => {
    setShowCreateTimeslot(true)
  }
  const updateTimeslot = (timeslotData: DOWTKTicketingAvailabilityTimeSlot) => {
    onUpdate(timeslotData)
    setShowCreateTimeslot(false)
  }

  return (
    <>
      <div className="flex flex-col flex-1 bg-cs-off-white">
        {timeslot && <div>{timeslot.start_time}</div>}
        <button onClick={editTimeslotCapacity}>{timeslot && <div>{timeslot.capacity || '∞'}</div>}</button>
        {showCreateTimeslot && <CreateTimeslot availabilityPeriod={availabilityPeriod} timeslot={timeslot} callback={updateTimeslot} />}

      </div>
    </>
  )
}

export default TimeslotConfiguration;