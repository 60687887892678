import ModalWrapper from "./ModalWrapper";

export function RevertToSavedModal({
    getShowRevertModal,
    setShowRevertModal,
    refetch,
}: {
    getShowRevertModal: boolean,
    setShowRevertModal: (value: boolean) => void,
    refetch: () => void,
}) {
  return (
    <ModalWrapper
      className="w-[500px] px-10 xl:px-0"
      open={getShowRevertModal}
      setOpen={setShowRevertModal}
    >
      <h3 className="text-2xl font-bold text-cs-gray">Revert to saved?</h3>
      <p className="text-base font-normal my-7 text-cs-gray">
        Any changes made since the last save will be discarded.
      </p>
      <h4 className="text-lg font-bold text-cs-gray">Are you sure?</h4>

      <div className="flex flex-row mt-10">
        <button
          className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
          onClick={refetch}
        >
          Yes, discard changes
        </button>

        <button
          className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
          onClick={() => {
            setShowRevertModal(false);
          }}
        >
          No, keep the changes
        </button>
      </div>
    </ModalWrapper>
  );
}
