import { TwoColTemplate } from "../../components/global/TwoColTemplate";
import { Link } from "react-router-dom";
const SearchClaim: React.FC = () => {

  return (
    <>
      <TwoColTemplate
        leftColMaxWidth="700px"
        sidebarText={"Showcase the best of your brand to our family audience... with over 35 million annual visitors, searching for things to do."}
        sidebarImage={<>
          <img src={'/assets/www/login-image-2.png'} alt="" className={''} />
          {/* <img src={'/assets/www/login-image-2.1.png'} alt="" className={`scale-[1.3] translate-y-8 mb-16`} /> */}
        </>}
        imageClassName={"scale-[1.3] mb-[50px]"}
      >
        <section className="w-full pt-6">
          <Link
            to="/search-claim"
            className="flex items-center mb-5 font-bold"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
            Back
          </Link>
          <h2 className="text-[28px] font-extrabold text-black mb-5">'Connect' Ai Powered Management Platform.</h2>
          <h2 className="text-[24px] font-extrabold text-black mb-3">The Power of Fresh Content is in Your Hands</h2>
          <p className="mb-3">In the ever-evolving digital landscape of family entertainment, the Day Out With The Kids (DOWTK) Connect Platform emerges as a pivotal tool for venue owners in the UK.</p>
          <p className="mb-3">The Connect Platform serves as a comprehensive hub for venue owners to manage and optimise their listings, with the help of AI (artificial intelligence), across the DOWTK website. It is designed to be intuitive, user-friendly, and packed with features essential for digital success.</p>
          <p className="mb-3">This innovative listing management platform revolutionises how attractions like aquariums, zip lines, soft plays, museums and more manage their online presence. By enabling owners to claim, verify, and enhance their listings, the Connect Platform not only boosts visibility on the DOWTK website but also offers crucial insights through live analytics and the Venue Optimisation Score (VOS)</p>
          <h2 className="text-[24px] font-extrabold text-black mb-3">What Connect Aims to Achieve</h2>
          <ul className="list-disc pl-5 mb-3">
            <li><strong>Simple 'Self-Serve' Listing Management</strong>: Facilitate easy claiming, verification, and editing of venue listings by the venue owner/manager.</li>
            <li><strong>SIncrease Online Visibility</strong>: Boost exposure for verified listings through enhanced prominence on DOWTK.</li>
            <li><strong>Analytics & Insights</strong>: Access to live analytics and Venue Optimisation Score PLUS tips and guides to improve your score.</li>
            <li><strong>Easy Upgrades and 'Power Ups'</strong>: With the power of AI, recommendations and enhancements are available in just a few clicks (additional charges may apply)</li>
          </ul>
          <h2 className="text-[24px] font-extrabold text-black mb-3">Connect Core Features</h2>
          <h3 className="text-[22px] font-extrabold text-black mb-3">Claiming and Verifying Listings</h3>
          <ul className="list-disc pl-5 mb-3">
            <li><strong>Verification Process</strong>: Claiming an existing listing or adding a new one via Connect enables verification, after a couple of simple checks.</li>
            <li><strong>Benefits of Verification</strong>: Verified listings receive more impressions and clicks, significantly enhancing their visibility.</li>
          </ul>
          <h3 className="text-[22px] font-extrabold text-black mb-3">Comprehensive Listing Management</h3>
          <ul className="list-disc pl-5 mb-3">
            <li><strong>Easy Editing</strong>: Owners can update all listing details, from images and descriptions to opening times and ticket booking</li>
            <li><strong>Real-Time Updates</strong>: Changes made on the platform are instantly reflected on the DOWTK website (depending on the listing package)</li>
          </ul>
          <h3 className="text-[22px] font-extrabold text-black mb-3">Live Analytics and Reporting</h3>
          <ul className="list-disc pl-5 mb-3">
            <li><strong>Insights on Performance</strong>: Standard, Enhanced, and Premium partners can access live analytics, including impressions, views, and clicks.</li>
            <li><strong>Data-Driven Decisions</strong>: These analytics help in understanding visitor behaviour and preferences.</li>
          </ul>
          <h3 className="text-[22px] font-extrabold text-black mb-3">Access to Venue Optimisation Score</h3>
          <ul className="list-disc pl-5 mb-3">
            <li><strong>Score Access</strong>: The VOS is available within the Connect Platform (Standard, Enhanced and Premium partners), offering a score out of 100, representative of how optimised a listing is.</li>
            <li><strong>Optimisation Guidance</strong>: The platform provides tips and advice to improve the VOS, thereby enhancing the listing's effectiveness.</li>
          </ul>
          <h3 className="text-[22px] font-extrabold text-black mb-3">3 Steps to Success with Connect</h3>
          <ul className="list-disc pl-5 mb-3">
            <li><strong>Claim or Add Listing</strong>: Verify your venue by claiming an existing listing or adding a new one.</li>
            <li><strong>Review and Edit</strong>: Ensure all information (imagery & copy) is accurate, up-to-date, unique and engaging.</li>
            <li><strong>Utilise Analytics & VOS</strong>: Check performance metrics to gauge visitor engagement check your VOS and implement suggested improvements.</li>
          </ul>
          <h2 className="text-[24px] font-extrabold text-black mb-3">Summary</h2>
          <p className="mb-3">The DOWTK Connect Platform is a game-changer for venue owners in the UK's family days out industry. By offering a centralised, self-serve solution for listing management, enhanced visibility through verification, and invaluable insights through analytics and VOS, the platform is an indispensable tool for maximising digital engagement. In embracing the Connect Platform, venues can improve their online presence and strategically position themselves to attract more families, ultimately leading to increased success in the vibrant and competitive world of family entertainment.</p>
        </section>
      </TwoColTemplate>
    </>
  );
};

export default SearchClaim;
