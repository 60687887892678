import React, { useState, useEffect} from "react";

export const useWindowResize = () => {
    interface Size {
        width: number | undefined;
        height: number | undefined;
    }

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const [size, setSize] = useState<Size>({width: windowWidth, height: windowHeight});
    const resizeHanlder = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        setSize({
            width: width,
            height: height,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', resizeHanlder);

        return () => {
            window.removeEventListener('resize', resizeHanlder);
        }
    }, []);

    return size
}