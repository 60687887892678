import type { UseFieldArrayReturn, UseFormGetValues } from "react-hook-form";
import { z } from "zod";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { globalActions } from "../store/globalSlice";
import { restartAnimation } from "../components/global/amp/ToastNotification";
import { Cropper, type CropperRef } from "react-advanced-cropper";

export const singleMediaSchema = z.object({
  url: z.string(),
  filename: z.string(),
  blob: z.any(),
  size: z.number(),
  createdAt: z.date(),
  isPrimary: z.boolean(),
  persisted: z.boolean().or(z.string()),
  image_db_id: z.string().optional(),
});
export const mediaSchema = z
  .object({
    url: z.string(),
    filename: z.string(),
    blob: z.any(),
    size: z.number(),
    createdAt: z.date(),
    isPrimary: z.boolean(),
    persisted: z.boolean().or(z.string()),
    image_db_id: z.string().optional(),
  })
  .array();

interface Props {
  getValues: UseFormGetValues<{
    media: z.infer<typeof mediaSchema>;
  }>;
  controls: UseFieldArrayReturn<
    {
      media: z.infer<typeof mediaSchema>;
    },
    "media",
    "id"
  >;
  croppingImage: number | undefined;
  setCroppingImage: React.Dispatch<React.SetStateAction<number | undefined>>;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCropModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomCroppingImage?: any
}

export function useMediaUpload({
  controls,
  croppingImage,
  setCroppingImage,
  setCustomCroppingImage,
  setShowDeleteModal,
  getValues,
  setShowCropModal,
}: Props) {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const { fields, update, remove, append } = controls;

  const [deletedPersisted, setDeletedPersisted] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<number[]>([]);

  const togglePrimary = (index: number) => {
    if (fields[index].isPrimary) {
      return;
    }

    fields.forEach((field, i) => {
      if (field.isPrimary) {
        update(i, {
          ...field,
          isPrimary: false,
        });
      }
    });

    update(index, {
      ...fields[index],
      isPrimary: !fields[index].isPrimary,
    });
  };
  const removeSingle = (index: number) => {
    const field = fields[index];

    if (field.persisted) {
      setDeletedPersisted([...deletedPersisted, field.image_db_id as string]);
    }

    remove(index);
  };

  const toggleSelect = (index: number) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter((i) => i !== index));
    } else {
      setSelectedImages([...selectedImages, index]);
    }
  };

  const removeSelected = () => {
    selectedImages.forEach((index) => {
      const field = fields[index];

      if (field.persisted) {
        setDeletedPersisted((current) => [
          ...current,
          field.image_db_id as string,
        ]);
      }
    });
    remove(selectedImages);
    setSelectedImages([]);
    setShowDeleteModal(false);
  };

  const createMedia = async (
    event: React.ChangeEvent<HTMLInputElement>,
    customAppend?: any,
    customAppendIndex?: number
  ) => {
    const files = (event.target as HTMLInputElement).files;
    let length = fields.length;

    if (files && files[0] && length < 12) {
      const correctType = ["image/jpeg", "image/png"].includes(files[0].type);
      const correctSize = files[0].size <= 5 * 1024 * 1024;
      if (correctType && correctSize) {
        const url = (await fileToDataString(files[0])) as string;

        const newMediaData = {
          url,
          blob: files[0],
          filename: files[0].name,
          size: files[0].size,
          isPrimary: false,
          createdAt: new Date(),
          persisted: false,
        };
        if (customAppend) {
          customAppend(newMediaData);
        }
        append(newMediaData);

        if (customAppend) {
          setCustomCroppingImage(customAppendIndex)
        } else {
          setCroppingImage(length);
        }
        setShowCropModal(true);
      } else {
        dispatch(
          globalActions.setToastNotifcation({
            ...toastNotification,
            type: "ERROR",
            message: correctType
              ? "Unsupported file type"
              : correctSize
              ? "File is too large"
              : "Invalid file",
            attractionApprovalStatus: "",
            attractionImage: "",
            attractionName: "",
            attractionAddress: "",
          })
        );
        restartAnimation();
      }
    }

    event.target.value = "";
  };

  const cropImage = (value: string) => {
    const index = croppingImage ?? 0;
    const newFile = dataStringToBlob(value);

    update(index, {
      ...getValues(`media.${index}`),
      url: value,
      blob: newFile,
    });
    setShowCropModal(false);
  };

  const cancelCrop = () => {
    const index = croppingImage ?? 0;
    remove(index);
    setShowCropModal(false);
  };

  return {
    deletedPersisted,
    setDeletedPersisted,
    selectedImages,
    setSelectedImages,

    cropImage,
    cancelCrop,
    removeSingle,
    togglePrimary,
    toggleSelect,
    removeSelected,
    createMedia,
  };
}

export function fileToDataString(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      resolve(event.target?.result as string);
    };
    reader.onerror = reject;
  });
}
export function dataStringToBlob(base64String: string) {
  const binaryString = atob(base64String.split(",")[1]); // Binary data string
  const blob = new Blob([binaryString], { type: "image/png" }); // Create a BLOB object
  return blob;
}

export const ImageCropper: React.FC<{
  file: string;
  stencilAspectRatio?: number;
  onAccept: (value: string) => void;
  onCancel: () => void;
}> = ({ file, stencilAspectRatio = 1 / 1, onAccept, onCancel }) => {
  const cropperRef = useRef<CropperRef>(null);
  const [cropperImage, setCropperImage] = useState<string>(file);

  useEffect(() => {
    setCropperImage(file);
  }, [file]);

  const onChange = (cropper: CropperRef) => {
    setCropperImage(cropper.getCanvas()?.toDataURL() ?? "");
  };

  const accepted = () => {
    const cropped = cropperRef.current?.getCanvas()?.toDataURL() ?? "";
    onAccept(cropperImage ? cropperImage : cropped);
  };

  return (
    <>
      <div className="w-full sm:w-[80%] max-w-full aspect-square overflow-none">
        <Cropper
          className="w-full max-w-full aspect-square"
          ref={cropperRef}
          src={file}
          resize-image={{
            adjustStencil: true,
          }}
          stencilProps={{
            aspectRatio: stencilAspectRatio,
            movable: true,
            resizable: true,
            handlers: true,
          }}
          onChange={onChange}
        />
      </div>

      <div className="flex flex-row mt-10">
        <button
          className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
          onClick={accepted}
        >
          Save
        </button>

        <button
          className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </>
  );
};
