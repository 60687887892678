import { type RouteKeys } from "../App";

const STORAGE_KEY = "new_attractions_finish_later";

type FilterKeys<T extends string> = T extends `ATTRACTION_${infer U}` ? T : never;
export type AttractionKeys = FilterKeys<RouteKeys>

type Storage = {
    [attractionId: string]: AttractionKeys,
} & {
    step?: number,
    allSteps?: number
}

function getStorage() {
    const raw = localStorage.getItem(STORAGE_KEY);
    if (!raw) return undefined;

    return JSON.parse(raw) as Storage
}
export function getNewAttractionWhichTabLeftOver(attractionId: string) {
    const storage = getStorage();
    return storage?.[attractionId]
}

export function getNewAttractionWhichStepLeftOver() {
    const storage = getStorage();
    return {
        step: storage?.step,
        allSteps: storage?.allSteps
    }
}
export function clearNewAttractionStepLeftOver() {
    const storage = getStorage() ?? {};
    delete storage['step'];
    delete storage['allSteps'];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage))
}

export function clearNewAttractionLeftOverTab(attractionId: string) {
    const storage = getStorage() ?? {};
    delete storage[attractionId];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage))
}
export function setNewAttractionLeftOverTab(attractionId: string, key: AttractionKeys, step?: number, allSteps?: number) {
    const storage = getStorage() ?? {};
    
    storage[attractionId] = key;
    storage.step = step;
    storage.allSteps = allSteps;

    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage))
}