import React, { useEffect, useRef } from "react";
import sparkline from "@fnando/sparkline";

type Colors = 'primary' | 'red' | 'yellow'
interface SparklineProps {
  data: number[];
  width: number;
  height: number;
  strokeWidth: number;
  color: Colors
}

export const Sparkline: React.FC<SparklineProps> = ({
  data,
  width,
  height,
  strokeWidth,
  color
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      sparkline(svgRef.current, data);
    }
  }, [data]);

  const colors: Record<Colors, string> = {
    primary: 'stroke-primary fill-primary/10',
    red: 'stroke-[#F66254] fill-[#F66254]/10',
    yellow: 'stroke-[#F2C96F] fill-[#F2C96F]/10'
  }

  return (
    <svg
      ref={svgRef}
      className={`sparkline ${colors[color]}`}
      width={width}
      height={height}
      strokeWidth={strokeWidth}
    ></svg>
  );
};
