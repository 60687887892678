import { ReactNode } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../global/Tooltip";

export function LightBulbTooltip({
  children,
  initialOpen = false,
}: {
  children: ReactNode;
  initialOpen?: boolean;
}) {
  return (
    <Tooltip
      open={initialOpen === true ? initialOpen : undefined}
      placement="bottom-start"
    >
      <TooltipTrigger>
        <LightBulb />
      </TooltipTrigger>
      <TooltipContent className="Tooltip">
        <div className="w-[276px]">
          <div className="bg-[#EDE8F8] py-2.5 px-5 rounded-lg text-sm font-normal font-fredoka leading-[21px] text-[#6836D1]">
            {children}
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  );
}

export function LightBulb({ className }: { className?: string }) {
  return (
    <svg
      className={`inline-block ${className}`}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8.41992H2.66667M8 2.41992V3.08659M13.3333 8.41992H14M3.73333 4.15326L4.2 4.61992M12.2667 4.15326L11.8 4.61992M6.46667 11.7533H9.53333M6 11.0866C5.44031 10.6668 5.02689 10.0816 4.81829 9.41381C4.60969 8.74603 4.61649 8.02954 4.83772 7.36583C5.05896 6.70212 5.48341 6.12485 6.05097 5.71578C6.61852 5.30671 7.30039 5.08659 8 5.08659C8.69961 5.08659 9.38148 5.30671 9.94903 5.71578C10.5166 6.12485 10.941 6.70212 11.1623 7.36583C11.3835 8.02954 11.3903 8.74603 11.1817 9.41381C10.9731 10.0816 10.5597 10.6668 10 11.0866C9.73971 11.3442 9.54373 11.6596 9.42791 12.007C9.3121 12.3545 9.2797 12.7243 9.33333 13.0866C9.33333 13.4402 9.19286 13.7793 8.94281 14.0294C8.69276 14.2794 8.35362 14.4199 8 14.4199C7.64638 14.4199 7.30724 14.2794 7.05719 14.0294C6.80714 13.7793 6.66667 13.4402 6.66667 13.0866C6.7203 12.7243 6.6879 12.3545 6.57209 12.007C6.45627 11.6596 6.26029 11.3442 6 11.0866Z"
        stroke="#6836D1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LightningIcon({
  className,
  tooltip,
}: {
  className?: string;
  tooltip?: string;
}) {
  return !tooltip ? (
    <svg
      className={`inline-block ${className}`}
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7298 7.82865C11.7017 7.70854 11.6444 7.59721 11.563 7.50454C11.4815 7.41187 11.3785 7.34073 11.263 7.2974L7.86047 6.02178L8.73547 1.64678C8.76746 1.48677 8.74639 1.32069 8.67546 1.17374C8.60453 1.02679 8.48761 0.906978 8.34243 0.832482C8.19725 0.757985 8.03174 0.732867 7.871 0.760938C7.71026 0.789009 7.56306 0.868737 7.45172 0.988029L0.451721 8.48803C0.367502 8.57817 0.30682 8.6877 0.275051 8.8069C0.243283 8.9261 0.241408 9.0513 0.269595 9.1714C0.297781 9.2915 0.355157 9.4028 0.436639 9.49542C0.518121 9.58804 0.621193 9.65914 0.736721 9.7024L4.13922 10.978L3.26422 15.353C3.23223 15.513 3.2533 15.6791 3.32423 15.8261C3.39516 15.973 3.51208 16.0928 3.65726 16.1673C3.80244 16.2418 3.96795 16.2669 4.12869 16.2389C4.28943 16.2108 4.43664 16.1311 4.54797 16.0118L11.548 8.51178C11.6321 8.42164 11.6927 8.31214 11.7244 8.19299C11.7562 8.07383 11.758 7.9487 11.7298 7.82865ZM5.25297 13.058L5.73547 10.6468C5.77037 10.4722 5.742 10.2908 5.65543 10.1352C5.56886 9.97958 5.42974 9.85984 5.26297 9.7974L2.31235 8.69178L6.74547 3.94178L6.26297 6.35303C6.22807 6.52765 6.25644 6.70899 6.34301 6.86461C6.42958 7.02023 6.5687 7.13997 6.73547 7.2024L9.68735 8.30803L5.25297 13.058Z"
        fill="#6836D1"
      />
    </svg>
  ) : (
    <Tooltip placement="bottom-start">
      <TooltipTrigger className={className ?? ""}>
        <svg
          className={`inline-block`}
          width="12"
          height="17"
          viewBox="0 0 12 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7298 7.82865C11.7017 7.70854 11.6444 7.59721 11.563 7.50454C11.4815 7.41187 11.3785 7.34073 11.263 7.2974L7.86047 6.02178L8.73547 1.64678C8.76746 1.48677 8.74639 1.32069 8.67546 1.17374C8.60453 1.02679 8.48761 0.906978 8.34243 0.832482C8.19725 0.757985 8.03174 0.732867 7.871 0.760938C7.71026 0.789009 7.56306 0.868737 7.45172 0.988029L0.451721 8.48803C0.367502 8.57817 0.30682 8.6877 0.275051 8.8069C0.243283 8.9261 0.241408 9.0513 0.269595 9.1714C0.297781 9.2915 0.355157 9.4028 0.436639 9.49542C0.518121 9.58804 0.621193 9.65914 0.736721 9.7024L4.13922 10.978L3.26422 15.353C3.23223 15.513 3.2533 15.6791 3.32423 15.8261C3.39516 15.973 3.51208 16.0928 3.65726 16.1673C3.80244 16.2418 3.96795 16.2669 4.12869 16.2389C4.28943 16.2108 4.43664 16.1311 4.54797 16.0118L11.548 8.51178C11.6321 8.42164 11.6927 8.31214 11.7244 8.19299C11.7562 8.07383 11.758 7.9487 11.7298 7.82865ZM5.25297 13.058L5.73547 10.6468C5.77037 10.4722 5.742 10.2908 5.65543 10.1352C5.56886 9.97958 5.42974 9.85984 5.26297 9.7974L2.31235 8.69178L6.74547 3.94178L6.26297 6.35303C6.22807 6.52765 6.25644 6.70899 6.34301 6.86461C6.42958 7.02023 6.5687 7.13997 6.73547 7.2024L9.68735 8.30803L5.25297 13.058Z"
            fill="#6836D1"
          />
        </svg>
      </TooltipTrigger>
      <TooltipContent className="Tooltip">
        <div className="w-[276px]">
          <div className="bg-[#EDE8F8] py-2.5 px-5 rounded-lg text-sm font-normal font-fredoka leading-[21px] text-[#6836D1]">
            {tooltip}
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  );
}
