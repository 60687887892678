import React, { useEffect, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { createSearchParams, Link, useLocation } from "react-router-dom";
import Table from "../../lib/react-table/Table";
import moment from "moment";
import { apiSlice } from "../../store/apiSlice";

import ClaimModal, { iPendingClaims } from "../../components/global/ClaimModal";
import { restartAnimation } from "../../components/global/amp/ToastNotification";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import { TableText } from "../global/TableText";
import { createPortal } from "react-dom";
import ModalWrapper from "../global/ModalWrapper";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import ContextMenuButtons from "../global/ContextMenuButtons";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const UpdateQueueAttractionsTable: React.FC = () => {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { data: attractionsTableData, isFetching } =
    apiSlice.useGetPublishQueueListQuery();
  const data = React.useMemo<AttractionDetails[]>(
    () => attractionsTableData ?? ([] as AttractionDetails[]),
    [attractionsTableData]
  );
  const [
    publishInQueue,
    { isError: isErrorPublish, isSuccess: isSuccessPublish },
  ] = apiSlice.useSubmitQueueMutation();
  const [
    denyInQueue,
    {
      isError: isErrorDeny,
      isSuccess: isSuccessDeny,
      isLoading: isDenyLoading,
    },
  ] = apiSlice.useDenyQueueMutation();

  useEffect(() => {
    isSuccessPublish &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Approved",
        })
      );
  }, [isSuccessPublish]);

  useEffect(() => {
    isErrorPublish &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot publish",
        })
      );
  }, [isErrorPublish]);

  useEffect(() => {
    isSuccessDeny &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Denied",
        })
      );
  }, [isSuccessDeny]);

  useEffect(() => {
    isErrorDeny &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot deny",
        })
      );
  }, [isErrorDeny]);

  const columns = React.useMemo<Column<AttractionDetails>[]>(
    () => [
      {
        Header: "ATTRACTION NAME",
        accessor: "name",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <Link
                to={`/attractions/${original?.short_id}`}
                state={{
                  from: "/dashboard/pending-approval" + location.search,
                }}
                className="whitespace-nowrap text-ellipsis overflow-hidden block font-bold text-black font-roboto-flex text-sm w-24 xs:w-60 xl:w-56"
              >
                {original.name}
              </Link>
            </>
          );
        },
        width: "65%",
        minWidth: 225,
      },
      {
        Header: "REQUESTED",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <TableText>
                {moment(original.requested_at).format("DD MMM YYYY")}
              </TableText>
            </>
          );
        },
        width: "12.8%",
        disableSortBy: true,
      },
      {
        Header: "AUTO APPROVAL",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <TableText>
                {moment(original.resolves_at).format("DD MMM YYYY")}
              </TableText>
            </>
          );
        },
        width: "12.8%",
        disableSortBy: true,
      },
      {
        Header: " ",
        accessor: (data) => {
          return (
            <ContextMenuButtons
              options={[
                {
                  name: "Approve",
                  action: async () => {
                    await publishInQueue(data.short_id);
                    restartAnimation();
                  },
                },
                {
                  name: "Deny",
                  action: () => {
                    setCurrentShortId(data.short_id);
                    setShowClaimModal(true);
                  },
                },
              ]}
            />
          );
        },
        width: "12%",
        disableSortBy: true,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = useTable<AttractionDetails>(
    {
      columns,
      data,
      disableSortRemove: true,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        hiddenColumns: ["email", "website"],
        sortBy: [{ id: "created_by_user", desc: true }],
        pageSize: 1000,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<AttractionDetails>;

  const [getCurrentShortId, setCurrentShortId] = useState("");
  const [getShowClaimModal, setShowClaimModal] = useState(false);

  const schema = z.object({
    note: z.string().min(1, "Reason for claim denied is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    setValue,
    getValues,
  } = useForm({
    resolver: async (data, context, options) => {
      return zodResolver(schema)(data, context, options);
    },
    defaultValues: {
      note: "",
    },
  });

  const onSubmitClaimApproval: SubmitHandler<{ note: string }> = async ({
    note,
  }) => {
    await denyInQueue({ id: getCurrentShortId, message: note });
    restartAnimation();
    setShowClaimModal(false);
  };
  return (
    <>
      <div id="pendingApprovalTable">
        <Table<AttractionDetails>
          // <table id>
          tableId="pendingApprovalTable"
          // <table className>
          tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
          // <thead className>
          tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
          // <tbody className>
          tableTbodyClassNames={`relative ${
            page.length === 0 ? "h-[60px]" : ""
          }`}
          // Mobile <tbody className>
          tableTbodyMobileClassNames="block"
          // <tbody><tr className>
          tableTbodyTrClassNames="h-1 last-of-type:hidden"
          // Empty <tbody><tr className>
          tableTbodyTrEmptyMobileClassNames="border-none"
          // Mobile <tbody><tr className>
          tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative shadow-cs-admin-table-td overflow-hidden border-2 border-cs-off-white font-sm font-normal text-cs-gray rounded-xl p-3"
          // <tbody><tr><td className>
          tableTbodyTrTdClassNames="relative bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
          // Empty <tbody><tr><td className>
          tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
          // Mobile <tbody><tr><td className>
          tableTbodyTrEmptyTdMobileClassNames="h-2"
          // Hide Columns On Mobile
          hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
          // react-table props
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          pages={attractionsTableData?.length}
          prepareRow={prepareRow}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          isFetching={isFetching} 
        />
      </div>

      {createPortal(
        <ModalWrapper
          className={`w-[787px]`}
          open={getShowClaimModal}
          setOpen={(val) => {
            setShowClaimModal(val);
          }}
        >
          <>
            <h3 className="text-2xl font-bold text-cs-gray">{"Deny Update"}</h3>
            <p className="text-base font-normal text-center my-7 text-cs-gray">
              Enter a reason for denying this update. This text will be sent
              directly to the owner via email, so check spelling & grammar
            </p>

            <form
              className="flex flex-col w-full h-full"
              onSubmit={handleSubmit(onSubmitClaimApproval)}
            >
              <fieldset className="flex flex-col w-full">
                <label className="flex font-normal text-cs-gray">
                  Why was this claim denied?
                </label>
                <textarea
                  {...register("note")}
                  className="w-full h-32 p-3 mt-2 rounded-sm text-cs-gray text-md border-cs-lt-gray border-cs-1 outline-cs-pink"
                ></textarea>
              </fieldset>

              <button
                type="submit"
                disabled={isDenyLoading}
                className={`mx-auto mt-14 mb-5 leading-none rounded-full h-cs-55 w-full py-2 px-12 text-white text-md border-cs-1 font-bold shadow-cs-button-backdrop hover:text-cs-pink hover:outline-cs-pink md:w-auto ${
                  false
                    ? "bg-cs-red border-cs-red hover:bg-white"
                    : isDenyLoading
                    ? "bg-cs-bright-lt-gray border-cs-bright-lt-gray text-opacity-50 !hover:bg-cs-bright-lt-gray hover:border-cs-bright-lt-gray hover:text-opacity-50 hover:text-white"
                    : "bg-cs-pink border-cs-pink hover:bg-white"
                }`}
              >
                Deny & send to owner
              </button>
            </form>
          </>
        </ModalWrapper>,
        document.body
      )}
    </>
  );
};

export default UpdateQueueAttractionsTable;
