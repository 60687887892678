import { useFormContext } from 'react-hook-form';
import TextInput from '../../../../components/form/TextInput';

export function TicketPrice() {
  const { register, control } = useFormContext()

  return <TextInput
    name={"default_price"}
    className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
    labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
    inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink"
    label="Ticket Price"
    control={control}
    required
    inputProps={{
      ...register('default_price'),
      type: "number",
      step: "0.01",
    }} />;
}
