import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

// Define a type guard for FetchBaseQueryError
function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
  return error && typeof error.status === "number";
}

// Define a type guard for SerializedError
function isSerializedError(error: any): error is SerializedError {
  return error && typeof error.message === "string";
}

// Custom hook to handle API errors
export const useApiError = (error: FetchBaseQueryError | SerializedError | undefined) => {
  let errorMessage: string | undefined;

  if (error) {
    if (isFetchBaseQueryError(error)) {
      if ("data" in error) {
        errorMessage = (error.data as { message?: string })?.message;
      }
    } else if (isSerializedError(error)) {
      errorMessage = error.message;
    } else {
      errorMessage = "Error";
    }
  }

  return errorMessage;
};