import { type ReactNode } from "react";

export function TableTextNormal({
  children,
  className = "",
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <div
      className={`text-black min-w-[90px] text-sm font-normal ${className}`}
    >
      {children}
    </div>
  );
}
