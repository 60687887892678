import { useFormContext } from 'react-hook-form';
import TextInput from '../../../../components/form/TextInput';

export function AvailabilityPeriodDates() {
  const { register, control } = useFormContext()

  return <>
    <TextInput
      name={"start_date"}
      className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
      labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
      inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink"
      label="Start Date"
      control={control}
      required
      inputProps={{
        ...register('start_date'),
        type: "date",
      }} />
    <TextInput
      name={"end_date"}
      className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
      labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
      inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink"
      label="End Date - Leave blank for no end date"
      control={control}
      inputProps={{
        ...register('end_date'),
        type: "date",
      }} />
  </>
}
