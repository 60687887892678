import Header from "../../components/global/amp/Header";
import Footer from "../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import MultiSelectOptions from "../../lib/react-select/MultiSelectOptions";
import { DropDownIndicator } from "./attractions/AttractionOpeningTimes";
import ClearIndicator from "../../lib/react-select/ClearIndicator";
import { apiSlice } from "../../store/apiSlice";
import { useWindowResize } from "../../hooks/useWindowResize";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import SingleSelectOption from "../../lib/react-select/SingleSelectOption";
import ModalWrapper from "../../components/global/ModalWrapper";
import type { AmpUserDetail } from "../../types/user";
import { useNavigate } from "react-router-dom";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { debounce } from "../../helpers/debounce";

interface AddUserForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  options: string;
  status: string;
  attractions: { value: string; label: string }[] | undefined;
  usertypes: { value: string; label: string } | undefined;
  operators: { value: string; label: string } | undefined;
}

const AddUser: React.FC = () => {
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const windowSize = useWindowResize();

  const userTypes: { value: string; label: string }[] = [
    { value: "OPERATOR_OWNER", label: "Owner" },
    { value: "ATTRACTION_MANAGER", label: "Manager" },
  ];

  const [getFetchingNewOperatorId, setFetchingNewOperatorId] = useState<
    string | null
  >();
  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);
  const [getSelectedOperatorOptions, setSelectedOperatorOptions] =
    useState<any[]>();
  const [getCopyDataOperator, setCopyDataOperator] = useState<{
    value: "0";
    label: "";
  }>();

  const [lazyGetOperators] = apiSlice.useLazyGetOperatorsQuery();
  const { data: getOperators, isFetching: isFetchingOperators } = apiSlice.useGetOperatorsQuery();

  const {
    data: getUserAttractions,
    isFetching,
    isLoading: isLoadingAttractions,
  } = apiSlice.useGetUserAttractionsQuery(
    getFetchingNewOperatorId == "0"
      ? {}
      : { operatorId: getFetchingNewOperatorId }
  );

  const schema = z.object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    email: z
      .string()
      .min(1, "Email is required")
      .email("Enter a valid email address"),
    phoneNumber: z
      .string()
      .min(10, "Phone number is required")
      .refine(
        (val) =>
          new RegExp(
            /^(\+44\s?|\(0\d{4}\)\s?|\(0\d{3}\)\s?|\(0\d{2}\)\s?|\d{4}\s?|\d{3}\s?|\d{2}\s?)\d{3}\s?\d{4}(\s?\#\d{3,4})?$/
          ).test(val),
        "Invalid phone number. It should be at least 10 digits with no spaces"
      ),
    operators: z
      .object({
        value: z.string(),
        label: z.string(),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (user?.role === "ADMIN" && value?.value === "0") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Please select an operator`,
          });
        }
      }),
    attractions: z.array(z.object({
      value: z.string(),
      label: z.string()
    }))
      .superRefine((value, ctx) => {
        if (!value?.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Please select at least one attraction`,
          });
        }
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    control,
    getValues,
    setValue,
    resetField,
    reset,
  } = useForm<AddUserForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      usertypes: userTypes[1],
      operators: { value: "0", label: "unassigned" },
      attractions: [],
    },
  });

  const [requestInviteUser, { isError, error, isSuccess, isLoading }] =
    apiSlice.useInviteUserMutation();

  const onSubmit: SubmitHandler<AddUserForm> = (e) => {
    requestInviteUser({
      first_name: getValues("firstName"),
      last_name: getValues("lastName"),
      email: getValues("email"),
      phone: getValues("phoneNumber"),
      attraction_ids:
        getValues("attractions")?.length === 0 || !getValues("attractions")
          ? []
          : getValues("attractions")?.map((attraction) =>
            attraction.value.toString()
          ),
      access_restricted: getValues("status") === "active" ? false : true,
      role: getValues("usertypes")?.value ?? "ATTRACTION_MANAGER",
      operator_id:
        getValues("operators")?.value === "0"
          ? null
          : getValues("operators")?.value,
    });
  };

  const handleChange = (value: any, data: any, meta: any, action: any) => {
    setCopyDataOperator({ value: value.value, label: value.label });
    setShowRevertModal(true);
  };

  const changeOperatorAndFetchAttractions = () => {
    setValue("operators", getCopyDataOperator, { shouldValidate: true });
    setFetchingNewOperatorId(getCopyDataOperator!.value);
    resetField("attractions");
    setShowRevertModal(false);
  };

  const [getHideForm, setHideForm] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Add User | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    setSelectedOperatorOptions(
      getOperators?.results
        .concat({ id: 0, title: "unassigned" })
        .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
        .map((operator) => ({ value: operator.id, label: operator.title }))
    );
  }, [getOperators]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !(user as AmpUserDetail)?.email_verified &&
      (user as AmpUserDetail).role !== "ADMIN"
    ) {
      navigate("/dashboard");
    }
  }, []);

  const loadOperators = (
    inputValue: string,
    callback: (
      options: {
        value: string;
        label: string;
      }[]) => void
  ) => {
    lazyGetOperators({
      query: inputValue,
    })
      .unwrap()
      .then((data) => {
        const options = data?.results.map((a) => ({
          value: a.id as string,
          label: a.title ?? "",
        }));

        callback(
          options
        );
      });
  }

  const loadOperatorsDebounced = debounce((inputValue: string, callback: (options: any) => void) => {
    loadOperators(inputValue, callback);
  }, 500)


  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header title="Add User" showBack />

      <SingleAttractionTemplate name={"User Details"}>
        {getHideForm ? (
          <div className="flex flex-col">
            <p className="mt-2 mb-4 font-normal text-cs-gray text-md">
              An invite has been sent to{" "}
              <span className="font-bold text-cs-pink">
                {getValues("firstName")} {getValues("lastName")}
              </span>
              , click below to add another user.
            </p>
            <button
              onClick={() => {
                reset();
                setHideForm(false);
              }}
              className="flex items-center px-5 py-3 mr-auto text-sm font-bold leading-normal h-cs-55 border-cs-1 border-cs-off-white shadow-cs-button-backdrop rounded-3xl text-cs-pink hover:bg-cs-pink hover:border-cs-pink hover:text-white xl:rounded-2xl"
            >
              {windowSize?.width! <= 1280 ? (
                <span className="text-xl font-bold">+</span>
              ) : (
                <>
                  <span className="mr-3 text-xl font-bold">+</span>Add another
                  user
                </>
              )}
            </button>
          </div>
        ) : (
          <form className="pb-10" onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="flex flex-wrap w-full mb-4 md:w-3/4 xl:w-2/5">
              <div className="w-full grid grid-cols-2 gap-2">
                <TextInput
                  className="group"
                  labelClassName="group-focus-within:text-cs-pink font-bold"
                  inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                  control={control}
                  name={"firstName"}
                  label="First name"
                  required
                  inputProps={{ ...register("firstName") }}
                />
                <TextInput
                  className="group"
                  labelClassName="group-focus-within:text-cs-pink font-bold"
                  inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                  control={control}
                  name={"lastName"}
                  label="Last name"
                  required
                  inputProps={{ ...register("lastName") }}
                />
              </div>
              <TextInput
                className="w-full mt-1 mb-3 group md:w-full"
                labelClassName="group-focus-within:text-cs-pink font-bold"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
                name={"email"}
                label="Email address"
                required
                inputProps={{ ...register("email") }}
              />
              <TextInput
                className="w-full mt-1 group"
                labelClassName="group-focus-within:text-cs-pink font-bold"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
                name={"phoneNumber"}
                label="Phone number"
                required
                inputProps={{ ...register("phoneNumber") }}
              />
              {user?.role === "ADMIN" && (
                <fieldset className="w-full mt-2.5">
                  <label className="block mb-2 text-sm font-bold text-black">
                    Operator
                  </label>
                  <Controller
                    control={control}
                    name={"operators"}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <AsyncSelect
                          className="w-full"
                          value={getValues("operators")}
                          placeholder="Select an operator"
                          loadOptions={loadOperatorsDebounced}
                          defaultOptions={getSelectedOperatorOptions}
                          components={{
                            Option: SingleSelectOption,
                            DropdownIndicator: DropDownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(value, meta) =>
                            handleChange(
                              value,
                              getSelectedOperatorOptions,
                              meta,
                              setSelectedOperatorOptions
                            )
                          }
                          options={getSelectedOperatorOptions}
                          isSearchable={true}
                          noOptionsMessage={() =>
                            isFetchingOperators ? "...Loading" : "No Options"
                          }
                          styles={{
                            menu: (styles) => ({
                              ...styles,
                              margin: 0,
                              border: "none",
                            }),
                            menuList: (base, isSelected) => ({
                              ...base,
                              paddingTop: 0,
                              paddingBottom: 0,
                              background: "white",

                              "::-webkit-scrollbar": {
                                width: 14,
                              },
                              "::-webkit-scrollbar-thumb": {
                                background: "#c2c2c2",
                                borderRadius: 10,
                                border: "4px solid white",
                              },
                            }),
                            option: (
                              base,
                              { data, isDisabled, isFocused, isSelected }
                            ) => {
                              return {
                                ...base,
                                background: "white",
                                border: "1px solid #c2c2c2",
                                color: isSelected ? "white" : "#555",
                                "&:hover": {
                                  backgroundColor: "#6836D1",
                                  color:
                                    isSelected || isFocused ? "white" : "#555",
                                  border: isFocused ? "1px solid white" : "",
                                },
                                "&:active": {
                                  backgroundColor: "#6836D1",
                                  color: "white",
                                },
                              };
                            },
                            noOptionsMessage: (base) => ({
                              ...base,
                              color: "#555",
                            }),
                            singleValue: (base) => ({
                              ...base,
                              color: "#555",
                              fontSize: 14,
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              cursor: "pointer",
                              outline: "none",
                              border: state.isFocused
                                ? "1px solid #6836D1"
                                : `1px solid ${error ? "red" : "#999999"}`,
                              "&:hover": {
                                border: state.isFocused
                                  ? "1px solid #6836D1"
                                  : "1px solid #6836D1",
                              },
                              boxShadow: "none",
                              paddingTop: 3,
                              paddingBottom: 3,
                              margin: 0,
                              minHeight: 50,
                            }),
                          }}
                        />
                        {error && error.message && (
                          <div className="flex items-center mt-2 text-cs-red">
                            <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                            <span className="flex-1">{error.message}</span>
                          </div>
                        )}
                      </>
                    )}
                  />
                </fieldset>
              )}
            </fieldset>

            <fieldset className="w-full mb-4 md:w-3/4 xl:w-2/5">
              <legend className="text-[22px] font-extrabold text-black">
                Attractions
              </legend>
              <p className="mt-1 mb-3 font-normal text-[#5F646D] text-sm">
                A list of attractions this user can manage
              </p>

              <Controller
                control={control}
                name="attractions"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Select
                      value={getValues("attractions")}
                      onChange={onChange}
                      isDisabled={user?.role === "ADMIN" && (!getFetchingNewOperatorId || getFetchingNewOperatorId == "0") ? true : false}
                      isMulti
                      isClearable={undefined}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      options={getUserAttractions?.results
                        .map((option: any) => ({
                          value: option.id,
                          label: option.name,
                        }))
                        .sort((a: { label: string }, b: { label: string }) =>
                          a.label.localeCompare(b.label)
                        )}
                      components={{
                        Option: MultiSelectOptions,
                        ClearIndicator: ClearIndicator,
                        DropdownIndicator: DropDownIndicator,
                        IndicatorSeparator: () => null
                      }}
                      placeholder={""}
                      isSearchable={false}
                      noOptionsMessage={() =>
                        isFetching ? "...Loading" : "No Options"
                      }
                      styles={{
                        multiValue: (styles) => ({
                          ...styles,
                          backgroundColor: "#F1F1F1",
                          borderRadius: 8,
                          height: isLoadingAttractions ? 0 : 31,
                          display: isLoadingAttractions ? "none" : "flex",
                          alignItems: "center",
                        }),
                        multiValueRemove: () => ({
                          display: "none",
                        }),
                        multiValueLabel: (styles) => ({
                          ...styles,
                          color: "#000",
                          fontSize: 14,
                          fontWeight: 400,
                          display: isLoadingAttractions ? "none" : "block",
                          paddingLeft: isLoadingAttractions ? 0 : 10,
                          paddingRight: isLoadingAttractions ? 0 : 10,
                        }),
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          paddingLeft: 5,
                          border: "1px solid #6836D1",
                        }),
                        menuList: (base) => ({
                          ...base,
                          marginTop: 0,
                          marginBottom: 0,

                          "::-webkit-scrollbar": {
                            width: 14,
                          },
                          "::-webkit-scrollbar-thumb": {
                            background: "#c2c2c2",
                            borderRadius: 10,
                            border: "4px solid white",
                          },
                        }),
                        noOptionsMessage: (base) => ({
                          ...base,
                          color: "#555",
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          cursor: "pointer",
                          outline: "none",
                          border: state.isFocused
                            ? "1px solid #6836D1"
                            : "1px solid #c2c2c2",
                          "&:hover": {
                            border: state.isFocused
                              ? "1px solid #6836D1"
                              : "1px solid #6836D1",
                          },
                          boxShadow: "none",
                          paddingTop: 0,
                          paddingRight: 8,
                          paddingLeft: 5,
                          paddingBottom: 0,
                          minHeight: 50,
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        background: "none",
                        colors: {
                          ...theme.colors,
                          neutral80: "#555",
                        },
                      })}
                    />
                    {error && error.message && (
                      <div className="flex items-center mt-2 text-cs-red">
                        <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                        <span className="flex-1">{error.message}</span>
                      </div>
                    )}
                  </>
                )}
              />
            </fieldset>

            <fieldset className="w-full mb-4 md:w-3/4 xl:w-2/5">
              <legend className="text-[22px] font-extrabold text-black">
                User Status
              </legend>

              {user?.role === "ADMIN" && (
                <fieldset className="mt-4 mb-6">
                  <label className="block mb-2 font-normal text-cs-gray">
                    User type
                  </label>
                  <Controller
                    control={control}
                    name={"usertypes"}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <Select
                        className="w-full"
                        value={getValues("usertypes")}
                        components={{
                          Option: SingleSelectOption,
                          DropdownIndicator: DropDownIndicator,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={onChange}
                        options={userTypes}
                        isSearchable={false}
                        noOptionsMessage={() =>
                          isFetchingOperators ? "...Loading" : "No Options"
                        }
                        styles={{
                          menu: (styles) => ({
                            ...styles,
                            margin: 0,
                            border: "none",
                          }),
                          menuList: (base, isSelected) => ({
                            ...base,
                            paddingTop: 0,
                            paddingBottom: 0,
                            background: "white",

                            "::-webkit-scrollbar": {
                              width: 14,
                            },
                            "::-webkit-scrollbar-thumb": {
                              background: "#c2c2c2",
                              borderRadius: 10,
                              border: "4px solid white",
                            },
                          }),
                          option: (
                            base,
                            { data, isDisabled, isFocused, isSelected }
                          ) => {
                            return {
                              ...base,
                              background: "white",
                              border: "1px solid #c2c2c2",
                              color: isSelected ? "white" : "#555",
                              "&:hover": {
                                backgroundColor: "#6836D1",
                                color:
                                  isSelected || isFocused ? "white" : "#555",
                                border: isFocused ? "1px solid white" : "",
                              },
                              "&:active": {
                                backgroundColor: "#6836D1",
                                color: "white",
                              },
                            };
                          },
                          noOptionsMessage: (base) => ({
                            ...base,
                            color: "#555",
                          }),
                          singleValue: (base) => ({
                            ...base,
                            color: "#555",
                            fontSize: 14,
                          }),
                          control: (styles, state) => ({
                            ...styles,
                            cursor: "pointer",
                            outline: "none",
                            border: state.isFocused
                              ? "1px solid #6836D1"
                              : "1px solid #999999",
                            "&:hover": {
                              border: state.isFocused
                                ? "1px solid #6836D1"
                                : "1px solid #6836D1",
                            },
                            boxShadow: "none",
                            paddingTop: 3,
                            paddingBottom: 3,
                            margin: 0,
                            minHeight: 50,
                          }),
                        }}
                      />
                    )}
                  />
                </fieldset>
              )}

              <p className="mt-1 mb-2 font-light text-[#5F646D] text-sm">
                Select a status for this user
              </p>

              <div className="mb-3 radio-item">
                <input
                  {...register("status")}
                  type="radio"
                  value="active"
                  id="fieldActive"
                  defaultChecked
                />
                <label htmlFor="fieldActive">Active</label>
              </div>

              <div className="radio-item">
                <input
                  {...register("status")}
                  type="radio"
                  value="suspended"
                  id="fieldAccessSuspended"
                />
                <label htmlFor="fieldAccessSuspended">Access suspended</label>
              </div>
            </fieldset>

            <PrimaryButton type="submit" loading={isLoading} scale="sm">
              Invite user to join
            </PrimaryButton>

            <ModalWrapper
              className="w-[500px]"
              open={getShowRevertModal}
              setOpen={setShowRevertModal}
            >
              <p className="mb-4 text-base font-normal text-cs-gray">
                This will clear the list of attraction this user can manage.{" "}
              </p>
              <h4 className="text-lg font-bold text-cs-gray">Are you sure?</h4>

              <div className="flex flex-row mt-10">
                <button
                  type="button"
                  className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
                  onClick={() => changeOperatorAndFetchAttractions()}
                >
                  Yes
                </button>

                <button
                  type="button"
                  className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
                  onClick={() => {
                    setShowRevertModal(false);
                  }}
                >
                  No
                </button>
              </div>
            </ModalWrapper>

            {isSubmitted && !isValid && (
              <div className="flex items-center mt-3 text-cs-red">
                <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                <span className="flex-1">
                  Please fix the errors indicated above to continue
                </span>
              </div>
            )}

            {error && "data" in error && (
              <div className="flex items-center mt-3 text-cs-red">
                <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                <span className="flex-1">
                  {error?.data?.message.replaceAll('"', "")}
                </span>
              </div>
            )}
          </form>
        )}
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AddUser;
