import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { createSearchParams, useNavigate } from "react-router-dom";
import TextInput from "../../../form/TextInput";
import AsyncSelect from "react-select/async";
import { z } from "zod";
import SingleSelectOption from "../../../../lib/react-select/SingleSelectOption";
import { useCallback, useEffect, useState } from "react";
import {
  type CreateAttractionArgs,
  apiSlice,
} from "../../../../store/apiSlice";
import { AttractionCard, type AttractionMinimal } from "./CreateClaimModal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthUser } from "../../../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { restartAnimation } from "../../amp/ToastNotification";
import { globalActions } from "../../../../store/globalSlice";
import { isValidURL } from "../../../../helpers/isValidUrl";
import { debounce } from "../../../../helpers/debounce";
import { Description, Heading } from "../../TwoColTemplate";
import { PrimaryButton } from "../../PrimaryButton";
import { ErrorMessage } from "../../ErrorMessage";
import { Link } from "react-router-dom";

type Props = {
  setSubmittedModal: (showModal: AttractionMinimal) => void;
};

type SearchOption = {
  label: string | JSX.Element;
  value: string;
  attraction: Omit<AttractionMinimal, "id">;
};
export const postcodeValidation = z
  .string()
  .min(1, "Postcode is required")
  .regex(
    /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/,
    { message: "Please enter a valid postcode." }
  );

export const SearchClaimModal: React.FC<Props> = ({ setSubmittedModal }) => {
  const [trigger] = apiSlice.useLazySearchAttractionsQuery();
  const [searchedValue, setSearchedValue] = useState("");

  const filterAttractions = (items: AttractionDetails[]): SearchOption[] => {
    return [
      ...items
        .map((item) => ({
          value: item.short_id,
          label: item.name ?? "",
          attraction: {
            title: item.name ?? "",
            image: item.display_image?.url ?? "",
            image_alt: item.display_image?.alt_text ?? "",
            location: item.town?.name ?? "",
          },
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      {
        value: "-1",
        label: (
          <div className="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
              />
            </svg>
            Can't find your attraction?{" "}
            <span className="font-semibold">Add New Listing</span>
          </div>
        ),
        attraction: {
          title: "",
          image: "",
          image_alt: "",
          location: "",
          phone: "",
        },
      },
    ];
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: SearchOption[]) => void
  ) => {
    trigger(inputValue)
      .unwrap()
      .then((data) => {
        callback(filterAttractions(data?.results ?? []));
      });
  };

  const loadOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadOptions(inputValue, callback);
    }, 1000),
    []
  );

  return (
    <>
      <div className="flex flex-col px-5 py-8 w-full">
        <Heading>
          Claim Existing Listing or<br></br> Add a Brand New One.
        </Heading>

        <Description>
          Grow your business with our Connect platform. Create or take ownership
          of existing listings to fuel your growth, access analytics, update
          your listing and more.
        </Description>
        <div className="relative">
          <label className="block mb-2.5 text-sm font-bold text-black mt-5">
            Type the name of your attraction
          </label>

          <div className="relative">
            <span className="absolute z-30 top-2.5 left-5 text-black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14z"
                />
              </svg>
            </span>
            <AsyncSelect
              components={{
                Option: SingleSelectOption,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => <LoadingIndicator />,
              }}
              cacheOptions
              loadOptions={loadOptionsDebounced as typeof loadOptions}
              defaultOptions={[]}
              noOptionsMessage={() => null}
              isSearchable={true}
              placeholder="Search attractions"
              onInputChange={setSearchedValue}
              styles={{
                menu: (styles) => ({
                  ...styles,
                  margin: 0,
                  border: "1px solid #F1F1F1",
                  boxShadow: "none",
                }),
                menuList: (base, isSelected) => ({
                  ...base,
                  paddingTop: 0,
                  paddingBottom: 0,
                  background: "white",
                  color: "#000",

                  "::-webkit-scrollbar": {
                    width: 14,
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#c2c2c2",
                    borderRadius: 10,
                    border: "4px solid white",
                  },
                }),
                option: (base, { data, isDisabled, isFocused, isSelected }) => {
                  let extra =
                    data.value === "-1"
                      ? {
                          background: "#eee",
                        }
                      : {
                          background: "white",
                        };
                  return {
                    ...base,
                    border: "1px solid #F1F1F1",
                    color: isSelected ? "white" : "#555",
                    "&:hover": {
                      backgroundColor: "#F1F1F1",
                      color: "#000",
                      border: isFocused ? "1px solid white" : "",
                    },
                    "&:active": { backgroundColor: "#6836D1", color: "white" },
                    ...extra,
                    height: "auto !important",
                    padding: "5px 10px !important",
                    fontSize: 14,
                  };
                },
                singleValue: (base) => ({
                  ...base,
                  color: "#000",
                  fontSize: 14,
                }),
                control: (styles, state) => ({
                  ...styles,
                  cursor: "pointer",
                  outline: "none",
                  border: state.isFocused
                    ? "1px solid #6836D1"
                    : "1px solid #CFDBD5",
                  "&:hover": {
                    border: state.isFocused
                      ? "1px solid #6836D1"
                      : "1px solid #6836D1",
                  },
                  boxShadow: "none",
                  margin: 0,
                  paddingLeft: 50,
                  height: "40px",
                  borderRadius: 8,
                  fontSize: 14,
                  fontWeight: 400,
                }),
              }}
              onChange={(value) => {
                const val = value?.value;

                if (val) {
                  setSubmittedModal({
                    id: val,
                    title:
                      val === "-1" ? searchedValue : value.attraction.title,
                    location: value.attraction.location,
                    image: value.attraction.image,
                    image_alt: value.attraction.image_alt,
                    phone: value.attraction.phone,
                  });
                }
              }}
            />
          </div>
          <Link to={`/about`} className="block underline text-sm my-3">
            Learn more about the Connect Management Platform
          </Link>
        </div>
      </div>
    </>
  );
};

export const SubmittedModal: React.FC<{
  attraction: AttractionMinimal;
  setSubmittedModal: (arg: AttractionMinimal) => void;
}> = ({ attraction, setSubmittedModal }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthUser();
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [locationName, setLocationName] = useState("");
  const { data } = apiSlice.useSearchTownsQuery("");
  const [trigger] = apiSlice.useLazySearchTownsQuery();

  const filterTowns = (
    items: TownList[]
  ): { label: string; value: string }[] => {
    return [
      ...items.map((item) => ({
        value: item.id,
        label: item.full_name,
      })),
    ];
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: { label: string; value: string }[]) => void
  ) => {
    trigger(inputValue)
      .unwrap()
      .then((data) => {
        callback(filterTowns(data?.results));
      });
  };

  const schema = z.object({
    attraction: z.string().min(1, "Attraction name is required"),
    website: z.string().superRefine((value, ctx) => {
      if (!isValidURL(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Please enter the url with correct format (e.g. example.com)`,
        });
      }
    }),
    city: z.string().min(1, "City name is required"),
    postcode: postcodeValidation,
    phone: z.string().superRefine((value, ctx) => {
      if (!watchNoPhoneNumber) {
        if (value.length < 10) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Phone number is required",
          });
        }
        if (
          !new RegExp(
            /^(\+44\s?|\(0\d{4}\)\s?|\(0\d{3}\)\s?|\(0\d{2}\)\s?|\d{4}\s?|\d{3}\s?|\d{2}\s?)\d{3}\s?\d{4}(\s?\#\d{3,4})?$/
          ).test(value)
        ) {
          ctx.addIssue({
            message:
              "Invalid phone number. It should be at least 10 digits with no spaces",
            code: z.ZodIssueCode.custom,
          });
        }
      }
    }),
    noPhoneNumber: z.boolean(),
    durationType: z.any(),
  });

  type CreateAttractionForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    control,
    getValues,
    setValue,
    setError
  } = useForm<CreateAttractionForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      attraction: attraction.title.trim() ?? "",
      noPhoneNumber: false,
    },
  });

  const durationTypeOptions = [
    {
      value: "permanent",
      label: (
        <>
          <DurationTypeOption
            title="New Attraction Listing"
            subtitle=""
            features={[
              "Up to 3 Images",
              "Basic Website Link",
              // "21 Day Content Approval",
              "Attraction Description",
              "Opening Times & Pricing",
              "Map View & Address",
              // "FAQs",
            ]}
            price="29"
            priceSubtitle="(one off charge)"
          />
        </>
      ),
    },
    // {
    //   value: "temporary",
    //   label: (
    //     <>
    //       <DurationTypeOption
    //         title="Event"
    //         subtitle="(inc clubs & festivals)"
    //         features={isLoggedIn ? [
    //           "Up to 12 Images",
    //           "Featured Website Link - Up to 150% More Traffic",
    //           "Express Content Approval (7 Days)",
    //           "Event Description",
    //           "Opening Times & Prices",
    //           "Map View & Address",
    //           "FAQs",
    //           "Reviews & Replies",
    //           "Analytics & Insights Dashboard",
    //         ] : [
    //           "Listing Live for 60 Days",
    //           "Up to 12 Images",
    //           "Featured Website Link - Up to 150% More Clicks",
    //           "Express Content Approval (7 Days)",
    //           "Reviews & Replies",
    //           "Analytics & Insights Dashboard",
    //         ]}
    //         price="89"
    //         priceSubtitle="(one off charge)"
    //         additionalPriceSubtitle="Listing Live for 60 Days"
    //       />
    //     </>
    //   ),
    // },
  ];

  const [createRequest, createRequestData] =
    apiSlice.useCreateAttractionMutation();
  const { isError, isSuccess, isLoading } = createRequestData;
  const [isPayment, setIsPayment] = useState(false);
  const [checkoutUrl] =
    apiSlice.useSubscriptionCheckoutMutation();
  const onSubmit: SubmitHandler<CreateAttractionForm> = async (e) => {
    const validatePostcode = await validatePostcodeAndCity(getValues('postcode'), getValues('city'))

    if (!validatePostcode) {
      setError('postcode', {
        type: 'custom',
        message: 'Postcode and town don\'t match.'
      })
      return;
    }

    if (isLoggedIn) {
      const payload: CreateAttractionArgs = {
        name: capitaliseFirstLetter(getValues("attraction")),
        postcode: getValues("postcode"),
        town_id: getValues("city"),
        website: getValues("website"),
        phone: getValues("noPhoneNumber") ? "" : getValues("phone"),
        no_phone_number: getValues("noPhoneNumber"),
        duration_type: getValues("durationType"),
      };

      const createdAttraction = await createRequest(payload);

      if ("data" in createdAttraction && createdAttraction.data?.short_id) {
        if (isPayment) {
          const checkoutPage = await checkoutUrl({
            venueId: (createdAttraction.data.id as string) ?? "",
            planId: "basic-plan-monthly",
          });

          if ("data" in checkoutPage) {
            window.location.href = new URL(
              checkoutPage.data?.sessionUrl ?? ""
            ).toString();
            return;
          }
        }
        navigate({
          pathname: "/skip-payment",
          search: createSearchParams(attraction).toString(),
        });
      }
    } else {
      navigate({
        pathname: "/create-claim",
        search: createSearchParams({
          id: "new",
          title: getValues("attraction").trim(),
          location: getValues("city"),
          image: "",
          image_alt: "",

          phone: getValues("phone"),
          website: getValues("website"),
          postcode: getValues("postcode"),
          location_name: locationName,

          duration_type: getValues("durationType"),
        }).toString(),
      });
    }
  };

  useEffect(() => {
    isSuccess &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Successfuly created attraction",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot create attraction",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const clearSearch = () => {
    setSubmittedModal({
      id: "",
      title: "",
      location: "",
      image: "",
      image_alt: "",
    });
  };

  const [claimRequest, claimRequestData] = apiSlice.useCreateClaimMutation();
  const {
    isError: isClaimedError,
    isSuccess: isClaimedSuccess,
    isLoading: isClaimedLoading,
    error: claimError,
  } = claimRequestData;
  const createClaim = async () => {
    // Clear toast notification message
    dispatch(
      globalActions.setToastNotifcation({
        ...toastNotification,
        type: "UNSET",
        message: "",
        attractionApprovalStatus: "",
        attractionImage: "",
        attractionName: "",
        attractionAddress: "",
      })
    );

    if (!isLoggedIn) {
      navigate({
        pathname: "/create-claim",
        search: createSearchParams(attraction).toString(),
      });
    } else {
      let attractionId = attraction?.id ?? "";
      if (attractionId === "new") {
        await createRequest({
          name: attraction?.title ?? "",
          website: attraction?.website ?? "",
          postcode: attraction?.postcode ?? "",
          town_id: attraction?.location ?? "",
          userId: user?.id ?? -1,
          phone: attraction.phone ?? "",
          no_phone_number: false,
          duration_type: "permanent",
          // listing_type: "attraction",
        });
      } else {
        await claimRequest({
          attractionId,
          userId: user?.id ?? -1,
        });
      }
    }
    restartAnimation();
  };

  useEffect(() => {
    isClaimedError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message:
            (claimError as { data: { message: string } })?.data?.message ??
            "Cannot create claim",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isClaimedError]);

  const watchNoPhoneNumber = useWatch({
    control,
    name: "noPhoneNumber",
  });

  async function validatePostcodeAndCity(postcode: string, city: string) {
    return true
    if (!postcode || !city) return false;
    const result = await fetch(
      `https://api.ideal-postcodes.co.uk/v1/postcodes/${encodeURIComponent(
        postcode
      )}`,
      {
        headers: {
          accept: "application/json",
          authorization:
            'IDEALPOSTCODES api_key="ak_lgapdyeky9f7QF6mw2Pyq8hyQjxdR"',
        },
        method: "GET",
      }
    );
    if (!result.ok) return true;
    const json = (await result.json()) as {
      result: {
        postcode: string;
        post_town: string; // Town
        thoroughfare: string
        country: string; // Country
        county: string; // County
        district: string;
        country_iso: string;
        country_iso_2: string;
        county_code: string;
        postal_county: string
      }[];
      code: 2000;
      message: "Success";
      limit: number;
      page: number;
      total: number;
    };

    const townName = locationName?.split(',')?.[0]?.trim() ?? "";
    const countyName = locationName?.split(',')?.[1]?.trim() ?? "";
    if (!json || !json.result) return true;
    return json.result.map((c) => c.post_town).includes(townName) && json.result.map((c) => c.postal_county).includes(countyName);
  }

  return (
    <div className="flex flex-col w-full">
      {isClaimedSuccess || (attraction.id === "new" && isSuccess) ? (
        <div className="flex flex-col gap-y-5">
          <Heading>Thanks. Your claim will be reviewed</Heading>

          <AttractionCard
            title={attraction?.title ?? ""}
            location={attraction?.location_name ?? attraction?.location ?? ""}
            image={attraction?.image ?? ""}
            imageAlt={attraction?.image_alt ?? ""}
          />

          <Description>
            We'll review your claim as quickly as we can and email you when
            we're done.
          </Description>
        </div>
      ) : attraction.id !== "-1" ? (
        <div className="flex flex-col gap-y-5">
          <Heading>You are able to claim the below attraction</Heading>

          <AttractionCard
            className="w-full"
            title={attraction.title}
            location={attraction.location_name ?? attraction.location}
            image={attraction.image}
            imageAlt={attraction.image_alt}
          />

          <PrimaryButton
            type="button"
            loading={isClaimedLoading}
            onClick={createClaim}
          >
            Claim now
          </PrimaryButton>
          <p className="text-sm font-normal font-roboto-flex text-black">
            Not your attraction?{" "}
            <button
              type="button"
              className="text-sm font-bold text-[#6836D1]"
              onClick={clearSearch}
            >
              Search again
            </button>
          </p>
        </div>
      ) : (
        <div className="mt-10">
          <Heading>
            Add an attraction to <br></br>Day Out With The Kids
          </Heading>
          <Description>
            Thank you for showing an interest in creating a new attraction!
          </Description>
          <Description margin={false}>
            On your journey to growing your attraction, you're also helping over
            35 million people per year searching for day out inspiration. To get
            started, tell us a little bit about you and your attraction.
          </Description>

          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              className="mt-6 group"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"attraction"}
              required
              label="Attraction Name"
              inputProps={{
                ...register("attraction", { setValueAs: (v) => capitaliseFirstLetter(v.trim()) }),
                autoComplete: "off",
              }}
            />
            <TextInput
              className="mt-6 group"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"phone"}
              label="Attraction Contact Number"
              inputProps={{ ...register("phone"), autoComplete: "off" }}
              readonly={watchNoPhoneNumber === true}
            />
            <div className="mt-3">
              <label
                className={`flex items-center text-sm font-normal checkbox-item primary-checkmark text-cs-gray md:whitespace-nowrap md:w-auto w-full md:my-0 my-2`}
              >
                If your attraction does not have a phone number, please tick
                this box.
                <Controller
                  control={control}
                  name={`noPhoneNumber`}
                  render={({
                    field: { ref, value, onChange },
                    fieldState: { error },
                  }) => (
                    <input
                      ref={ref}
                      className="!w-20 !h-5"
                      type="checkbox"
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                />
                <span className="checkmark !rounded-full border-cs-bright-lt-gray"></span>
              </label>
            </div>
            <TextInput
              className="mt-6 group"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"website"}
              required
              label="Attraction Website URL"
              inputProps={{ ...register("website"), autoComplete: "off" }}
            />
            <div className="mt-6">
              <label className={`text-black font-bold text-sm mb-2 block`}>
                Attraction Town{" "}
                <small className="font-normal text-sm">
                  (start typing and select from the list)
                </small>
                <span className="text-cs-pink"> *</span>
              </label>
              <AsyncSelect
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => <LoadingIndicator />,
                }}
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions={filterTowns(data?.results ?? [])}
                isSearchable={true}
                placeholder="Search town"
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    let extra =
                      data.value === "-1"
                        ? {
                            background: "#eee",
                          }
                        : {
                            background: "white",
                          };
                    return {
                      ...base,
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                      ...extra,
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "2px solid #CFDBD5"
                      : "2px solid #CFDBD5",
                    "&:hover": {
                      border: state.isFocused
                        ? "2px solid #CFDBD5"
                        : "2px solid #CFDBD5",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 50,
                    borderRadius: 8,
                  }),
                }}
                onChange={(value) => {
                  const val = value?.value;

                  if (val) {
                    setValue("city", val);
                    setLocationName(value?.label ?? "");
                  }
                }}
              />
              {errors.city && (
                <ErrorMessage>{errors.city.message}</ErrorMessage>
              )}
            </div>
            <TextInput
              className="mt-6 group"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"postcode"}
              required
              label="Attraction Postcode"
              inputProps={{
                ...register("postcode", {
                  setValueAs: (value: string) => value.toUpperCase(),
                  onChange: (e) => {
                    e.target.value = e.target.value.toUpperCase();
                  },
                }),
                autoComplete: "off",
              }}
            />

            <div className="mt-6">
              <label className={`block text-black font-bold text-sm mb-2`}>
                {/* Choose a Listing Package
                <span className="text-cs-pink"> *</span> */}
              </label>
              <Controller
                defaultValue="permanent"
                control={control}
                name={`durationType`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <div className="grid gap-2.5">
                      {durationTypeOptions.map((d) => (
                        <>
                          <button
                            className={`self-baseline p-[15px] rounded-lg border border-[#CFDBD5] ${
                              value === d.value ? "bg-[#F2F2F2]" : ""
                            }`}
                            type="button"
                            onClick={() => onChange(d.value)}
                          >
                            {d.label}
                          </button>
                        </>
                      ))}
                    </div>
                  </>
                )}
              />
            </div>

            {/* {watch("durationType") === "temporary" && (
              <>
                <div className="mt-6 grid grid-cols-2 gap-4">
                  <TextInput
                    className="group"
                    labelClassName="group-focus-within:text-cs-pink"
                    inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex w-full focus:outline-cs-pink"
                    control={control}
                    name={"startDate"}
                    label="Start date"
                    inputProps={{
                      ...register("startDate", {
                        setValueAs(value) {
                          if (value.length > 0) {
                            return new Date(value);
                          }
                          return "";
                        },
                      }),
                      type: "date",
                      autoComplete: "off",
                    }}
                  />
                  <TextInput
                    className="group"
                    labelClassName="group-focus-within:text-cs-pink"
                    inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex w-full focus:outline-cs-pink"
                    control={control}
                    name={"endDate"}
                    label="End date"
                    inputProps={{
                      ...register("endDate", {
                        setValueAs(value) {
                          if (value.length > 0) {
                            return new Date(value);
                          }
                          return "";
                        },
                      }),
                      type: "date",
                      autoComplete: "off",
                    }}
                  />
                </div>
                <div className="text-red-500 text-xs mt-2.5">
                  * Your listing will be live on <a href="https://www.dayoutwiththekids.co.uk">www.dayoutwiththekids.co.uk</a> for 60 days from the date payment is made. The start date and end date of the event will be clearly shown on your listing page to visitors.
                </div>
              </>
            )} */}

            {!isLoggedIn || user?.role === "ADMIN" ? (
              <PrimaryButton
                className="mt-5"
                type="submit"
                disabled={isLoading}
              >
                Continue
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton
                  className="mt-5"
                  type="submit"
                  loading={isLoading}
                  onClick={() => setIsPayment(true)}
                >
                  Continue to payment
                </PrimaryButton>

                {/* <button
                  className="mt-2.5 w-full mb-5 text-center underline text-sm font-light text-black"
                  type="submit"
                  disabled={isLoading}
                  onClick={() => setIsPayment(false)}
                >
                  Skip now, pay later
                </button> */}
              </>
            )}

            {isSubmitted && !isValid && (
              <ErrorMessage>
                Please fix the errors indicated above to continue
              </ErrorMessage>
            )}
          </form>

          <p className="text-sm font-normal mt-5 mb-10">
            <button
              type="button"
              className="text-sm font-bold text-cs-pink"
              onClick={clearSearch}
            >
              Search again
            </button>
          </p>
        </div>
      )}

      {/* <div className="w-full pt-8 mx-auto mt-8 text-center border-t border-gray-300 md:w-5/6">
                <h2 className="text-lg font-bold text-cs-gray">What happens when I claim?</h2>
                <p className="mt-3 font-normal text-cs-gray">We verify each claim to ensure that only the rightful owners are able to manage content and view statistics. </p>

                <p className="mt-5 text-sm font-normal text-center text-cs-gray">
                    Need help? <a href="mailto:connect@dayoutwiththekids.co.uk" className="text-sm font-normal underline text-cs-pink">Contact us</a>
                </p>

                {(isClaimedSuccess || (attraction.id === 'new' && isSuccess)) && <>
                    <div className="flex justify-center">
                        <Link to="/dashboard" className={`rounded-2xl px-20 py-4 mt-6 text-base border-2 font-bold block mx-auto shadow-cs-button-backdrop text-white hover:outline-cs-pink hover:text-cs-pink bg-cs-pink border-cs-pink hover:bg-white`}>
                            Continue
                        </Link>
                    </div>
                </>}
            </div> */}
    </div>
  );
};

const LoadingIndicator: React.FC = () => {
  return (
    <svg
      className="w-5 h-5 mr-3 -ml-1 animate-spin text-cs-pink"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

function DurationTypeOption({
  title,
  subtitle,
  features,
  price,
  priceSubtitle,
  additionalPriceSubtitle = "",
}: {
  title: string;
  subtitle: string;
  features: string[];
  price: string;
  priceSubtitle: string;
  additionalPriceSubtitle?: string;
}) {
  const { isLoggedIn } = useAuthUser();

  return (
    <>
      <div>
        <div className="flex items-center gap-0.5">
          <h2 className="text-black font-bold text-lg">{title}</h2>{" "}
          <span className="text-[13px] font-normal">{subtitle}</span>
        </div>

        <ul className="mt-2.5 text-left">
          {features.map((f) => (
            <li className="text-xs font-normal list-disc ml-5 mb-1">{f}</li>
          ))}
        </ul>

        <div
          className={`flex gap-1 mt-5 ${
            isLoggedIn
              ? "flex-col items-end"
              : "flex-row justify-end items-center"
          }`}
        >
          <div className="text-black text-lg font-bold">
            <span className="text-sm">Price</span> £{price}{" "}
            {isLoggedIn && <span className="text-xs font-bold">+vat</span>}
          </div>
          <div className="text-black text-xs font-normal">{priceSubtitle}</div>
          {additionalPriceSubtitle && isLoggedIn && (
            <div className="text-black text-xs font-bold">
              {additionalPriceSubtitle}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function capitaliseFirstLetter(name: string) {
  const string = name.trim();
  return string.charAt(0).toUpperCase() + string.slice(1);
}