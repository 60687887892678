import React from "react"

interface IIcon {
    className?: string | undefined
    svgGroupPathClassName?: string | undefined
    svgPathClassName?: string | undefined
    svgLinePathClassName?: string | undefined,
    onClick?: () => void
}

export const PersonIcon: React.FC<IIcon> = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
        <path d="M8.56106 7.57288C9.5456 8.03823 10.3851 8.76255 10.9896 9.6683C11.5941 10.5741 11.941 11.6272 11.9931 12.7149C11.9999 12.8145 11.9868 12.9145 11.9545 13.0091C11.9222 13.1036 11.8713 13.1907 11.8049 13.2653C11.7384 13.3398 11.6578 13.4004 11.5676 13.4434C11.4775 13.4864 11.3796 13.5109 11.2798 13.5156C11.1801 13.5203 11.0804 13.5049 10.9866 13.4705C10.8928 13.4362 10.8069 13.3834 10.7338 13.3153C10.6606 13.2472 10.6019 13.1653 10.5609 13.0742C10.5199 12.9831 10.4975 12.8847 10.4951 12.7849C10.4405 11.6294 9.94316 10.5393 9.10616 9.74085C8.26916 8.94241 7.15682 8.49695 6.00006 8.49695C4.8433 8.49695 3.73097 8.94241 2.89397 9.74085C2.05696 10.5393 1.55958 11.6294 1.50506 12.7849C1.49159 12.9805 1.40208 13.163 1.25568 13.2934C1.10929 13.4238 0.91766 13.4917 0.721828 13.4825C0.525996 13.4734 0.341531 13.3879 0.207936 13.2444C0.0743415 13.1009 0.00223686 12.9109 0.00706354 12.7149C0.0589244 11.6272 0.405595 10.5742 1.00997 9.6684C1.61434 8.76263 2.45365 8.03828 3.43806 7.57288C2.80708 7.04657 2.35353 6.33866 2.13912 5.54545C1.9247 4.75224 1.95983 3.91224 2.23973 3.13971C2.51962 2.36718 3.0307 1.69961 3.70343 1.22783C4.37616 0.756038 5.17789 0.50293 5.99956 0.50293C6.82124 0.50293 7.62297 0.756038 8.2957 1.22783C8.96843 1.69961 9.4795 2.36718 9.7594 3.13971C10.0393 3.91224 10.0744 4.75224 9.86001 5.54545C9.6456 6.33866 9.19205 7.04657 8.56106 7.57288ZM8.50006 4.49988C8.50006 3.83684 8.23667 3.20095 7.76783 2.73211C7.29899 2.26327 6.6631 1.99988 6.00006 1.99988C5.33702 1.99988 4.70114 2.26327 4.2323 2.73211C3.76346 3.20095 3.50006 3.83684 3.50006 4.49988C3.50006 5.16292 3.76346 5.7988 4.2323 6.26764C4.70114 6.73649 5.33702 6.99988 6.00006 6.99988C6.6631 6.99988 7.29899 6.73649 7.76783 6.26764C8.23667 5.7988 8.50006 5.16292 8.50006 4.49988Z" fill="#212121"/>
    </svg>
)

export function HideIcon() {
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_4723)">
          <path
            d="M20.5455 10.7127C18.486 6.90545 14.8744 4.60156 10.8777 4.60156C6.88103 4.60156 3.26325 6.90545 1.22214 10.7127L1.05103 10.9999L1.20991 11.2932C3.26936 15.1005 6.88103 17.4043 10.8777 17.4043C14.8744 17.4043 18.4921 15.131 20.5455 11.2932L20.7044 10.9999L20.5455 10.7127ZM10.8777 16.1516C7.43714 16.1516 4.27769 14.2327 2.44436 10.9999C4.27769 7.76712 7.43714 5.84823 10.8777 5.84823C14.3182 5.84823 17.441 7.77323 19.3049 10.9999C17.441 14.2327 14.3121 16.1516 10.8777 16.1516Z"
            fill="black"
          />
          <path
            d="M11.055 15.2106C13.3703 15.2106 15.2472 13.3337 15.2472 11.0184C15.2472 8.70309 13.3703 6.82617 11.055 6.82617C8.73972 6.82617 6.86279 8.70309 6.86279 11.0184C6.86279 13.3337 8.73972 15.2106 11.055 15.2106Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_4723">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
}
  
export function ShowIcon() {
return (
    <svg
    width="22"
    height="22"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <g clipPath="url(#clip0_1_1919)">
        <path
        d="M18.37 11.17C17.5607 11.171 16.7581 11.3166 16 11.6L24.8 20.4C25.0872 19.6326 25.2329 18.8194 25.23 18C25.2221 16.1858 24.4958 14.4487 23.2102 13.1686C21.9245 11.8886 20.1842 11.17 18.37 11.17Z"
        fill="black"
        />
        <path
        d="M34.29 17.53C30.92 11.3 25.01 7.53003 18.47 7.53003C16.6893 7.53421 14.9206 7.82112 13.23 8.38003L14.84 10C16.0258 9.69356 17.2452 9.53568 18.47 9.53003C24.1 9.53003 29.22 12.67 32.27 17.96C31.1511 19.9226 29.6679 21.6536 27.9 23.06L29.32 24.48C31.3661 22.8294 33.0653 20.7902 34.32 18.48L34.58 18L34.29 17.53Z"
        fill="black"
        />
        <path
        d="M4.86988 5.77998L9.32988 10.24C6.5108 12.0551 4.20678 14.5657 2.63988 17.53L2.37988 18L2.63988 18.48C6.00988 24.71 11.9199 28.48 18.4599 28.48C21.0125 28.4795 23.532 27.9017 25.8299 26.79L30.8299 31.79L32.5799 30.29L6.57988 4.28998L4.86988 5.77998ZM13.1699 14.08C12.2343 15.3994 11.7958 17.0073 11.932 18.619C12.0682 20.2307 12.7703 21.7422 13.914 22.8859C15.0577 24.0296 16.5692 24.7317 18.1809 24.8679C19.7926 25.0041 21.4005 24.5655 22.7199 23.63L24.3199 25.23C22.4677 26.0217 20.4742 26.4299 18.4599 26.43C12.8299 26.43 7.70988 23.29 4.65988 18C6.12359 15.4082 8.23161 13.2382 10.7799 11.7L13.1699 14.08Z"
        fill="black"
        />
    </g>
    <defs>
        <clipPath id="clip0_1_1919">
        <rect width="36" height="36" fill="white" />
        </clipPath>
    </defs>
    </svg>
);
}
  
export const ArrowIcon: React.FC<IIcon> = ({ className, svgPathClassName }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
    <path className={svgPathClassName} d="M1.1875 12.8784L6.8125 7.25342L1.1875 1.62842" stroke="#212121" strokeWidth="1.78125" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const PlusIcon: React.FC<IIcon> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M20.21 10.3251C20.21 10.6341 20.0873 10.9304 19.8688 11.149C19.6503 11.3675 19.3539 11.4902 19.0449 11.4902H11.6654V18.8697C11.6654 19.1787 11.5426 19.4751 11.3241 19.6936C11.1056 19.9121 10.8093 20.0349 10.5002 20.0349C10.1912 20.0349 9.89483 19.9121 9.67632 19.6936C9.45781 19.4751 9.33505 19.1787 9.33505 18.8697V11.4902H1.95558C1.64656 11.4902 1.35019 11.3675 1.13168 11.149C0.913165 10.9304 0.790405 10.6341 0.790405 10.3251C0.790405 10.016 0.913165 9.71966 1.13168 9.50115C1.35019 9.28264 1.64656 9.15988 1.95558 9.15988H9.33505V1.78041C9.33505 1.47139 9.45781 1.17502 9.67632 0.956507C9.89483 0.737994 10.1912 0.615234 10.5002 0.615234C10.8093 0.615234 11.1056 0.737994 11.3241 0.956507C11.5426 1.17502 11.6654 1.47139 11.6654 1.78041V9.15988H19.0449C19.3539 9.15988 19.6503 9.28264 19.8688 9.50115C20.0873 9.71966 20.21 10.016 20.21 10.3251Z" fill="#212121"/>
  </svg>
)

export const XIcon: React.FC<IIcon> = ({ className, svgPathClassName }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
    <path className={svgPathClassName} d="M1.63999 0.27L5.49999 4.13L9.34 0.29C9.42482 0.199717 9.527 0.127495 9.64041 0.0776622C9.75382 0.0278298 9.87613 0.00141434 10 0C10.2652 0 10.5196 0.105357 10.7071 0.292893C10.8946 0.48043 11 0.734784 11 1C11.0023 1.1226 10.9796 1.24439 10.9332 1.35788C10.8867 1.47138 10.8176 1.57419 10.73 1.66L6.83999 5.5L10.73 9.39C10.8948 9.55124 10.9915 9.76959 11 10C11 10.2652 10.8946 10.5196 10.7071 10.7071C10.5196 10.8946 10.2652 11 10 11C9.87255 11.0053 9.7454 10.984 9.62661 10.9375C9.50783 10.8911 9.40001 10.8204 9.31 10.73L5.49999 6.87L1.64999 10.72C1.5655 10.8073 1.46456 10.8769 1.353 10.925C1.24143 10.9731 1.12146 10.9986 0.999995 11C0.734778 11 0.480424 10.8946 0.292888 10.7071C0.105352 10.5196 -5.14003e-06 10.2652 -5.14003e-06 10C-0.00233663 9.8774 0.0204103 9.75561 0.0668398 9.64212C0.113269 9.52862 0.182402 9.42581 0.269995 9.34L4.15999 5.5L0.269995 1.61C0.10518 1.44876 0.00853448 1.23041 -5.14003e-06 1C-5.14003e-06 0.734784 0.105352 0.48043 0.292888 0.292893C0.480424 0.105357 0.734778 0 0.999995 0C1.23999 0.003 1.47 0.1 1.63999 0.27Z" fill="white"/>
  </svg>
)

export const TickIcon: React.FC<IIcon> = ({ className, svgPathClassName }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="36" height="27" viewBox="0 0 36 27" fill="none">
    <path className={svgPathClassName} d="M12.4343 25.5806L1.15391 14.3002C0.476204 13.6225 0.476204 12.5236 1.15391 11.8459L3.60814 9.39156C4.28585 8.71379 5.38474 8.71379 6.06244 9.39156L13.6615 16.9905L29.9377 0.714333C30.6154 0.0366284 31.7143 0.0366284 32.392 0.714333L34.8462 3.16863C35.5239 3.84634 35.5239 4.94516 34.8462 5.62293L14.8886 25.5806C14.2108 26.2583 13.112 26.2583 12.4343 25.5806Z" fill="#CCD3E1"/>
  </svg>
)

export const CameraIcon: React.FC<IIcon> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32" fill="none">
    <path d="M1.979 10.9959C1.979 10.4741 1.979 10.2132 2.00078 9.99343C2.21077 7.87397 3.88744 6.19731 6.0069 5.98731C6.22665 5.96554 6.50163 5.96554 7.05158 5.96554C7.26349 5.96554 7.36945 5.96554 7.45941 5.96009C8.60814 5.89052 9.61406 5.1655 10.0433 4.09772C10.0769 4.0141 10.1084 3.91983 10.1712 3.7313C10.234 3.54278 10.2655 3.44851 10.2991 3.36489C10.7283 2.29711 11.7343 1.57209 12.883 1.50252C12.9729 1.49707 13.0723 1.49707 13.271 1.49707H20.4768C20.6755 1.49707 20.7749 1.49707 20.8648 1.50252C22.0135 1.57209 23.0195 2.29711 23.4487 3.36489C23.4823 3.44851 23.5138 3.54278 23.5766 3.7313C23.6394 3.91983 23.6709 4.0141 23.7045 4.09772C24.1337 5.1655 25.1397 5.89052 26.2884 5.96009C26.3783 5.96554 26.4843 5.96554 26.6962 5.96554C27.2462 5.96554 27.5212 5.96554 27.7409 5.98731C29.8604 6.19731 31.537 7.87397 31.747 9.99343C31.7688 10.2132 31.7688 10.4741 31.7688 10.9959V22.6478C31.7688 25.1504 31.7688 26.4017 31.2818 27.3575C30.8534 28.1983 30.1698 28.8819 29.329 29.3103C28.3731 29.7974 27.1218 29.7974 24.6192 29.7974H9.12855C6.62598 29.7974 5.37469 29.7974 4.41883 29.3103C3.57803 28.8819 2.89444 28.1983 2.46604 27.3575C1.979 26.4017 1.979 25.1504 1.979 22.6478V10.9959Z" stroke="#CFDBD5" strokeWidth="2.84314" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.8739 23.0947C20.1644 23.0947 22.8319 20.4272 22.8319 17.1367C22.8319 13.8462 20.1644 11.1788 16.8739 11.1788C13.5834 11.1788 10.9159 13.8462 10.9159 17.1367C10.9159 20.4272 13.5834 23.0947 16.8739 23.0947Z" stroke="#CFDBD5" strokeWidth="2.84314" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const DeleteIcon: React.FC<IIcon> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path d="M1.05359 4.16755H13.6964M4.74109 4.16755V3.64076C4.74109 2.9422 5.01859 2.27225 5.51255 1.7783C6.00651 1.28434 6.67646 1.00684 7.37502 1.00684C8.07358 1.00684 8.74353 1.28434 9.23749 1.7783C9.73144 2.27225 10.0089 2.9422 10.0089 3.64076V4.16755M5.79466 7.32932V11.5457M8.95537 7.32932V11.5457M2.63395 4.16755H12.1161V13.6497C12.1161 13.9291 12.0051 14.1971 11.8075 14.3947C11.6099 14.5923 11.3419 14.7033 11.0625 14.7033H3.68752C3.40809 14.7033 3.14011 14.5923 2.94253 14.3947C2.74495 14.1971 2.63395 13.9291 2.63395 13.6497V4.16755Z" stroke="#212121" strokeWidth="1.40476" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const PenIcon: React.FC<IIcon> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M1.25 10.7502L4.02463 9.68299C4.2021 9.61473 4.29084 9.5806 4.37386 9.53603C4.4476 9.49645 4.5179 9.45077 4.58402 9.39946C4.65846 9.34169 4.72569 9.27447 4.86014 9.14001L10.5 3.50015C11.0523 2.94787 11.0523 2.05244 10.5 1.50015C9.94773 0.947867 9.0523 0.947866 8.50001 1.50015L2.86014 7.14001C2.72569 7.27446 2.65846 7.34169 2.6007 7.41613C2.54939 7.48225 2.5037 7.55255 2.46412 7.62629C2.41955 7.70931 2.38542 7.79805 2.31717 7.97552L1.25 10.7502ZM1.25 10.7502L2.27906 8.07463C2.3527 7.88317 2.38952 7.78744 2.45267 7.74359C2.50786 7.70527 2.57615 7.69078 2.64215 7.70338C2.71767 7.7178 2.79019 7.79033 2.93524 7.93538L4.06479 9.06493C4.20984 9.20997 4.28236 9.2825 4.29678 9.35802C4.30939 9.42401 4.29489 9.49231 4.25657 9.5475C4.21272 9.61065 4.11699 9.64747 3.92554 9.72111L1.25 10.7502Z" stroke="#565657" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const CropIcon: React.FC<IIcon> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M11.4265 8.99408C11.4265 9.14297 11.3674 9.28576 11.2621 9.39104C11.1568 9.49633 11.014 9.55547 10.8651 9.55547L9.55523 9.55547L9.55523 10.8654C9.55523 11.0143 9.49608 11.1571 9.3908 11.2623C9.28552 11.3676 9.14273 11.4268 8.99384 11.4268C8.84495 11.4268 8.70216 11.3676 8.59688 11.2623C8.4916 11.1571 8.43245 11.0143 8.43245 10.8654L8.43245 9.55547L3.00569 9.55547C2.8568 9.55547 2.714 9.49633 2.60872 9.39104C2.50344 9.28576 2.4443 9.14297 2.4443 8.99408L2.4443 3.56732L1.13439 3.56732C0.985498 3.56732 0.842706 3.50817 0.737425 3.40289C0.632144 3.29761 0.572998 3.15482 0.572998 3.00593C0.572998 2.85704 0.632144 2.71425 0.737425 2.60897C0.842706 2.50369 0.985498 2.44454 1.13439 2.44454L2.4443 2.44454L2.4443 1.13463C2.4443 0.985742 2.50344 0.84295 2.60872 0.737669C2.714 0.632388 2.8568 0.573242 3.00569 0.573242C3.15457 0.573242 3.29737 0.632388 3.40265 0.737669C3.50793 0.84295 3.56707 0.985742 3.56707 1.13463L3.56707 8.43269L10.8651 8.43269C11.014 8.43269 11.1568 8.49184 11.2621 8.59712C11.3674 8.7024 11.4265 8.84519 11.4265 8.99408ZM4.87698 3.56732L8.43245 3.56732L8.43245 7.12278C8.43245 7.27167 8.4916 7.41447 8.59688 7.51975C8.70216 7.62503 8.84495 7.68417 8.99384 7.68417C9.14273 7.68417 9.28552 7.62503 9.3908 7.51975C9.49608 7.41447 9.55523 7.27167 9.55523 7.12278L9.55523 3.00593C9.55523 2.85704 9.49608 2.71425 9.3908 2.60897C9.28552 2.50369 9.14273 2.44454 8.99384 2.44454L4.87698 2.44454C4.72809 2.44454 4.5853 2.50369 4.48002 2.60897C4.37474 2.71425 4.31559 2.85704 4.31559 3.00593C4.31559 3.15482 4.37474 3.29761 4.48002 3.40289C4.5853 3.50817 4.72809 3.56732 4.87698 3.56732Z" fill="#565657"/>
  </svg>
) 