import { type ReactNode } from "react";

export default function DashboardContainer({children}: {children: ReactNode,}) {
    return (
        <>
            <main className={`mt-5 xl:pl-[300px] max-w-[100vw]`}>
                {children}
            </main>
        </>
    )
}