import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SubmitHandler,
  useForm,
  useWatch,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import MultiSelectCategoryOptions from "../../../lib/react-select/MultiSelectCategoryOptions";
import MultiSelectCategoryOptionsSm from "../../../lib/react-select/MultiSelectCategoryOptionsSm";
import { apiSlice } from "../../../store/apiSlice";
import RichTextEditor from "../../../components/form/RichTextEditor";
import moment from "moment";
import { components } from "react-select";
import { globalActions } from "../../../store/globalSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { useAppDispatch, useAppSelector } from "../../../store";
import { UnsavedModal } from "../../../components/global/amp/UnsavedModal";
import { hasUrl } from "../../../helpers/hasUrl";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { OutlineButton } from "../../../components/global/OutlineButton";
import { unlockNewAttractionTab } from "../../../helpers/newAttractionsTabPermission";
import { RevertToSavedModal } from "../../../components/global/RevertToSavedModal";
import { filterPayload } from "../../../helpers/filterPayload";
import Stepper from "../../../components/amp/Stepper";
import { SaveButtonsEditAttraction } from "../../../components/amp/SaveButtonsEditAttraction";
import { StepperHeadingEditAttraction } from "../../../components/amp/StepperHeadingEditAttraction";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import {
  clearNewAttractionStepLeftOver,
  getNewAttractionWhichStepLeftOver,
  setNewAttractionLeftOverTab,
} from "../../../helpers/newAttractionsFinishLater";
import { LightningIcon } from "../../../components/amp/LightBulbTooltip";
import { getVoScoreTooltip, useVoScoreNeedImprovementsFields } from "../../../hooks/useVoScoreNeedImprovementsFields";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

interface AttactionOpeningTimesForm {
  statusOfBusiness: "open" | "permanently_closed" | "temporarily_closed";
  showOpeningTimes: boolean;
  isMondayClosed: boolean | undefined;
  isTuesdayClosed: boolean | undefined;
  isWednesdayClosed: boolean | undefined;
  isThursdayClosed: boolean | undefined;
  isFridayClosed: boolean | undefined;
  isSaturdayClosed: boolean | undefined;
  isSundayClosed: boolean | undefined;

  mondayTimes: OpeningTimes[] | null | undefined;
  tuesdayTimes: OpeningTimes[] | null | undefined;
  wednesdayTimes: OpeningTimes[] | null | undefined;
  thursdayTimes: OpeningTimes[] | null | undefined;
  fridayTimes: OpeningTimes[] | null | undefined;
  saturdayTimes: OpeningTimes[] | null | undefined;
  sundayTimes: OpeningTimes[] | null | undefined;

  special: SpecialDayOpeningInfo[] | null | undefined;

  openingHtml: string | null;
  busiestTimesMonday: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  busiestTimesTuesday: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  busiestTimesWednesday: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  busiestTimesThursday: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  busiestTimesFriday: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  busiestTimesSaturday: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  busiestTimesSunday: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
}

const AttractionOpeningTimes: React.FC<IAttractionDetails> = () => {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [getIsMondayClosed, setIsMondayClosed] = useState<boolean>(true);
  const [getIsTuesdayClosed, setIsTuesdayClosed] = useState<boolean>(true);
  const [getIsWednesdayClosed, setIsWednesdayClosed] = useState<boolean>(true);
  const [getIsThursdayClosed, setIsThursdayClosed] = useState<boolean>(true);
  const [getIsFridayClosed, setIsFridayClosed] = useState<boolean>(true);
  const [getIsSaturdayClosed, setIsSaturdayClosed] = useState<boolean>(true);
  const [getIsSundayClosed, setIsSundayClosed] = useState<boolean>(true);

  const [getBusiestTimesMonday, setSelectedBusiestTimesMonday] =
    useState<
      { value: string | null | undefined; label: string | null | undefined }[]
    >();
  const [getBusiestTimesTuesday, setSelectedBusiestTimesTuesday] =
    useState<
      { value: string | null | undefined; label: string | null | undefined }[]
    >();
  const [getBusiestTimesWednesday, setSelectedBusiestTimesWednesday] =
    useState<
      { value: string | null | undefined; label: string | null | undefined }[]
    >();
  const [getBusiestTimesThursday, setSelectedBusiestTimesThursday] =
    useState<
      { value: string | null | undefined; label: string | null | undefined }[]
    >();
  const [getBusiestTimesFriday, setSelectedBusiestTimesFriday] =
    useState<
      { value: string | null | undefined; label: string | null | undefined }[]
    >();
  const [getBusiestTimesSaturday, setSelectedBusiestTimesSaturday] =
    useState<
      { value: string | null | undefined; label: string | null | undefined }[]
    >();
  const [getBusiestTimesSunday, setSelectedBusiestTimesSunday] =
    useState<
      { value: string | null | undefined; label: string | null | undefined }[]
    >();

  const findMondayMorningBusiestTimes = getBusiestTimesMonday
    ?.find(
      (getBusiestTimesMonday) =>
        getBusiestTimesMonday?.value == "Morning_true" ||
        getBusiestTimesMonday?.value == "Morning_false"
    )
    ?.value?.replace("Morning_", "");
  const findTuesdayMorningBusiestTimes = getBusiestTimesTuesday
    ?.find(
      (getBusiestTimesTuesday) =>
        getBusiestTimesTuesday?.value == "Morning_true" ||
        getBusiestTimesTuesday?.value == "Morning_false"
    )
    ?.value?.replace("Morning_", "");
  const findWednesdayMorningBusiestTimes = getBusiestTimesWednesday
    ?.find(
      (getBusiestTimesWednesday) =>
        getBusiestTimesWednesday?.value == "Morning_true" ||
        getBusiestTimesWednesday?.value == "Morning_false"
    )
    ?.value?.replace("Morning_", "");
  const findThursdayMorningBusiestTimes = getBusiestTimesThursday
    ?.find(
      (getBusiestTimesThursday) =>
        getBusiestTimesThursday?.value == "Morning_true" ||
        getBusiestTimesThursday?.value == "Morning_false"
    )
    ?.value?.replace("Morning_", "");
  const findFridayMorningBusiestTimes = getBusiestTimesFriday
    ?.find(
      (getBusiestTimesFriday) =>
        getBusiestTimesFriday?.value == "Morning_true" ||
        getBusiestTimesFriday?.value == "Morning_false"
    )
    ?.value?.replace("Morning_", "");
  const findSaturdayMorningBusiestTimes = getBusiestTimesSaturday
    ?.find(
      (getBusiestTimesSaturday) =>
        getBusiestTimesSaturday?.value == "Morning_true" ||
        getBusiestTimesSaturday?.value == "Morning_false"
    )
    ?.value?.replace("Morning_", "");
  const findSundayMorningBusiestTimes = getBusiestTimesSunday
    ?.find(
      (getBusiestTimesSunday) =>
        getBusiestTimesSunday?.value == "Morning_true" ||
        getBusiestTimesSunday?.value == "Morning_false"
    )
    ?.value?.replace("Morning_", "");
  const findMondayAfternoonBusiestTimes = getBusiestTimesMonday
    ?.find(
      (getBusiestTimesMonday) =>
        getBusiestTimesMonday?.value == "Afternoon_true" ||
        getBusiestTimesMonday?.value == "Afternoon_false"
    )
    ?.value?.replace("Afternoon_", "");
  const findTuesdayAfternoonBusiestTimes = getBusiestTimesTuesday
    ?.find(
      (getBusiestTimesTuesday) =>
        getBusiestTimesTuesday?.value == "Afternoon_true" ||
        getBusiestTimesTuesday?.value == "Afternoon_false"
    )
    ?.value?.replace("Afternoon_", "");
  const findWednesdayAfternoonBusiestTimes = getBusiestTimesWednesday
    ?.find(
      (getBusiestTimesWednesday) =>
        getBusiestTimesWednesday?.value == "Afternoon_true" ||
        getBusiestTimesWednesday?.value == "Afternoon_false"
    )
    ?.value?.replace("Afternoon_", "");
  const findThursdayAfternoonBusiestTimes = getBusiestTimesThursday
    ?.find(
      (getBusiestTimesThursday) =>
        getBusiestTimesThursday?.value == "Afternoon_true" ||
        getBusiestTimesThursday?.value == "Afternoon_false"
    )
    ?.value?.replace("Afternoon_", "");
  const findFridayAfternoonBusiestTimes = getBusiestTimesFriday
    ?.find(
      (getBusiestTimesFriday) =>
        getBusiestTimesFriday?.value == "Afternoon_true" ||
        getBusiestTimesFriday?.value == "Afternoon_false"
    )
    ?.value?.replace("Afternoon_", "");
  const findSaturdayAfternoonBusiestTimes = getBusiestTimesSaturday
    ?.find(
      (getBusiestTimesSaturday) =>
        getBusiestTimesSaturday?.value == "Afternoon_true" ||
        getBusiestTimesSaturday?.value == "Afternoon_false"
    )
    ?.value?.replace("Afternoon_", "");
  const findSundayAfternoonBusiestTimes = getBusiestTimesSunday
    ?.find(
      (getBusiestTimesSunday) =>
        getBusiestTimesSunday?.value == "Afternoon_true" ||
        getBusiestTimesSunday?.value == "Afternoon_false"
    )
    ?.value?.replace("Afternoon_", "");
  const findMondayEveningBusiestTimes = getBusiestTimesMonday
    ?.find(
      (getBusiestTimesMonday) =>
        getBusiestTimesMonday?.value == "Evening_true" ||
        getBusiestTimesMonday?.value == "Evening_false"
    )
    ?.value?.replace("Evening_", "");
  const findTuesdayEveningBusiestTimes = getBusiestTimesTuesday
    ?.find(
      (getBusiestTimesTuesday) =>
        getBusiestTimesTuesday?.value == "Evening_true" ||
        getBusiestTimesTuesday?.value == "Evening_false"
    )
    ?.value?.replace("Evening_", "");
  const findWednesdayEveningBusiestTimes = getBusiestTimesWednesday
    ?.find(
      (getBusiestTimesWednesday) =>
        getBusiestTimesWednesday?.value == "Evening_true" ||
        getBusiestTimesWednesday?.value == "Evening_false"
    )
    ?.value?.replace("Evening_", "");
  const findThursdayEveningBusiestTimes = getBusiestTimesThursday
    ?.find(
      (getBusiestTimesThursday) =>
        getBusiestTimesThursday?.value == "Evening_true" ||
        getBusiestTimesThursday?.value == "Evening_false"
    )
    ?.value?.replace("Evening_", "");
  const findFridayEveningBusiestTimes = getBusiestTimesFriday
    ?.find(
      (getBusiestTimesFriday) =>
        getBusiestTimesFriday?.value == "Evening_true" ||
        getBusiestTimesFriday?.value == "Evening_false"
    )
    ?.value?.replace("Evening_", "");
  const findSaturdayEveningBusiestTimes = getBusiestTimesSaturday
    ?.find(
      (getBusiestTimesSaturday) =>
        getBusiestTimesSaturday?.value == "Evening_true" ||
        getBusiestTimesSaturday?.value == "Evening_false"
    )
    ?.value?.replace("Evening_", "");
  const findSundayEveningBusiestTimes = getBusiestTimesSunday
    ?.find(
      (getBusiestTimesSunday) =>
        getBusiestTimesSunday?.value == "Evening_true" ||
        getBusiestTimesSunday?.value == "Evening_false"
    )
    ?.value?.replace("Evening_", "");

  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);

  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess: isGetSuccess,
    isFetching,
    refetch,
    error: attractionError,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  const [updateAttraction, { isLoading, isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();

  const schema = z.object({
    statusOfBusiness: z.enum([
      "open",
      "permanently_closed",
      "temporarily_closed",
    ]),
    special: z.array(
      z.object({
        date: z.string().min(10, { message: "Please select a date" }),
      })
    ),
    openingHtml: z
      .string()
      .nullable()
      .superRefine((value, ctx) => {
        if (hasUrl(value ?? "")) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `You are not allowed include a url.`,
          });
        }
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors, isDirty, dirtyFields },
    control,
    setValue,
    getValues,
    reset,
    trigger: triggerValidation,
  } = useForm<AttactionOpeningTimesForm>({
    resolver: async (data, context, options) => {
      return zodResolver(schema)(data, context, options);
    },
    defaultValues: {
      openingHtml: "",
      showOpeningTimes: true,
      isMondayClosed: true,
      mondayTimes: [
        {
          alwaysOpen: false,
          opensAt: "09:00",
          closesAt: "17:00",
        },
      ],
      isTuesdayClosed: true,
      tuesdayTimes: [
        {
          alwaysOpen: false,
          opensAt: "09:00",
          closesAt: "17:00",
        },
      ],
      isWednesdayClosed: true,
      wednesdayTimes: [
        {
          alwaysOpen: false,
          opensAt: "09:00",
          closesAt: "17:00",
        },
      ],
      isThursdayClosed: true,
      thursdayTimes: [
        {
          alwaysOpen: false,
          opensAt: "09:00",
          closesAt: "17:00",
        },
      ],
      isFridayClosed: true,
      fridayTimes: [
        {
          alwaysOpen: false,
          opensAt: "09:00",
          closesAt: "17:00",
        },
      ],
      isSaturdayClosed: true,
      saturdayTimes: [
        {
          alwaysOpen: false,
          opensAt: "09:00",
          closesAt: "17:00",
        },
      ],
      isSundayClosed: true,
      sundayTimes: [
        {
          alwaysOpen: false,
          opensAt: "09:00",
          closesAt: "17:00",
        },
      ],
      busiestTimesMonday: [
        {
          value: null,
          label: null,
        },
      ],
      busiestTimesTuesday: [
        {
          value: null,
          label: null,
        },
      ],
      busiestTimesWednesday: [
        {
          value: null,
          label: null,
        },
      ],
      busiestTimesThursday: [
        {
          value: null,
          label: null,
        },
      ],
      busiestTimesFriday: [
        {
          value: null,
          label: null,
        },
      ],
      busiestTimesSaturday: [
        {
          value: null,
          label: null,
        },
      ],
      busiestTimesSunday: [
        {
          value: null,
          label: null,
        },
      ],
    },
  });

  const [isPublishing, setIsPublishing] = useState(false);
  const [stepsFields] = useState<(keyof AttactionOpeningTimesForm)[][]>([
    [
      "statusOfBusiness",
      "showOpeningTimes",
      "mondayTimes",
      "isMondayClosed",
      "sundayTimes",
      "isSundayClosed",
      "tuesdayTimes",
      "isTuesdayClosed",
      "wednesdayTimes",
      "isWednesdayClosed",
      "thursdayTimes",
      "isThursdayClosed",
      "fridayTimes",
      "isFridayClosed",
      "saturdayTimes",
      "isSaturdayClosed",
    ],
    ["special", "openingHtml"],
    // [],
    // [
    //   "busiestTimesSunday",
    //   "busiestTimesMonday",
    //   "busiestTimesTuesday",
    //   "busiestTimesWednesday",
    //   "busiestTimesThursday",
    //   "busiestTimesFriday",
    //   "busiestTimesSaturday",
    // ],
    // [],
  ]);
  const [stepsPostFields] = useState<string[][]>([
    ["status", "opening_times_json"],
    ["opening_times_json", "opening_html"],
    // [],
    // ["busiest_times_json"],
    // [],
  ]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = stepsFields[currentStepIndex] ?? [];
  const currentPostStep = stepsPostFields[currentStepIndex] ?? [];
  const { user } = useAppSelector((state) => state.global);
  const isStepperActive =
    user?.role !== "ADMIN" &&
    !result?.finished_onboarding;

  const getPayload = () => {
    return {
      status: getValues("statusOfBusiness"),
      opening_times_json: {
        showOpeningTimes: getValues("showOpeningTimes"),
        openingTimes: {
          weekly: [
            {
              day: "Monday",
              data: {
                isClosed: getValues("isMondayClosed"),
                times: getValues("mondayTimes"),
              },
            },
            {
              day: "Tuesday",
              data: {
                isClosed: getValues("isTuesdayClosed"),
                times: getValues("tuesdayTimes"),
              },
            },
            {
              day: "Wednesday",
              data: {
                isClosed: getValues("isWednesdayClosed"),
                times: getValues("wednesdayTimes"),
              },
            },
            {
              day: "Thursday",
              data: {
                isClosed: getValues("isThursdayClosed"),
                times: getValues("thursdayTimes"),
              },
            },
            {
              day: "Friday",
              data: {
                isClosed: getValues("isFridayClosed"),
                times: getValues("fridayTimes"),
              },
            },
            {
              day: "Saturday",
              data: {
                isClosed: getValues("isSaturdayClosed"),
                times: getValues("saturdayTimes"),
              },
            },
            {
              day: "Sunday",
              data: {
                isClosed: getValues("isSundayClosed"),
                times: getValues("sundayTimes"),
              },
            },
          ],
          special: getValues("special"),
        },
      },
      opening_html: getValues("openingHtml"),
      busiest_times_json: {
        monday: {
          morning: findMondayMorningBusiestTimes?.includes("true")
            ? true
            : false,
          afternoon: findMondayAfternoonBusiestTimes?.includes("true")
            ? true
            : false,
          evening: findMondayEveningBusiestTimes?.includes("true")
            ? true
            : false,
        },
        tuesday: {
          morning: findTuesdayMorningBusiestTimes?.includes("true")
            ? true
            : false,
          afternoon: findTuesdayAfternoonBusiestTimes?.includes("true")
            ? true
            : false,
          evening: findTuesdayEveningBusiestTimes?.includes("true")
            ? true
            : false,
        },
        wednesday: {
          morning: findWednesdayMorningBusiestTimes?.includes("true")
            ? true
            : false,
          afternoon: findWednesdayAfternoonBusiestTimes?.includes("true")
            ? true
            : false,
          evening: findWednesdayEveningBusiestTimes?.includes("true")
            ? true
            : false,
        },
        thursday: {
          morning: findThursdayMorningBusiestTimes?.includes("true")
            ? true
            : false,
          afternoon: findThursdayAfternoonBusiestTimes?.includes("true")
            ? true
            : false,
          evening: findThursdayEveningBusiestTimes?.includes("true")
            ? true
            : false,
        },
        friday: {
          morning: findFridayMorningBusiestTimes?.includes("true")
            ? true
            : false,
          afternoon: findFridayAfternoonBusiestTimes?.includes("true")
            ? true
            : false,
          evening: findFridayEveningBusiestTimes?.includes("true")
            ? true
            : false,
        },
        saturday: {
          morning: findSaturdayMorningBusiestTimes?.includes("true")
            ? true
            : false,
          afternoon: findSaturdayAfternoonBusiestTimes?.includes("true")
            ? true
            : false,
          evening: findSaturdayEveningBusiestTimes?.includes("true")
            ? true
            : false,
        },
        sunday: {
          morning: findSundayMorningBusiestTimes?.includes("true")
            ? true
            : false,
          afternoon: findSundayAfternoonBusiestTimes?.includes("true")
            ? true
            : false,
          evening: findSundayEveningBusiestTimes?.includes("true")
            ? true
            : false,
        },
      },
    };
  };
  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<AttactionOpeningTimesForm> = async (e) => {
      setIsPublishing(publish);
      if (result) {
        const rawPayload = getPayload();
        const payload = filterPayload(
          rawPayload,
          currentPostStep as (keyof typeof rawPayload)[],
          isStepperActive
        );

        const results = await updateAttraction({
          id: attractionId,
          publish,
          payload,
        });
        if (!isSilent) {
          restartAnimation();
        }
        return "data" in results;
      }
      return false;
    };
    return fn;
  };

  const statusOnlyonSubmit = () => {
    const fn: SubmitHandler<AttactionOpeningTimesForm> = async (e) => {
      if (result) {
        await updateAttraction({
          id: attractionId,
          publish: false,
          payload: {
            status: getValues("statusOfBusiness"),
          },
        });
        restartAnimation();
      }
    };
    return fn;
  };

  const {
    fields: specialFields,
    append: appendSpecial,
    remove: removeSpecial,
  } = useFieldArray({
    control,
    name: "special",
  });

  const watchSpecial = useWatch({ control, name: "special" });

  const specialControlledFields = specialFields.map((field, index) => {
    return {
      ...field,
      ...watchSpecial![index],
    };
  });

  const [getChangeOpensAt, setChangeOpensAt] = useState<string>();
  const [getChangeClosesAt, setChangeClosesAt] = useState<string>();

  useEffect(() => {}, [getChangeOpensAt, getChangeClosesAt]);

  useEffect(() => {
    if (result && isGetSuccess && !isFetching) {
      const busiestTimesMonday = [
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.monday?.morning
              ? "Morning_true"
              : "Morning_false"
            : "Morning_false",
          label: "Morning",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.monday?.afternoon
              ? "Afternoon_true"
              : "Afternoon_false"
            : "Afternoon_false",
          label: "Afternoon",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.monday?.evening
              ? "Evening_true"
              : "Evening_false"
            : "Evening_false",
          label: "Evening",
        },
      ];
      const busiestTimesTuesday = [
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.tuesday?.morning
              ? "Morning_true"
              : "Morning_false"
            : "Morning_false",
          label: "Morning",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.tuesday?.afternoon
              ? "Afternoon_true"
              : "Afternoon_false"
            : "Afternoon_false",
          label: "Afternoon",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.tuesday?.evening
              ? "Evening_true"
              : "Evening_false"
            : "Evening_false",
          label: "Evening",
        },
      ];
      const busiestTimesWednesday = [
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.wednesday?.morning
              ? "Morning_true"
              : "Morning_false"
            : "Morning_false",
          label: "Morning",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.wednesday?.afternoon
              ? "Afternoon_true"
              : "Afternoon_false"
            : "Afternoon_false",
          label: "Afternoon",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.wednesday?.evening
              ? "Evening_true"
              : "Evening_false"
            : "Evening_false",
          label: "Evening",
        },
      ];
      const busiestTimesThursday = [
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.thursday?.morning
              ? "Morning_true"
              : "Morning_false"
            : "Morning_false",
          label: "Morning",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.thursday?.afternoon
              ? "Afternoon_true"
              : "Afternoon_false"
            : "Afternoon_false",
          label: "Afternoon",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.thursday?.evening
              ? "Evening_true"
              : "Evening_false"
            : "Evening_false",
          label: "Evening",
        },
      ];
      const busiestTimesFriday = [
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.friday?.morning
              ? "Morning_true"
              : "Morning_false"
            : "Morning_false",
          label: "Morning",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.friday?.afternoon
              ? "Afternoon_true"
              : "Afternoon_false"
            : "Afternoon_false",
          label: "Afternoon",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.friday?.evening
              ? "Evening_true"
              : "Evening_false"
            : "Evening_false",
          label: "Evening",
        },
      ];
      const busiestTimesSaturday = [
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.saturday?.morning
              ? "Morning_true"
              : "Morning_false"
            : "Morning_false",
          label: "Morning",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.saturday?.afternoon
              ? "Afternoon_true"
              : "Afternoon_false"
            : "Afternoon_false",
          label: "Afternoon",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.saturday?.evening
              ? "Evening_true"
              : "Evening_false"
            : "Evening_false",
          label: "Evening",
        },
      ];
      const busiestTimesSunday = [
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.sunday?.morning
              ? "Morning_true"
              : "Morning_false"
            : "Morning_false",
          label: "Morning",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.sunday?.afternoon
              ? "Afternoon_true"
              : "Afternoon_false"
            : "Afternoon_false",
          label: "Afternoon",
        },
        {
          value: result?.busiest_times_json
            ? result?.busiest_times_json?.sunday?.evening
              ? "Evening_true"
              : "Evening_false"
            : "Evening_false",
          label: "Evening",
        },
      ];
      setSelectedBusiestTimesMonday(busiestTimesMonday);
      setSelectedBusiestTimesTuesday(busiestTimesTuesday);
      setSelectedBusiestTimesWednesday(busiestTimesWednesday);
      setSelectedBusiestTimesThursday(busiestTimesThursday);
      setSelectedBusiestTimesFriday(busiestTimesFriday);
      setSelectedBusiestTimesSaturday(busiestTimesSaturday);
      setSelectedBusiestTimesSunday(busiestTimesSunday);

      let showOpeningTimes = true;
      if (typeof result?.opening_times_json?.showOpeningTimes === "boolean") {
        showOpeningTimes = result?.opening_times_json.showOpeningTimes;
      }

      const getOpeningTimesData = (
        dayName:
          | "monday"
          | "tuesday"
          | "wednesday"
          | "thursday"
          | "friday"
          | "saturday"
          | "sunday"
      ) => {
        if (!result?.opening_times_json) return getValues(`${dayName}Times`);

        const day = result?.opening_times_json?.openingTimes?.weekly?.find(
          (day) => day.day.toLowerCase() === dayName
        );

        if (day?.data?.times?.length! === 0)
          return getValues(`${dayName}Times`);

        return day?.data.times;
      };

      reset(
        {
          special: result?.opening_times_json
            ? result?.opening_times_json?.openingTimes?.special ?? []
            : [],
          statusOfBusiness:
            result?.status as AttactionOpeningTimesForm["statusOfBusiness"],
          showOpeningTimes,
          mondayTimes: getOpeningTimesData("monday"),
          tuesdayTimes: getOpeningTimesData("tuesday"),
          wednesdayTimes: getOpeningTimesData("wednesday"),
          thursdayTimes: getOpeningTimesData("thursday"),
          fridayTimes: getOpeningTimesData("friday"),
          saturdayTimes: getOpeningTimesData("saturday"),
          sundayTimes: getOpeningTimesData("sunday"),

          isMondayClosed: result?.opening_times_json
            ? result?.opening_times_json?.openingTimes?.weekly?.find(
                (getBusiestTimesMonday) => getBusiestTimesMonday.day == "Monday"
              )?.data?.isClosed
              ? true
              : false
            : false,
          isTuesdayClosed: result?.opening_times_json
            ? result?.opening_times_json?.openingTimes?.weekly?.find(
                (getBusiestTimesMonday) =>
                  getBusiestTimesMonday.day == "Tuesday"
              )?.data?.isClosed
              ? true
              : false
            : false,
          isWednesdayClosed: result?.opening_times_json
            ? result?.opening_times_json?.openingTimes?.weekly?.find(
                (getBusiestTimesMonday) =>
                  getBusiestTimesMonday.day == "Wednesday"
              )?.data?.isClosed
              ? true
              : false
            : false,
          isThursdayClosed: result?.opening_times_json
            ? result?.opening_times_json?.openingTimes?.weekly?.find(
                (getBusiestTimesMonday) =>
                  getBusiestTimesMonday.day == "Thursday"
              )?.data?.isClosed
              ? true
              : false
            : false,
          isFridayClosed: result?.opening_times_json
            ? result?.opening_times_json?.openingTimes?.weekly?.find(
                (getBusiestTimesMonday) => getBusiestTimesMonday.day == "Friday"
              )?.data?.isClosed
              ? true
              : false
            : false,
          isSaturdayClosed: result?.opening_times_json
            ? result?.opening_times_json?.openingTimes?.weekly?.find(
                (getBusiestTimesMonday) =>
                  getBusiestTimesMonday.day == "Saturday"
              )?.data?.isClosed
              ? true
              : false
            : false,
          isSundayClosed: result?.opening_times_json
            ? result?.opening_times_json?.openingTimes?.weekly?.find(
                (getBusiestTimesMonday) => getBusiestTimesMonday.day == "Sunday"
              )?.data?.isClosed
              ? true
              : false
            : false,

          openingHtml: result?.opening_html ?? "",

          busiestTimesMonday: [
            {
              value: null,
              label: null,
            },
          ],
          busiestTimesTuesday: [
            {
              value: null,
              label: null,
            },
          ],
          busiestTimesWednesday: [
            {
              value: null,
              label: null,
            },
          ],
          busiestTimesThursday: [
            {
              value: null,
              label: null,
            },
          ],
          busiestTimesFriday: [
            {
              value: null,
              label: null,
            },
          ],
          busiestTimesSaturday: [
            {
              value: null,
              label: null,
            },
          ],
          busiestTimesSunday: [
            {
              value: null,
              label: null,
            },
          ],
        },
        { keepDefaultValues: false }
      );

      setShowRevertModal(false);
    }
  }, [result, isFetching]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Attraction Opening Times | Day Out With The Kids";
  }, []);

  const businessStatusOptions: {
    value: AttactionOpeningTimesForm["statusOfBusiness"];
    label: string;
  }[] = [
    {
      value: "open",
      label: "Operating as usual",
    },
    {
      value: "permanently_closed",
      label: "Permanently Closed",
    },
    {
      value: "temporarily_closed",
      label: "Temporarily Closed",
    },
  ];

  const openingTimeOptions: { value: string; label: string }[] | any = [
    { value: "00_00", label: "00:00" },
    { value: "00_30", label: "00:30" },
    { value: "01_00", label: "01:00" },
    { value: "01_30", label: "01:30" },
    { value: "02_00", label: "02:00" },
    { value: "02_30", label: "02:30" },
    { value: "03_00", label: "03:00" },
    { value: "03_30", label: "03:30" },
    { value: "04_00", label: "04:00" },
    { value: "04_30", label: "04:30" },
    { value: "05_00", label: "05:00" },
    { value: "05_30", label: "05:30" },
    { value: "06_00", label: "06:00" },
    { value: "06_30", label: "06:30" },
    { value: "07_00", label: "07:00" },
    { value: "07_30", label: "07:30" },
    { value: "08_00", label: "08:00" },
    { value: "08_30", label: "08:30" },
    { value: "09_00", label: "09:00" },
    { value: "09_30", label: "09:30" },
    { value: "10_00", label: "10:00" },
    { value: "10_30", label: "10:30" },
    { value: "11_00", label: "11:00" },
    { value: "11_30", label: "11:30" },
    { value: "12_00", label: "12:00" },
    { value: "12_30", label: "12:30" },
    { value: "13_00", label: "13:00" },
    { value: "13_30", label: "13:30" },
    { value: "14_00", label: "14:00" },
    { value: "14_30", label: "14:30" },
    { value: "15_00", label: "15:00" },
    { value: "15_30", label: "15:30" },
    { value: "16_00", label: "16:00" },
    { value: "16_30", label: "16:30" },
    { value: "17_00", label: "17:00" },
    { value: "17_30", label: "17:30" },
    { value: "18_00", label: "18:00" },
    { value: "18_30", label: "18:30" },
    { value: "10_00", label: "19:00" },
    { value: "19_30", label: "19:30" },
    { value: "20_00", label: "20:00" },
    { value: "20_30", label: "20:30" },
    { value: "21_00", label: "21:00" },
    { value: "21_30", label: "21:30" },
    { value: "22_00", label: "22:00" },
    { value: "22_30", label: "22:30" },
    { value: "23_00", label: "23:00" },
    { value: "23_30", label: "23:30" },
  ];

  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(result?.listing_package ?? "");

  useEffect(() => {
    if (isUpdateSuccess) {
      reset({}, { keepValues: true });

      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);

  const stepperStep = useState(0);
  useEffect(() => {
    const steps = getNewAttractionWhichStepLeftOver();
    if (steps.step && steps.allSteps) {
      if (steps.allSteps === stepsFields.length) {
        stepperStep[1](steps.step);
        setCurrentStepIndex(steps.step);
      }
      clearNewAttractionStepLeftOver();
    }
  }, []);

  useEffect(() => {
    dispatch(
      globalActions.setSaveCurrentTabStep({
        attractionId: attractionId ?? "",
        key: "ATTRACTION_OPENING_TIMES",
        allSteps: stepsFields.length,
        step: currentStepIndex,
      })
    );
  }, [attractionId, currentStepIndex, stepsFields.length, dispatch]);

  const { data: getMetrics } =
  apiSlice.useGetVenueOptScoreMetricsQuery();
  const voScoreImprovements = useVoScoreNeedImprovementsFields({
    allMetrics: getMetrics?.result || [],
    completedMetrics: getAttraction?.result?.published_version?.optimisation_scores || [],
    results: result
  })['ATTRACTION_OPENING_TIMES']

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        triggerValidation={handleSubmit}
        saveFn={onSubmit}
        statusOnlySaveFn={statusOnlyonSubmit}
        getPayload={getPayload}
        isDirty={isDirty}
        // isPermClose={getValues('statusOfBusiness') === 'permanently_closed' && result?.status !== 'permanently_closed'}
      />

      <SingleAttractionTemplate
        unstyled={!isStepperActive}
        result={result}
        attractionId={attractionId}
        isAttraction={true}
        doneWalkthrough={result ? result?.done_walkthrough ?? false : undefined}
        name={isStepperActive ? result?.name ?? "" : ""}
      >
        <form className="w-full" onSubmit={handleSubmit(onSubmit(false))}>
          <Stepper
            controlledStepping={stepperStep}
            isActive={isStepperActive}
            isLoading={isLoading}
            saveFn={onSubmit}
            onStepChange={setCurrentStepIndex}
            triggerValidation={async () => {
              return await triggerValidation(currentStep);
            }}
            onNextPage={async () => {
              unlockNewAttractionTab(attractionId ?? "", "ATTRACTION_PRICES");
              await new Promise((res) => setTimeout(res, 500));
              navigate(`/attractions/${attractionId}/prices`);
            }}
            title={
              <StepperHeadingEditAttraction
                name={result?.name || ""}
                title="🕗 Opening Times"
                isActive={isStepperActive}
              />
            }
            nonStepperSubmit={
              <SaveButtonsEditAttraction isLoading={isLoading} />
            }
            onBeforeFinishLater={() => {
              setNewAttractionLeftOverTab(
                attractionId ?? "",
                "ATTRACTION_OPENING_TIMES",
                currentStepIndex,
                stepsFields.length
              );
            }}
            onGoPrevPage={() => {
              navigate(`/attractions/${attractionId}/location`);
            }}
          >
            <Stepper.Step componentType="Step">
              {/* Opening business status */}
              <fieldset>
                <div>
                  <label className="pb-20 text-sm font-bold text-black">
                    Select a status for your business
                  </label>
                  <Controller
                    control={control}
                    name="statusOfBusiness"
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <Select
                          className="mt-1"
                          onChange={(value) => value && onChange(value?.value)}
                          value={
                            businessStatusOptions.find(
                              (v) => v.value === value
                            ) ?? null
                          }
                          defaultValue={null}
                          isClearable={undefined}
                          closeMenuOnSelect={false}
                          menuIsOpen={true}
                          hideSelectedOptions={false}
                          options={businessStatusOptions}
                          components={{ Option: MultiSelectCategoryOptionsSm }}
                          placeholder={""}
                          captureMenuScroll={false}
                          noOptionsMessage={() => "...Loading"}
                          styles={{
                            multiValue: (styles) => ({
                              ...styles,
                              backgroundColor: "#6836D1",
                              borderRadius: 20,
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                            }),
                            multiValueRemove: () => ({
                              display: "none",
                            }),
                            multiValueLabel: (styles) => ({
                              ...styles,
                              color: "#FFF",
                              paddingLeft: 10,
                              paddingRight: 10,
                              fontSize: 14,
                              fontWeight: 400,
                            }),
                            menu: (styles) => ({
                              ...styles,
                              margin: 0,
                              paddingLeft: 5,
                              border: "0",
                              position: "relative",
                              overflow: "hidden",
                              boxShadow: "none",
                              display: "flex",
                              width: "100%",
                            }),
                            menuList: (base) => ({
                              ...base,
                              marginTop: 0,
                              marginBottom: 0,
                              overflow: "hidden",
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              margin: "0 -5px",
                            }),
                            option: (base) => ({
                              ...base,
                              margin: "5px",
                            }),
                            noOptionsMessage: (base) => ({
                              ...base,
                              color: "#555",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              display: "none",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            background: "none",
                            colors: {
                              ...theme.colors,
                              neutral80: "#555",
                            },
                          })}
                        />
                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                      </>
                    )}
                  />
                </div>
                <div className="mt-5">
                  {/* Show list opening times */}
                  {user?.role === "ADMIN" ? (
                    <>
                      <label className="text-sm text-black font-bold">
                        Show or hide opening times on the front-end website
                      </label>
                      <fieldset className="flex mt-3 mb-10">
                        <div className="text-sm font-normal text-cs-gray">
                          Show list of opening times
                          <label className="ml-5 toggle-switch">
                            <input
                              {...register("showOpeningTimes")}
                              type="checkbox"
                            />
                            <span className="switch" />
                          </label>
                        </div>
                      </fieldset>
                    </>
                  ) : (
                    <label className="text-sm font-bold text-[#212121] mb-2.5 block">
                      Show list of opening times {!isStepperActive && !!voScoreImprovements.opening_times_json && <LightningIcon tooltip={getVoScoreTooltip(voScoreImprovements.opening_times_json)} className="ml-1" />}
                    </label>
                  )}
                  {
                    <>
                      <OpeningTimes
                        control={control}
                        day={"Monday"}
                        name={getValues("mondayTimes")}
                        selectedState={setIsMondayClosed}
                        isClosed={getValues("isMondayClosed")}
                        getIsDayClosed={getIsMondayClosed}
                        options={openingTimeOptions}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        setChangeOpensAt={setChangeOpensAt}
                        setChangeClosesAt={setChangeClosesAt}
                      />
                      <OpeningTimes
                        control={control}
                        day={"Tuesday"}
                        name={getValues("tuesdayTimes")}
                        selectedState={setIsTuesdayClosed}
                        isClosed={getValues("isTuesdayClosed")}
                        getIsDayClosed={getIsTuesdayClosed}
                        options={openingTimeOptions}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        setChangeOpensAt={setChangeOpensAt}
                        setChangeClosesAt={setChangeClosesAt}
                      />
                      <OpeningTimes
                        control={control}
                        day={"Wednesday"}
                        name={getValues("wednesdayTimes")}
                        selectedState={setIsWednesdayClosed}
                        isClosed={getValues("isWednesdayClosed")}
                        getIsDayClosed={getIsWednesdayClosed}
                        options={openingTimeOptions}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        setChangeOpensAt={setChangeOpensAt}
                        setChangeClosesAt={setChangeClosesAt}
                      />
                      <OpeningTimes
                        control={control}
                        day={"Thursday"}
                        name={getValues("thursdayTimes")}
                        selectedState={setIsThursdayClosed}
                        isClosed={getValues("isThursdayClosed")}
                        getIsDayClosed={getIsThursdayClosed}
                        options={openingTimeOptions}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        setChangeOpensAt={setChangeOpensAt}
                        setChangeClosesAt={setChangeClosesAt}
                      />
                      <OpeningTimes
                        control={control}
                        day={"Friday"}
                        name={getValues("fridayTimes")}
                        selectedState={setIsFridayClosed}
                        isClosed={getValues("isFridayClosed")}
                        getIsDayClosed={getIsFridayClosed}
                        options={openingTimeOptions}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        setChangeOpensAt={setChangeOpensAt}
                        setChangeClosesAt={setChangeClosesAt}
                      />
                      <OpeningTimes
                        control={control}
                        day={"Saturday"}
                        name={getValues("saturdayTimes")}
                        selectedState={setIsSaturdayClosed}
                        isClosed={getValues("isSaturdayClosed")}
                        getIsDayClosed={getIsSaturdayClosed}
                        options={openingTimeOptions}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        setChangeOpensAt={setChangeOpensAt}
                        setChangeClosesAt={setChangeClosesAt}
                      />
                      <OpeningTimes
                        control={control}
                        day={"Sunday"}
                        name={getValues("sundayTimes")}
                        selectedState={setIsSundayClosed}
                        isClosed={getValues("isSundayClosed")}
                        getIsDayClosed={getIsSundayClosed}
                        options={openingTimeOptions}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        setChangeOpensAt={setChangeOpensAt}
                        setChangeClosesAt={setChangeClosesAt}
                      />
                    </>
                  }
                </div>
              </fieldset>
              {/* End opening business status */}
            </Stepper.Step>

            <Stepper.Step componentType="Step" grouping="b">
              {/* Special days */}
              <fieldset>
                <div>
                  <legend className="font-extrabold text-[20px] text-black leading-[26px]">
                    🎅🏼 Seasonal Opening Times
                  </legend>

                  <p className="my-5 text-[#5F646D] text-sm font-normal">
                    Please include any holiday periods, where regular operating
                    hours may vary (for example, Christmas)
                  </p>

                  {specialControlledFields.map((field, index: any) => {
                    return (
                      <>
                        <div
                          key={field.id}
                          className="flex flex-col items-center my-5 xl:flex-row gap-10 xl:my-2"
                        >
                          <div className="flex gap-5 items-center">
                            <div className="flex flex-col">
                              <label className="font-normal text-xs text-black w-full">
                                Date
                              </label>
                              <input
                                className={`p-2 border-cs-1 w-cs-188 text-cs-gray text-sm rounded-lg mt-2 h-10 ${
                                  isSubmitted &&
                                  !isValid &&
                                  errors.special &&
                                  errors.special[index]
                                    ? "border-cs-red"
                                    : "outline-cs-pink border-[#CED3CF]"
                                }`}
                                value={moment(`${field.date}`).format(
                                  "YYYY-MM-DD"
                                )}
                                type="date"
                                min={new Date().toISOString().split("T")[0]}
                                {...register(`special.${index}.date`)}
                                max="9999-12-31"
                              />
                            </div>

                            <button
                              className="text-black mt-[25px]"
                              type="button"
                              onClick={() => removeSpecial(index)}
                            >
                              <svg
                                width="20"
                                height="22"
                                viewBox="0 0 20 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6.90625 4.125V2.0625H13.0938V4.125H6.90625ZM4.84375 4.125V1.375C4.84375 1.01033 4.98862 0.660591 5.24648 0.402728C5.50434 0.144866 5.85408 0 6.21875 0L13.7812 0C14.1459 0 14.4957 0.144866 14.7535 0.402728C15.0114 0.660591 15.1562 1.01033 15.1562 1.375V4.125H18.5938C18.8673 4.125 19.1296 4.23365 19.323 4.42705C19.5163 4.62044 19.625 4.88275 19.625 5.15625C19.625 5.42975 19.5163 5.69206 19.323 5.88545C19.1296 6.07885 18.8673 6.1875 18.5938 6.1875H18.0919L17.0702 19.4604C17.0172 20.1512 16.7054 20.7966 16.1971 21.2675C15.6887 21.7383 15.0214 22 14.3285 22H5.6715C4.97861 22 4.31127 21.7383 3.80294 21.2675C3.29461 20.7966 2.98276 20.1512 2.92975 19.4604L1.90813 6.1875H1.40625C1.13275 6.1875 0.870443 6.07885 0.677046 5.88545C0.483649 5.69206 0.375 5.42975 0.375 5.15625C0.375 4.88275 0.483649 4.62044 0.677046 4.42705C0.870443 4.23365 1.13275 4.125 1.40625 4.125H4.84375ZM3.9775 6.1875H16.0225L15.0133 19.3022C15.0001 19.4749 14.9223 19.6362 14.7953 19.754C14.6684 19.8718 14.5017 19.9373 14.3285 19.9375H5.6715C5.49834 19.9373 5.33163 19.8718 5.20468 19.754C5.07774 19.6362 4.99991 19.4749 4.98675 19.3022L3.9775 6.1875Z"
                                  fill="black"
                                />
                              </svg>
                            </button>
                          </div>

                          {
                            <fieldset className="flex flex-col w-full sm:w-auto">
                              <SpecialTimes
                                setChangeOpensAt={setChangeOpensAt}
                                setChangeClosesAt={setChangeClosesAt}
                                {...register(`special.${index}.times`)}
                                control={control}
                                index={index}
                                options={openingTimeOptions}
                                name={getValues(`special`)}
                                getValues={getValues}
                                setValue={setValue}
                              />
                            </fieldset>
                          }

                          <div className="flex gap-10 mt-[25px]">
                            <label
                              className={`flex items-center h-4 text-sm font-normal checkbox-item primary-checkmark text-cs-gray w-[125px] whitespace-nowrap ${
                                getValues(`special.${index}.isClosed`)
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              }`}
                            >
                              <span className="text-xs font-normal text-[#212121]">Open 24 hours</span>
                              <input
                                disabled={getValues(
                                  `special.${index}.isClosed`
                                )}
                                className="!w-20 !h-5"
                                type="checkbox"
                                {...control.register(
                                  `special.${index}.times.0.alwaysOpen`
                                )}
                                defaultChecked={
                                  getValues(
                                    `special.${index}.times.0.alwaysOpen`
                                  ) ?? false
                                }
                                readOnly
                              />
                              <span className="checkmark radio-checkmark border-cs-bright-lt-gray"></span>
                            </label>

                            <label className="flex items-center h-4 text-sm font-normal checkbox-item primary-checkmark text-black w-[80px]">
                            <span className="text-xs font-normal text-[#212121]">Closed</span>
                              <input
                                className="!w-20 !h-5"
                                type="checkbox"
                                {...register(`special.${index}.isClosed`, {
                                  onChange: (e) => {
                                    setValue(
                                      `special.${index}.isClosed`,
                                      getValues(`special.${index}.isClosed`)
                                    );
                                  },
                                })}
                                defaultChecked={
                                  getValues(`special.${index}.isClosed`) ??
                                  false
                                }
                                readOnly
                              />
                              <span className="checkmark radio-checkmark border-cs-bright-lt-gray"></span>
                            </label>
                          </div>
                        </div>
                        {errors.special && errors.special[index] && (
                          <div className="flex items-center mt-1 mb-5 text-cs-red">
                            <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                            <span className="flex-1">
                              {errors.special[index]?.date?.message}
                            </span>
                          </div>
                        )}
                      </>
                    );
                  })}

                  <div className="w-[145px] mt-2">
                    <OutlineButton
                      scale="sm"
                      type="button"
                      className="whitespace-nowrap"
                      onClick={() =>
                        appendSpecial({
                          date: null,
                          isClosed: false,
                          times: [
                            {
                              alwaysOpen: true,
                              opensAt: "00:00",
                              closesAt: "00:00",
                            },
                          ],
                        })
                      }
                    >
                      <span className="mr-2 text-xl font-bold">+</span> Add
                      dates
                    </OutlineButton>
                  </div>
                </div>
                <div className="w-full xl:w-3/5 mt-5">
                  {result?.opening_html !== undefined && (
                    <RichTextEditor
                      initialValue={result?.opening_html ?? ""}
                      onChange={(value: string) =>
                        setValue("openingHtml", value)
                      }
                      onDirty={() =>
                        setTimeout(() => {
                          setValue("openingHtml", getValues("openingHtml"), {
                            shouldDirty: true,
                          });
                        }, 100)
                      }
                      label="Is there any extra info that our users should know, in relation to your opening hours?"
                      placeholder="Please include information such as: The best time to visit? Are there any special events or occasions that may affect your normal opening hours?"
                      labelClassName="text-sm text-[#212121] font-normal"
                      maxChars={200}
                      isWordCount={true}
                      maxCharSuffix=" words"
                    />
                  )}

                  {errors["openingHtml"]?.message && (
                    <div className="text-cs-red flex items-center">
                      <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                      <span className="flex-1">
                        {errors["openingHtml"]?.message}
                      </span>
                    </div>
                  )}
                </div>
              </fieldset>
              {/* End special days */}
            </Stepper.Step>
          </Stepper>

          <RevertToSavedModal
            getShowRevertModal={getShowRevertModal}
            setShowRevertModal={setShowRevertModal}
            refetch={refetch}
          />

          <UnsavedModal
            isDirty={isDirty && JSON.stringify(dirtyFields) !== "{}"}
          />
        </form>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AttractionOpeningTimes;

// Components

export const OpeningTimes = (control: any) => {
  const dayToLower = control.day.toLowerCase();

  const {
    fields: openingDayTimeFields,
    append: appendTimes,
    remove: removeTimes,
  } = useFieldArray({
    control: control.control,
    name: `${dayToLower}Times`,
  });

  const watchDayTimes = useWatch({
    control: control.control,
    name: `${dayToLower}Times`,
  });

  const openingDayTimesControlledFields = openingDayTimeFields.map(
    (field, index) => {
      return {
        ...field,
        ...watchDayTimes[index],
      };
    }
  );

  const changeOpensAtTime = (value: any, index: number) => {
    control.setValue(`${dayToLower}Times.${index}.opensAt`, value.label);
    control.setChangeOpensAt((Math.random() * 100).toString(36).substring(7));
  };

  const changeClosesAtTime = (value: any, index: number) => {
    control.setValue(`${dayToLower}Times.${index}.closesAt`, value.label);
    control.setChangeClosesAt((Math.random() * 100).toString(36).substring(7));
  };

  const opensAtRef = useRef<Array<any | null>>([]);
  const closesAtRef = useRef<Array<any | null>>([]);

  useEffect(() => {
    opensAtRef.current = opensAtRef.current.slice(
      0,
      openingDayTimesControlledFields.length
    );
    closesAtRef.current = closesAtRef.current.slice(
      0,
      openingDayTimesControlledFields.length
    );
  }, [openingDayTimesControlledFields]);

  return (
    <fieldset
      className={`flex flex-col gap-10 my-5 min-h-[50px] ${
        !control.isClosed ? "" : "xl:items-center"
      } lg:flex-row xl:my-0`}
    >
      <div className="flex">
        <legend
          className={`float-left text-black text-sm font-bold w-20 flex items-center`}
        >
          {control.day}
        </legend>
      </div>
      {
        <div
          className={`flex flex-col ${dayToLower === "monday" ? "-mt-1" : ""}`}
        >
          {openingDayTimesControlledFields.map((field, index) => {
            let formatRetrievedChosenDate = control
              .getValues(`${dayToLower}Times[${index}].opensAt`)
              ?.replace(" ", "_")
              ?.replace(":", "_")
              ?.toLowerCase();
            let formatRetrievedOpensAt = {
              value: formatRetrievedChosenDate,
              label: control.getValues(`${dayToLower}Times[${index}].opensAt`),
            };
            let formatRetrievedClosesAt = {
              value: formatRetrievedChosenDate,
              label: control.getValues(`${dayToLower}Times[${index}].closesAt`),
            };

            return (
              <div
                key={field.id}
                className={`relative [@media(max-width:485px)]:flex-wrap max-w-[85%] sm:max-w-auto gap-x-8 ${
                  field.alwaysOpen && index == 0
                    ? "flex"
                    : control.getValues(`${dayToLower}Times[0].alwaysOpen`) &&
                      !field.alwaysOpen &&
                      index != 0
                    ? "hidden"
                    : !field.alwaysOpen && index != 0
                    ? "flex"
                    : "flex"
                }`}
              >
                <div className="relative">
                  {index === 0 && dayToLower === "monday" && (
                    <label className="font-normal text-xs text-black">
                      Open time
                    </label>
                  )}
                  <Controller
                    control={control.control}
                    name={`${dayToLower}Times.${index}.opensAt`}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                          isDisabled={
                            control.isClosed ||
                            control.getValues(
                              `${dayToLower}Times.${index}.alwaysOpen`
                            )
                          }
                          ref={(el) => (opensAtRef.current[index] = el)}
                          onMenuOpen={() =>
                            onMenuOpen(value, opensAtRef.current[index])
                          }
                          onChange={(newValue) =>
                            changeOpensAtTime(newValue, index)
                          }
                          value={
                            control.options.find(
                              (v: any) => v.label === value
                            ) ?? control.options[0]
                          }
                          className="my-2"
                          defaultValue={
                            index == 0
                              ? formatRetrievedOpensAt || control.options[0]
                              : formatRetrievedOpensAt || control.options[1]
                          }
                          isClearable={undefined}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          isSearchable={false}
                          options={
                            index == 0
                              ? control.options
                              : control.options.filter(
                                  (option: any) =>
                                    option.value != "open_24_hours"
                                )
                          }
                          components={{
                            Option: SingleSelectOption,
                            DropdownIndicator: DropDownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          noOptionsMessage={() => "...Loading"}
                          placeholder={""}
                          styles={{
                            menu: (styles) => ({
                              ...styles,
                              margin: 0,
                              border: "none",
                              zIndex: 8,
                            }),
                            menuList: (base, isSelected) => ({
                              ...base,
                              paddingTop: 0,
                              paddingBottom: 0,
                              background: "white",

                              "::-webkit-scrollbar": {
                                width: 14,
                              },
                              "::-webkit-scrollbar-thumb": {
                                background: "#c2c2c2",
                                borderRadius: 10,
                                border: "4px solid white",
                              },
                            }),
                            option: (base, { label, isFocused }) => {
                              const isSelected = value === label;
                              return {
                                ...base,
                                background: isSelected ? "#6836D1" : "white",
                                border: "1px solid #c2c2c2",
                                color: isSelected ? "white" : "#555",
                                "&:hover": {
                                  backgroundColor: "#6836D1",
                                  color:
                                    isSelected || isFocused ? "white" : "#555",
                                  border: isFocused ? "1px solid white" : "",
                                },
                                "&:active": {
                                  backgroundColor: "#6836D1",
                                  color: "white",
                                },
                              };
                            },
                            singleValue: (base) => ({
                              ...base,
                              color: "#555",
                              fontSize: 14,
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              cursor: "pointer",
                              outline: "none",
                              border: state.isFocused
                                ? "1px solid #CED3CF"
                                : "1px solid #CED3CF",
                              "&:hover": {
                                border: state.isFocused
                                  ? "1px solid #CED3CF"
                                  : "1px solid #CED3CF",
                              },
                              boxShadow: "none",
                              paddingTop: 3,
                              paddingBottom: 3,
                              margin: 0,
                              minHeight: 40,
                              width: 88,
                              borderRadius: 8,
                            }),
                          }}
                        />
                      </>
                    )}
                  />
                </div>
                {
                  <div className="relative">
                    {index === 0 && dayToLower === "monday" && (
                      <label className="font-normal text-xs text-black">
                        Close time
                      </label>
                    )}
                    <Controller
                      control={control.control}
                      name={`${dayToLower}Times.${index}.closesAt`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Select
                            isDisabled={
                              control.isClosed ||
                              control.getValues(
                                `${dayToLower}Times.${index}.alwaysOpen`
                              )
                            }
                            ref={(el) => (closesAtRef.current[index] = el)}
                            onMenuOpen={() =>
                              onMenuOpen(
                                value,
                                closesAtRef.current[index],
                                "17:00"
                              )
                            }
                            onChange={(nweValue) =>
                              changeClosesAtTime(nweValue, index)
                            }
                            value={control.options.filter(
                              (v: any) => v.label === value
                            )}
                            className="my-2"
                            defaultValue={
                              index == 0
                                ? formatRetrievedClosesAt || control.options[0]
                                : formatRetrievedClosesAt || control.options[1]
                            }
                            isClearable={undefined}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            isSearchable={false}
                            options={control.options.filter(
                              (option: any) => option.value != "open_24_hours"
                            )}
                            components={{
                              Option: SingleSelectOption,
                              DropdownIndicator: DropDownIndicator,
                              IndicatorSeparator: () => null,
                            }}
                            noOptionsMessage={() => "...Loading"}
                            placeholder={""}
                            styles={{
                              menu: (styles) => ({
                                ...styles,
                                margin: 0,
                                border: "none",
                                zIndex: 8,
                              }),
                              menuList: (base, isSelected) => ({
                                ...base,
                                paddingTop: 0,
                                paddingBottom: 0,
                                background: "white",

                                "::-webkit-scrollbar": {
                                  width: 14,
                                },
                                "::-webkit-scrollbar-thumb": {
                                  background: "#c2c2c2",
                                  borderRadius: 10,
                                  border: "4px solid white",
                                },
                              }),
                              option: (base, { isFocused, label }) => {
                                const isSelected = value === label;
                                return {
                                  ...base,
                                  background: isSelected ? "#6836D1" : "white",
                                  border: "1px solid #c2c2c2",
                                  color: isSelected ? "white" : "#555",
                                  "&:hover": {
                                    backgroundColor: "#6836D1",
                                    color:
                                      isSelected || isFocused
                                        ? "white"
                                        : "#555",
                                    border: isFocused ? "1px solid white" : "",
                                  },
                                  "&:active": {
                                    backgroundColor: "#6836D1",
                                    color: "white",
                                  },
                                };
                              },
                              singleValue: (base) => ({
                                ...base,
                                color: "#555",
                                fontSize: 14,
                              }),
                              control: (styles, state) => ({
                                ...styles,
                                cursor: "pointer",
                                outline: "none",
                                border: state.isFocused
                                  ? "1px solid #CED3CF"
                                  : "1px solid #CED3CF",
                                "&:hover": {
                                  border: state.isFocused
                                    ? "1px solid #CED3CF"
                                    : "1px solid #CED3CF",
                                },
                                boxShadow: "none",
                                paddingTop: 3,
                                paddingBottom: 3,
                                margin: 0,
                                minHeight: 40,
                                width: 88,
                                borderRadius: 8,
                              }),
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                }

                {index == 0 ? (
                  <button
                    disabled={
                      control.isClosed ||
                      control.getValues(
                        `${dayToLower}Times.${index}.alwaysOpen`
                      )
                    }
                    className={`inline-flex items-center -ml-5 text-sm text-cs-gray ${
                      control.isClosed ||
                      control.getValues(
                        `${dayToLower}Times.${index}.alwaysOpen`
                      )
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    type="button"
                    onClick={() =>
                      appendTimes({
                        alwaysOpen: false,
                        opensAt: "09:00",
                        closesAt: "17:00",
                      })
                    }
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="icon-park-outline:plus">
                        <path
                          id="Vector"
                          d="M9.0225 4.66992L9.009 15.1699M3.75 9.91992H14.25"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </button>
                ) : (
                  <button
                    disabled={
                      control.isClosed ||
                      control.getValues(
                        `${dayToLower}Times.${index}.alwaysOpen`
                      )
                    }
                    className={`-ml-5 text-cs-gray ${
                      control.isClosed ||
                      control.getValues(
                        `${dayToLower}Times.${index}.alwaysOpen`
                      )
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    type="button"
                    onClick={() => removeTimes(index)}
                  >
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.90625 4.125V2.0625H13.0938V4.125H6.90625ZM4.84375 4.125V1.375C4.84375 1.01033 4.98862 0.660591 5.24648 0.402728C5.50434 0.144866 5.85408 0 6.21875 0L13.7812 0C14.1459 0 14.4957 0.144866 14.7535 0.402728C15.0114 0.660591 15.1562 1.01033 15.1562 1.375V4.125H18.5938C18.8673 4.125 19.1296 4.23365 19.323 4.42705C19.5163 4.62044 19.625 4.88275 19.625 5.15625C19.625 5.42975 19.5163 5.69206 19.323 5.88545C19.1296 6.07885 18.8673 6.1875 18.5938 6.1875H18.0919L17.0702 19.4604C17.0172 20.1512 16.7054 20.7966 16.1971 21.2675C15.6887 21.7383 15.0214 22 14.3285 22H5.6715C4.97861 22 4.31127 21.7383 3.80294 21.2675C3.29461 20.7966 2.98276 20.1512 2.92975 19.4604L1.90813 6.1875H1.40625C1.13275 6.1875 0.870443 6.07885 0.677046 5.88545C0.483649 5.69206 0.375 5.42975 0.375 5.15625C0.375 4.88275 0.483649 4.62044 0.677046 4.42705C0.870443 4.23365 1.13275 4.125 1.40625 4.125H4.84375ZM3.9775 6.1875H16.0225L15.0133 19.3022C15.0001 19.4749 14.9223 19.6362 14.7953 19.754C14.6684 19.8718 14.5017 19.9373 14.3285 19.9375H5.6715C5.49834 19.9373 5.33163 19.8718 5.20468 19.754C5.07774 19.6362 4.99991 19.4749 4.98675 19.3022L3.9775 6.1875Z"
                        fill="black"
                      ></path>
                    </svg>
                  </button>
                )}
              </div>
            );
          })}
        </div>
      }

      <div className="flex my-auto gap-10">
        <label
          className={`flex items-center h-4 text-sm font-normal checkbox-item primary-checkmark text-cs-gray w-[125px] whitespace-nowrap ${
            control.getValues(`is${control.day}Closed`)
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
        >
          <span className="text-xs font-normal text-[#212121]">Open 24 hours</span>
          <Controller
            control={control.control}
            name={`${dayToLower}Times.${0}.alwaysOpen`}
            render={({
              field: { ref, value, onChange },
              fieldState: { error },
            }) => (
              <input
                ref={ref}
                disabled={control.getValues(`is${control.day}Closed`)}
                className="!w-20 !h-5"
                type="checkbox"
                checked={value}
                onChange={(value) => {
                  if (value) {
                    openingDayTimesControlledFields.forEach((_, index) => {
                      control.setValue(`${dayToLower}Times.${index}.opensAt`, '00:00')
                      control.setValue(`${dayToLower}Times.${index}.closesAt`, '00:00')
                    })
                  }
                  onChange(value)
                }}
              />
            )}
          />
          <span className="checkmark radio-checkmark border-cs-bright-lt-gray"></span>
        </label>

        <label className="flex items-center h-4 text-sm font-normal checkbox-item primary-checkmark text-cs-gray w-[80px]">
        <span className="text-xs font-normal text-[#212121]">Closed</span>
          <input
            className="!w-20 !h-5"
            type="checkbox"
            {...control.register(`is${control.day}Closed`)}
            defaultChecked={control.isClosed ?? false}
            readOnly
          />
          <span className="checkmark radio-checkmark border-cs-bright-lt-gray"></span>
        </label>
      </div>
    </fieldset>
  );
};

export const SpecialTimes = (control: any) => {
  const {
    fields: specialTimeFields,
    append: appendSpecialTime,
    remove: removeSpecialTime,
  } = useFieldArray({
    control: control.control,
    name: `special[${control.index}].times`,
  });

  const watchSpecialTimes = useWatch({
    control: control.control,
    name: `special[${control.index}].times`,
  });

  const specialTimesControlledFields = specialTimeFields.map((field, index) => {
    return {
      ...field,
      ...watchSpecialTimes[index],
    };
  });

  const changeOpensAtTime = (value: any, index: number) => {
    value.value == "open_24_hours"
      ? control.setValue(
          `special[${control.index}].times[${index}].alwaysOpen`,
          true
        )
      : control.setValue(
          `special[${control.index}].times[${index}].alwaysOpen`,
          false
        );

    control.setValue(
      `special[${control.index}].times[${index}].opensAt`,
      value.label
    );

    control.setChangeOpensAt((Math.random() * 100).toString(36).substring(7));
  };

  const changeClosesAtTime = (value: any, index: number) => {
    control.setValue(
      `special[${control.index}].times[${index}].closesAt`,
      value.label
    );
    control.setChangeClosesAt((Math.random() + 100).toString(36).substring(7));
  };

  const opensAtRef = useRef<Array<any | null>>([]);
  const closesAtRef = useRef<Array<any | null>>([]);

  useEffect(() => {
    opensAtRef.current = opensAtRef.current.slice(
      0,
      specialTimesControlledFields.length
    );
    closesAtRef.current = closesAtRef.current.slice(
      0,
      specialTimesControlledFields.length
    );
  }, [specialTimesControlledFields]);

  return (
    <>
      {specialTimesControlledFields.map((field, index) => {
        let formatRetrievedChosenDate = control
          .getValues(`special[${control.index}].times[${index}].opensAt`)
          ?.replace(/ /g, "_")
          .replace(":", "_")
          ?.toLowerCase();
        let formatRetrievedOpensAt = {
          value: formatRetrievedChosenDate,
          label: control.getValues(
            `special[${control.index}].times[${index}].opensAt`
          ),
        };
        let formatRetrievedClosesAt = {
          value: formatRetrievedChosenDate,
          label: control.getValues(
            `special[${control.index}].times[${index}].closesAt`
          ),
        };

        return (
          <div
            key={field.id}
            className={`relative [@media(max-width:485px)]:flex-wrap max-w-[85%] sm:max-w-auto gap-8 ${
              field.alwaysOpen && index == 0
                ? "flex"
                : control.getValues(
                    `special[${control.index}].times[0].alwaysOpen`
                  ) &&
                  !field.alwaysOpen &&
                  index != 0
                ? "hidden"
                : !field.alwaysOpen && index != 0
                ? "flex"
                : "flex"
            }`}
          >
            <div>
              <label className="font-normal text-xs text-black">Open time</label>
              <Controller
                control={control.control}
                name={`special[${control.index}].times[${index}].opensAt`}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isDisabled={
                      control.getValues(
                        `special[${control.index}].times[${index}].alwaysOpen`
                      ) ||
                      control.getValues(`special[${control.index}].isClosed`)
                    }
                    ref={(el) => (opensAtRef.current[index] = el)}
                    onMenuOpen={() =>
                      onMenuOpen(value, opensAtRef.current[index])
                    }
                    onChange={(value) => changeOpensAtTime(value, index)}
                    className="my-2"
                    defaultValue={
                      index == 0
                        ? formatRetrievedOpensAt || control.options[0]
                        : formatRetrievedOpensAt || control.options[1]
                    }
                    isClearable={undefined}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isSearchable={false}
                    options={
                      index == 0
                        ? control.options
                        : control.options.filter(
                            (option: any) => option.value != "open_24_hours"
                          )
                    }
                    components={{
                      Option: SingleSelectOption,
                      DropdownIndicator: DropDownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    noOptionsMessage={() => "...Loading"}
                    placeholder={""}
                    styles={{
                      menu: (styles) => ({
                        ...styles,
                        margin: 0,
                        border: "none",
                        zIndex: 8,
                      }),
                      menuList: (base, isSelected) => ({
                        ...base,
                        paddingTop: 0,
                        paddingBottom: 0,
                        background: "white",

                        "::-webkit-scrollbar": {
                          width: 14,
                        },
                        "::-webkit-scrollbar-thumb": {
                          background: "#c2c2c2",
                          borderRadius: 10,
                          border: "4px solid white",
                        },
                      }),
                      option: (base, { label, isFocused }) => {
                        const isSelected = value === label;
                        return {
                          ...base,
                          background: isSelected ? "#6836D1" : "white",
                          border: "1px solid #c2c2c2",
                          color: isSelected ? "white" : "#555",
                          "&:hover": {
                            backgroundColor: "#6836D1",
                            color: isSelected || isFocused ? "white" : "#555",
                            border: isFocused ? "1px solid white" : "",
                          },
                          "&:active": {
                            backgroundColor: "#6836D1",
                            color: "white",
                          },
                        };
                      },
                      singleValue: (base) => ({
                        ...base,
                        color: "#555",
                        fontSize: 14,
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        cursor: "pointer",
                        outline: "none",
                        border: state.isFocused
                          ? "1px solid #CED3CF"
                          : "1px solid #CED3CF",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #CED3CF"
                            : "1px solid #CED3CF",
                        },
                        boxShadow: "none",
                        paddingTop: 3,
                        paddingBottom: 3,
                        margin: 0,
                        minHeight: 40,
                        width: 88,
                        borderRadius: 8,
                      }),
                    }}
                  />
                )}
              />
            </div>
            {
              <div>
                <label className="font-normal text-xs text-black">
                  Close time
                </label>
                <Controller
                  control={control.control}
                  name={`special[${control.index}].times[${index}].closesAt`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isDisabled={
                        control.getValues(
                          `special[${control.index}].times[${index}].alwaysOpen`
                        ) ||
                        control.getValues(`special[${control.index}].isClosed`)
                      }
                      ref={(el) => (closesAtRef.current[index] = el)}
                      onMenuOpen={() =>
                        onMenuOpen(value, closesAtRef.current[index])
                      }
                      onChange={(value) => changeClosesAtTime(value, index)}
                      className="my-2"
                      defaultValue={
                        index == 0
                          ? formatRetrievedClosesAt || control.options[0]
                          : formatRetrievedClosesAt || control.options[1]
                      }
                      isClearable={undefined}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      isSearchable={false}
                      options={control.options.filter(
                        (option: any) => option.value != "open_24_hours"
                      )}
                      components={{
                        Option: SingleSelectOption,
                        DropdownIndicator: DropDownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      noOptionsMessage={() => "...Loading"}
                      placeholder={""}
                      styles={{
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          border: "none",
                          zIndex: 8,
                        }),
                        menuList: (base, isSelected) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                          background: "white",

                          "::-webkit-scrollbar": {
                            width: 14,
                          },
                          "::-webkit-scrollbar-thumb": {
                            background: "#c2c2c2",
                            borderRadius: 10,
                            border: "4px solid white",
                          },
                        }),
                        option: (base, { label, isFocused }) => {
                          const isSelected = value === label;
                          return {
                            ...base,
                            background: isSelected ? "#6836D1" : "white",
                            border: "1px solid #c2c2c2",
                            color: isSelected ? "white" : "#555",
                            "&:hover": {
                              backgroundColor: "#6836D1",
                              color: isSelected || isFocused ? "white" : "#555",
                              border: isFocused ? "1px solid white" : "",
                            },
                            "&:active": {
                              backgroundColor: "#6836D1",
                              color: "white",
                            },
                          };
                        },
                        singleValue: (base) => ({
                          ...base,
                          color: "#555",
                          fontSize: 14,
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          cursor: "pointer",
                          outline: "none",
                          border: state.isFocused
                            ? "1px solid #CED3CF"
                            : "1px solid #CED3CF",
                          "&:hover": {
                            border: state.isFocused
                              ? "1px solid #CED3CF"
                              : "1px solid #CED3CF",
                          },
                          boxShadow: "none",
                          paddingTop: 3,
                          paddingBottom: 3,
                          margin: 0,
                          minHeight: 40,
                          width: 88,
                          borderRadius: 8,
                        }),
                      }}
                    />
                  )}
                />
              </div>
            }

            {index == 0 ? (
              <button
                disabled={
                  control.getValues(
                    `special[${control.index}].times[${index}].alwaysOpen`
                  ) || control.getValues(`special[${control.index}].isClosed`)
                }
                className={`inline-flex items-center -ml-5 text-sm text-cs-gray ${
                  control.getValues(
                    `special[${control.index}].times[${index}].alwaysOpen`
                  ) || control.getValues(`special[${control.index}].isClosed`)
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                type="button"
                onClick={() =>
                  appendSpecialTime({
                    alwaysOpen: false,
                    opensAt: "09:00",
                    closesAt: "17:00",
                  })
                }
              >
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="icon-park-outline:plus">
                    <path
                      id="Vector"
                      d="M9.0225 4.66992L9.009 15.1699M3.75 9.91992H14.25"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </g>
                </svg>
              </button>
            ) : (
              <button
                disabled={
                  control.getValues(
                    `special[${control.index}].times[${index}].alwaysOpen`
                  ) || control.getValues(`special[${control.index}].isClosed`)
                }
                className={`-ml-5 text-cs-gray ${
                  control.getValues(
                    `special[${control.index}].times[${index}].alwaysOpen`
                  ) || control.getValues(`special[${control.index}].isClosed`)
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                type="button"
                onClick={() => removeSpecialTime(index)}
              >
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.90625 4.125V2.0625H13.0938V4.125H6.90625ZM4.84375 4.125V1.375C4.84375 1.01033 4.98862 0.660591 5.24648 0.402728C5.50434 0.144866 5.85408 0 6.21875 0L13.7812 0C14.1459 0 14.4957 0.144866 14.7535 0.402728C15.0114 0.660591 15.1562 1.01033 15.1562 1.375V4.125H18.5938C18.8673 4.125 19.1296 4.23365 19.323 4.42705C19.5163 4.62044 19.625 4.88275 19.625 5.15625C19.625 5.42975 19.5163 5.69206 19.323 5.88545C19.1296 6.07885 18.8673 6.1875 18.5938 6.1875H18.0919L17.0702 19.4604C17.0172 20.1512 16.7054 20.7966 16.1971 21.2675C15.6887 21.7383 15.0214 22 14.3285 22H5.6715C4.97861 22 4.31127 21.7383 3.80294 21.2675C3.29461 20.7966 2.98276 20.1512 2.92975 19.4604L1.90813 6.1875H1.40625C1.13275 6.1875 0.870443 6.07885 0.677046 5.88545C0.483649 5.69206 0.375 5.42975 0.375 5.15625C0.375 4.88275 0.483649 4.62044 0.677046 4.42705C0.870443 4.23365 1.13275 4.125 1.40625 4.125H4.84375ZM3.9775 6.1875H16.0225L15.0133 19.3022C15.0001 19.4749 14.9223 19.6362 14.7953 19.754C14.6684 19.8718 14.5017 19.9373 14.3285 19.9375H5.6715C5.49834 19.9373 5.33163 19.8718 5.20468 19.754C5.07774 19.6362 4.99991 19.4749 4.98675 19.3022L3.9775 6.1875Z"
                    fill="black"
                  ></path>
                </svg>
              </button>
            )}
          </div>
        );
      })}
    </>
  );
};

export const BusyDay = (control: any) => {
  return (
    <fieldset className="flex flex-col my-3 xl:flex-row xl:my-0">
      <legend className="flex items-center float-left w-20 mr-10 text-sm font-bold text-black">
        {control.day}
      </legend>
      <Controller
        control={control.control}
        name={`busiestTimes${control.day}`}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <Select
            className="flex flex-1 responsive-select-category-options"
            onChange={(value, meta) =>
              control.handleChange(
                value,
                control.getBusiestTimesDay,
                meta,
                control.setSelectedBusiestTimesDay
              )
            }
            value={value}
            defaultValue={null}
            isClearable={false}
            closeMenuOnSelect={false}
            menuIsOpen={true}
            isMulti
            hideSelectedOptions={false}
            options={control.getBusiestTimesDay}
            components={{
              Option: MultiSelectCategoryOptionsLight,
              ClearIndicator: () => null,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            noOptionsMessage={() => "...Loading"}
            placeholder={""}
            captureMenuScroll={false}
            isSearchable={false}
            styles={{
              multiValue: (styles) => ({
                ...styles,
                backgroundColor: "#6836D1",
                borderRadius: 20,
                height: 40,
                display: "none",
                alignItems: "center",
              }),
              multiValueRemove: () => ({
                display: "none",
              }),
              multiValueLabel: (styles) => ({
                ...styles,
                color: "#FFF",
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 14,
                fontWeight: 400,
              }),
              menu: (styles) => ({
                ...styles,
                margin: 0,
                paddingLeft: 5,
                border: "0",
                position: "relative",
                overflow: "hidden",
                boxShadow: "none",
                display: "flex",
                width: "100%",
                top: 0,
              }),
              menuList: (base) => ({
                ...base,
                marginTop: 0,
                marginBottom: 0,
                overflow: "hidden",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                margin: "0 -5px",
              }),
              option: (base) => ({
                ...base,
                margin: "5px 10px",
              }),
              noOptionsMessage: (base) => ({
                ...base,
                color: "#555",
              }),
              control: (styles, state) => ({
                ...styles,
                display: "none",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              background: "none",
              colors: {
                ...theme.colors,
                neutral80: "#555",
              },
            })}
          />
        )}
      />
    </fieldset>
  );
};

const onMenuOpen = (value: any, opensAtRef: any, customHour?: string) => {
  const scrollToOption = ["Open 24 hours", "00:00"].includes(value)
    ? customHour
      ? customHour
      : "09:00"
    : value;

  setTimeout(() => {
    const menuList = (opensAtRef as any)?.menuListRef;
    if (!menuList) return;
    const options = menuList?.querySelectorAll(`div`);
    const option: any[] = (Array.from(options) as any[])?.filter(
      (v: any) => v.innerText === scrollToOption
    );
    if (!option?.[0]) return;

    menuList.scrollTo(0, option?.[0]?.offsetTop ?? 0);
  }, 100);
};

export const DropDownIndicator = () => {
  return (
    <div className="pr-2.5">
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

const MultiSelectCategoryOptionsLight: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  innerRef,
  ref,
  value,
  defaultValue,
  selectProps,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    color: "#555",
    display: "flex",
    fontSize: 14,
    fontWeight: 400,
    height: 40,
    padding: 0,
    flexWrap: "wrap",
    width: "auto",
    borderRadius: 40,
  };

  const props = {
    ...innerProps,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
      innerRef={innerRef}
    >
      <label
        className={`checkbox-item white-checkmark w-full h-10 rounded-full px-8 text-sm font-normal flex items-center border-2 ${
          isSelected || value == true || (value && value.includes("true"))
            ? "bg-[#F2F2F2] border-[#F2F2F2]"
            : "border-[#CFDBD5] text-cs-gray"
        } ${isDisabled ? "bg-gray-100 opacity-80" : ""}`}
      >
        <input
          className={`${
            selectProps.customDefaultValue &&
            selectProps.customDefaultValue.filter(
              (option: any) => option?.value == value
            ).length > 0
              ? "default-selected"
              : ""
          } !w-full !h-10 `}
          type="checkbox"
          checked={
            isSelected || value == true
              ? true
              : false || (value && value.includes("true"))
              ? true
              : false
          }
          readOnly
        />

        {children}
      </label>
    </components.Option>
  );
};
