import VerifyEmailModal from "../../components/global/www/modals/VerifyEmailModal";
import { useSearchParams } from "react-router-dom";
import { TwoColTemplate } from "../../components/global/TwoColTemplate";

const VerifyEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  return (
    <>
      <TwoColTemplate
        sidebarImage="/assets/www/activate-account-image.png"
        sidebarText="Tailor your listing content, add images, and highlight what sets your business apart from competitors."
      >
        <VerifyEmailModal email={searchParams.get("email") ?? ""} />
      </TwoColTemplate>
    </>
  );
};

export default VerifyEmail;
