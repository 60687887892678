import { type ReactNode } from "react";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  children: ReactNode;
  className?: string;

  color?: Colors,
  scale?: Size,
}

type Colors = 'primary' | 'dark' | 'light' | 'outline' | 'outlineGray'
type Size = 'base' | 'sm' | 'md'

export function Button({
  type = 'button',
  children,
  className = "",

  color = 'primary',
  scale = 'base',


  ...args
}: ButtonProps) {
  const colors: Record<Colors, string> = {
    dark: 'bg-black text-white',
    primary: 'bg-cs-pink text-white',
    light: 'bg-transparent text-black',
    outline: 'bg-transparent text-black border border-[#E4E9E9] hover:bg-[#E4E9E9]',
    outlineGray: 'bg-transparent text-black border border-[#979797]'
  } 

  const sizes: Record<Size, string> = {
    base: 'px-9 py-2 h-9 text-xs',
    md: 'px-2.5 py-2 h-9 text-sm',
    sm: 'px-3 py-2 h-8 text-xs'
  } 
  return (
    <button
      type={type}
      className={`flex items-center justify-center leading-none rounded-lg font-bold transition-colors ${sizes[scale]} ${colors[color]} ${className}`}
      {...args}
    >
      {children}
    </button>
  );
}
