import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import TextInput from "../../../form/TextInput";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiSlice } from "../../../../store/apiSlice";
import { Description, Heading } from "../../TwoColTemplate";
import { PrimaryButton } from "../../PrimaryButton";
import { ErrorMessage } from "../../ErrorMessage";
import { HideIcon, ShowIcon } from "./LoginModal";

interface ResetPasswordForm {
  password: string;
}

const ResetPasswordModal: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const context: "forgot" | "invite" =
    (searchParams.get("c") as any) ?? "forgot";

  const [resetPassword, { isSuccess, isLoading }] =
    apiSlice.usePasswordResetMutation();

  const mustBe: string =
    "Must be at least 8 characters, with at least one number and one special character";

  const schema = z.object({
    password: z
      .string()
      .min(1, "Password is required")
      .min(8, mustBe)
      .refine(
        (passwordText) =>
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$£%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$£%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(
            passwordText
          ),
        mustBe
      ),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isSubmitted, errors },
    control,
  } = useForm<ResetPasswordForm>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<ResetPasswordForm> = (e) => {
    const token = searchParams.get("t");
    const userId = searchParams.get("u");

    if (!token || !userId) {
      //TODO - Error handle this somehow
      return;
    }

    resetPassword({
      password: e.password,
      token,
      userId,
    });
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    isSuccess && navigate("/login", { replace: true });
  }, [isSuccess]);

  return (
    <div className="flex flex-col w-full">
      <Heading>Create a new password</Heading>
      <Description>
        Enter your new password and press Confirm. <br />
        You will be asked to log in with your new password
      </Description>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-5 py-5">
          <TextInput
            className="group"
            labelClassName=""
            inputClassName="border text-black rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
            control={control}
            name={"password"}
            label={"Password"}
            required
            inputProps={{
              type: `${passwordShown ? "text" : "password"}`,
              ...register("password"),
            }}
            endAdornment={
              <span
                onClick={togglePasswordVisiblity}
                className={`leading-snug underline absolute right-5 top-4 border-cs-red cursor-pointer hover:text-cs-pink ${
                  errors.password ? "text-cs-red" : "text-cs-gray"
                }`}
              >
                {passwordShown ? <ShowIcon /> : <HideIcon />}
              </span>
            }
            requirements={
              "* Minimum of 8 characters with at least one number and one special character"
            }
          />
        </div>

        <PrimaryButton type="submit" loading={isLoading}>
          {context === "invite" ? "Confirm account" : "Confirm password"}
        </PrimaryButton>

        {isSubmitted && !isValid && (
          <ErrorMessage className="mt-3">
            Please fix the errors indicated above to continue
          </ErrorMessage>
        )}
      </form>
    </div>
  );
};

export default ResetPasswordModal;
