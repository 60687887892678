import { BaseQueryFn, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AmpUserDetail, AmpUserInvitePayload, AmpUserPartial, AmpUserRegisterPayload, AmpUserUpdatePayload } from '../types/user'
import { CreateAdPlacementPayload } from '../pages/amp/AddAdPlacement';
import { VenueOptTask } from '../pages/amp/attractions/AttractionVenueOptScore';
import { BlogsResponse, FcnBlogContentRequest, FcnBlogDetailsResponse } from '../../../client-core/src/types/blog';
import { CreateBrandPayload } from '../pages/amp/AddBrand';
import { SiteConfigurationResponse } from '../../../client-core/src/types/types';


interface CustomError {
    data: {
        message: string,
        stack: string
    },
    status: number
}

export interface ShortData {
    interval_start: string
    interval_end: string
    count: string
}

export interface CreateAttractionArgs {
    userId?: number,
    name: string,
    website: string,
    town_id: string,
    postcode: string,
    phone: string,
    no_phone_number: boolean,
    // listing_type: 'attraction' | 'event' | 'club' | 'festival', 
    duration_type: 'permanent' | 'temporary',
    // start_working?: string,
    // end_working?: string
}

export const objectToQueryString = (object: any) => {
    const searchParams = new URLSearchParams();
    Object.keys(object).forEach(key => { 
        if(object[key])
            searchParams.append(key, object[key])
    });
    return searchParams.toString()
}

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, CustomError> = async (
    args,
    api,
    extraOptions
  ) => {
    const { endpoint } = api;
    let baseUrl = '/api/amp/v1';
  
    // Override base URL
    if (endpoint === 'searchTown') {
      baseUrl = '/api/v1';
    }
  
    const baseQuery = fetchBaseQuery({ baseUrl }) as BaseQueryFn<string | FetchArgs, unknown, CustomError>;
    return baseQuery(args, api, extraOptions);
};

export const apiSlice = createApi({
    reducerPath: "globalApi",
    baseQuery: customBaseQuery,
    tagTypes: ['Pending', 'Attraction', 'Queue', 'AdPlacements', 'Brands', 'Events', "Blogs"],
    endpoints: (builder) => ({
        login: builder.mutation<AmpUserPartial | null, LoginRequest>({
            query: (req) => ({
                url: `sign-in`,
                method: "POST",
                body: req
            })
        }),
        register: builder.mutation<{ id: number, alreadyExist?: boolean } | null, AmpUserRegisterPayload>({
            query: (req) => ({
                url: `sign-up`,
                method: "POST",
                body: req
            })
        }),
        verifyEmail: builder.mutation<null, { userId: string, token: string }>({
            query: (arg) => ({
                url: `verify-email`,
                method: "POST",
                body: arg
            })
        }),
        session: builder.query<AmpUserPartial | null, void>({
            query: () => `session`,
            keepUnusedDataFor: 0
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: `sign-out`,
                method: "POST"
            })
        }),
        passwordResetRequest: builder.mutation<MessageResponse, string>({
            query: (email) => ({
                url: `password-reset-request`,
                method: "POST",
                body: { email },
            }),
        }),
        passwordReset: builder.mutation<MessageResponse, PasswordResetRequest>({
            query: (req) => ({
                url: `password-reset`,
                method: "POST",
                body: req
            })
        }),
        getUsers: builder.query<ObjectionResponse<AmpUserDetail>, ListQuery | void>({
            query: (query) => `users${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getUserByEmail: builder.query<AmpUserDetail, string>({
            query: (email) => `users/email/${email}`,
            keepUnusedDataFor: 0
        }),
        getUserAttractions: builder.query<ObjectionResponse<AttractionDetails>, any>({
            query: (arg) => `/attractions?pageSize=300&sortBy=name${!!arg?.showUnapproved ? "&showUnapproved=true" : ""}${(!!arg?.onlyBasicInformation) ? "&onlyBasicInformation=true" : ""}${(!!arg?.hideBasic) ? `&hideBasic=true` : ''}${arg?.operatorId ? `&operatorId=${arg?.operatorId}` : ""}${arg?.page ? '&page=' + arg?.page : ''}${arg?.query ? '&query=' + arg?.query : ''}`,
            keepUnusedDataFor: 0
        }),
        updateUser: builder.mutation<AmpUserDetail, EntityUpdateRequest<AmpUserUpdatePayload>>({
            query: (arg) => ({
                url: `users/${arg.id}`,
                method: "PATCH",
                body: arg.payload
            })
        }),
        inviteUser: builder.mutation<MessageResponse, AmpUserInvitePayload>({
            query: (body) => ({
                url: `users/invite`,
                method: "POST",
                body
            })
        }),
        sendEmailVerification: builder.mutation<{ message: string }, { userId: string }>({
            query: (body) => ({
                url: `users/verify`,
                method: "POST",
                body
            })
        }),
        getAttraction: builder.query<EntityDetailsResponse<AttractionDetails>, string>({
            query: (query) => `attractions/${query}`,
            keepUnusedDataFor: 0,
            providesTags: (data) => [{ type: 'Attraction', id: data?.result?.short_id }]
        }),
        getAttractionHeader: builder.query<EntityDetailsResponse<AttractionDetails>, string>({
            query: (query) => `attractions/${query}`,
            keepUnusedDataFor: 0,
        }),
        searchAttractions: builder.query<{ results: AttractionDetails[] }, string>({
            query: (query) => `attractions/search${query ? `?query=${query}&pageSize=300` : ''}`,
            keepUnusedDataFor: 0
        }),
        searchFullAttractions: builder.query<{ results: AttractionDetails[] }, string>({
            query: (query) => `attractions/full-search${query ? `?query=${query}&pageSize=300` : ''}`,
            keepUnusedDataFor: 0
        }),
        searchSingleAttraction: builder.query<{result: AttractionDetails}, string>({
            query: (query) => `attractions/search${query ? `?attractionId=${query}` : ''}`,
            keepUnusedDataFor: 0
        }),
        getAttractionsList: builder.query<ObjectionResponse<AttractionDetails>, ListQuery | void>({
            query: (query) => `attractions${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getPublishQueueList: builder.query<AttractionDetails[], ListQuery | void>({
            query: (query) => `publish-queues${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0,
            providesTags: ['Queue'],
        }),
        searchTowns: builder.query<ObjectionResponse<TownList>, string>({
            query: (query) => `towns${query ? `?query=${query}` : ''}`,
            keepUnusedDataFor: 0
        }),
        searchCounties: builder.query<ObjectionResponse<TownList>, string>({
            query: (query) => `counties${query ? `?query=${query}` : ''}`,
            keepUnusedDataFor: 0
        }),
        listAttractions: builder.query<ObjectionResponse<AttractionDetails>, ListQuery | { all: boolean } | void>({
            query: (query) => `attractions${query?.all ? '/claims' : ''}`,
            keepUnusedDataFor: 0,
            transformResponse: (response: ObjectionResponse<AttractionClaims>, meta, arg: ListQuery | { all: boolean } | void) => {
                return {
                    results: arg?.all ? response.results.map(r => ({
                        ...r.venue,
                        status: r.status,
                        venue_status: r.venue.status,
                        submit_approval: r.submit_approval,
                        total_vo_score: r.venue.total_vo_score,
                        max_possible_vo_score: r.venue.max_possible_vo_score

                    })) : (response.results as unknown as AttractionDetails[]),
                    total: response.total
                }
            }
        }),
        uploadImageToAttraction: builder.mutation<AttractionMedia, {
            venueId: string,
            file: FormData
        }>({
            query: (arg) => {
                return ({
                    url: `attractions/draft/image/${arg.venueId}`,
                    method: "POST",
                    body: arg.file
                })
            }
        }),
        updateAttractionDraft: builder.mutation<AttractionDetails, EntityUpdateRequest<any> & { publish?: boolean }>({
            query: (arg) => ({
                url: `attractions/draft/${arg.id}${arg.publish ? '?publish=true' : ''}`,
                method: "PATCH",
                body: arg.payload
            }),
            invalidatesTags: (data, error) => error ? [] : [{ type: 'Attraction', id: data?.short_id }]
        }),
        updateAttractionPublish: builder.mutation<AttractionDetails, string>({
            query: (id) => ({
                url: `attractions/publish/${id}`,
                method: "PATCH"
            })
        }),
        publishStatus: builder.mutation<{ status: boolean }, { id: string, forcePublish: boolean }>({
            query: (args) => ({
                url: `attractions/publish-status/${args.id}${args.forcePublish ? '?forcePublish=true' : ''}`,
                method: "PATCH"
            })
        }),
        doneWalkthrough: builder.mutation<AttractionDetails, string>({
            query: (id) => ({
                url: `attractions/done-walkthrough/${id}`,
                method: "PATCH"
            }),
            invalidatesTags: (data, error) => error ? [] : [{ type: 'Attraction', id: data?.short_id }]
        }),
        editDoneWalkthrough: builder.mutation<AttractionDetails, string>({
            query: (id) => ({
                url: `attractions/edit-done-walkthrough/${id}`,
                method: "PATCH"
            }),
            invalidatesTags: (data, error) => error ? [] : [{ type: 'Attraction', id: data?.short_id }]
        }),
        finishedOnboarding: builder.mutation<AttractionDetails, string>({
            query: (id) => ({
                url: `attractions/finished-onboarding/${id}`,
                method: "PATCH"
            }),
            invalidatesTags: (data, error) => error ? [] : [{ type: 'Attraction', id: data?.short_id }]
        }),
        userDoneWalkthrough: builder.mutation<{success: true}, void>({
            query: () => ({
                url: `users/done-walkthrough`,
                method: "PATCH"
            }),
        }),
        getAttractionDraftPreview: builder.query<{ url: string }, string>({
            query: (id) => `attractions/preview/request/${id}`,
            keepUnusedDataFor: 0
        }),
        getAttractionCategories: builder.query<AttractionCategories[], void>({
            query: () => `attractions/categories`,
            keepUnusedDataFor: 0
        }),
        getOperators: builder.query<ObjectionResponse, { query?: string } | void>({
            query: (args) => `operators?pageSize=100&sortBy=title${args?.query ? '&query=' + args.query : ''}`,
            keepUnusedDataFor: 0
        }),
        getPendingApprovalAttractions: builder.query<ObjectionResponse<AttractionDetails>, AttractionDetails | void>({
            query: () => `attractions/claims?pageSize=1000&pendingApproval=true`,
            providesTags: ['Pending'],
            keepUnusedDataFor: 0
        }),
        getNewApprovalAttractions: builder.query<ObjectionResponse<AttractionDetails>, AttractionDetails | void>({
            query: () => `attractions/claims?pageSize=1000&new=true`,
            providesTags: ['Pending'],
            keepUnusedDataFor: 0
        }),
        getEventApprovalAttractions: builder.query<ObjectionResponse<AttractionDetails>, AttractionDetails | void>({
            query: () => `attractions/claims?pageSize=1000&event=true`,
            providesTags: ['Pending'],
            keepUnusedDataFor: 0
        }),
        getDeniedApprovalAttractions: builder.query<ObjectionResponse<AttractionDetails>, AttractionDetails | void>({
            query: () => `attractions/claims?pageSize=100&denied=true`,
            providesTags: ['Pending'],
            keepUnusedDataFor: 0
        }),
        updatePendingApproval: builder.mutation<any, any>({
            query: (arg) => ({
                url: `attractions/resolve-claim/${arg.id}`,
                method: "POST",
                body: arg.payload
            }),
            invalidatesTags: ['Pending']
        }),
        updateSubmitClaim: builder.mutation<any, any>({
            query: (id) => ({
                url: `attractions/submit-claim/${id}`,
                method: "POST",
            }),
            invalidatesTags: ['Pending']
        }),
        submitQueue: builder.mutation<any, any>({
            query: (id) => ({
                url: `attractions/submit-publish/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ['Queue']

        }),
        denyQueue: builder.mutation<any, any>({
            query: (payload) => ({
                url: `attractions/deny-publish/${payload.id}`,
                body: {
                    message: payload.message
                },
                method: "PATCH",
            }),
            invalidatesTags: ['Queue']
        }),
        getVersionNumber: builder.query<{ version: string }, void>({
            query: () => `/meta-data`,
            keepUnusedDataFor: 0
        }),
        createClaim: builder.mutation<{ success: boolean }, { userId: number, attractionId: string }>({
            query: (arg) => ({
                url: `attractions/claim/${arg.attractionId}`,
                method: "POST",
                body: {
                    userId: arg.userId,
                }
            })
        }),
        createAttraction: builder.mutation<AttractionDetails, CreateAttractionArgs>({
            query: (arg) => ({
                url: `attractions`,
                method: "POST",
                body: (() => {
                    const outputObject: Partial<CreateAttractionArgs> = {};
                    for (const key in arg) {
                        if (arg.hasOwnProperty(key) && arg[key as keyof CreateAttractionArgs] !== 'undefined') {
                            // @ts-ignore
                            outputObject[key] = arg[key];
                        }
                    }
                    return outputObject;
                })()
            })
        }),
        getAttractionData: builder.mutation<AttractionsDataResponse, AttractionsDataRequest>({
            query: (arg) => ({
                url: `attractions/data`,
                method: "POST",
                body: arg
            })
        }),
        getAttractionShortData: builder.query<{ created: ShortData[], published: ShortData[], claimed: ShortData[], blogsSubmitted: ShortData[] }, void>({
            query: () => ({
                url: `attractions/short-data`,
                method: "GET",
            })
        }),
        getAttractionChangeLog: builder.query<ObjectionResponse<AttractionChangeLog>, any>({
            query: ({ id, ...query }) => `attraction/${id}/change-log${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getAdPlacements: builder.query<ObjectionResponse<AmpAdPlacements>, ListQuery | void>({
            query: (query) => `ad-placements${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0,
            providesTags: ['AdPlacements'],
        }),
        uploadImageToAdPlacements: builder.mutation<AttractionMedia, { file: string }>({
            query: (arg) => {
                return ({
                    url: `ad-placements/draft/image`,
                    method: "POST",
                    body: {
                        base64: arg.file
                    }
                })
            }
        }),
        addPlacement: builder.mutation<CreateAdPlacementPayload & { id: string }, CreateAdPlacementPayload & { id?: string }>({
            query: (arg) => {
                return ({
                    url: `ad-placements`,
                    method: "POST",
                    body: arg
                })
            },
            invalidatesTags: ['AdPlacements']
        }),
        deleteAdPlacement: builder.mutation<{}, { id: string }>({
            query: (arg) => {
                return ({
                    url: `ad-placements`,
                    method: "DELETE",
                    body: {
                        id: arg.id,
                    }
                })
            },
            invalidatesTags: ['AdPlacements']
        }),
        getPlacement: builder.query<AmpAdPlacements, { id: string }>({
            query: (arg) => {
                return ({
                    url: `ad-placements/${arg.id}`,
                    method: "GET",
                })
            },
        }),
        getLatestUnpaidAttraction: builder.query<AttractionDetails, void>({
            query: () => {
                return ({
                    url: `attractions/latest-unpaid`,
                    method: "GET",
                })
            },
        }),
        createPaymentUrl: builder.mutation<{ sessionUrl: string }, { id: string, planId: string, submit_approval?: string, isOnboarding?: boolean }>({
            query: (arg) => {
                return ({
                    url: `attractions/create-payment/${arg.id}`,
                    method: "POST",
                    body: {
                        submit_approval: arg.submit_approval,
                        planId: arg.planId,
                        isOnboarding: arg.isOnboarding

                    }
                })
            }
        }),
        subscriptionCheckout: builder.mutation<{ sessionUrl: string }, { venueId: string, planId: string, submit_approval?: string }>({
            query: (arg) => {
                return ({
                    url: `subscription/checkout`,
                    method: "POST",
                    body: {
                        submit_approval: arg.submit_approval,
                        planId: arg.planId,
                        venueId: arg.venueId

                    }
                })
            }
        }),
        getTicketingProductList: builder.query<ObjectionResponse<DOWTKTicketingProduct>, ListQuery | void>({
            query: (query) => `tickets/products${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getTicketingProduct: builder.query<DOWTKTicketingProduct, string>({
            query: (ticketingProductId) => `tickets/products/${ticketingProductId}`,
            keepUnusedDataFor: 0,
        }),
        createTicketingProduct: builder.mutation<{ success: boolean }, DOWTKTicketingProduct>({
            query: (body) => ({
                url: `tickets/products`,
                method: "POST",
                body
            })
        }),
        updateTicketingProduct: builder.mutation<DOWTKTicketingProduct, DOWTKTicketingProduct>({
            query: (arg) => ({
                url: `tickets/products/${arg.id}`,
                method: "PATCH",
                body: arg
            }),
        }),
        getTicketList: builder.query<ObjectionResponse<TicketDetails>, ListQuery | void>({
            query: (query) => `tickets${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getProductList: builder.query<ObjectionResponse<TicketDetails>, ListQuery | void>({
            query: (query) => `tickets/all-products${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        updateTicket: builder.mutation<TicketDetails, TicketDetails>({
            query: (arg) => ({
                url: `tickets/products/${arg.product_id}`,
                method: "PATCH",
                body: arg
            }),
        }),
        createTicket: builder.mutation<{ success: boolean }, TicketDetails>({
            query: (body) => ({
                url: `tickets`,
                method: "POST",
                body
            })
        }),
        createNewProduct: builder.mutation<{ id: string }, any>({
            query: (body) => ({
                url: `tickets/products/create`,
                method: "POST",
                body
            })
        }),
        getTicket: builder.query<DOWTKTicket, string>({
            query: (ticketId) => `tickets/${ticketId}`,
            keepUnusedDataFor: 0,
        }),
        getProduct: builder.query<{
            id: string,
            venue_id: string,
            name: string,
            description: string,
            status: "pending",
            created_at: string,
            updated_at: string,
            cancellation_policy: "anytime" | "no" | "24hours" | "48hours" | "72hours" | null,
            periods: {
                name: string,
                end_date: string,
                start_date: string,
                monday_availability_id: string | null,
                tuesday_availability_id: string | null,
                wednesday_availability_id: string | null,
                thursday_availability_id: string | null,
                friday_availability_id: string | null,
                saturday_availability_id: string | null,
                sunday_availability_id: string | null,
            }[],
            tickets: {
                id: string,
                category: string,
                name: string,
                description: string,
                default_price: string,
                default_price_formatted: string,
                default_price_currency: string,
                status: "pending",
                created_at: string,
                updated_at: string,
                min_age: number,
                max_age: number,
                capacity: number,
            }[]
        }, string>({
            query: (productId) => `products/${productId}`,
            keepUnusedDataFor: 0,
        }),
        getAvailabilityPeriodList: builder.query<ObjectionResponse<DOWTKTicketingAvailabilityPeriod>, ListQuery | void>({
            query: (query) => `tickets/availability-periods${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getAvailabilityPeriod: builder.query<DOWTKTicketingAvailabilityPeriod, string>({
            query: (availabilityPeriodId) => `tickets/availability-periods/${availabilityPeriodId}`,
            keepUnusedDataFor: 0,
        }),
        createAvailabilityPeriod: builder.mutation<{ success: boolean }, DOWTKTicketingAvailabilityPeriod>({
            query: (body) => ({
                url: `tickets/availability-periods/create`,
                method: "POST",
                body
            })
        }),
        updateAvailabilityPeriod: builder.mutation<DOWTKTicketingAvailabilityPeriod, DOWTKTicketingAvailabilityPeriod>({
            query: (arg) => ({
                url: `tickets/availability-periods/${arg.id}`,
                method: "PATCH",
                body: arg
            }),
        }),
        createAvailabilityTimeslots: builder.mutation<{ success: boolean }, { availabilityPeriodId: string, timeslots: DOWTKTicketingAvailabilityTimeSlot[] }>({
            query: ({ availabilityPeriodId, timeslots }) => ({
                url: `tickets/availability-periods/${availabilityPeriodId}/timeslots`,
                method: "POST",
                body: { timeslots }
            })
        }),
        updateAvailabilityTimeslot: builder.mutation<{ success: boolean }, { availabilityPeriodId: string, timeslot: Partial<DOWTKTicketingAvailabilityTimeSlot> }>({
            query: ({ availabilityPeriodId, timeslot }) => ({
                url: `tickets/availability-periods/${availabilityPeriodId}/timeslots/${timeslot.id}`,
                method: "PATCH",
                body: { timeslot }
            })
        }),
        createAvailabilityPeriodTicketPricing: builder.mutation<DOWTKAvailabilityPeriodTicketPricing, DOWTKAvailabilityPeriodTicketPricing>({
            query: (body) => ({
                url: `tickets/availability-periods/pricing/create`,
                method: "POST",
                body
            })
        }),
        updateAvailabilityPeriodTicketPricing: builder.mutation<DOWTKAvailabilityPeriodTicketPricing, DOWTKAvailabilityPeriodTicketPricing>({
            query: (body) => ({
                url: `tickets/availability-periods/pricing/${body.id}`,
                method: "PATCH",
                body
            })
        }),
        getAvailabilityPeriodTicketPricing: builder.mutation<DOWTKAvailabilityPeriodTicketPricing[], { ticketId: string, availabilityPeriodId: string }>({
            query: ({ ticketId, availabilityPeriodId }) => `tickets/availability-periods/${availabilityPeriodId}/pricing/${ticketId}`,
        }),
        getReservationByQR: builder.mutation<any, { value: string, token?: string }>({
            query: (qr) => `reservation/by-qrcode/${qr.value}${qr.token ? `?token=${qr.token}` : ''}`,
        }),
        getReservationByReference: builder.mutation<any, { value: string, token?: string }>({
            query: (reference) => `reservation/by-reference/${reference.value}${reference.token ? `?token=${reference.token}` : ''}`,
        }),
        getReservationScanToken: builder.mutation<{ token: { expires_at: string, token: string, user_id: string, id: string }}, void>({
            query: () => ({
                url: '/reservation/scan-token',
                method: 'POST'
            }),
        }),
        checkInReservation: builder.mutation<any, string>({
            query: (reservationID) => ({
                url: `reservation/check-in/${reservationID}`,
                method: "POST",
            }),
        }),
        completePayment: builder.mutation<void, { cbHostedPageId: string }>({
            query: (arg) => {
                return ({
                    url: `attractions/complete-payment/${arg.cbHostedPageId}`,
                    method: "POST",
                })
            }
        }),

        getSubscriptionPlans: builder.query<SubscriptionPlan[], { venueId?: string }>({
            query: (arg) => {
                return ({
                    url: `/subscription/plans${arg.venueId ? `?venueId=${arg.venueId}` : ''}`,
                    method: "GET",
                })
            }
        }),

        getVenueOptScoreMetrics: builder.query<{result: VenueOptTask[]}, void>({
            query: () => {
                return ({
                    url: `attractions/venue-opt-score-metrics`,
                    method: 'GET'
                })
            }
        }),
        getBookings: builder.query<ObjectionResponse<{
            id: string | null
            ticket_id: string | null
            user_id: string | null
            email: string | null
            venue_id: string | null
            created_at: string | null
            updated_at: string | null
            verified: string | null
            total_cost: string | null
            booking_details: any
            booking_response: any
            tickets: any
            customer_details: any
            payment_service_response: any
            payment_service_name: string | null
            payment_service_transaction_id: string | null
            payment_service_transaction_amount: string | null
            payment_status: string | null
            booking_status: string | null
            expires_at: string | null
            metadata: any
            booking_reference: string | null
            delivery_methods: any
            selected_delivery_method: any
            ticket_cost: string | null,
            ticket: any,
            venue: AttractionDetails,
            ticket_reservations: {
                id: string,
                booking_id: string,
                ticket_id: string,
                pricing_option_id: string,
                price: string,
                quantity: string,
                expires_at: string,
                status: string,
                created_at: string,
                updated_at: string,
                checked_in_at: string,
                reservation_reference: string,
                timeslot_id: string
            }
        }>, { page: string, pageSize: string,  from: string,
            to: string,
            fixedPeriod: '12months' | '6months',
            dateBooked: string,
            venue_id: string }>({
            query: (args) => {
                return ({
                    url: `bookings?${new URLSearchParams(args).toString()}`,
                    method: 'GET'
                })
            }
        }),


        createTicketOnboardingUser: builder.mutation<any, any>({
            query: (arg) => {
                return ({
                    url: `ticket-onboarding`,
                    method: "POST",
                    body: arg
                })
            }
        }),

        ticketingReport: builder.query<Record<string, {
            ticket: {
              name: string,
              commission: number,
              is_active: boolean
            },
            venueName: string,
            provider: string,
            revenue: number,
            bookings: number,
            commission: number,
            rawBookings: {
                revenue: number,
                commission: number,
                created_at: string
            }[]
          }>, {
            from: string,
            to: string,
            fixedPeriod: '12months' | '6months',
            dateBooked: string,
            venue_id: string
        }>({
            query: (arg) => {
                return ({
                    url: `ticketing/report?${new URLSearchParams(arg).toString()}`,
                    method: 'GET',
                })
            }
        }),

        createSignature: builder.mutation<{ envelopeId: string, redirectUrl: string }, { name: string, email: string, url: string }>({
            query: (arg) => {
                return ({
                    url: `signature/create`,
                    method: 'POST',
                    body: arg
                })
            }
        }),

        getBrands: builder.query<ObjectionResponse<Brands>, ListQuery | void>({
            query: (query) => `brands${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0,
            providesTags: ['Brands'],
        }),
        getBrand: builder.query<Brands, { id: string }>({
            query: (arg) => {
                return ({
                    url: `brands/${arg.id}`,
                    method: "GET",
                })
            },
        }),
        deleteBrands: builder.mutation<{}, { id: string }>({
            query: (arg) => {
                return ({
                    url: `brands`,
                    method: "DELETE",
                    body: {
                        id: arg.id,
                    }
                })
            },
            invalidatesTags: ['Brands']
        }),
        addBrand: builder.mutation<DeepPartial<CreateBrandPayload> & { id: string }, DeepPartial<CreateBrandPayload> & { id?: string }>({
            query: (arg) => {
                return ({
                    url: `brands`,
                    method: "POST",
                    body: arg
                })
            },
            invalidatesTags: ['Brands']
        }),
        adminContentBlogs: builder.query<BlogsResponse, ListQuery | void>({
            query: (query) => `fcn/admin/blogs${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            providesTags: ["Blogs"],
            keepUnusedDataFor: 0
        }),
        pendingConentBlogs: builder.query<BlogsResponse, void>({
            query: (query) => `fcn/blogs?pendingApproval=true`,
            providesTags: ["Blogs"],
            keepUnusedDataFor: 0
        }),
        getBlogDetails: builder.query<FcnBlogDetailsResponse, string>({
            query: (id) => `fcn/blogs/${id}/details`,
            providesTags: ["Blogs"]
        }),
        searchTown: builder.query<{name: string, priority:number}[], {query: string}>({
            query: (args) => `search/towns?${objectToQueryString(args)}`,
            keepUnusedDataFor: 0
        }),
        uploadImage: builder.mutation<{
            id: string
            public_id: string
            url: string
            cloudinary_url: string
            created_at: string
            alt_text: string
        }, FormData>({
            query: (formData) => {
                return ({
                    url: `fcn/images`,
                    method: "POST",
                    body: formData
                })
            }
        }),
        deleteImage: builder.query<boolean, string>({
            query: (id) => `fcn/images/${id}/delete`,
        }),
        content: builder.mutation<FcnBlogDetailsResponse, FcnBlogContentRequest>({
            query: (body) => {
                return ({
                    url: `fcn/blogs/content`,
                    method: "POST",
                    body
                })
            },
            invalidatesTags: ["Blogs"]
        }),
        resolveContent: builder.mutation<any, {approve: boolean, reason?: string, contentId: string}>({
            query: (body) => {
                return ({
                    url: `fcn/blogs/content/${body?.contentId}/resolve`,
                    method: "POST",
                    body
                })
            },
            invalidatesTags: ["Blogs"]
        }),
        getSiteConfig: builder.query<SiteConfigurationResponse, void>({
            query: () => `fcn/config`
        }),


        getEvents: builder.query<ObjectionResponse<CreateAmpEventsPayload>, ListQuery | void>({
            query: (query) => `events${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0,
            providesTags: ['Events'],
        }),
       addEvent: builder.mutation<CreateAmpEventsPayload & { id: string }, CreateAmpEventsPayload & { id?: string }>({
            query: (arg) => {
                return ({
                    url: `events`,
                    method: "POST",
                    body: arg
                })
            },
            invalidatesTags: ['Events']
        }),
        deleteEvent: builder.mutation<{}, { id: string }>({
            query: (arg) => {
                return ({
                    url: `events`,
                    method: "DELETE",
                    body: {
                        id: arg.id,
                    }
                })
            },
            invalidatesTags: ['Events']
        }),
        getEvent: builder.query<AmpEvents, { id: string }>({
            query: (arg) => {
                return ({
                    url: `events/${arg.id}`,
                    method: "GET",
                })
            },
        }),
        uploadImageToEvent: builder.mutation<AttractionMedia, { file: FormData }>({
            query: (arg) => {
                return ({
                    url: `events/draft/image`,
                    method: "POST",
                    body: arg.file
                })
            }
        }),


        getUserCampaignInterests: builder.query<{
            christmas_campaign_interest: boolean | null
            all_campaigns_interest: boolean | null
        }, void>({
            query: () => {
                return ({
                    url: `users/campaign-interest`,
                    method: "GET",
                })
            },
        }),

        christmasCampaignInterest: builder.mutation<{ success: boolean }, void>({
            query: () => {
                return ({
                    url: `users/christmas-campaign-interest`,
                    method: "PATCH",
                })
            },
        }),

        allCampaignInterest: builder.mutation<{ success: boolean }, void>({
            query: () => {
                return ({
                    url: `users/all-campaign-interest`,
                    method: "PATCH",
                })
            },
        }),

        adminTicketReportingBookings: builder.query<{
            results: any[]
            total: number
        }, ListQuery & {venue_id?: string, provider?: string}>({
            query: (query) => {
                return ({
                    url: `ticketing-reporting/bookings${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
                    method: "GET",
                })
            },
        }),
        getVenuesWithTickets: builder.query<AttractionDetails[], void>({
            query: () => {
                return ({
                    url: `ticketing/venues-with-tickets`,
                    method: "GET",
                })
            },
        }),
    })
})