import { type ReactNode } from "react";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  children: ReactNode;
  scale?: 'sm' | 'base' | 'none',
  active?: boolean,
}

export function OutlineButton({
  type = "button",
  children,
  className = "",
  scale = 'base',
  active = false,
  ...args
}: ButtonProps) {

  const size = scale === 'none' ? '' : scale === 'base' ? 'h-[52px] text-base p-4 w-full' : 'h-9 text-sm px-10 leading-[16px] w-full'

  return (
    <>
      <button
        type={type}
        className={`flex items-center justify-center rounded-lg text-black font-bold border hover:bg-[#F2F2F2] hover:border-[#F2F2F2] transition-colors ${active ? 'bg-[#F2F2F2] border-[#F2F2F2]' : 'border-[#CFDBD5]'} ${size} ${className ?? ''}`}
        {...args}
      >
        {children}
      </button>
    </>
  );
}
