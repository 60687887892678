import React, { useEffect, useRef, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Table from "../../../lib/react-table/Table";
import Footer from "../../../components/global/amp/Footer";
import moment from "moment";
import { apiSlice } from "../../../store/apiSlice";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { Button } from "../../../components/global/Button";
import { TableText } from "../../../components/global/TableText";
import Header from "../../../components/global/amp/Header";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const AttractionTickets: React.FC = () => {
  const { attractionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [getQuerySortBy, setQuerySortBy] = useState<string>("venue");
  const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
  const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");
  const [getFilter, setFilter] = useState<'all' | 'active' | 'inactive' | 'mapped' | 'unmapped'>('all')

  const prevQuerySearch = useRef(getQuerySearchQuery);
  const { data: tableData, isFetching } = apiSlice.useGetTicketListQuery({
    pageSize: getQueryPageSize,
    page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
    sortBy: getQuerySortBy,
    query: getQuerySearchQuery,
    sortDesc: getQuerySortDesc,
    filter: getFilter,
    venueShortId: attractionId as string

  });

  const data = React.useMemo<TicketDetails[]>(
    () => tableData?.results ?? ([] as TicketDetails[]),
    [tableData]
  );

  const columns = React.useMemo<Column<TicketDetails>[]>(
    () => [
      {
        Header: "Ticket Name",
        accessor: "name",
        id: "name",
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return (
            <>
              {original.name}
            </>
          );
        },
        disableSortBy: true,
        sortType: (a: any, b: any) => {
          if (a.original?.name > b.original?.name) return 1;
          if (b.original?.name > a.original?.name) return -1;
          return 0;
        },
        width: "65%",
      },

      {
        Header: "Updated At",
        accessor: "updated_at",
        id: "updated_at",
        disableSortBy: true,
        sortType: (a: any, b: any) => {
          const previousRow = a.original.updated_at;
          const nextRow = b.original.updated_at;

          if (previousRow < nextRow) {
            return 1;
          } else {
            return -1;
          }
        },
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return (
            <>
              <TableText className="min-w-[8rem]">
                {moment(original.updated_at).format("DD MMM YYYY")}
              </TableText>
            </>
          );
        },
        width: "10%",
      },
      {
        Header: "",
        accessor: "id",
        id: "actions",
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return (
            <div className="flex justify-center text-sm">
              <Link to={`/attractions/${attractionId}/ticketing/create-old/${original.id}`} className="mr-1">
                <Button>Edit Ticket</Button>
              </Link>
              <Link to={`/attractions/${attractionId}/ticketing/seasonal-pricing/${original.id}`}>
                <Button>Seasonal Pricing</Button>
              </Link>
            </div>
          );
        },
        disableGlobalFilter: true,
        width: "25%",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    gotoPage,
    setPageSize,
    setSortBy,
    state: { pageSize, pageIndex, sortBy, globalFilter },
  } = useTable<TicketDetails>(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: tableData?.total,
      disableSortRemove: true,
      initialState: {
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        pageIndex: parseInt(getQueryPage),
        globalFilter: searchParams.get("query") || getQuerySearchQuery,
        sortBy: [
          {
            id: searchParams.get("sortBy") || getQuerySortBy,
            desc:
              searchParams.get("sortDesc") === "true" ||
              getQuerySortDesc !== "false",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<TicketDetails>;

  useEffect(() => {
    document.title = "Ticketing | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (getQuerySearchQuery !== prevQuerySearch.current) {
      setQueryPage("0");
      gotoPage(0);

      prevQuerySearch.current = getQuerySearchQuery;
    }
  }, [getQuerySearchQuery]);
  useEffect(() => {
    if (!isFetching) {
      setSearchParams(
        {
          pageSize: pageSize.toString(),
          page: pageIndex.toString(),
          query: globalFilter ? globalFilter : "",
          sortBy: sortBy[0].id.toLowerCase(),
          sortDesc: sortBy[0].desc!.toString(),
        },
        { replace: JSON.stringify(searchParams) === "{}" }
      );

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySearchQuery(globalFilter ? globalFilter : "");

      prevQuerySearch.current = getQuerySearchQuery;
    } else {
    }
  }, [
    pageSize,
    pageIndex,
    sortBy[0].id,
    globalFilter,
    sortBy[0].desc,
    isFetching,
  ]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA]">
      <Header
        title="Tickets"
        mobilePublishButtons={false}
        showPublishingButtons={false}
      />

      <SingleAttractionTemplate name={""}>
        <DashboardHeaderContainer>
          <div className="mx-auto flex mr-0">
            <Link to={`/attractions/${attractionId}/ticketing/products`} className="mr-1">
              <div className="ml-auto">
                <Button>Products</Button>
              </div>
            </Link>
            <Link to="/ticketing/scan" className="mr-1">
              <div className="ml-auto">
                <Button>Scan Ticket</Button>
              </div>
            </Link>
            <Link to={`/attractions/${attractionId}/ticketing/create-old`} className="mr-1">
              <div className="ml-auto">
                <Button>New Ticket</Button>
              </div>
            </Link>
            <Link to={`/attractions/${attractionId}/ticketing/availability-periods`} className="ml-auto">
              <div className="ml-auto">
                <Button>Availability</Button>
              </div>
            </Link>
          </div>
        </DashboardHeaderContainer>
        <section className="flex flex-col px-[70px]">
          <div id="attractionsTable" className="mt-5 max-w-[calc(100vw-3rem)]">
            <Table<TicketDetails>
              // <table id>
              tableId="attractionsTable"
              // <table className>
              tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
              // <thead className>
              tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // <tbody className>
              tableTbodyClassNames={`relative ${page.length === 0 ? 'h-[60px]' : ''}`}
              // Mobile <tbody className>
              tableTbodyMobileClassNames="block"
              // <tbody><tr className>
              tableTbodyTrClassNames="h-1 last-of-type:hidden"
              // Empty <tbody><tr className>
              tableTbodyTrEmptyMobileClassNames="border-none"
              // Mobile <tbody><tr className>
              tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative shadow-cs-admin-table-td overflow-hidden border-2 border-cs-off-white font-sm font-normal text-cs-gray rounded-xl p-3"
              // <tbody><tr><td className>
              tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // Empty <tbody><tr><td className>
              tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
              // Mobile <tbody><tr><td className>
              tableTbodyTrEmptyTdMobileClassNames="h-2"
              // Hide Columns On Mobile
              hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
              // react-table props
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              pages={tableData?.total}
              prepareRow={prepareRow}
              pageIndex={pageIndex}
              pageSize={pageSize}
              pageOptions={pageOptions}
              gotoPage={gotoPage}
              setPageSize={setPageSize}
              isFetching={isFetching}
            />
          </div>
        </section>
      </SingleAttractionTemplate>
      <Footer />
    </div>
  );
};

export default AttractionTickets;
