import React, { useEffect, useState } from "react";
import { z } from "zod";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { useParams } from "react-router-dom";
import { apiSlice } from "../../../store/apiSlice";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ImageUpload from "../../../components/form/ImageUpload";
import { useApiError } from "../../../hooks/useApiError";
import { base64ToFile, FcnBlogContentRequestInterfaceKeys, fetchFileData, fileToDataString, filterProperties } from "../../../helpers/media";
import DiaryDetailsHeader from "./DiaryDetailsHeader";
import { FcnBlogContentRequest } from "../../../../../client-core/src/types/blog";

export const mediaJsonSchema = {
    data: z.string().min(1, "Image data is required"),
    id: z.string().optional(),
    caption: z.string().optional()
}

export const optionalMediaJsonSchema = {
    data: z.string().optional(),
    id: z.string().optional(),
    caption: z.string().optional()
}

interface MediaDbObject {
    id: string
    public_id: string
    url: string
    cloudinary_url: string
    created_at: string
    alt_text: string
}

export type MediaArrayItem = Partial<MediaDbObject> & {
    data?: string // base64 of new image
    caption?: string | null
}

const schema = z.object({
    first_half_summary: z.string().optional(),
    second_half_summary: z.string().optional(),
    food_drink_summary: z.string().optional(),
    first_half_images_json: z.array(z.object(optionalMediaJsonSchema)).min(3, { message: "At least 3 images are required" })
    .superRefine((images, context) => {
      const validImagesCount = images.filter(image => image.data && image.data.trim() !== "").length;
  
      if (validImagesCount < 3) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "At least 3 images must have valid data",
        });
      }
    }),
    first_half_image_1_id: z.string().optional(),
    first_half_caption_1: z.string().optional(),
    first_half_image_2_id: z.string().optional(),
    first_half_caption_2: z.string().optional(),
    first_half_image_3_id: z.string().optional(),
    first_half_caption_3: z.string().optional(),
    first_half_image_4_id: z.string().optional(),
    first_half_caption_4: z.string().optional(),
    second_half_images_json: z.object(mediaJsonSchema).array().min(4, { message: "At least 4 images are required"}),
    second_half_image_1_id: z.string().optional(),
    second_half_caption_1: z.string().optional(),
    second_half_image_2_id: z.string().optional(),
    second_half_caption_2: z.string().optional(),
    second_half_image_3_id: z.string().optional(),
    second_half_caption_3: z.string().optional(),
    second_half_image_4_id: z.string().optional(),
    second_half_caption_4: z.string().optional(),
    food_drink_images_json: z.object(optionalMediaJsonSchema).array().min(3, { message: "At least 3 images are required" })
    .superRefine((images, context) => {
      const validImagesCount = images.filter(image => image.data && image.data.trim() !== "").length;
  
      if (validImagesCount < 3) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "At least 3 images must have valid data",
        });
      }
    }),
    food_drink_image_1_id: z.string().optional(),
    food_drink_caption_1: z.string().optional(),
    food_drink_image_2_id: z.string().optional(),
    food_drink_caption_2: z.string().optional(),
    food_drink_image_3_id: z.string().optional(),
    food_drink_caption_3: z.string().optional(),
    food_drink_image_4_id: z.string().optional(),
    food_drink_caption_4: z.string().optional(),
});

type DiaryForm = z.infer<typeof schema>;

const DiaryDayOutBreakdown: React.FC = () => {
    const { blogId } = useParams();
    const { data, isLoading } = apiSlice.useGetBlogDetailsQuery(blogId ?? "");
    const [uploadContent, { isLoading: isLoadingContent, error }] = apiSlice.useContentMutation();
    const [uploadMedia, {isLoading: isLoadingUploadMedia}] = apiSlice.useUploadImageMutation();
    const [deleteMedia] = apiSlice.useLazyDeleteImageQuery();
    const [resolveContent, {isLoading: isLoadingResolve, error: resolveError}] = apiSlice.useResolveContentMutation();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [defaultValuesData, setDefaultValuesData] = useState<any>();
    const [resolveType, setResolveType] = useState<string>("");
    const contentError = useApiError(error);
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { isValid, isSubmitted, errors, isDirty },
        control,
        setValue,
        getValues,
        reset,
        trigger: triggerValidation,
        clearErrors,
        watch
    } = useForm<DiaryForm>({
        resolver: async (data, context, options) => {
        return zodResolver(schema)(data, context, options);
        },
        defaultValues: {}
    });

    const firstHalfSummary = watch('first_half_summary', '') ?? "";
    const secondHalfSummary = watch('second_half_summary', '') ?? "";
    const foodDrinkSummary = watch('food_drink_summary', '') ?? "";

    const firstHalfCaption1 = watch('first_half_images_json.0.caption', '') ?? "";
    const firstHalfCaption2 = watch('first_half_images_json.1.caption', '') ?? "";
    const firstHalfCaption3 = watch('first_half_images_json.2.caption', '') ?? "";
    const firstHalfCaption4 = watch('first_half_images_json.3.caption', '') ?? "";

    const secondHalfCaption1 = watch('second_half_images_json.0.caption', '') ?? "";
    const secondHalfCaption2 = watch('second_half_images_json.1.caption', '') ?? "";
    const secondHalfCaption3 = watch('second_half_images_json.2.caption', '') ?? "";
    const secondHalfCaption4 = watch('second_half_images_json.3.caption', '') ?? "";

    const foodDrinkCaption1 = watch('food_drink_images_json.0.caption', '') ?? "";
    const foodDrinkCaption2 = watch('food_drink_images_json.1.caption', '') ?? "";
    const foodDrinkCaption3 = watch('food_drink_images_json.2.caption', '') ?? "";
    const foodDrinkCaption4 = watch('food_drink_images_json.3.caption', '') ?? "";

    const clearErrorsOnChange = (fieldName: string) => {
		clearErrors(fieldName as any);
	};

    const onSaveMedia = async (key: string, max: number, media: MediaArrayItem[], oldMedia?: MediaArrayItem[]) => {
        // example implementation of images in AttractionImages.tsx

        let form: { [key: string]: string | undefined | null } = {};

        // set all media & caption fields null to be re-set using media array
        for(let i = 0; i < max; i++){
            form[`${key}_${i + 1}_id`] = null;
            form[`${key}_caption_${i + 1}`] = null;
        }

        // loop through media array, upload if needed and set new positions
        for(let i = 0; i < media.length; i++){
            const mediaObject = media[i];
            let id = mediaObject.id;
            if(mediaObject.data && !id){
                const file = await base64ToFile(mediaObject.data);
                const formData = new FormData();
                formData.append("file", file);
                let mediaResponse = await uploadMedia(formData).unwrap();
                if(mediaResponse?.id){
                    if(key === "favourite_image"){
                        form[`favourite_image_id`] = mediaResponse?.id;
                    }else if(key === "family_creator_image"){
                        form[`family_creator_image_id`] = mediaResponse?.id;
                    }else{
                        form[`${key}_${i + 1}_id`] = mediaResponse?.id;
                    }
                }
            }else{
                if(key === "favourite_image"){
                    form[`favourite_image_id`] = id;
                }else if(key === "family_creator_image"){
                    form[`family_creator_image_id`] = id;
                }else{
                    form[`${key}_${i + 1}_id`] = id;
                }
            }
            form[`${key}_caption_${i + 1}`] = mediaObject.caption;
        }

        // find media which has been deleted and call the delete API
        if(oldMedia){
            const deletedMedia = oldMedia.filter((v) => !media.some((m) => m.id == v.id));
            for(let i = 0; i < deletedMedia.length; i++){
                const deletedMediaObject = deletedMedia[i];
                if(deletedMediaObject?.id){
                    await deleteMedia(deletedMediaObject?.id ?? "")
                }
            }
        }
        return form;
    }

    const onSubmit: SubmitHandler<DiaryForm> = async () => {
        const formValues = { 
            ...getValues()
        };
        setIsDisabled(true);
        const contentUpdateRequest: any = {
            ...formValues,
            ...(await onSaveMedia("first_half_image", 4, formValues.first_half_images_json, defaultValuesData?.first_half_images_json)),
            ...(await onSaveMedia("second_half_image", 4, formValues.second_half_images_json, defaultValuesData?.second_half_images_json)),
            ...(await onSaveMedia("food_drink_image", 4, formValues.food_drink_images_json, defaultValuesData?.food_drink_images_json)),
        }  
        const filteredPayload = filterProperties<FcnBlogContentRequest>(contentUpdateRequest, FcnBlogContentRequestInterfaceKeys);

        await uploadContent({
            ...filteredPayload,
            blog_id: data?.blog_id,
            content_id: data?.last_modified_content?.content_id,
        });
        setIsDisabled(false);
    };

    const handleResolveContent = async (type: string) => {
        setResolveType(type);
        if(type === "approve"){
            await resolveContent({
                approve: true,
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else if(type === "deny"){
            await resolveContent({
                approve: false,
                reason: "",
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else{
            console.log("No resolve type selected")
        }
    }

    const handlePreviewContent = async () => {
        setIsLoadingPreview(true);
        try {
          const response = await fetch(`/api/amp/v1/fcn/blogs/content/${data?.last_modified_content?.content_id}/preview`);
          const url = await response.text();
          if(url){
            window.open(url, '_blank', 'noopener,noreferrer');
          }
        } catch (err) {
            console.log("Preview Error: ", err)
        } finally{
            setIsLoadingPreview(false);
        }
    };

    const countWords = (text: string) => {
        if(!text){
            return 0;
        }
        return text.trim().split(/\s+/).length;
    };

    useEffect(() => {
        async function fetchBlogDetails(){
            if(data){
              const { 
                    first_half_image_1, first_half_image_2, first_half_image_3, first_half_image_4,
                    first_half_image_caption_1, first_half_image_caption_2, first_half_image_caption_3, first_half_image_caption_4, 

                    second_half_image_1, second_half_image_2, second_half_image_3, second_half_image_4,
                    second_half_image_caption_1, second_half_image_caption_2, second_half_image_caption_3, second_half_image_caption_4, 

                    food_drink_image_1, food_drink_image_2, food_drink_image_3, food_drink_image_4,
                    food_drink_image_caption_1, food_drink_image_caption_2, food_drink_image_caption_3, food_drink_image_caption_4, 
                } = data?.last_modified_content ?? {};

                if(first_half_image_1){
                    const data = await fetchFileData(first_half_image_1.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("first_half_images_json.0.data", urlString);
                    setValue("first_half_images_json.0.id", first_half_image_1?.id);
                    setValue("first_half_images_json.0.caption", first_half_image_caption_1 ?? "");
                    setValue("first_half_image_1_id", first_half_image_1?.id);
                }
                if(first_half_image_2){
                    const data = await fetchFileData(first_half_image_2.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("first_half_images_json.1.data", urlString);
                    setValue("first_half_images_json.1.id", first_half_image_2?.id);
                    setValue("first_half_images_json.1.caption", first_half_image_caption_2 ?? "");
                    setValue("first_half_image_2_id", first_half_image_2?.id);
                }
                if(first_half_image_3){
                    const data = await fetchFileData(first_half_image_3.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("first_half_images_json.2.data", urlString);
                    setValue("first_half_images_json.2.id", first_half_image_3?.id);
                    setValue("first_half_images_json.2.caption", first_half_image_caption_3 ?? "");
                    setValue("first_half_image_3_id", first_half_image_3?.id);
                }
                if(first_half_image_4){
                    const data = await fetchFileData(first_half_image_4.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("first_half_images_json.3.data", urlString);
                    setValue("first_half_images_json.3.id", first_half_image_4?.id);
                    setValue("first_half_images_json.3.caption", first_half_image_caption_4 ?? "");
                    setValue("first_half_image_4_id", first_half_image_4?.id);
                }

                if(second_half_image_1){
                    const data = await fetchFileData(second_half_image_1.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("second_half_images_json.0.data", urlString);
                    setValue("second_half_images_json.0.id", second_half_image_1?.id);
                    setValue("second_half_images_json.0.caption", second_half_image_caption_1 ?? "");
                    setValue("second_half_image_1_id", second_half_image_1?.id);
                }
                if(second_half_image_2){
                    const data = await fetchFileData(second_half_image_2.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("second_half_images_json.1.data", urlString);
                    setValue("second_half_images_json.1.id", second_half_image_2?.id);
                    setValue("second_half_images_json.1.caption", second_half_image_caption_2 ?? "");
                    setValue("second_half_image_2_id", second_half_image_2?.id);
                }
                if(second_half_image_3){
                    const data = await fetchFileData(second_half_image_3.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("second_half_images_json.2.data", urlString);
                    setValue("second_half_images_json.2.id", second_half_image_3?.id);
                    setValue("second_half_images_json.2.caption", second_half_image_caption_3 ?? "");
                    setValue("second_half_image_3_id", second_half_image_3?.id);
                }
                if(second_half_image_4){
                    const data = await fetchFileData(second_half_image_4.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("second_half_images_json.3.data", urlString);
                    setValue("second_half_images_json.3.id", second_half_image_4?.id);
                    setValue("second_half_images_json.3.caption", second_half_image_caption_4 ?? "");
                    setValue("second_half_image_4_id", second_half_image_4?.id);
                }

                if(food_drink_image_1){
                    const data = await fetchFileData(food_drink_image_1.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("food_drink_images_json.0.data", urlString);
                    setValue("food_drink_images_json.0.id", food_drink_image_1?.id);
                    setValue("food_drink_images_json.0.caption", food_drink_image_caption_1 ?? "");
                    setValue("food_drink_image_1_id", food_drink_image_1?.id);
                }
                if(food_drink_image_2){
                    const data = await fetchFileData(food_drink_image_2.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("food_drink_images_json.1.data", urlString);
                    setValue("food_drink_images_json.1.id", food_drink_image_2?.id);
                    setValue("food_drink_images_json.1.caption", food_drink_image_caption_2 ?? "");
                    setValue("food_drink_image_2_id", food_drink_image_2?.id);
                }
                if(food_drink_image_3){
                    const data = await fetchFileData(food_drink_image_3.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("food_drink_images_json.2.data", urlString);
                    setValue("food_drink_images_json.2.id", food_drink_image_3?.id);
                    setValue("food_drink_images_json.2.caption", food_drink_image_caption_3 ?? "");
                    setValue("food_drink_image_3_id", food_drink_image_3?.id);
                }
                if(food_drink_image_4){
                    const data = await fetchFileData(food_drink_image_4.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("food_drink_images_json.3.data", urlString);
                    setValue("food_drink_images_json.3.id", food_drink_image_4?.id);
                    setValue("food_drink_images_json.3.caption", food_drink_image_caption_4 ?? "");
                    setValue("food_drink_image_4_id", food_drink_image_4?.id);
                }


                setValue("first_half_summary", data?.last_modified_content?.first_half_summary ?? "");
                setValue("second_half_summary", data?.last_modified_content?.second_half_summary ?? "");
                setValue("food_drink_summary", data?.last_modified_content?.food_drink_summary ?? "");
                const formValues = getValues();
                const clonedFormValues = JSON.parse(JSON.stringify(formValues));
                setDefaultValuesData(clonedFormValues);
            }
        }
        fetchBlogDetails();
    }, [data]);

    return(
        <div className={`flex flex-col flex-1 bg-[#F5F5FA] doodle-bg ${isLoading && "blur-sm"}`}>
            <DashboardHeaderContainer slim={true}>
            {
                blogId &&
                <DiaryDetailsHeader
                    blogId={blogId}
                />
            }
            </DashboardHeaderContainer>
            <DashboardContainer>
                <form className={`z-10 ${false ? "blur-sm" : ""}`} onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-x-3 px-5 xl:px-[20px] w-full">
                        <div className="w-full p-6 mb-6 bg-white rounded-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/activity.png" alt="activity"/>
                                {"The first half of your day out"}
                            </h1>
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                    Talk us through the first half of your visit. What were your first activities, and how did the day begin to unfold? 
                                </label>
                                <Controller
                                    name="first_half_summary"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 200 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="first_half_summary"
                                            rows={2}
                                            className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                            placeholder="For example: We started by checking the weather forecast and packing accordingly. We made a checklist of essentials like snacks, sunscreen, and hats. We also looked up the attraction’s website for any special tips and packed a few extra items just in case"
                                            maxLength={200}
                                        />
                                    )}
                                />
                                <p className="text-sm text-cs-gray text-end">{countWords(firstHalfSummary)} / 200 words</p>
                            </div>
                            <div>
                                <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.first_half_images_json ? "text-cs-red" : ""}`}>
                                    Share some photos of your family travelling to the attraction!
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <div className="text-sm font-normal text-cs-gray my-1.5">Add a minimum of 3 photos.</div>
                                <div className="flex flex-wrap gap-3 mt-2">
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="first_half_images_json.0.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.first_half_images_json && errors?.first_half_images_json[0]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaText="Drop image here"
                                            dropAreaSubText="Upload image"
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="first_half_images_json.0.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="first_half_images_json.0.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{firstHalfCaption1.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="first_half_images_json.1.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.first_half_images_json && errors?.first_half_images_json[1]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="first_half_images_json.1.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="first_half_images_json.1.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{firstHalfCaption2.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="first_half_images_json.2.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.first_half_images_json && errors?.first_half_images_json[2]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="first_half_images_json.2.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="first_half_images_json.2.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{firstHalfCaption3.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="first_half_images_json.3.data"
                                            setValue={setValue}
                                            className="dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted border-black text-xs"
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="first_half_images_json.3.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="first_half_images_json.3.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{firstHalfCaption4.length} / 35 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-8 mb-6 bg-white rounded-b-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/activity.png" alt="activity"/>
                                {"The second half of your day out"}
                            </h1>
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                    Next, talk through the second half of your visit. What activities did you enjoy the most as the day continued? Share any details that made the second half of your day memorable.
                                </label>
                                <Controller
                                    name="second_half_summary"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 200 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="second_half_summary"
                                            rows={2}
                                            className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                            placeholder="Share personal stories and memorable experiences from your trip."
                                            maxLength={200}
                                        />
                                    )}
                                />
                                <p className="text-sm text-cs-gray text-end">{countWords(secondHalfSummary)} / 200 words</p>
                            </div>
                            <div>
                                <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.second_half_images_json ? "text-cs-red" : ""}`}>
                                    Share some photos that capture these moments. 
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <div className="text-sm font-normal text-cs-gray my-1.5">Add a minimum of 4 photos.</div>
                                <div className="flex flex-wrap gap-3 mt-2">
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="second_half_images_json.0.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.second_half_images_json && errors?.second_half_images_json[0]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaText="Drop image here"
                                            dropAreaSubText="Upload image"
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="second_half_images_json.0.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="second_half_images_json.0.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{secondHalfCaption1.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="second_half_images_json.1.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.second_half_images_json && errors?.second_half_images_json[1]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="second_half_images_json.1.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="second_half_images_json.1.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{secondHalfCaption2.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="second_half_images_json.2.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.second_half_images_json && errors?.second_half_images_json[2]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="second_half_images_json.2.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="second_half_images_json.2.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{secondHalfCaption3.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="second_half_images_json.3.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.second_half_images_json && errors?.second_half_images_json[3]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="second_half_images_json.3.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="second_half_images_json.3.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{secondHalfCaption4.length} / 35 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-6 bg-white rounded-t-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/burger.png" alt="burger"/>
                                {"Food and drink at your day out"}
                            </h1>
                            <div className="mt-2 mb-8 text-sm font-normal text-cs-gray">If there were no food options available, or you did not stop for food, feel free to skip this section.</div>   
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                    What did you eat or drink, and how did you find it? 
                                </label>
                                <Controller
                                    name="food_drink_summary"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 200 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="food_drink_summary"
                                            rows={2}
                                            className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                            placeholder="For example: we found a few food options that we liked the sound of.I had a hot dog and a lemonade from one of the food stands, which was fairly priced. The kids had burgers and milkshakes, which were a bit on the pricier side. Overall, I’d say it’s not the best food and I’d recommend taking snacks with you."
                                            maxLength={200}
                                        />
                                    )}
                                />
                                <p className="text-sm text-cs-gray text-end">{countWords(foodDrinkSummary)} / 200 words</p>
                            </div>
                            <div>
                                <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.food_drink_images_json ? "text-cs-red" : ""}`}>
                                    Share some photos of your family travelling to the attraction!
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <div className="text-sm font-normal text-cs-gray my-1.5">Add a minimum of 3 photos.</div>
                                <div className="flex flex-wrap gap-3 mt-2">
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="food_drink_images_json.0.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.food_drink_images_json && errors?.food_drink_images_json[0]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaText="Drop image here"
                                            dropAreaSubText="Upload image"
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="food_drink_images_json.0.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="food_drink_images_json.0.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{foodDrinkCaption1.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="food_drink_images_json.1.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.food_drink_images_json && errors?.food_drink_images_json[1]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="food_drink_images_json.1.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="food_drink_images_json.1.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{foodDrinkCaption2.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="food_drink_images_json.2.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.food_drink_images_json && errors?.food_drink_images_json[2]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="food_drink_images_json.2.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="food_drink_images_json.2.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{foodDrinkCaption3.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="food_drink_images_json.3.data"
                                            setValue={setValue}
                                            className="dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted border-black text-xs"
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="food_drink_images_json.3.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="food_drink_images_json.3.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder=""
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{foodDrinkCaption4.length} / 35 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button 
                                className={`px-4 mt-4 py-2 text-sm font-bold text-white rounded-lg bg-cs-pink ${isDisabled ? "opacity-50" : "opacity-100"}`} 
                                type="submit"
                                disabled={isDisabled}
                            >
                                Save Changes
                            </button>
                            {
                                contentError &&
                                <p className="mt-2 text-sm text-cs-red">{contentError}</p>
                            }
                        </div>
                    </div>
                </form>
            </DashboardContainer>
        </div>
    )
}

export default DiaryDayOutBreakdown;