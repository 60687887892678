import { ScrollRestoration } from "react-router-dom";
import { useState } from "react";
import SubmittedModal from "../../components/global/www/modals/SubmittedModal";
import ForgottenPasswordModal from "../../components/global/www/modals/ForgotPasswordModal";
import { TwoColTemplate } from "../../components/global/TwoColTemplate";

const ForgottenPassword: React.FC = () => {
  const [showSubmittedModal, setSubmittedModal] = useState<boolean>(false);

  return (
    <>
      <ScrollRestoration />

      <TwoColTemplate
        sidebarText="Fuel your business growth with our upgrade options and unlock exclusive marketing opportunities."
        sidebarImage="/assets/www/forgot-password-image.png"
      >
        {showSubmittedModal ? (
          <SubmittedModal />
        ) : (
          <ForgottenPasswordModal setSubmittedModal={setSubmittedModal} />
        )}
      </TwoColTemplate>
    </>
  );
};

export default ForgottenPassword;
