import { useCallback, useMemo} from "react";

export const usePaginationPages = ({
  pageIndex,
  gotoPage,
  length,
  pageSize
}: {
  pageIndex: number,
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  length: number;
  pageSize: number;
}) => {
  const currentPage = pageIndex;

  const totalPages = useMemo<number>(() => {
    return Math.ceil(length / pageSize);
  }, [length, pageSize]);

  const pages = useMemo(() => {
    const totalPages = Math.ceil(length / pageSize);
    const maxPagesToShow = 10;
    let startPage = currentPage - Math.floor(maxPagesToShow / 2);
    
    if (startPage < 1) {
      startPage = 1;
    } else if (startPage + maxPagesToShow > totalPages) {
      startPage = totalPages - maxPagesToShow + 1;
    }
    
    return Array.from({ length: maxPagesToShow }, (_, index) => startPage + index).filter(n => n * pageSize <= findHighestDivisibleNumber(length, pageSize));
  }, [currentPage, length, pageSize]);

  const canGo = useMemo(() => {
    return {
      next: (currentPage + 1) < totalPages && pages.length > 0,
      previous: currentPage > 0
    };
  }, [currentPage, totalPages, pages.length]);
  
  const oneOfTotalPages = useMemo(() => {
    const start = Math.floor((currentPage) / 10) * 10;
    return Array.from({ length: totalPages }, (_, i) => start + i + 1);
  }, [currentPage, totalPages]);

  const goNext = useCallback(() => {
    if (canGo.next) {
      gotoPage((prev) => prev + 1);
    }
  }, [canGo, gotoPage]);

  const goPrev = useCallback(() => {
    if (canGo.previous) {
      gotoPage((prev) => prev - 1);
    }
  }, [canGo, gotoPage]);

  const goTo = (pg: number) => {
    gotoPage(pg);
  };

  return {
    canGo,
    currentPage,
    pages,
    oneOfTotalPages,
    goTo,
    goNext,
    goPrev
  };
};

function findHighestDivisibleNumber(totalItems: number, divider: number) {
  let highestDivisibleNumber = Math.ceil(totalItems / divider) * divider;
  return highestDivisibleNumber;
}