import { ReactNode, useEffect, useState } from "react";
import Header from "../../../../components/global/amp/Header";
import DashboardContainer from "../../../../components/amp/DashboardContainer";
import ReportChart from "./Chart";
import { ChartHeader } from "../../dashboard/Dashboard";
import moment from "moment";
import { apiSlice } from "../../../../store/apiSlice";
import Select from "react-select";
import SingleSelectOption from "../../../../lib/react-select/SingleSelectOption";
import Footer from "../../../../components/global/amp/Footer";
import Pagination from "../../../../components/global/amp/Pagination";
import { useParams } from "react-router-dom";

const periodOptions = Object.entries({
  "12months": "12 Months",
  "6months": "6 months",
}).map((o) => ({
  label: o[1],
  value: o[0],
}));

export default function TicketsReporting() {
  const { attractionId } = useParams();
  const { data: getAttraction } = apiSlice.useGetAttractionQuery(
    attractionId ?? ""
  );
  const venueId = (getAttraction?.result?.venue_id as string) || "";

  const [dateBooked, setDateBooked] = useState("");
  const [period, setPeriod] = useState<"12months" | "6months">("6months");
  const [validDateFrom, setValidDateFrom] = useState("");
  const [validDateTo, setValidDateTo] = useState("");

  const { data, isLoading } = apiSlice.useTicketingReportQuery(
    {
      from: validDateFrom,
      to: validDateTo,
      dateBooked: dateBooked,
      fixedPeriod: period,
      venue_id: venueId,
    },
    {
      skip: !venueId,
    }
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const { data: bookings } = apiSlice.useGetBookingsQuery(
    {
      page: page.toString(),
      pageSize: pageSize.toString(),
      from: validDateFrom,
      to: validDateTo,
      dateBooked: dateBooked,
      fixedPeriod: period,
      venue_id: venueId,
    },
    {
      skip: !venueId,
    }
  );

  useEffect(() => {
    setPage(0);
  }, [dateBooked, period, validDateFrom, validDateTo]);
  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Ticket Manager"
        mobilePublishButtons={false}
        showPublishingButtons={false}
        hideLogo={true}
      />

      <div className="h-0 xl:h-[109px]"></div>

      <Container>
        <section className={`bg-white rounded-lg xl:max-w-[calc(100vw-440px)]`}>
          <div className="text-[22px] font-extrabold leading-[26px] xl:mt-0 mt-10">
            Reporting
          </div>

          <div className="mt-[32px] flex flex-wrap gap-[30px] items-center">
            <div className="flex items-center gap-2.5">
              <label htmlFor="" className="font-normal text-base">
                Date Booked:
              </label>
              <input
                className={`p-2 border-cs-1 w-[128px] text-cs-gray text-sm rounded-lg mt-2 h-10`}
                value={moment(dateBooked).format("YYYY-MM-DD")}
                type="date"
                onChange={(event) => {
                  setDateBooked(event.target.value);
                }}
                placeholder="DD-MM-YYYY"
                max={new Date().toISOString().split("T")[0]}
              />
            </div>

            <div className="flex items-center gap-2.5">
              <label htmlFor="" className="font-normal text-base">
                Visit Date:
              </label>

              <div className="flex flex-wrap gap-2.5 items-center">
                <div className="relative">
                  <div className="absolute mx-auto inset-x-0 text-center -top-[15px] text-[#565657] text-sm font-normal">
                    From
                  </div>
                  <input
                    className={`p-2 border-cs-1 w-[128px] text-cs-gray text-sm rounded-lg mt-2 h-10`}
                    value={moment(validDateFrom).format("YYYY-MM-DD")}
                    type="date"
                    onChange={(event) => {
                      setValidDateFrom(event.target.value);
                    }}
                    placeholder="DD-MM-YYYY"
                  />
                </div>
                <div className="hidden sm:block">-</div>
                <div className="relative">
                  <div className="absolute mx-auto inset-x-0 text-center -top-[15px] text-[#565657] text-sm font-normal">
                    To
                  </div>
                  <input
                    className={`p-2 border-cs-1 w-[128px] text-cs-gray text-sm rounded-lg mt-2 h-10`}
                    value={moment(validDateTo).format("YYYY-MM-DD")}
                    type="date"
                    onChange={(event) => {
                      setValidDateTo(event.target.value);
                    }}
                    placeholder="DD-MM-YYYY"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2.5">
              <label htmlFor="" className="font-normal text-base">
                Fixed Period:
              </label>
              <div className="w-[164px]">
                <Select
                  components={{
                    Option: SingleSelectOption,
                    DropdownIndicator: () => (
                      <div className="px-2.5">
                        <svg
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    ),
                    IndicatorSeparator: () => null,
                  }}
                  value={periodOptions.find((o) => o.value === period)}
                  options={periodOptions}
                  isSearchable={true}
                  styles={{
                    menu: (styles) => ({
                      ...styles,
                      margin: 0,
                      border: "none",
                      zIndex: 15,
                    }),
                    menuList: (base, isSelected) => ({
                      ...base,
                      paddingTop: 0,
                      paddingBottom: 0,
                      background: "white",

                      "::-webkit-scrollbar": {
                        width: 14,
                      },
                      "::-webkit-scrollbar-thumb": {
                        background: "#c2c2c2",
                        borderRadius: 10,
                        border: "4px solid white",
                      },
                    }),
                    option: (
                      base,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...base,
                        background: "white",
                        border: "1px solid #c2c2c2",
                        color: isSelected ? "white" : "#555",
                        "&:hover": {
                          backgroundColor: "#6836D1",
                          color: isSelected || isFocused ? "white" : "#555",
                          border: isFocused ? "1px solid white" : "",
                        },
                        "&:active": {
                          backgroundColor: "#6836D1",
                          color: "white",
                        },
                      };
                    },
                    singleValue: (base) => ({
                      ...base,
                      color: "#555",
                      fontSize: 14,
                    }),
                    control: (styles, state) => ({
                      ...styles,
                      cursor: "pointer",
                      outline: "none",
                      border: state.isFocused
                        ? "1px solid #CED3CF"
                        : `1px solid #CED3CF`,
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #CED3CF"
                          : "1px solid #CED3CF",
                      },
                      boxShadow: "none",
                      paddingTop: 3,
                      paddingBottom: 3,
                      margin: 0,
                      minHeight: 40,
                      height: 40,
                      width: 164,
                      borderRadius: 8,
                      paddingLeft: 12,
                    }),
                  }}
                  onChange={(v) => {
                    v?.value && setPeriod(v.value as typeof period);
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </Container>
      <DashboardContainer>
        <div className="flex flex-col xl:mx-[70px] bg-white xl:rounded-lg border border-[#F1F1F1] md:overflow-x-hidden overflow-x-auto">
          <table className="border border-[#F1F1F1] text-sm min-w-[700px]">
            <thead>
              <tr>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[14.57%] rounded-tl-lg">
                  Order No.
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[23.18%]">
                  Ticket Name
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[16.56%]">
                  Date of Booking
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[16.56%]">
                  Redemption Time
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[15.89%]">
                  Lead Booker Surname
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[13.25%] rounded-tr-lg">
                  Number of Tickets
                </th>
              </tr>
            </thead>
            <tbody>
              {bookings?.results?.map((booking) => (
                <tr>
                  <td className="py-2.5 border border-[#F1F1F1] text-center">
                    {booking?.booking_reference ?? "-"}
                  </td>
                  <td className="py-2.5 border border-[#F1F1F1] text-center">
                    {booking?.ticket?.display_name ||
                      booking?.ticket?.name ||
                      "-"}
                  </td>
                  <td className="py-2.5 border border-[#F1F1F1] text-center">
                    {moment(booking.created_at).format("YYYY-MM-DD")}
                  </td>
                  <td className="py-2.5 border border-[#F1F1F1] text-center">
                    {booking.booking_status === 'checked_in' ? booking.ticket_reservations?.checked_in_at
                      ? moment(booking.ticket_reservations.checked_in_at).format(
                          "HH:mm"
                        )
                      : "-" : "-"}
                  </td>
                  <td className="py-2.5 border border-[#F1F1F1] text-center">
                    {booking.customer_details?.lastName ?? "-"}
                  </td>
                  <td className="py-2.5 border border-[#F1F1F1] text-center">
                    {booking.ticket_reservations?.quantity ?? "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-5">
          <Pagination
            pageIndex={page}
            gotoPage={(newPage) => setPage(newPage)}
            length={bookings?.total ?? 0}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>
      </DashboardContainer>
      <Container>
        <section
          className={`pb-20 bg-white rounded-lg min-h-[425px] max-w-full min-w-[400px] xl:max-w-[calc(100vw-440px)]`}
        >
          <ChartHeader title="Total Sales" />

          {!isLoading ? (
            <div className="h-[380px] relative px-5">
              <ReportChart
                // data={
                //   data
                //     ? Object.entries(data).map((d) => ({
                //         label: d[1].ticket.name,
                //         data: d[1].revenue,
                //         secondaryData: d[1].commission,
                //       }))
                //     : []
                // }
                // Show per day instead of total sales
                data={
                  data
                    ? Object.entries(data).map((d) => {
                      return d[1].rawBookings.map(b => ({
                        label: moment(b.created_at).format('YYYY MMM DD'),
                        data: b.revenue,
                        secondaryData: b.commission,
                        created_at: b.created_at
                      }))
                    }).flat().sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).reduce((acc, curr) => {
                      const existing = acc.find(item => item.label === curr.label);
                      
                      if (existing) {
                        existing.data += curr.data;
                        existing.secondaryData += curr.secondaryData;
                      } else {
                        acc.push({ ...curr });
                      }
                      
                      return acc;
                    }, [] as {
                      label: string,
                      data: number,
                      secondaryData: number,
                      created_at: string
                    }[])
                    : []
                }
                activePeriod={1}
              />
            </div>
          ) : (
            <div className="h-[380px] relative px-5 flex justify-center items-center text-sm font-light">
              {isLoading ? <LoadingIndicator /> : "No data found"}
            </div>
          )}
        </section>
      </Container>

      <Container>
        <section
          className={`pb-20 bg-white rounded-lg min-h-[425px] xl:max-w-[calc(100vw-440px)]`}
        >
          <ChartHeader title="Number of Tickets Sold Per Day" />

          {!isLoading ? (
            <div className="h-[380px] relative px-5">
              <ReportChart
                hasFilters={false}
                customTooltipLabel="Total Sold"
                // data={
                //   data
                //     ? Object.entries(data).map((d) => ({
                //         label: d[1].ticket.name,
                //         data: d[1].bookings,
                //       }))
                //     : []
                // }
                data={
                  data
                    ? Object.entries(data).map((d) => {
                      return d[1].rawBookings.map(b => ({
                        label: moment(b.created_at).format('YYYY MMM DD'),
                        data: 1,
                        created_at: b.created_at
                      }))
                    }).flat()
                    .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).reduce((acc, curr) => {
                      const existing = acc.find(item => item.label === curr.label);
                      
                      if (existing) {
                        existing.data += curr.data;
                      } else {
                        acc.push({ ...curr });
                      }
                      
                      return acc;
                    }, [] as {
                      label: string,
                      data: number,
                      created_at: string
                    }[])
                    : []
                }
                activePeriod={1}
              />
            </div>
          ) : (
            <div className="h-[380px] relative px-5 flex justify-center items-center text-sm font-light">
              {isLoading ? <LoadingIndicator /> : "No data found"}
            </div>
          )}
        </section>
      </Container>

      <div className="h-[50px]"></div>
      <Footer />
    </div>
  );
}
function Container({ children }: { children: ReactNode }) {
  return (
    <DashboardContainer>
      <section
        className={`flex flex-col  xl:mx-[70px] p-5 bg-white xl:rounded-lg`}
      >
        {children}
      </section>
    </DashboardContainer>
  );
}

const LoadingIndicator: React.FC = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-cs-pink"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
