import {type ReactNode } from 'react'

export interface iPendingClaims {
    showApprove?: boolean
    claimedBy?: string
    operatorTitle?: string | null | undefined
    alreadyClaimed?: boolean
    owners?: any[]
    managedByUsers?: any[]
    id?: number | string | undefined 
    status?: "APPROVED" | "DENIED" | null
    note?: string | null
    show?: boolean
    primaryImage?: string | null
    name?: string | null
    address?: string | null
}

const ClaimModal: React.FC<{ className?: string, setValue: any, open: iPendingClaims, setOpen: React.Dispatch<React.SetStateAction<iPendingClaims>>, children: ReactNode }> = ({ setValue, className, children, open, setOpen }) => {
    
    const clickOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        
        if ((event.target as HTMLElement).id === 'modalOverlay') {
            setValue("note", "")
            setOpen({alreadyClaimed: false, claimedBy: "", operatorTitle: "", owners: [], managedByUsers: [], showApprove: false, show: false, id: "", primaryImage: "", name: "", address: ""})
        }
    }

    return (
        <>
        {open.show && <div id="modalOverlay" className="fixed w-full h-full top-0 left-0 z-30 flex flex-col before:content-[''] before:bg-opacity-50 before:bg-black before:w-full before:h-full before:fixed" onClick={clickOverlay}>
            <div className={`absolute flex max-h-[80%] top-1/2 inset-x-0 mx-auto -translate-y-1/2 max-w-full ${className}`}>
                <button type="button" className="absolute right-0 ml-auto -top-16 bg-white flex justify-center items-center rounded-full font-bold z-40 w-14 h-14 text-2xl text-cs-pink" onClick={() => setOpen({claimedBy: "", operatorTitle: "", alreadyClaimed: false, status: "DENIED", showApprove: false, show: false, id: "", primaryImage: "/assets/amp/dowtk-mono.png", name: "", address: ""})}>X</button>
                <div className="flex flex-col items-center w-full px-32 py-10 bg-white rounded-lg">
                    {children}
                </div>
            </div>
        </div>}
        {open.showApprove && <div id="modalOverlay" className="fixed w-full h-full top-0 left-0 z-[101] flex flex-col before:content-[''] before:bg-opacity-50 before:bg-black before:w-full before:h-full before:fixed" onClick={clickOverlay}>
            <div className={`absolute flex max-h-[80%] top-1/2 inset-x-0 mx-auto -translate-y-1/2 max-w-full ${className}`}>
                <button type="button" className="absolute right-0 ml-auto -top-16 bg-white flex justify-center items-center rounded-full font-bold z-40 w-14 h-14 text-2xl text-cs-pink" onClick={() => setOpen({claimedBy: "", operatorTitle: "", alreadyClaimed: false, status: "APPROVED", showApprove: false, show: false, id: "", primaryImage: "/assets/amp/dowtk-mono.png", name: "", address: ""})}>X</button>
                <div className="flex flex-col items-center w-full px-32 py-10 bg-white rounded-lg">
                    {children}
                </div>
            </div>
        </div>}
        </>
    )
}

export default ClaimModal;