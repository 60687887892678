import React, { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { useParams } from "react-router-dom";
import { apiSlice } from "../../../store/apiSlice";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ImageUpload from "../../../components/form/ImageUpload";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import ButtonMultiSelect from "../../../components/form/ButtonMultiSelect";
import { queueTime, travelMethods, travelTimes } from "../../../helpers/constants";
import TownInput from "../../../components/form/TownInput";
import { debounce } from "../../../helpers/debounce";
import { XIcon } from "../../../components/misc/Icons";
import { useApiError } from "../../../hooks/useApiError";
import { base64ToFile, FcnBlogContentRequestInterfaceKeys, fetchFileData, fileToDataString, filterProperties } from "../../../helpers/media";
import DiaryDetailsHeader from "./DiaryDetailsHeader";
import { FcnBlogContentRequest } from "../../../../../client-core/src/types/blog";

export const mediaJsonSchema = {
    data: z.string().min(1, "Image data is required"),
    id: z.string().optional(),
    caption: z.string().optional()
}

interface MediaDbObject {
    id: string
    public_id: string
    url: string
    cloudinary_url: string
    created_at: string
    alt_text: string
}

export type MediaArrayItem = Partial<MediaDbObject> & {
    data?: string // base64 of new image
    caption?: string | null
}

const schema = z.object({
    travel_time: z.string().min(1, "Travel time is required"),
    travel_method: z.string().min(1, "Travel method is required"),
    travel_origin: z.string().min(1, "Travel from is required"),
    travel_summary: z.string().min(1, "Travel summary is required"),
    parking_price: z.string().nullable().refine(value => value !== undefined, {
        message: "Parking price is required",
    }),
    free_parking: z.boolean().optional(),
    queue_time: z.string().optional(),
    arrival_summary: z.string().optional(),
    travel_images_json: z.object(mediaJsonSchema).array().min(4, { message: "At least 4 images are required"}),
    travel_image_1_id: z.string().optional(),
    travel_image_caption_1: z.string().optional(),
    travel_image_2_id: z.string().optional(),
    travel_image_caption_2: z.string().optional(),
    travel_image_3_id: z.string().optional(),
    travel_image_caption_3: z.string().optional(),
    travel_image_4_id: z.string().optional(),
    travel_image_caption_4: z.string().optional(),
    arrival_images_json: z.object(mediaJsonSchema).array().min(4, { message: "At least 4 images are required"}),
    arrival_image_1_id: z.string().optional(),
    arrival_image_caption_1: z.string().optional(),
    arrival_image_2_id: z.string().optional(),
    arrival_image_caption_2: z.string().optional(),
    arrival_image_3_id: z.string().optional(),
    arrival_image_caption_3: z.string().optional(),
    arrival_image_4_id: z.string().optional(),
    arrival_image_caption_4: z.string().optional()
});

type DiaryForm = z.infer<typeof schema>;

const DiaryTravelling: React.FC = () => {
    const { blogId } = useParams();
    const { data, isLoading } = apiSlice.useGetBlogDetailsQuery(blogId ?? "");
    const [searchTown, {isLoading: isLoadingSearchTown}] = apiSlice.useLazySearchTownQuery();
    const [filteredCities, setFilteredCities] = useState<{name: string, priority: number}[]>([]);
    const [uploadContent, { isLoading: isLoadingContent, error }] = apiSlice.useContentMutation();
    const [uploadMedia, {isLoading: isLoadingUploadMedia}] = apiSlice.useUploadImageMutation();
    const [deleteMedia] = apiSlice.useLazyDeleteImageQuery();
    const [resolveContent, {isLoading: isLoadingResolve, error: resolveError}] = apiSlice.useResolveContentMutation();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [defaultValuesData, setDefaultValuesData] = useState<any>();
    const contentError = useApiError(error);
    const [resolveType, setResolveType] = useState<string>("");
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { isValid, isSubmitted, errors, isDirty },
        control,
        setValue,
        getValues,
        reset,
        trigger: triggerValidation,
        clearErrors,
        watch
    } = useForm<DiaryForm>({
        resolver: async (data, context, options) => {
        return zodResolver(schema)(data, context, options);
        },
        defaultValues: {
        },
    });
    const cityValue = watch("travel_origin", "");
    const travelSummary = watch('travel_summary', '');
    const travelCaption1 = watch('travel_images_json.0.caption', '') ?? "";
    const travelCaption2 = watch('travel_images_json.1.caption', '') ?? "";
    const travelCaption3 = watch('travel_images_json.2.caption', '') ?? "";
    const travelCaption4 = watch('travel_images_json.3.caption', '') ?? "";

    const arrivalCaption1 = watch('arrival_images_json.0.caption', '') ?? "";
    const arrivalCaption2 = watch('arrival_images_json.1.caption', '') ?? "";
    const arrivalCaption3 = watch('arrival_images_json.2.caption', '') ?? "";
    const arrivalCaption4 = watch('arrival_images_json.3.caption', '') ?? "";
    const freeParking = watch('free_parking', false)
    const arrivalSummary = watch('arrival_summary', '') ?? "";

    const onSaveMedia = async (key: string, max: number, media: MediaArrayItem[], oldMedia?: MediaArrayItem[]) => {
        // example implementation of images in AttractionImages.tsx

        let form: { [key: string]: string | undefined | null } = {};

        // set all media & caption fields null to be re-set using media array
        for(let i = 0; i < max; i++){
            form[`${key}_${i + 1}_id`] = null;
            form[`${key}_caption_${i + 1}`] = null;
        }

        // loop through media array, upload if needed and set new positions
        for(let i = 0; i < media.length; i++){
            const mediaObject = media[i];
            let id = mediaObject.id;
            if(mediaObject.data && !id){
                const file = await base64ToFile(mediaObject.data);
                const formData = new FormData();
                formData.append("file", file);
                let mediaResponse = await uploadMedia(formData).unwrap();
                if(mediaResponse?.id){
                    if(key === "favourite_image"){
                        form[`favourite_image_id`] = mediaResponse?.id;
                    }else if(key === "family_creator_image"){
                        form[`family_creator_image_id`] = mediaResponse?.id;
                    }else{
                        form[`${key}_${i + 1}_id`] = mediaResponse?.id;
                    }
                }
            }else{
                if(key === "favourite_image"){
                    form[`favourite_image_id`] = id;
                }else if(key === "family_creator_image"){
                    form[`family_creator_image_id`] = id;
                }else{
                    form[`${key}_${i + 1}_id`] = id;
                }
            }
            form[`${key}_caption_${i + 1}`] = mediaObject.caption;
        }

        // find media which has been deleted and call the delete API
        if(oldMedia){
            const deletedMedia = oldMedia.filter((v) => !media.some((m) => m.id == v.id));
            for(let i = 0; i < deletedMedia.length; i++){
                const deletedMediaObject = deletedMedia[i];
                if(deletedMediaObject?.id){
                    await deleteMedia(deletedMediaObject?.id ?? "")
                }
            }
        }
        return form;
    }

    const onSubmit: SubmitHandler<DiaryForm> = async () => {
        const formValues = { 
            ...getValues()
        };
        setIsDisabled(true);
        const contentUpdateRequest: any = {
            ...formValues,
            ...(await onSaveMedia("travel_image", 4, formValues.travel_images_json, defaultValuesData?.travel_images_json)),
            ...(await onSaveMedia("arrival_image", 4, formValues.arrival_images_json, defaultValuesData?.arrival_images_json)),
        }  
        const filteredPayload = filterProperties<FcnBlogContentRequest>(contentUpdateRequest, FcnBlogContentRequestInterfaceKeys);
  
        await uploadContent({
            ...filteredPayload,
            blog_id: data?.blog_id,
            content_id: data?.last_modified_content?.content_id,
            parking_price: getValues("parking_price") ? Number(getValues("parking_price")) : null,
        });
        setIsDisabled(false);
    };

    const clearErrorsOnChange = (fieldName: string) => {
		clearErrors(fieldName as any);
	};

    const countWords = (text: string) => {
        if(!text){
            return 0;
        }
        return text.trim().split(/\s+/).length;
    };

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSearchTownData = useCallback(
        debounce(async (value: string) => {
          try {
            const result = await searchTown({ query: capitalizeFirstLetter(value) }).unwrap();
            if(result && result?.length){
                setFilteredCities(result);
            }else{
                setFilteredCities([]);
            }
          } catch (error) {
            console.error('Error on get town data:', error);
          }
        }, 300),
        [searchTown]
    );

    const handleCityInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if(inputValue){
            handleSearchTownData(inputValue);
        }else{
            setFilteredCities([]);
        }
        setValue('travel_origin', inputValue);
        clearErrors('travel_origin');
    };

    const handlePreviewContent = async () => {
        setIsLoadingPreview(true);
        try {
          const response = await fetch(`/api/amp/v1/fcn/blogs/content/${data?.last_modified_content?.content_id}/preview`);
          const url = await response.text();
          if(url){
            window.open(url, '_blank', 'noopener,noreferrer');
          }
        } catch (err) {
            console.log("Preview Error: ", err)
        } finally{
            setIsLoadingPreview(false);
        }
    };

    const handleResolveContent = async (type: string) => {
        setResolveType(type);
        if(type === "approve"){
            await resolveContent({
                approve: true,
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else if(type === "deny"){
            await resolveContent({
                approve: false,
                reason: "",
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else{
            console.log("No resolve type selected")
        }
    }

    useEffect(() => {
        async function fetchBlogDetails(){
            if(data){
              const { travel_image_1, travel_image_2, travel_image_3, travel_image_4,
                travel_image_caption_1, travel_image_caption_2, travel_image_caption_3, travel_image_caption_4,
                arrival_image_1, arrival_image_2, arrival_image_3, arrival_image_4,
                arrival_image_caption_1, arrival_image_caption_2, arrival_image_caption_3, arrival_image_caption_4 } = data?.last_modified_content ?? {};

                if(travel_image_1){
                    const data = await fetchFileData(travel_image_1.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("travel_images_json.0.data", urlString);
                    setValue("travel_images_json.0.id", travel_image_1?.id);
                    setValue("travel_images_json.0.caption", travel_image_caption_1 ?? "");
                    setValue("travel_image_1_id", travel_image_1?.id);
                }
                if(travel_image_2){
                    const data = await fetchFileData(travel_image_2.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("travel_images_json.1.data", urlString);
                    setValue("travel_images_json.1.id", travel_image_2?.id);
                    setValue("travel_images_json.1.caption", travel_image_caption_2 ?? "");
                    setValue("travel_image_2_id", travel_image_2?.id);
                }
                if(travel_image_3){
                    const data = await fetchFileData(travel_image_3.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("travel_images_json.2.data", urlString);
                    setValue("travel_images_json.2.id", travel_image_3?.id);
                    setValue("travel_images_json.2.caption", travel_image_caption_3 ?? "");
                    setValue("travel_image_3_id", travel_image_3?.id);
                }
                if(travel_image_4){
                    const data = await fetchFileData(travel_image_4.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("travel_images_json.3.data", urlString);
                    setValue("travel_images_json.3.id", travel_image_4?.id);
                    setValue("travel_images_json.3.caption", travel_image_caption_4 ?? "");
                    setValue("travel_image_4_id", travel_image_4?.id);
                }

                if(arrival_image_1){
                    const data = await fetchFileData(arrival_image_1.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("arrival_images_json.0.data", urlString);
                    setValue("arrival_images_json.0.id", arrival_image_1?.id);
                    setValue("arrival_images_json.0.caption", arrival_image_caption_1 ?? "");
                    setValue("arrival_image_1_id", arrival_image_1?.id);
                }
                if(arrival_image_2){
                    const data = await fetchFileData(arrival_image_2.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("arrival_images_json.1.data", urlString);
                    setValue("arrival_images_json.1.id", arrival_image_2?.id);
                    setValue("arrival_images_json.1.caption", arrival_image_caption_2 ?? "");
                    setValue("arrival_image_2_id", arrival_image_2?.id);
                }
                if(arrival_image_3){
                    const data = await fetchFileData(arrival_image_3.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("arrival_images_json.2.data", urlString);
                    setValue("arrival_images_json.2.id", arrival_image_3?.id);
                    setValue("arrival_images_json.2.caption", arrival_image_caption_3 ?? "");
                    setValue("arrival_image_3_id", arrival_image_3?.id);
                }
                if(arrival_image_4){
                    const data = await fetchFileData(arrival_image_4.url);
                    let  urlString = await fileToDataString(data.data);
                    setValue("arrival_images_json.3.data", urlString);
                    setValue("arrival_images_json.3.id", arrival_image_4?.id);
                    setValue("arrival_images_json.3.caption", arrival_image_caption_4 ?? "");
                    setValue("arrival_image_4_id", arrival_image_4?.id);
                }

                setValue("travel_time", data?.last_modified_content?.travel_time ?? "");
                setValue("travel_method", data?.last_modified_content?.travel_method ?? "");
                setValue("travel_origin", data?.last_modified_content?.travel_origin ?? "");
                setValue("travel_summary", data?.last_modified_content?.travel_summary ?? "");
                if(data?.last_modified_content?.parking_price){
                    setValue("parking_price", data?.last_modified_content?.parking_price.toString());
                    setValue("free_parking", false);
                }else{
                    setValue("parking_price", null);
                    setValue("free_parking", true);
                }
                setValue("queue_time", data?.last_modified_content?.queue_time ?? "");
                setValue("arrival_summary", data?.last_modified_content?.arrival_summary ?? "");
                const formValues = getValues();
                const clonedFormValues = JSON.parse(JSON.stringify(formValues));
                setDefaultValuesData(clonedFormValues);
            }
        }
        fetchBlogDetails();
    }, [data]);

    return(
        <div className={`flex flex-col flex-1 bg-[#F5F5FA] doodle-bg ${isLoading && "blur-sm"}`}>
            <DashboardHeaderContainer slim={true}>
            {
                blogId &&
                <DiaryDetailsHeader
                    blogId={blogId}
                />
            }                
            </DashboardHeaderContainer>
            <DashboardContainer>
                <form className={`z-10 ${false ? "blur-sm" : ""}`} onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-x-3 px-5 xl:px-[20px] w-full">
                        <div className="w-full p-6 mb-6 bg-white rounded-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/car.png" alt="car"/>
                                {"Travelling to your day out"}
                            </h1>
                            <div className="mt-2 mb-8 text-sm font-normal text-cs-gray">Tell us about your travel to and from the attraction. Your insights and advice will help other families plan their trips!</div>   
                            <ButtonMultiSelect 
                                containerClassName="my-4 w-full max-w-[740px]"
                                control={control}
                                label={`How long did it take you to get to ${data?.last_modified_content?.title}?`}
                                subLabel=""
                                options={travelTimes}
                                name="travel_time"
                                selectOptionsContainerClassName="overflow-y-auto max-h-[170px]"
                                required
                                maxHeight={170}
                                clearErrors={clearErrorsOnChange}
                            />
                            <ButtonMultiSelect 
                                containerClassName="my-4 w-full max-w-[740px]"
                                control={control}
                                label={`How did you travel to the attraction?`}
                                subLabel=""
                                options={travelMethods}
                                name="travel_method"
                                selectOptionsContainerClassName="overflow-y-auto max-h-[170px]"
                                required
                                maxHeight={170}
                                clearErrors={clearErrorsOnChange}
                            /> 
                            <TownInput 
                                name="travel_origin" 
                                control={control} 
                                handleCityInputChange={handleCityInputChange} 
                                filteredCities={ cityValue ? filteredCities : []}
                                setValue={setValue}
                                clearErrors={()=> clearErrors("travel_origin")}
                                setFilteredCities={setFilteredCities}
                                label="Where did you travel from?"
                                isLoading={isLoadingSearchTown}
                            />
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className={`group-focus-within:text-cs-pink text-sm font-bold ${errors?.travel_summary ? "text-cs-red" : ""}`}>
                                    Talk us through your travel experience
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <Controller
                                    name="travel_summary"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 200 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="travel_summary"
                                            rows={2}
                                            className={`border-cs-1 ${errors?.travel_summary ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36`}
                                            placeholder="For example: We drove to [attraction name], and it was super easy! We followed our Apple Maps the whole way and avoided traffic by taking a back route. We stopped for a quick snack on the way, and the kids didn’t get too impatient."
                                            maxLength={200}
                                            onChange={(e) => {
                                                setValue('travel_summary', e?.target?.value);
                                                clearErrorsOnChange('travel_summary');
                                            }}
                                        />
                                    )}
                                />
                                <div className="flex justify-between gap-x-2 !mt-0">
                                    {
                                        errors?.travel_summary &&
                                        <ErrorMessage>{errors?.travel_summary?.message}</ErrorMessage>
                                    }
                                    <p className="mt-3 ml-auto text-sm text-cs-gray text-end">{countWords(travelSummary)} / 200 words</p>
                                </div>
                            </div>
                            <div>
                                <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.travel_images_json ? "text-cs-red" : ""}`}>
                                    Share some photos of your family travelling to the attraction!
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <div className="flex flex-wrap gap-3 mt-2">
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="travel_images_json.0.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.travel_images_json && errors?.travel_images_json[0]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaText="Drop image here"
                                            dropAreaSubText="Upload image"
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="travel_images_json.0.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="travel_images_json.0.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder="Example: Selfie on the way to our day out!"
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{travelCaption1.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="travel_images_json.1.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.travel_images_json && errors?.travel_images_json[1]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="travel_images_json.1.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="travel_images_json.1.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder="Example: Selfie on the way to our day out!"
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{travelCaption2.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="travel_images_json.2.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.travel_images_json && errors?.travel_images_json[2]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="travel_images_json.2.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="travel_images_json.2.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder="Example: Selfie on the way to our day out!"
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{travelCaption3.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="travel_images_json.3.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.travel_images_json && errors?.travel_images_json[3]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="travel_images_json.3.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="travel_images_json.3.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder="Example: Selfie on the way to our day out!"
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{travelCaption4.length} / 35 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-6 bg-white rounded-t-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/family.png" alt="family"/>
                                {`Arriving at ${data?.last_modified_content?.title}`}
                            </h1>
                            <div className="mt-2 text-sm font-normal text-cs-gray">We want to hear all about your arrival to the attraction. Include information on the check-in process, and any tips you have for families planning a visit soon.</div>
                            <p className={`text-sm font-bold group-focus-within:text-cs-pink mt-4 mb-1.5 ${errors?.parking_price ? "text-cs-red" : ""}`}>Price Of Parking</p>
                            <div className="flex flex-wrap items-center gap-2">
                                <Controller
                                    name="travel_summary"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <div className="relative">
                                            <span className="absolute -translate-y-1/2 left-2 top-1/2">£</span>
                                            <input
                                                type='text'
                                                placeholder='Enter price'
                                                {...register('parking_price')}
                                                onChange={(e) => {
                                                    const newValue = e.target.value.replace(
                                                        /\D/g,
                                                        '',
                                                    );
                                                    setValue('parking_price', newValue);
                                                    clearErrorsOnChange('accountNumber');
                                                    setValue("free_parking", false)
                                                }}
                                                className={`border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-2.5 pr-2 pl-6 text-sm flex flex-col w-full max-w-[118px] focus:outline-cs-pink h-10`}
                                            />
                                        </div>
                                    )}
                                />
                                <span className="">OR</span>
                                <div 
                                    className={`cursor-pointer relative checkbox-item white-checkmark w-fit h-10 rounded-full px-5 text-sm font-normal flex items-center border ${freeParking ? "text-cs-pink border-cs-pink" : "border-[#CFDBD5] text-cs-gray"} `}
                                    onClick={() => {
                                        setValue("parking_price", null);
                                        setValue("free_parking", !freeParking);
                                    }}
                                >
                                <span className={`${freeParking ? "pr-3" : ""}`}>Free Parking</span>
                                { freeParking && <button className="absolute right-2.5 z-50" type="button"><XIcon className="w-2 h-2" svgPathClassName="fill-cs-pink"/></button>}
                                </div>
                            </div>
                            <ButtonMultiSelect 
                                containerClassName="my-4 w-full max-w-cs-574"
                                control={control}
                                label="Queue Time At Arrival"
                                subLabel=""
                                options={queueTime}
                                name="queue_time"
                                selectOptionsContainerClassName="overflow-y-auto max-h-[170px]"
                                maxHeight={170}
                                clearErrors={clearErrorsOnChange}
                            />
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                    Tell us about your families arrival. What was everyone’s first impression, and how did the start of your day out go?
                                </label>
                                <Controller
                                    name="arrival_summary"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 200 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="arrival_summary"
                                            rows={2}
                                            className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                            placeholder="For example: Arriving at the attraction was quite smooth. We arrived just before opening, to avoid huge queues. The check in process was quick, they just scanned our tickets. We waited about 10 minutes in line, but the staff were friendly and kept things moving."
                                            maxLength={200}
                                        />
                                    )}
                                />
                                <p className="text-sm text-cs-gray text-end">{countWords(arrivalSummary)} / 200 words</p>
                            </div>  
                            <div>
                                <label className={`text-sm font-bold group-focus-within:text-cs-pink ${errors?.arrival_images_json ? "text-cs-red" : ""}`}>
                                    Share some photos of when you first arrived.
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <div className="text-sm font-normal text-cs-gray my-1.5">These can show the entrance, any queues... or a family selfie! Please add a minimum of 4 photos.</div>
                                <div className="flex flex-wrap gap-3 mt-2">
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="arrival_images_json.0.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.arrival_images_json && errors?.arrival_images_json[0]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaText="Drop image here"
                                            dropAreaSubText="Upload image"
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="arrival_images_json.0.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="arrival_images_json.0.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder="Example: Very excited kids..."
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{arrivalCaption1.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="arrival_images_json.1.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.arrival_images_json && errors?.arrival_images_json[1]?.data ? "border-cs-red": "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="arrival_images_json.1.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="arrival_images_json.1.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder="Example: Very excited kids..."
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{arrivalCaption2.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="arrival_images_json.2.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.arrival_images_json && errors?.arrival_images_json[2]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="arrival_images_json.2.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="arrival_images_json.2.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder="Example: Very excited kids..."
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{arrivalCaption3.length} / 35 characters</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ImageUpload 
                                            control={control} 
                                            clearErrors={clearErrorsOnChange} 
                                            name="arrival_images_json.3.data"
                                            setValue={setValue}
                                            className={`dropzone flex items-center justify-center w-full md:w-[223px] h-[179px] border border-dotted ${errors?.arrival_images_json && errors?.arrival_images_json[3]?.data ? "border-cs-red" : "border-black"} text-xs`}
                                            imageStyle={{ width: '100px', height: '100px', objectFit: 'contain', marginRight: '10px' }}
                                            dropAreaImage={true}
                                            isSquareCrop={true}
                                        />
                                        <div className="mt-3 space-y-2">
                                            <label className="text-sm font-bold group-focus-within:text-cs-pink">
                                                Caption
                                            </label>
                                            <Controller
                                                name="arrival_images_json.3.caption"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 35 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="arrival_images_json.3.caption"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-16"
                                                        placeholder="Example: Very excited kids..."
                                                        maxLength={35}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{arrivalCaption4.length} / 35 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button 
                                className={`px-4 mt-4 py-2 text-sm font-bold text-white rounded-lg bg-cs-pink ${isDisabled ? "opacity-50" : "opacity-100"}`} 
                                type="submit"
                                disabled={isDisabled}
                            >
                                Save Changes
                            </button>
                            {
                                contentError &&
                                <p className="mt-2 text-sm text-cs-red">{contentError}</p>
                            }
                        </div>
                    </div>
                </form>
            </DashboardContainer>
        </div>
    )
}

export default DiaryTravelling;