import { type ReactNode } from "react"

export type Colors = 'primary' | 'white' | 'black'

export function Badge({ color, children, className }: {color:  Colors, children: ReactNode, className?: string}) {
    const colors: Record<Colors, string> = {
        primary: 'bg-[#CFDCFC] text-[#0052FF]',
        white: 'bg-white text-black',
        black: 'bg-black text-white'
    }
    return (
        <>
        <span className={`font-roboto-flex px-2 text-xs font-bold text-[#0052FF] bg-[#CFDCFC] h-[18px] min-w-[26px] flex justify-center items-center rounded-xl ${colors[color]} ${className ?? ''}`}>
            {children}
        </span>
        </>
    )
}