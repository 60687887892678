import{ ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export default function ConditionalPortal({ children, target }: {children: ReactNode, target: string}) {
  const [element, setElement] = useState<HTMLElement | null>(null);

  const checkElementExistence = useCallback(() => {
    const element = document.querySelector(target) as HTMLElement;
    setElement(element);
  }, [setElement, target]);

  useEffect(() => {
    checkElementExistence();
    const intervalId = setInterval(checkElementExistence, 500);
    return () => clearInterval(intervalId);
  }, [checkElementExistence]);

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(
    children,
    element
  );
};
