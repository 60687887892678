import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { apiSlice } from "../store/apiSlice";
import { globalActions } from "../store/globalSlice";

export const useAuthUser = () => {

    const { appLoaded, user } = useAppSelector(state => state.global);

    return {
        isLoggedIn: appLoaded && user
    }
}

export const useLogin = () => {
    const [callLogin, loginData] = apiSlice.useLoginMutation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(loginData.data){
            dispatch(globalActions.setUser(loginData.data));
        }
    }, [loginData])

    return [callLogin, loginData] as const;    
}

export const useGetSession = (props?: { onComplete?: () => void}) => {

    const skipSession = false;

    const { onComplete } = props ?? {};

    const sessionData = apiSlice.useSessionQuery(skipSession ? skipToken : undefined);
    const { data, fulfilledTimeStamp, isSuccess, isError } = sessionData;
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.global);

    useEffect(() => {
        if(skipSession){
            onComplete && onComplete();
        }
    }, [skipSession])

    useEffect(() => {
        if(fulfilledTimeStamp || isError){
            if(data){
                dispatch(globalActions.setUser(data));
            } else {
                onComplete && onComplete();
            }
        }
    }, [data, fulfilledTimeStamp, isError])

    useEffect(() => {
        if(user){   //Once the user is actually in state, we say the getSession query is done. Just going off the isLoading and data properties alone causes an issue as there's a small delay between receiving the data and dispatching it to state, causes a redirect bug
            onComplete && onComplete();
        }
    }, [user])

    return sessionData;
}

export const useLogout = () => {

    const [logoutApi, logoutData] = apiSlice.useLogoutMutation();
    const dispatch = useAppDispatch();

    const logout = async () => {
        await logoutApi();
        //dispatch(globalActions.setUser(undefined));
        window.location.pathname = "/";
    }

    return [logout, logoutData] as const;

}