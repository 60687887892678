import { type ReactNode } from "react";

export function ErrorMessage({ children, className = '' }: { children: ReactNode, className?: string }) {
  return (
    <div className={`flex items-center text-cs-red ${className}`}>
      <div>
          <ul className="pl-5 mt-2 text-xs list-disc text-cs-red">
              <li> {children}</li>
          </ul>
      </div>
    </div>
  );
}
