import { ScrollRestoration } from "react-router-dom";
import ResetPasswordModal from "../../components/global/www/modals/ResetPasswordModal";
import { TwoColTemplate } from "../../components/global/TwoColTemplate";

export default function ResetPassword() {
  return (
    <>
      <ScrollRestoration />

      <TwoColTemplate
        sidebarImage="/assets/www/reset-password-image.png"
        sidebarText={`With over 500% more visitors than any other family-focused "things to do" website, capture the attention of your ideal audience.`}
      >
        <ResetPasswordModal />
      </TwoColTemplate>
    </>
  );
}
