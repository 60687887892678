import Select from "react-select";
import { components } from "react-select";

interface Props extends React.ComponentProps<Select> {}

export function MultiSelect({ ...args }: Props) {
  return (
    <Select
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      components={{
        Option: MultiSelectOptions,
        IndicatorSeparator: () => <></>,
        ClearIndicator: (props) => (
          <components.ClearIndicator {...props}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </components.ClearIndicator>
        ),
        DropdownIndicator: () => (
          <div className="px-2.5">
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                fill="black"
              />
            </svg>
          </div>
        ),
      }}
      styles={{
        multiValue: (styles) => ({
          ...styles,
          backgroundColor: "#F1F1F1",
          borderRadius: 8,
          height: 31,
          display: "flex",
          alignItems: "center",
        }),
        multiValueRemove: () => ({
          display: "none",
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: "#000",
          fontSize: 14,
          fontWeight: 400,
          display: "block",
          paddingLeft: 10,
          paddingRight: 10,
        }),
        menu: (styles) => ({
          ...styles,
          margin: 0,
          paddingLeft: 5,
          border: "1px solid #6836D1",
        }),
        menuList: (base) => ({
          ...base,
          marginTop: 0,
          marginBottom: 0,

          "::-webkit-scrollbar": {
            width: 14,
          },
          "::-webkit-scrollbar-thumb": {
            background: "#c2c2c2",
            borderRadius: 10,
            border: "4px solid white",
          },
        }),
        noOptionsMessage: (base) => ({
          ...base,
          color: "#212121",
        }),
        control: (styles, state) => ({
          ...styles,
          cursor: "pointer",
          outline: "none",
          border: state.isFocused
            ? "1px solid #CED3CF"
            : "1px solid #CED3CF",
          "&:hover": {
            border: state.isFocused
              ? "1px solid #CED3CF"
              : "1px solid #CED3CF",
          },
          boxShadow: "none",
          paddingTop: 0,
          paddingRight: 8,
          paddingLeft: 5,
          paddingBottom: 0,
          minHeight: 40,
          borderRadius: 8,
          fontWeight: 400,
          fontSize: 14,
          color: '#212121'
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#212121'
        })
      }}
      theme={(theme) => ({
        ...theme,
        background: "none",
        colors: {
          ...theme.colors,
          neutral80: "#6836D1",
        },
      })}
      {...args}
    />
  );
}

const MultiSelectOptions: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  value,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    color: "#555",
    display: "flex",
    fontSize: 14,
    fontWeight: 400,
    height: 0,
    padding: 15,
    margin: 0,
  };

  const props = {
    ...innerProps,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <label className="checkbox-item">
        <input
          className="!w-40 !h-3 !opacity-0"
          type="checkbox"
          checked={isSelected || value.includes("true") ? true : false}
          readOnly
        />
        <span className="checkmark"></span>
      </label>
      {children}
    </components.Option>
  );
};
