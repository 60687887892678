import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Editor,
  BaseEditor,
  Descendant,
  createEditor,
  Element as SlateElement,
  Text as SlateText,
} from "slate";
import {
  useSlate,
  Slate,
  Editable,
  withReact,
  ReactEditor,
  RenderLeafProps,
} from "slate-react";
import { jsx } from "slate-hyperscript";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { useAppSelector } from "../../store";

type CustomElement = { type: "paragraph"; children: CustomText[] };
type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};
type SlateNode = SlateElement & CustomText;

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

interface LeafProps {
  attributes: Record<string, unknown>;
  children: ReactNode;
  leaf: any;
}

type Formats = "bold" | "italic" | "underline";

const RichTextEditor = ({
  initialValue,
  label,
  maxChars,
  isWordCount = false,
  charLabel,
  onChange,
  onDirty,
  labelClassName,
  placeholder = 'Enter some plain text...',
  maxCharSuffix = ''
}: {
  initialValue?: string;
  label?: string;
  maxChars?: number;
  isWordCount?: boolean;
  charLabel?: string;
  onChange?: Function;
  onDirty?: Function;
  labelClassName?: string,
  placeholder?: string,
  maxCharSuffix?: string
}) => {
  const { user } = useAppSelector(state => state.global)
  const [key, setKey] = useState(0);
  const [canEdit, setCanEdit] = useState(true)
  const [value, setValue] = useState<Descendant[]>(prepareInitialValue(initialValue));
  const [editor] = useState(() => withReact(createEditor()));
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    if (onChange) {
      const serialized = serialize(value as SlateNode[])
      onChange(serialized === '<p></p>' ? '' : serialized);
    }
  }, [value, onChange]);


  useEffect(() => {
    setCharCount(isWordCount ? wordCount(value as SlateNode[]) : characterCount(value as SlateNode[]))
  }, [value]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (canEdit) {
      setKey(k => k + 1);
      setValue(prepareInitialValue(initialValue));
      setCanEdit(false)

      timeout = setTimeout(() => {
        setCanEdit(true)
      }, 1000)
    }

    return () => clearTimeout(timeout)
  }, [initialValue, canEdit]);

  return (
    <GrammarlyEditorPlugin
      clientId={"client_FHM1b345AmZdpuPwDHkmry"}
      config={{
        documentDialect: "british",
      }}
    >
      <Slate key={key} editor={editor} value={value} onChange={(v) => setValue(v)}>
        {/* Example - https://www.slatejs.org/examples/richtext */}
        <div className="flex flex-col sm:flex-row items-center mb-1">
          {label && <label className={`w-full sm:w-auto mb-2 sm:mb-0 ${labelClassName ? labelClassName : 'text-sm font-bold text-[#5F646D]'}`}>{label}</label>}
          {user?.role === 'ADMIN' && <div className="flex gap-1 ml-auto">
            <MarkButton format="bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M6.8 19V5h5.525q1.625 0 3 1T16.7 8.775q0 1.275-.575 1.963t-1.075.987q.625.275 1.388 1.025T17.2 15q0 2.225-1.625 3.113t-3.05.887H6.8Zm3.025-2.8h2.6q1.2 0 1.463-.613t.262-.887q0-.275-.263-.887T12.35 13.2H9.825v3Zm0-5.7h2.325q.825 0 1.2-.425t.375-.95q0-.6-.425-.975t-1.1-.375H9.825V10.5Z"
                />
              </svg>
            </MarkButton>

            <MarkButton format="italic">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M5 19v-2.5h4l3-9H8V5h10v2.5h-3.5l-3 9H15V19H5Z"
                />
              </svg>
            </MarkButton>

            <MarkButton format="underline">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M5 21v-2h14v2H5Zm7-4q-2.525 0-3.925-1.575t-1.4-4.175V3H9.25v8.4q0 1.4.7 2.275t2.05.875q1.35 0 2.05-.875t.7-2.275V3h2.575v8.25q0 2.6-1.4 4.175T12 17Z"
                />
              </svg>
            </MarkButton>
          </div>}
        </div>

        <Editable
          placeholder={placeholder}
          renderLeaf={renderLeaf}
          className={`border rounded-lg px-5 py-5 !min-h-[150px] text-cs-gray text-sm ${(maxChars && charCount > maxChars) ? 'border-cs-red' : 'border-[#CFDBD5]'}`}
          value={initialValue}
          onKeyDown={() => {
            onDirty?.()
          }}
          onPaste={() => {
            onDirty?.()
          }}
          onCut={() => {
            onDirty?.()
          }}
        />

        {maxChars && <p className="text-cs-gray text-sm flex w-full mt-2">
          <div className="text-sm text-cs-gray font-normal">{charLabel}</div>
          <div className="text-sm text-cs-gray font-normal ml-auto">{charCount + ' / ' + maxChars}{maxCharSuffix}</div>
        </p>}

        {(maxChars && (maxChars < charCount)) && <div className="text-cs-red flex items-center mt-2">
            <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
            <span className="flex-1">Text is too long</span>
        </div>}
      </Slate>
    </GrammarlyEditorPlugin>
  );
};

const toggleMark = (editor: BaseEditor & ReactEditor, format: Formats) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: BaseEditor & ReactEditor, format: Formats) => {
  const marks = Editor.marks(editor);
  // @ts-ignore
  return marks ? marks[format] === true : false;
};

const Leaf = ({ attributes, children, leaf }: LeafProps) => {
  if (leaf.bold) {
    children = <strong className="font-bold [&>*]:font-bold">{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const MarkButton = ({
  format,
  children,
  className,
}: {
  format: Formats;
  children: ReactNode;
  className?: string;
}) => {
  const editor = useSlate();
  return (
    <button
      type="button"
      className={`p-1 transition rounded-sm ${className} ${
        isMarkActive(editor, format) && "bg-slate-200"
      }`}
      onMouseDown={() => {
        toggleMark(editor, format);
      }}
    >
      {children}
    </button>
  );
};

const deserialize = (
  el: HTMLElement,
  markAttributes: { bold?: boolean, italic?: boolean, underline?: boolean } = {}
): object | string | null => {
  if (el.nodeType === Node.TEXT_NODE && !!el.textContent?.trim()) {
    return jsx("text", {...markAttributes }, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map((node) => {
      const deserialized = deserialize(node as HTMLElement, nodeAttributes)
      return deserialized
    })
    .flat();

  if (children.filter(c => c !== null).length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return jsx(
        "text",
        {},
        [{text: '\n'}]
      );
    case "SPAN":
      return (!Array.isArray(children) || (Array.isArray(children) && children.length === 1)) ? jsx(
        "text",
        {},
        children
      ) : jsx(
        "element",
        { type: 'paragraph', grouped: true },
        children
      );
    case "SUP":
        return (!Array.isArray(children) || (Array.isArray(children) && children.length === 1)) ? jsx(
          "text",
          {},
          children
        ) : jsx(
          "element",
          { type: 'paragraph' },
          children
        );
    case "SUB":
        return (!Array.isArray(children) || (Array.isArray(children) && children.length === 1)) ? jsx(
          "text",
          {},
          children
        ) : jsx(
          "element",
          { type: 'paragraph' },
          children
        );      
    case "LI":
      return (!Array.isArray(children) || (Array.isArray(children) && children.length === 1)) ? jsx(
        "text",
        {},
        children
      ) : jsx(
        "element",
        { type: 'paragraph' },
        children
      );
    case "P":
      return jsx("element", { type: "paragraph" }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );
    case "STRONG":
      return children
    case "EM":
      return children
    case "U":
      return children  
    default:
      return jsx("element", { type: "paragraph" }, children);
  }
};

function hoistGrouped(obj: any): any {
  if (!obj.children) {
    return obj;
  }

  const updatedChildren = [];

  for (const child of obj.children) {
    if (child.grouped) {
      updatedChildren.push(child.children);
    } else {
      updatedChildren.push(hoistGrouped(child));
    }
  }

  return {
    ...obj,
    children: updatedChildren.flat(),
  };
}

function hoistGroupedChildrenRecursive(objs: any): any {
  return objs.map((obj: any)=> hoistGrouped(obj));
}


export const serialize = (node: SlateNode | SlateNode[]): string => {
  if (Array.isArray(node)) {
    return node.map((n) => serialize(n)).join("");
  }

  if (node.children) {
    return `<p>${node.children.map((c: SlateText | SlateNode) => {
      if (c.text) return serializeNode(c)
      if ((c as SlateNode).children) return (c as SlateNode).children.map((n) => serialize(n as SlateNode)).join("");
    }).join('')}</p>`;
  }

  return serializeNode(node);
};

function convertSiblingFloatingTextNodesToParagraphs(item: any[]) {
  return item.map((child: any, index: any) => {
    if (
      child.text &&
      item.filter((c: any) => c.type === "paragraph").length > 0
    ) {
      return { type: "paragraph", children: [{ text: child.text }] };
    }
    return child;
  })
}

function convertTextToParagraph(data: any, isRoot = false): any {
  if (Array.isArray(data) && isRoot) {
    return convertSiblingFloatingTextNodesToParagraphs(data);
  }

  return data.map((item: any) => {
    if (item.type === "paragraph" && item.children) {
      const modifiedChildren = convertSiblingFloatingTextNodesToParagraphs(item.children);
      return { ...item, children: convertTextToParagraph(modifiedChildren) };
    }

    return item;
  });
}

const serializeNode = (node: CustomText) => {
  let output = node.text;

  if (node.italic) {
    output = "<em>" + output + "</em>";
  }

  if (node.underline) {
    output = "<u>" + output + "</u>";
  }

  if (node.bold) {
    output = "<strong>" + output + "</strong>";
  }

  return output;
};

const characterCount = (node: SlateNode | SlateNode[]): number => {
  if (Array.isArray(node)) {
    return node.map((n) => characterCount(n)).reduce((a, b) => a + b, 0);
  }

  if (node.children) {
    return node.children.map((c: SlateText) => c.text).reduce((a, b) => a + (b?.length ?? 0), 0)
  }

  return node.text.length;
};

const wordCount = (node: SlateNode | SlateNode[]): number => {
  if (Array.isArray(node)) {
    return node.map((n) => wordCount(n)).reduce((a, b) => a + b, 0);
  }

  if (node.children) {
    return node.children.map((c: SlateText) => c.text).reduce((a, b) => a + (b === '' ? 0 : (b?.split(' ')?.length ?? 0)), 0)
  }

  return node.text.split(' ').length;
};


const prependParagraph = (rawInput: HTMLElement) => {
  const body = rawInput.innerHTML;

  if (!body.startsWith('<p>')) {
    rawInput.innerHTML = `<p>${body}</p>`
  }

  return rawInput
}

export const prepareInitialValue = (input: string | undefined): Descendant[] => {
  const rawHTML = new DOMParser().parseFromString(
    input ?? "",
    "text/html"
  );
  const deserialized = convertTextToParagraph(hoistGroupedChildrenRecursive(deserialize(prependParagraph(rawHTML.body)) as Descendant[]), true);
  
  return input ? deserialized : [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]
}

export const getCharLength = (input: string) => {
  const serialized = prepareInitialValue(input);

  return characterCount(serialized as SlateNode[])
}

export const getWordLength = (input: string) => {
  const serialized = prepareInitialValue(input);

  return wordCount(serialized as SlateNode[])
}

export const shouldDirty = (initial: string | null | undefined, value: string) => {
  const isEmpty = [null, '', undefined].includes(initial) && ['', '<p></p>'].includes(value);
  return (initial ?? '') !== value && !Boolean(isEmpty)
}


export default RichTextEditor;
