import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { z } from "zod";
import {
  useEffect,
  useState,
  createRef,
  useRef,
  Children as ReactChildren,
  isValidElement,
  cloneElement,
} from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm, useWatch, Controller } from "react-hook-form";
import TextInput from "../../../components/form/TextInput";
import {
  PostcodeLookup,
  type ControllerConfig,
} from "@ideal-postcodes/postcode-lookup";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { apiSlice } from "../../../store/apiSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { globalActions } from "../../../store/globalSlice";
import { UnsavedModal } from "../../../components/global/amp/UnsavedModal";
import { hasUrl } from "../../../helpers/hasUrl";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { unlockNewAttractionTab } from "../../../helpers/newAttractionsTabPermission";
import { RevertToSavedModal } from "../../../components/global/RevertToSavedModal";
import Stepper from "../../../components/amp/Stepper";
import { SaveButtonsEditAttraction } from "../../../components/amp/SaveButtonsEditAttraction";
import { StepperHeadingEditAttraction } from "../../../components/amp/StepperHeadingEditAttraction";
import {
  clearNewAttractionStepLeftOver,
  getNewAttractionWhichStepLeftOver,
  setNewAttractionLeftOverTab,
} from "../../../helpers/newAttractionsFinishLater";
import { filterPayload } from "../../../helpers/filterPayload";
import { getWordLength } from "../../../components/form/RichTextEditor";
import { postcodeValidation } from "../../../components/global/www/modals/SearchClaimModal";
import { LightningIcon } from "../../../components/amp/LightBulbTooltip";
import { getVoScoreTooltip, useVoScoreNeedImprovementsFields } from "../../../hooks/useVoScoreNeedImprovementsFields";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

export const PostcodeLookupComponent = (props: Partial<ControllerConfig>) => {
  const context = createRef<HTMLDivElement>();

  useEffect(() => {
    PostcodeLookup.setup({
      apiKey: "ak_lgapdyeky9f7QF6mw2Pyq8hyQjxdR",
      context: context.current!,
      ...props,
    });
  }, []);

  return <div ref={context}></div>;
};

const AttractionLocation: React.FC<IAttractionDetails> = () => {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);

  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess: isGetSuccess,
    isFetching,
    refetch,
    error: attractionError,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  const [updateAttraction, { isLoading, isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();

  const [address, setAddress] = useState({
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    county: "",
    postcode: "",
    latitude: 51.5266512,
    longitude: -0.0245508,
  });

  const schema = z.object({
    address: z.string().min(1, { message: "Address is required." }),
    address2: z.string().nullable(),
    address3: z.string().nullable(),
    town: z.string(),
    county: z.string(),
    postcode: postcodeValidation,
    location_html: z.string().superRefine((value, ctx) => {
      if (hasUrl(value ?? "")) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You are not allowed include a url.`,
        });
      }

      const words = getWordLength(value);
      if (words > 200) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You reached maximum word limit.`,
        });
      }
    }),
    location: z
      .object({
        lat: z.number(),
        lng: z.number(),
      })
      .nullable(),
  });
  type AttractionLocationsForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors, isDirty },
    control,
    getValues,
    setValue,
    reset,
    trigger: triggerValidation,
  } = useForm<AttractionLocationsForm>({
    resolver: zodResolver(schema),
  });
  const watchedLocationHtml = useWatch({
    control,
    name: "location_html",
  });

  const [isPublishing, setIsPublishing] = useState(false);
  const [stepsFields] = useState<(keyof AttractionLocationsForm)[][]>([
    [
      "address",
      "address2",
      "address3",
      "town",
      "county",
      "postcode",
      "location",
      "location_html",
    ],
  ]);
  const [stepsPostFields] = useState<string[][]>([
    [
      "address",
      "address2",
      "address3",
      "town_name",
      "county_name",
      "postcode",
      "location_html",
      "location",
    ],
  ]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = stepsFields[currentStepIndex] ?? [];
  const currentPostStep = stepsPostFields[currentStepIndex] ?? [];
  const { user } = useAppSelector((state) => state.global);
  const isStepperActive =
    user?.role !== "ADMIN" &&
    !result?.finished_onboarding;
  const getPayload = (): Record<string, unknown> => {
    return {
      town_name: getValues("town"),
      county_name: getValues("county"),
      address: getValues("address"),
      address2: getValues("address2"),
      address3: getValues("address3"),
      postcode: getValues("postcode"),
      location_html: getValues("location_html"),
      location: getValues("location")
        ? {
            type: "Point",
            coordinates: [
              getValues("location")?.lng,
              getValues("location")?.lat,
            ],
          }
        : null,
    };
  };
  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<AttractionLocationsForm> = async (e) => {
      setIsPublishing(publish);
      const payload = filterPayload(
        getPayload(),
        currentPostStep,
        isStepperActive
      );

      const results = await updateAttraction({
        id: attractionId,
        publish,
        payload,
      });
      if (!isSilent) {
        restartAnimation();
      }

      return "data" in results;
    };
    return fn;
  };

  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(result?.listing_package ?? "");

  useEffect(() => {
    if (isUpdateSuccess) {
      reset({}, { keepValues: true });
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  useEffect(() => {
    if (result && isGetSuccess && !isFetching) {
      setValue("address", result?.address ?? "");
      setValue("address2", result?.address2 ?? "");
      setValue("address3", result?.address3 ?? "");
      setValue("town", result?.town?.name ?? "");
      setValue("county", result?.town?.county.name ?? "");
      setValue("postcode", result?.postcode ?? "");
      setValue("location_html", result?.location_html ?? "");

      const location =
        typeof result?.location === "string"
          ? JSON.parse(result?.location)
          : result?.location;
      setValue(
        "location",
        location && location.coordinates
          ? {
              lat: location.coordinates[1],
              lng: location.coordinates[0],
            }
          : null
      );
      setShowRevertModal(false);
    }
  }, [result, isFetching]);

  useEffect(() => {
    if (address.line_1) {
      setValue("address", address.line_1, { shouldDirty: true });
      setValue("address2", address.line_2, { shouldDirty: true });
      setValue("address3", address.line_3, { shouldDirty: true });
      setValue("town", address.post_town, { shouldDirty: true });
      setValue("county", address.county, { shouldDirty: true });
      setValue("postcode", address.postcode, { shouldDirty: true });
      setValue(
        "location",
        {
          lat: address.latitude,
          lng: address.longitude,
        },
        { shouldDirty: true }
      );
    }
  }, [address]);

  useEffect(() => {
    document.title = "Attraction Location | Day Out With The Kids";
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);

  const { data: getMetrics } =
  apiSlice.useGetVenueOptScoreMetricsQuery();
  const voScoreImprovements = useVoScoreNeedImprovementsFields({
    allMetrics: getMetrics?.result || [],
    completedMetrics: getAttraction?.result?.published_version?.optimisation_scores || [],
    results: result
  })['ATTRACTION_LOCATION']

  const step1 = () => (
    <div className="grid grid-cols-1 gap-5">
      <fieldset>
        <PostcodeLookupComponent
          onAddressSelected={(address) => setAddress(address)}
          inputClass="border border-[#CFDBD5] text-black rounded-lg py-3 px-4 h-9 focus:outline-cs-pink border-[#CFDBD5] hover:border-cs-pink w-full sm:w-auto mt-2"
          buttonClass="rounded-lg bg-[#F2F2F2] h-9 py-2.5 px-4 mt-3 text-black text-sm font-bold w-full sm:w-auto ml-0 sm:ml-2 leading-[16px]"
          selectClass="custom-select bg-white border-[1px] border-[#CED3CF] text-sm text-[#212121] rounded-lg py-[0.58rem] px-4 focus:outline-cs-pink hover:border-cs-pink mt-5 w-full md:w-3/4 xl:w-1/2"
          messageClass="text-cs-red mt-3"
          placeholder=""
        />

        <div className={"grid grid-cols-1"}>
          <div>
            <TextInput
              name={"address"}
              label="Address first line"
              inputProps={{ ...register("address") }}
              className="w-full mt-3 group"
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
              inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
            />
            <TextInput
              name={"address2"}
              label="Address second line (optional)"
              inputProps={{ ...register("address2") }}
              className="w-full mt-3 group"
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
              inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
            />
            <TextInput
              name={"address3"}
              label="Address third line (optional)"
              inputProps={{ ...register("address3") }}
              className="w-full mt-3 group"
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
              inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );

  const step3 = () => (
    <fieldset>
      <legend className="text-[#212121] font-bold">Map</legend>

      <p className="text-[#5F646D] font-normal text-sm my-3">
        Move the location pin directly to your attraction’s address.
      </p>

      <div className={"w-full h-[377px]"}>
        <Controller
          control={control}
          name={"location"}
          render={({
            field: { ref, value, onChange },
            fieldState: { error },
          }) => (
            <GoogleMap
              marker={{
                lat:
                  value?.lat ??
                  result?.town?.location?.coordinates?.[1] ??
                  51.50703296721856,
                lng:
                  value?.lng ??
                  result?.town?.location?.coordinates?.[0] ??
                  -0.12325286865234375,
              }}
              onChange={onChange}
            />
          )}
        />
      </div>
    </fieldset>
  );

  const step2 = () => (
    <fieldset className={"grid grid-cols-1"}>
      <div>
        <TextInput
          name={"town"}
          label="City or Town"
          inputProps={{ ...register("town") }}
          readonly
          className="w-full mt-3 group"
          labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
          inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
          control={control}
        />
        <TextInput
          name={"county"}
          label="County"
          inputProps={{ ...register("county") }}
          readonly
          className="w-full mt-3 group"
          labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
          inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
          control={control}
        />
        <TextInput
          name={"postcode"}
          label="Postcode"
          inputProps={{ ...register("postcode") }}
          className="w-full mt-3 group"
          labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
          inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
          control={control}
        />
      </div>
    </fieldset>
  );

  const step4 = () => (
    <fieldset className="flex flex-col w-full mt-6">
      <legend className="mb-3 text-black font-bold">
        Additional information (optional)
      </legend>
      <label className="text-sm font-normal text-[#5F646D]">
        If your venue is hard to find, include information that you think will
        help people find you
      </label>
      <textarea
        {...register("location_html")}
        placeholder="Example: Give us the 'what 3 words' that relates to your attraction, are there any well-known landmarks near your attraction? Is the attraction signposted from the main road?"
        className="w-full md:w-3/4 xl:w-1/2 h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
      ></textarea>
      <p className="w-full md:w-3/4 xl:w-1/2 mt-2 text-sm text-right text-[#5F646D] font-normal">
        {(watchedLocationHtml?.split(" ")?.length ?? 0) + " / 200 words"}
      </p>

      {errors["location_html"]?.message && (
        <div className="text-cs-red flex items-center">
          <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
          <span className="flex-1">{errors["location_html"]?.message}</span>
        </div>
      )}
    </fieldset>
  );

  const stepperStep = useState(0);
  useEffect(() => {
    const steps = getNewAttractionWhichStepLeftOver();
    if (steps.step && steps.allSteps) {
      if (steps.allSteps === stepsFields.length) {
        stepperStep[1](steps.step);
        setCurrentStepIndex(steps.step);
      }
      clearNewAttractionStepLeftOver();
    }
  }, []);

  useEffect(() => {
    dispatch(
      globalActions.setSaveCurrentTabStep({
        attractionId: attractionId ?? "",
        key: "ATTRACTION_LOCATION",
        allSteps: stepsFields.length,
        step: currentStepIndex,
      })
    );
  }, [attractionId, currentStepIndex, stepsFields.length, dispatch]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        triggerValidation={handleSubmit}
        saveFn={onSubmit}
        getPayload={getPayload}
        isDirty={isDirty}
      />

      <SingleAttractionTemplate
        result={result}
        attractionId={attractionId}
        isAttraction={true}
        doneWalkthrough={result ? result?.done_walkthrough ?? false : undefined}
        name={isStepperActive ? result?.name ?? "" : ""}
        unstyled={!isStepperActive}
      >
        <form onSubmit={handleSubmit(onSubmit(false))}>
          <Stepper
            controlledStepping={stepperStep}
            isActive={isStepperActive}
            isLoading={isLoading}
            saveFn={onSubmit}
            onStepChange={setCurrentStepIndex}
            customSubmitText={'Proceed to Opening Times'}
            triggerValidation={async () => {
              return await triggerValidation(currentStep);
            }}
            onNextPage={async () => {
              unlockNewAttractionTab(
                attractionId ?? "",
                "ATTRACTION_OPENING_TIMES"
              );
              await new Promise((res) => setTimeout(res, 500));
              navigate(`/attractions/${attractionId}/opening-times`);
            }}
            title={
              <StepperHeadingEditAttraction
                name={result?.name || ""}
                title={<>📍 Location {!isStepperActive && !!voScoreImprovements.address && <LightningIcon tooltip={getVoScoreTooltip(voScoreImprovements.address)} />}</>}
                isActive={isStepperActive}
              />
            }
            nonStepperSubmit={
              <SaveButtonsEditAttraction isLoading={isLoading} />
            }
            onBeforeFinishLater={() => {
              setNewAttractionLeftOverTab(
                attractionId ?? "",
                "ATTRACTION_LOCATION",
                currentStepIndex,
                stepsFields.length
              );
            }}
            onGoPrevPage={() => {
              navigate(`/attractions/${attractionId}/details`);
            }}
          >
            <Stepper.Step componentType="Step">
            <div className="text-sm font-normal text-[#212121]">
                  Add your postcode below. If your attraction is difficult to
                  find, enter your closest postcode and you can adjust the
                  location via the map pin.
                </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                
                <div>
                  {step1()}
                  {step2()}
                </div>
                <div>{step3()}</div>
              </div>

              <div className="mt-5">{step4()}</div>
            </Stepper.Step>
          </Stepper>

          {isSubmitted && !isValid && Object.keys(errors).length !== 0 && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}
        </form>

        <RevertToSavedModal
          getShowRevertModal={getShowRevertModal}
          setShowRevertModal={setShowRevertModal}
          refetch={refetch}
        />

        <UnsavedModal isDirty={isDirty} />
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

const MapRenderer = (status: Status) => {
  return <div>{status}</div>;
};

export const GoogleMap: React.FC<{
  marker: google.maps.LatLngLiteral;
  onChange?: (position: google.maps.LatLngLiteral) => void;
}> = ({ marker, onChange }) => {
  const [zoom, setZoom] = useState(13); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: marker.lat ?? 51.5266512,
    lng: marker.lng ?? -0.0245508,
  });
  const [dirtyMarker, setDirtyMarker] = useState<boolean>(false);

  useEffect(() => {
    setCenter(marker);
  }, [marker]);

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  return (
    <Wrapper
      apiKey={"AIzaSyDLwEuIahf0DuCO5LcJZDxuyn7W6uMnkUc"}
      render={MapRenderer}
    >
      <Map center={center} zoom={zoom}>
        <Marker
          position={marker}
          onChange={(pos, map) => {
            if (onChange) {
              onChange(pos);
            }

            if (map) {
              onIdle(map as google.maps.Map);
            }

            if (!dirtyMarker) {
              setDirtyMarker(true);
            }
          }}
        />
      </Map>
    </Wrapper>
  );
};
interface MapProps extends google.maps.MapOptions {
  children: React.ReactNode;
  onIdle?: (map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({ onIdle, children, ...options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      map.setOptions({
        zoom: options.zoom,
      });
      map.panTo(options.center!);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onIdle]);

  return (
    <>
      <div ref={ref} className="h-full" />
      {ReactChildren.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

interface MarkerOptions extends google.maps.MarkerOptions {
  onChange?: (
    position: google.maps.LatLngLiteral,
    map?: google.maps.Map | google.maps.StreetViewPanorama | null
  ) => void;
}
const Marker: React.FC<MarkerOptions> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker({ draggable: true }));
    } else if (!google.maps.event.hasListeners(marker, "dragend")) {
      google.maps.event.addListener(
        marker!,
        "dragend",
        (marker: { latLng: google.maps.LatLng }) => {
          if (!marker) return;

          const pos = marker.latLng;
          if (options.onChange && pos) {
            options.onChange(
              {
                lat: pos.lat(),
                lng: pos.lng(),
              },
              options.map
            );
          }
        }
      );
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default AttractionLocation;
