import {
  ScrollRestoration,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Footer from "../../components/global/www/Footer";
import Header from "../../components/global/www/Header";
import { useEffect, useState } from "react";
import {
  CreateClaimModal,
  SubmittedModal,
} from "../../components/global/www/modals/CreateClaimModal";
import { useAppSelector } from "../../store";
import { useAuthUser } from "../../hooks/useAuth";
import { type CreateAttractionArgs } from "../../store/apiSlice";
import { TwoColTemplate } from "../../components/global/TwoColTemplate";

const CreateClaim: React.FC = () => {
  const { user } = useAppSelector((state) => state.global);
  const { isLoggedIn } = useAuthUser();
  const navigate = useNavigate();

  const [showSubmittedModal, setSubmittedModal] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const attraction = {
    id: searchParams.get("id") ?? "",
    title: searchParams.get("title") ?? "",
    location: searchParams.get("location") ?? "",
    image: searchParams.get("image") ?? "",
    image_alt: searchParams.get("image_alt") ?? "",
    postcode: searchParams.get("postcode") ?? undefined,
    website: searchParams.get("website") ?? undefined,
    location_name: searchParams.get("location_name") ?? undefined,
    phone: searchParams.get("phone") ?? undefined,

    // listing_type: (searchParams.get("listing_type") ??
    //   "attraction") as CreateAttractionArgs["listing_type"],
    duration_type: (searchParams.get("duration_type") ??
      "permanent") as CreateAttractionArgs["duration_type"],
    // start_working: searchParams.get("start_working") ?? undefined,
    // end_working: searchParams.get("end_working") ?? undefined,
  };
  const [newUserEmail, setNewUserEmail] = useState("");

  useEffect(() => {
    if (isLoggedIn && user?.role === "ATTRACTION_MANAGER") {
      navigate("/forbidden");
    }
  }, []);

  return (
    <>
      <ScrollRestoration />

      <TwoColTemplate
        sidebarText={
          showSubmittedModal
            ? 'With over 500% more visitors than any other family-focused "things to do" website, capture the attention of your ideal audience.'
            : "Leverage your analytics dashboard to maximize the results of your listing on our platform."
        }
        smallSidebarText={'* included on standard, enhanced and premium packages'}
        sidebarImage={"/assets/www/reset-password-image.png"}
      >
        {showSubmittedModal ? (
          <SubmittedModal attraction={attraction} newUserEmail={newUserEmail} />
        ) : (
          <CreateClaimModal
            attraction={attraction}
            setSubmittedModal={setSubmittedModal}
            setNewUserEmail={setNewUserEmail}
          />
        )}
      </TwoColTemplate>
    </>
  );
};

export default CreateClaim;
