import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from "zod";
import { PrimaryButton } from '../../../../components/global/PrimaryButton';
import { apiSlice } from '../../../../store/apiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardContainer from '../../../../components/amp/DashboardContainer';
import DashboardHeaderContainer from '../../../../components/amp/DashboardHeaderContainer';
import { TicketingProductName } from './TicketingProductName';
import { TicketingProductDescription } from './TicketingProductDescription';

const CreateTicketingProduct: React.FC = () => {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();
  const { attractionId } = useParams();
  const isEditing = !!productId
  const [createTicketingProduct] = apiSlice.useCreateTicketingProductMutation()
  const [updateTicketingProduct] = apiSlice.useUpdateTicketingProductMutation()
  const { data: productQuery } = apiSlice.useGetTicketingProductQuery(productId || '', { skip: !isEditing })
  const formMethods = useForm<TicketingProductFormType>({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (!productQuery) return
    formMethods.reset(productQuery)
  }, [productQuery])

  const onSubmit = () => {
    const fn: SubmitHandler<TicketingProductFormType> = async (e) => {

      if (isEditing) {
        await updateTicketingProduct(e as any).unwrap().then((res) => {
          navigate(-1);
        })
      } else {
        await createTicketingProduct(e as any).unwrap().then((res) => {
          navigate(-1);
        })
      }
    };

    return fn;
  }

  return (
    <>
      <div className="flex flex-col flex-1 bg-cs-off-white">
        <DashboardHeaderContainer>
          <h1 className="font-extrabold text-xl text-black font-roboto-flex ">Product</h1>
        </DashboardHeaderContainer>
        <DashboardContainer>
          <section className={`flex flex-col px-6 pt-4 pb-8 bg-white xl:pt-6 xl:rounded-lg xl:mx-[70px]`}>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit())}>
                <legend className="mb-3 text-lg font-bold text-cs-gray">
                  {isEditing ? 'Edit Period' : 'Create Product'}
                </legend>
                <input {...formMethods.register("venue_short_id")} type='hidden' value={attractionId} />
                <TicketingProductName />
                <TicketingProductDescription />
                <div className="flex items-center gap-5 max-w-[350px] mt-5">
                  <PrimaryButton scale="sm" type="submit">
                    {isEditing ? 'Update Product' : 'Create Product'}
                  </PrimaryButton>
                </div>
              </form>
            </FormProvider>
          </section>
        </DashboardContainer>
      </div>
    </>
  )
}

export default CreateTicketingProduct;

export type TicketingProductFormType = z.infer<typeof schema>;
const schema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: "Please enter a name for this period." }),
  description: z.string(),
  venue_short_id: z.string(),
});
