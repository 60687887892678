import { ReactNode } from "react";

export function StepperHeadingEditAttraction({
  isActive,
  name,
  title
}: {
  isActive: boolean;
  name: string,
  title: string | ReactNode
}) {
  return (
    <>
      {!isActive && <h2 className="mb-4 text-[22px] font-extrabold text-black">
        {name}
      </h2>}
      <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
        {title}
      </h1>
    </>
  );
}
