import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, Control, UseFormSetValue, useController } from 'react-hook-form';
// import { CameraIcon, CropIcon, DeleteIcon, PenIcon } from './misc/Icons';
import { Cropper, CropperRef } from "react-advanced-cropper";
import { CameraIcon, CropIcon, DeleteIcon, PenIcon } from '../misc/Icons';
import ModalWrapper from '../global/ModalWrapper';
// import ModalWrapper from './ModalWrapper';

interface ImageUploadProps {
    control: Control<any>;
    name: string;
    setValue: UseFormSetValue<any>;
    clearErrors: (val: string) => void;
    className?: string;
    imageStyle?: React.CSSProperties;
    label?: string;
    subLabel?: string;
    dropAreaText?: string;
    dropAreaSubText?: string;
    dropAreaImage?: boolean;
    isSquareCrop?: boolean
}

const ImageCropper: React.FC<{
    file: string;
    stencilAspectRatio?: number;
    onAccept: (value: string) => void;
    onCancel: () => void;
  }> = ({ file, stencilAspectRatio = 1 / 1, onAccept, onCancel }) => {
    const cropperRef = useRef<CropperRef>(null);
    const [cropperImage, setCropperImage] = useState<string>(file);
  
    useEffect(() => {
      setCropperImage(file);
    }, [file]);
    
    const onChange = (cropper: CropperRef) => {
      setCropperImage(cropper.getCanvas()?.toDataURL() ?? "");
    };
  
    const accepted = () => {
      const cropped = cropperRef.current?.getCanvas()?.toDataURL() ?? "";
      onAccept(cropperImage ? cropperImage : cropped);
    };
  
    return (
      <>
        <div className="w-full sm:w-[80%] max-w-full aspect-square overflow-none">
          <Cropper
            className="w-full max-w-full aspect-square"
            ref={cropperRef}
            src={file}
            resize-image={{
              adjustStencil: true,
            }}
            stencilProps={{
              aspectRatio: stencilAspectRatio,
              movable: true,
              resizable: true,
              handlers: true,
            }}
            onChange={onChange}
          />
        </div>
  
        <div className="flex flex-row mt-10">
          <button
            className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
            onClick={accepted}
            type='button'
          >
            Save
          </button>
  
          <button
            className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
            onClick={onCancel}
            type='button'
          >
            Cancel
          </button>
        </div>
      </>
    );
};

const ImageUpload: React.FC<ImageUploadProps> = ({ control, name, setValue, clearErrors, className, imageStyle, label, subLabel, dropAreaText, dropAreaSubText, dropAreaImage, isSquareCrop }) => {
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const { field } = useController({ name, control });
    const [getShowCropModal, setShowCropModal] = useState<boolean>(false);

    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            if(file?.type.indexOf("image") > -1){
                const base64String = await fileToBase64(file);
                setImagePreview(base64String);
                clearErrors(name);
                setValue(name, base64String);
                if(name?.indexOf(".data") > -1){
                    setValue(`${name.replace('data', 'id')}`, "");
                }
                setShowCropModal(true);
            }
        }
    }, [setValue, name, clearErrors]);

    const cancelCrop = () => {
        setShowCropModal(false);
    };

    const cropImage = async (value: string) => {
        setValue(name, value);
        if(name?.indexOf(".data") > -1){
            setValue(`${name.replace('data', 'id')}`, "");
        }
        setShowCropModal(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
    } as any);

    useEffect(() => {
        setImagePreview(field?.value ?? "");
    }, [field?.value])

    return (
        <>
            <div {...getRootProps({ className: `${className} ${imagePreview ? "!border-transparent" : "border-dotted border-black"}` || `dropzone flex items-center justify-center w-full h-full border text-xs` })}>
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <input
                            {...getInputProps()}
                            type="file"
                            onChange={async (e) => {
                                const files = e.target.files;
                                if (files && files.length > 0) {
                                    const file = files[0];
                                    if(file?.type.indexOf("image") > -1){
                                        const base64String = await fileToBase64(file);
                                        setImagePreview(base64String);
                                        setValue(name, base64String);
                                        if(name?.indexOf(".data") > -1){
                                            setValue(`${name.replace('data', 'id')}`, "");
                                        }
                                    }
                                }
                            }}
                            style={{ display: 'none' }}
                        />
                    )}
                />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p>
                        :
                        imagePreview ?
                            <div className="relative flex items-center justify-center w-full h-full">
                                <img
                                    src={imagePreview}
                                    alt={`uploaded-file`}
                                    style={{ width: '100%', height: '100%', objectFit: 'contain', border: '1px solid #f5f5f5' }}
                                />
                                <button 
                                    className="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full shadow-inner top-2 right-2 hover:bg-cs-off-white"
                                    onClick={(e) =>{
                                        e.stopPropagation()
                                        setImagePreview(null);
                                        setValue(name, "");
                                        setValue(`${name.replace('data', 'id')}`, "");
                                    }}
                                    type='button'
                                >
                                    <DeleteIcon/>
                                </button>
                                <button 
                                    className="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full shadow-inner bottom-2 right-2 hover:bg-cs-off-white"
                                    onClick={(e) =>{}}
                                    type='button'
                                >
                                    <PenIcon/>
                                </button>
                                <button 
                                    className="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full shadow-inner bottom-2 left-2 hover:bg-cs-off-white"
                                    onClick={(e) =>{
                                        e.stopPropagation();
                                        setShowCropModal(true);
                                    }}
                                    type='button'
                                >
                                    <CropIcon/>
                                </button>
                            </div>
                            :
                            dropAreaImage ?
                            <CameraIcon/>
                            :
                            dropAreaText ?
                            <p className='text-center'>{dropAreaText}<br />{dropAreaSubText}</p>
                            :
                            <p className='text-center'>Drop image here<br />Upload image</p>
                }
            </div>
            {getShowCropModal && (
                <ModalWrapper
                    closeOnOverlay={false}
                    className="w-[616px] min-h-cs-394 px-0 sm:px-10 xl:px-0"
                    open={getShowCropModal}
                    setOpen={setShowCropModal}
                    onClose={cancelCrop}
                >
                    <h3 className="mb-3 text-2xl font-bold text-cs-gray">Crop Image</h3>

                    <ImageCropper
                        file={field?.value}
                        stencilAspectRatio={isSquareCrop ? 1 / 1 : 16 / 9}
                        onAccept={cropImage}
                        onCancel={cancelCrop}
                    />
                </ModalWrapper>
            )}
        </>
    );
};

export default ImageUpload;