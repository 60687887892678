import Joyride, { CallBackProps, STATUS } from "react-joyride";
import {
  WalkthroughType,
  walkthroughActions,
} from "../../store/walkthroughSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import ModalWrapper from "../global/ModalWrapper";
import { PrimaryButton } from "../global/PrimaryButton";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { apiSlice } from "../../store/apiSlice";
import { OutlineButton } from "../global/OutlineButton";
import { useWindowResize } from "../../hooks/useWindowResize";
import { PublishButtons } from "../global/amp/Header";
import { restartAnimation } from "../global/amp/ToastNotification";
import { SubmitHandler } from "react-hook-form";

export interface WalkThroughStep {
  target: string;
  content: string | ReactNode;
  disableBeacon: boolean;
  placement: string;
  title: string;
  width?: number;
}

export function Walkthrough() {
  const {
    walkthroughStatus,
    walkthroughType: type,
    reactJoyrideId,
    attractionId: attractionIdState,
  } = useAppSelector((state) => state.walkthrough);
  const dispatch = useAppDispatch();
  const { attractionId } = useParams();

  const [startModalStep, setStartModalStep] = useState("");
  const [endModalStep, setEndModalStep] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [updateAttraction] = apiSlice.useUpdateAttractionDraftMutation();
  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<{}> = async () => {
      const payload = {};

      const results = await updateAttraction({
        id: attractionId,
        publish,
        payload: payload,
      });
      if (!isSilent) {
        restartAnimation();
      }

      return "data" in results;
    };

    return fn;
  };

  let timeout: NodeJS.Timeout;
  const handleResize = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(walkthroughActions.refreshJoyrideId());
    }, 500);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const windowSize = useWindowResize();
  const tooltipsMapper: Record<WalkthroughType, WalkThroughStep[]> =
    useMemo(() => {
      const editAttraction = [
        {
          target: "#editAttractionFieldsExample",
          title: "Edit information",
          content: (
            <>
              To change any attraction details simply make changes within the
              form.{" "}
              <span className="font-bold">
                Remember to scroll down to the purple ‘Save changes’ button to
                ensure your updates are saved.
              </span>
            </>
          ),
          disableBeacon: true,
          placement: "right",
        },
        {
          target: "#editAttractionLightBulbExample",
          title: "VO Score improvements",
          content:
            "Following the bulb icon, you can see where improvements can be made to increase your VO Score. Simply hover over the icon to reveal the suggestions to improve your score.",
          disableBeacon: true,
          placement: "right",
        },
      ];
      const newAttractionDone = [
        {
          target: "#attractionOptimizationScoreNavigation",
          title: "Venue Optimisation Score (VO Score)",
          content: (
            <>
              With the information you submitted, we have calculated your
              attraction’s VO Score. To improve your score, simply edit your
              attraction information and follow our prompts.
             <Link
                to={
                  location.pathname.split("/").slice(0, 3).join("/") +
                  "/venue-optimisation-score"
                }
                className="font-bold block mt-2.5 underline"
              >
                Find out more
              </Link>
            </>
          ),
          disableBeacon: true,
          placement: "right",
        },
      ].filter(item => {
        if (!window.connectFeatureFlags?.venueOptScore && item.target === '#attractionOptimizationScoreNavigation') return false;
        return true
      });
      const newAttraction: any[] = [
      ];
      const dashboard = [
        {
          target: "#yourAttractions",
          title: "Your Attractions",
          content: "Your attractions will appear in this carousel.",
          disableBeacon: true,
          placement: "left-start",
          width: 327,
          noWrap: true,
        },
        {
          target: "#attractionsBadgesExample",
          title: "Attraction Status",
          disableBeacon: true,
          placement: "left",
          width: 327,
          content: (
            <div className="text-sm font-normal text-[#212121] flex flex-col gap-2.5">
              <p>
                <span className="font-bold">‘Verified’</span> - once an
                attraction has been claimed, a special verified tick will appear
                on the attraction card.
              </p>
              <p>
                <span className="font-bold">‘Active’</span> - the attraction has
                been approved and is live.
              </p>
              <p>
                <span className="font-bold">‘Pending Approval’</span> - our
                internal team are yet to approve your listing. Please allow up
                to 5 working days.
              </p>
              <p>
                <span className="font-bold">‘Temporarily Closed’</span> - the
                attraction can still be viewed on DOWTK but will show as closed.
              </p>
              <p>
                <span className="font-bold">‘Incomplete’</span> - attraction
                details have not been completed, take action as soon as
                possible.
              </p>
            </div>
          ),
        },
        {
          target: "#attractionVOExample",
          title: "Venue Optimisation Score",
          content: (
            <div>
              <p>
                Venue Optimisation Score - once your attraction is live, a VO
                Score will be calculated. This shows the optimisation level of a
                venue’s listing on Day Out With The Kids.
              </p>
              <p className="mt-2.5">
                You’ll get regular tips and insights to help you improve this
                score.
              </p>

              <button type="button" className="mt-2.5 font-bold">
                Upgrade To Access Your VO Score
              </button>
            </div>
          ),
          disableBeacon: true,
          placement: "left",
          width: 327,
        },

        {
          target: "#analyticsSection",
          title: "Analytics",
          content: (
            <div>
              Access all analytics associated with your listing, including
              impressions, page views and link clicks.
              <div className="text-sm font-bold text-[#212121] mt-2.5 flex items-center justify-center gap-2.5">
                <span className="text-2xl">🏆</span>
                <div>
                  <div>Upgrade To Unlock Analytics</div>
                </div>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.24219 10.5156L8.75781 7L5.24219 3.48437"
                    stroke="#212121"
                    strokeWidth="1.17188"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          ),
          disableBeacon: true,
          placement: "left-start",
          width: 327,
        },
      ].filter(item => {
        if (!window.connectFeatureFlags?.venueOptScore && item.target === '#attractionVOExample') return false;
        return true
      });;

      const desktopOnly = [
        "#navigation",
        "#attractionOptimizationScoreNavigation",
      ];
      const filterDesktopOnly = (fields: { target: string }) => {
        if ((windowSize?.width ?? 1280) < 1280) {
          if (desktopOnly.includes(fields.target)) return false;
        }
        return true;
      };
      return {
        editAttraction: editAttraction.filter(filterDesktopOnly),
        newAttractionDone: newAttractionDone.filter(filterDesktopOnly),

        newAttraction: newAttraction.filter(filterDesktopOnly),
        dashboard: dashboard.filter(filterDesktopOnly),
        newClaimedAttraction: []
      };
    }, [location, windowSize.width]);

  const endModalsMapper = useMemo<
    Record<
      WalkthroughType,
      {
        id: string;
        heading: ReactNode;
        content: ReactNode;
        buttons: ReactNode;
        className?: string;
      }[]
    >
  >(
    () => ({
      editAttraction: [],
      newClaimedAttraction: [],
      newAttractionDone: [
        // {
        //   id: "finish",
        //   heading: (
        //     <div className="pt-[26px]">Attraction Onboarding Complete</div>
        //   ),
        //   content: (
        //     <div className="pr-16">
        //       <p>
        //         To get the most out of your listing, improve your attraction’s
        //         VO Score before going live by clicking ‘Improve VO Score’. Or,
        //         if you are happy with your content please press ‘Publish’.
        //       </p>
        //     </div>
        //   ),
        //   buttons: (
        //     <div className="flex gap-2.5">
        //       <PublishButtons
        //         attractionId={attractionId ?? ""}
        //         previewButton={() => <></>}
        //         rawSaveFunction={
        //           onSubmit as (
        //             publish: boolean,
        //             isSilent?: boolean
        //           ) => () => Promise<EntityDetailsResponse<AttractionDetails>>
        //         }
        //         getPayload={() => ({})}
        //         publishButton={({ onClick, loading }) => (
        //           <OutlineButton
        //             type="button"
        //             scale={"sm"}
        //             onClick={async () => {
        //               await actionsMapper["newAttractionDone"]();
        //               await onClick();
        //               stop();
        //             }}
        //             className="px-[30px]"
        //           >
        //             Publish
        //           </OutlineButton>
        //         )}
        //       />

        //       {window.connectFeatureFlags?.venueOptScore && <PrimaryButton
        //         type="button"
        //         scale={"sm"}
        //         onClick={async () => {
        //           await actionsMapper["newAttractionDone"]();

        //           const id = attractionId || location.pathname.split("/")[2];
        //           navigate(
        //             `/attractions/${
        //               id === "undefined" || !id ? attractionIdState : id
        //             }/venue-optimisation-score`
        //           );
        //           stop();
        //         }}
        //         className="px-[30px]"
        //       >
        //         Improve VO Score
        //       </PrimaryButton>}
        //     </div>
        //   ),
        // },
      ],
      newAttraction: [],
      dashboard: [
        {
          id: "finish",
          heading: <div className="pt-[26px]">Walkthrough Complete! 🎉</div>,
          content: (
            <div className="pr-16">
              <p>
                Now that you're familiar with what your dashboard offers,
                explore on your own or add your first attraction!
              </p>
              <p className="mt-5">
                If you have any questions regarding Connect, please feel free to{" "}
                <a
                  href="mailto:connect@dayoutwiththekids.co.uk"
                  className="border-b border-[#202020] cursor-pointer"
                >
                  contact us
                </a>
                .
              </p>
            </div>
          ),
          buttons: (
            <div className="flex gap-2.5">
              <PrimaryButton
                type="button"
                scale={"sm"}
                onClick={async () => {
                  await actionsMapper["dashboard"]();
                  stop();
                }}
                className="px-[30px]"
              >
                Finish
              </PrimaryButton>
            </div>
          ),
        },
      ],
    }),
    []
  );

  const startModalsMapper = useMemo<
    Record<
      WalkthroughType,
      {
        id: string;
        heading: ReactNode;
        content: ReactNode;
        className?: string;
        noContentMaxWidth?: boolean;
        heightClassName?: string;
        doodleClassName?: string;
        doodleImage?: string;
        customButtonText?: string
      }[]
    >
  >(
    () => ({
      editAttraction: [
        {
          id: "welcome",
          heading: (
            <div className="pt-[26px]">
              Welcome to the <br />
              Add Attraction Page 👋
            </div>
          ),
          content: (
            <div className="pr-16">
              <p>
                This is where you can update any attraction details, change your
                opening times, upload any new images and so much more.
              </p>
            </div>
          ),
        },
      ],
      newAttractionDone: [
        // {
        //   id: "welcome",
        //   noContentMaxWidth: true,
        //   heightClassName: "min-h-[326px]",
        //   className: "pt-[38px] pb-[90px]",
        //   heading: (
        //     <div className="pt-[7px]">Woohoo! Onboarding is complete 🎉</div>
        //   ),
        //   content: (
        //     <div>
        //       <p>You made it! Thanks for completing all of your information.</p>
        //       <p className="block mt-5">
        //         Now we’ll take you through a quick tour of the platform so you
        //         can get the most benefit out of it.
        //       </p>
        //     </div>
        //   ),
        // },
      ],
      newAttraction: [
        {
          id: "welcome",
          className: "pt-[30px] pb-[33px]",
          doodleClassName: 'bottom-[-15px] -rotate-[2.37deg] left-0',
          heading: (
            <>
              Welcome to the <br />
              Add Attraction Page 👋
            </>
          ),
          content: (
            <>
              Before your listing goes live on Day Out With The Kids, we need as
              much information about your attraction as possible.
              <br />
              <br />
              To make the process as simple as possible, we’ve added prompts to
              help you in optimise your listing to it’s best potential!
            </>
          ),
        },
      ],
      newClaimedAttraction: [
        {
          id: "welcome",
          className: "pt-[30px] pb-[33px]",
          doodleClassName: 'bottom-[-15px] -rotate-[2.37deg] left-0',
          customButtonText: 'Review & Unlock Now',
          heading: (
            <>
              Review & Update Your Listing 🫶 
            </>
          ),
          content: (
            <>
              It might have been some time since your listing content was updated. Please take the time now to review and update all areas of your listing where possible.
              <br />
              <br />
              Once you have reviewed everything, all sections will then become unlocked making it easy for you to dive straight into any section in the future.
              <br />
              <br />
              <span className="font-bold">To make the process as simple as possible, we’ve added prompts to help you in optimise your listing to it’s best potential!</span>
            </>
          ),
        },
      ],
      dashboard: [
        {
          id: "welcome",
          doodleClassName: "top-0 right-0",
          doodleImage: "/assets/amp/doodle-rotated.png",
          heading: (
            <div className="pt-[26px]">
              Welcome to your <br />
              Connect dashboard 👋
            </div>
          ),
          content: (
            <>
              <div className="pr-5">
                The “one stop shop” for enhancing your listings and maximising
                visibility on our platform! Let us show you around.
              </div>
              <div className="mt-5">
                <h2 className="font-fredoka text-[#2D1838] font-normal text-[20px]">
                  📈 Grow Your Business
                </h2>
                <p className="text-[#212121] text-sm font-normal mt-2.5">
                  Gain access to analytics, tailored marketing packages and
                  strategies for increased visibility and growth.
                </p>
              </div>
              <div className="mt-5">
                <h2 className="font-fredoka text-[#2D1838] font-normal text-[20px]">
                  👩‍💻 Update Your Listings
                </h2>
                <p className="text-[#212121] text-sm font-normal mt-2.5">
                  Make sure your content remains current and up-to-date to
                  elevate your listing’s rankings.
                </p>
              </div>
             {window.connectFeatureFlags?.venueOptScore && <div className="mt-5">
                <h2 className="font-fredoka text-[#2D1838] font-normal text-[20px]">
                  ✨ Improve Venue Optimisation Score (VO Score)
                </h2>
                <p className="text-[#212121] text-sm font-normal mt-2.5">
                  Boost your presence on our platform through VOS, our site's
                  revolutionary AI algorithm, and experience heightened
                  visibility like never before.
                </p>
              </div>}
            </>
          ),
        },
      ],
    }),
    []
  );

  const [newDoneWalkthrough] = apiSlice.useDoneWalkthroughMutation();
  const [editDoneWalkthrough] = apiSlice.useEditDoneWalkthroughMutation();
  const [finishedOnboarding] = apiSlice.useFinishedOnboardingMutation();
  const [userDoneWalkthrough] = apiSlice.useUserDoneWalkthroughMutation();
  const [sessionDataTrigger] = apiSlice.useLazySessionQuery();

  const actionsMapper = useMemo<Record<WalkthroughType, () => Promise<void>>>(
    () => ({
      editAttraction: async () => {
        const attractionId = location.pathname.split("/").filter((v) => v)[1];
        if (!attractionId) return;

        await editDoneWalkthrough(attractionId);
      },
      newAttraction: async () => {
        const attractionId = location.pathname.split("/").filter((v) => v)[1];
        if (!attractionId) return;

        await newDoneWalkthrough(attractionId);
      },
      newClaimedAttraction: async () => {
        const attractionId = location.pathname.split("/").filter((v) => v)[1];
        if (!attractionId) return;

        await newDoneWalkthrough(attractionId);
      },
      dashboard: async () => {
        await userDoneWalkthrough();
        await sessionDataTrigger(undefined);
      },
      newAttractionDone: async () => {
        if (!attractionId) return;

        await finishedOnboarding(attractionId);
        navigate('/dashboard')
      },
    }),
    [
      location.pathname,
      attractionId,
      newDoneWalkthrough,
      editDoneWalkthrough,
      userDoneWalkthrough,
      finishedOnboarding,
      sessionDataTrigger,
      navigate
    ]
  );

  useEffect(() => {
    if (!startModalStep && startModalsMapper[type][0]) {
      setStartModalStep(startModalsMapper[type][0].id);
    }
  }, [type, startModalsMapper, startModalStep]);
  useEffect(() => {
    if (!endModalStep && endModalsMapper[type][0]) {
      setEndModalStep(endModalsMapper[type][0].id);
    }
  }, [type, endModalsMapper, endModalStep]);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    if (data.status === 'running') {
      document.body.classList.add('!overflow-x-hidden')
    } else {
      document.body.classList.remove('!overflow-x-hidden')
    }
    const { status, index: stepIndex } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      if (endModalsMapper[type].length > 0) {
        dispatch(walkthroughActions.setWalkthroughStatus("stopModal"));
      } else {
        stop();

        const finishAction = actionsMapper[type];
        await finishAction?.();
      }
    }

    if (tooltipsMapper[type][stepIndex].target) {
      dispatch(walkthroughActions.setJoyrideStepTarget(tooltipsMapper[type][stepIndex].target || ""));
    }
  };

  function startJoyride() {
    dispatch(walkthroughActions.setWalkthroughStatus("tooltips"));
    setTimeout(() => {
      dispatch(walkthroughActions.refreshJoyrideId());
    }, 100)
  }

  function stop() {
    dispatch(walkthroughActions.setWalkthroughStatus("none"));
    setStartModalStep("");
    setEndModalStep("");
  }

  useEffect(() => {
    if (["startModal", "stopModal"].includes(walkthroughStatus)) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }

    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [walkthroughStatus]);

  return (
    <>
      <Joyride
        key={reactJoyrideId}
        callback={handleJoyrideCallback}
        steps={tooltipsMapper[type] as any}
        run={walkthroughStatus === "tooltips"}
        disableScrolling={type === "editAttraction"}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: "transparent",
          },
        }}
        continuous
        hideCloseButton
        scrollToFirstStep
        scrollOffset={300}
        showProgress
        tooltipComponent={Tooltip}
        disableOverlayClose={true}
        floaterProps={{
          disableFlip: true
        }}
      />
      {startModalsMapper[type].map((modal, index) => (
        <StartModal
          key={modal.id}
          heading={modal.heading}
          content={modal.content}
          doodleClassName={modal.doodleClassName}
          doodleImage={modal.doodleImage}
          customButtonText={modal.customButtonText}
          open={
            walkthroughStatus === "startModal" && startModalStep === modal.id
          }
          onNext={async () => {
            const lastStep =
              startModalsMapper[type][startModalsMapper[type].length - 1];
            if (lastStep.id === modal.id) {
              if (tooltipsMapper[type].length > 0) {
                startJoyride();
              } else {
                const finishAction = actionsMapper[type];
                await finishAction?.();
                stop();
              }
            } else {
              setStartModalStep(startModalsMapper[type][index + 1].id);
            }
          }}
          className={modal.className}
          final={tooltipsMapper[type].length === 0}
          noContentMaxWidth={!!modal?.noContentMaxWidth}
          heightClassName={modal?.heightClassName}
        />
      ))}
      {endModalsMapper[type].map((modal, index) => (
        <GeneralModal
          key={modal.id}
          heading={modal.heading}
          content={modal.content}
          open={walkthroughStatus === "stopModal" && endModalStep === modal.id}
          buttons={modal.buttons}
          className={modal.className}
        />
      ))}
    </>
  );
}

function Tooltip(props: any) {
  const {
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    size,
  } = props;

  const width = step.width || 398;
  return (
    <div
      className="bg-white min-h-[160px] rounded-lg py-[22px] px-5 relative"
      style={{
        width: `${width}px`,
      }}
      {...tooltipProps}
    >
      <img
        src={"/assets/amp/doodle-6.png"}
        className="xl:block not-sr-only absolute bottom-0 left-0 select-none pointer-events-none"
        alt=""
      />
      <div className="relative z-10">
        {step.title && (
          <h3 className="font-fredoka text-lg text-[#2D1838] flex justify-between items-center">
            {step.title}

            <span className="font-sans font-bold text-sm">
              {index + 1}/{size}
            </span>
          </h3>
        )}
        <div
          className={`mt-2.5 font-normal text-sm leading-[21px] ${
            step.noWrap ? "whitespace-nowrap" : ""
          }`}
        >
          {step.content}
        </div>
        <div className="mt-2.5 flex gap-2 justify-end">
          {/* {index > 0 && (
            <OutlineButton
              type="button"
              scale="sm"
              className="px-[30px] w-[92px]"
              {...backProps}
            >
              Back
            </OutlineButton>
          )} */}
          {continuous && (
            <PrimaryButton
              type="button"
              scale="sm"
              className="px-[30px] cursor-pointer"
              {...primaryProps}
            >
              {isLastStep ? "Done" : "Next"}
            </PrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
}

function GeneralModal({
  open,
  heading,
  content,
  buttons,
  className = "",
  noContentMaxWidth = false,
  heightClassName,
  doodleClassName = "",
  doodleImage,
}: {
  open: boolean;
  heading: string | ReactNode;
  content: string | ReactNode;
  buttons: ReactNode;
  className?: string;
  noContentMaxWidth?: boolean;
  heightClassName?: string;
  doodleClassName?: string;
  doodleImage?: string;
}) {
  return (
    <ModalWrapper
      className="w-[95%] md:w-[768px]"
      open={open}
      setOpen={() => null}
      closeOnOverlay={false}
      hideCloseButton={true}
      customContainerClassName={`relative overflow-y-auto flex flex-col w-full pl-[43px] pr-[20px] bg-white rounded-lg overflow-y-auto overflow-x-hidden ${
        heightClassName ? heightClassName : "min-h-[421px]"
      } ${className || "pt-[38px] pb-[25px]"}`}
    >
      <img
        src={doodleImage ? doodleImage : "/assets/amp/doodle.png"}
        className={`xl:block not-sr-only absolute select-none pointer-events-none ${
          doodleClassName
            ? doodleClassName
            : "bottom-[18px] -rotate-[2.37deg] left-0"
        }`}
        alt=""
      />
      <h3 className="font-fredoka text-4xl text-[#2D1838] leading-[43px]">
        {heading}
      </h3>

      <div
        className={`mt-5 text-[#212121] text-sm leading-[21px] font-normal ${
          !noContentMaxWidth ? "max-w-[495px]" : ""
        }`}
      >
        {content}
      </div>

      <div className="mt-auto flex justify-end relative z-10">{buttons}</div>
    </ModalWrapper>
  );
}

function StartModal({
  open,
  heading,
  content,
  onNext,
  className = "",
  final = false,
  noContentMaxWidth = false,
  heightClassName,
  doodleClassName = "",
  doodleImage,
  customButtonText,
}: {
  open: boolean;
  heading: string | ReactNode;
  content: string | ReactNode;
  onNext: Function;
  className?: string;
  final?: boolean;
  noContentMaxWidth?: boolean;
  heightClassName?: string;
  doodleClassName?: string;
  doodleImage?: string;
  customButtonText?: string
}) {
  return (
    <GeneralModal
      open={open}
      heading={heading}
      content={content}
      noContentMaxWidth={noContentMaxWidth}
      heightClassName={heightClassName}
      doodleClassName={doodleClassName}
      doodleImage={doodleImage}
      buttons={
        <>
          <PrimaryButton
            type="button"
            scale={"sm"}
            onClick={() => onNext()}
            className="px-[30px] cursor-pointer"
          >
            {customButtonText ? customButtonText : (final ? "Get Started" : "Next")}
          </PrimaryButton>
        </>
      }
      className={className}
    />
  );
}
