export function debounce(fn: Function, delay = 250) {
    let timeout: NodeJS.Timeout | null = null;

    return (...args: any) => {
        clearTimeout(timeout!);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}
