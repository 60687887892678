import React, { useEffect, useRef, useState } from "react";
import { CellProps, Column, TableInstance, useGlobalFilter, usePagination, UsePaginationInstanceProps, UsePaginationState, useSortBy, UseSortByInstanceProps, useTable } from "react-table";
import { AmpUserDetail } from "../../types/user";
import { createSearchParams, useSearchParams } from "react-router-dom";
import Table from "../../lib/react-table/Table";
import { TableTextNormal } from "../../components/global/TableTextNormal";
import ContextMenu from "../../components/global/ContextMenu";
import { apiSlice } from "../../store/apiSlice";
import DashboardHeaderContainer from "../../components/amp/DashboardHeaderContainer";
import { SearchField } from "../../components/global/SearchField";
import DashboardContainer from "../../components/amp/DashboardContainer";
import Footer from "../../components/global/amp/Footer";

interface ICreators{
  activeTab: string;
  setActiveTab: (val: string) => void
}

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
};

const Creators: React.FC<ICreators> = ({ activeTab, setActiveTab }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
    const [getQueryPage, setQueryPage] = useState<string>(
      searchParams.get("page")
        ? parseInt(searchParams?.get("page") ?? "0").toString()
        : "0"
    );
    const [getQuerySortBy, setQuerySortBy] = useState<string>("first_name");
    const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
    const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");
    const [getFilter, setFilter] = useState<string>("creator");

    const prevQuerySearch = useRef(getQuerySearchQuery);

    const { data: tableData, isFetching } = apiSlice.useGetUsersQuery({
      pageSize: getQueryPageSize,
      page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
      sortBy: getQuerySortBy,
      query: getQuerySearchQuery,
      sortDesc: getQuerySortDesc,
      role: "CONTENT_CREATOR",
    });

    const columns = React.useMemo<Column<AmpUserDetail>[]>(
        () =>[
                {
                    Header: "First Name",
                    accessor: "first_name",
                    width: "18%",
                    Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                    return (
                        <>
                        <div className="w-[7rem]">
                            <TableTextNormal className="w-full">
                            {original.first_name}
                            </TableTextNormal>
                        </div>
                        </>
                    );
                    },
                },
                {
                    Header: "Last Name",
                    accessor: "last_name",
                    width: "12%",
                    Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                    return (
                        <>
                        <div className="w-[7rem]">
                            <TableTextNormal className="w-full">
                            {original.last_name}
                            </TableTextNormal>
                        </div>
                        </>
                    );
                    },
                },
                {
                  Header: "Email",
                  accessor: (data) => data.email,
                  width: "35%",
                  id: 'email',
                },
                {
                    Header: "Age Range",
                    accessor: "age_range",
                    width: "15%",
                    Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                    return (
                        <>
                        <div className="w-[7rem]">
                            <TableTextNormal className="w-full">
                            {original.age_range}
                            </TableTextNormal>
                        </div>
                        </>
                    );
                    },
                },
                {
                    Header: "Closest city",
                    accessor: "city",
                    width: "15%",
                    Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                    return (
                        <>
                        <div className="w-[7rem]">
                            <TableTextNormal className="w-full">
                            {original.city}
                            </TableTextNormal>
                        </div>
                        </>
                    );
                    },
                },
                {
                    Header: "",
                    accessor: "id",
                    disableSortBy: true,
                    Cell: ({ row: { original } }: CellProps<AmpUserDetail>) => {
                      return (
                        <ContextMenu
                          options={[
                            {
                              name: "View / Edit",
                              linkProps: {
                                to: {
                                  pathname: `/content-user/${original.id}`,
                                  search: createSearchParams({
                                    email: original.email,
                                  }).toString(),
                                },
                              },
                            },
                          ]}
                        />
                      );
                    },
                    disableGlobalFilter: true,
                    width: "7.35%",
                },
            ]
        ,[]
    );
    
    const data = React.useMemo<AmpUserDetail[]>(
        () => tableData?.results ?? ([] as AmpUserDetail[]),
        [tableData]
    );

    const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      setGlobalFilter(value);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setGlobalFilter,
        pageOptions,
        gotoPage,
        setPageSize,
        setSortBy,
        state: { pageSize, pageIndex, sortBy, globalFilter },
      } = useTable<AmpUserDetail>(
        {
          columns,
          data,
          autoResetPage: false,
          manualPagination: true,
          manualSortBy: true,
          pageCount: tableData?.total,
          disableSortRemove: true,
          initialState: {
            hiddenColumns: [],
            pageSize:
              parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
            pageIndex: parseInt(getQueryPage),
            globalFilter: searchParams.get("query") || getQuerySearchQuery,
            sortBy: [
              {
                id: searchParams.get("sortBy") || getQuerySortBy,
                desc:
                  searchParams.get("sortDesc") === "true" ||
                  getQuerySortDesc !== "false",
              },
            ],
          },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    ) as TableInstanceWithHooks<AmpUserDetail>;

    useEffect(() => {
      if (!isFetching) {
        setSearchParams(
          {
            pageSize: pageSize.toString(),
            page: pageIndex.toString(),
            query: globalFilter ? globalFilter : "",
            sortBy: sortBy[0].id.toLowerCase(),
            sortDesc: sortBy[0].desc!.toString(),
          },
          { replace: JSON.stringify(searchParams) === "{}" }
        );
  
        setQueryPageSize(pageSize.toString());
        setQueryPage(pageIndex.toString());
        setQuerySearchQuery(globalFilter ? globalFilter : "");
        setQuerySortBy(sortBy[0].id.toLowerCase());
        setQuerySortDesc(sortBy[0].desc!.toString());
      }
    }, [
      pageSize,
      pageIndex,
      sortBy[0].id,
      globalFilter,
      sortBy[0].desc,
      isFetching,
    ]);

    return(
      <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
        <DashboardHeaderContainer slim={true}>
            <div className="flex items-center justify-between flex-grow">
                <h1 className="text-xl font-extrabold text-black">Family Creator Network</h1>
                <div className="relative w-full max-w-cs-320">
                    <SearchField
                        parentClassName="max-w-full w-[437px] ml-auto"
                        defaultValue={searchParams.get("query") ?? ""}
                        onChange={handleFilterInputChange}
                        placeholder={`Search for a creator`}
                    />
                </div>
            </div>
        </DashboardHeaderContainer>
        <DashboardContainer>
          <div className="flex gap-x-3 px-5 xl:px-[20px]">
            <button 
                className={`border border-black rounded-lg px-4 py-2 text-sm font-semibold ${activeTab === "Diaries" ? "bg-cs-pink border-cs-pink text-white" : ""}`}
                onClick={() => { setActiveTab("Diaries"); setSearchParams("")}}
            >
                All Diaries
            </button>
            <button 
                className={`border border-black rounded-lg px-4 py-2 text-sm font-semibold ${activeTab === "Creators" ? "bg-cs-pink border-cs-pink text-white" : ""}`}
                onClick={() => {setActiveTab("Creators"); setSearchParams("")}}
            >
                All Creators
            </button>
          </div>
          <section className="flex flex-col pt-4 px-5 xl:px-[20px]">
              <div id="creatorsTable" className="max-w-[calc(100vw-3rem)]">
                  <Table<AmpUserDetail>
                      // <table id>
                      tableId="manageUsersTable"
                      // <table className>
                      tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
                      // <thead className>
                      tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl [&:nth-last-child(2)]:rounded-r-xl"
                      // <tbody className>
                      tableTbodyClassNames={`relative ${
                          page.length === 0 ? "h-[60px]" : ""
                      }`}
                      // Mobile <tbody className>
                      tableTbodyMobileClassNames="block"
                      // <tbody><tr className>
                      tableTbodyTrClassNames="h-1 last-of-type:hidden"
                      // Empty <tbody><tr className>
                      tableTbodyTrEmptyMobileClassNames="border-none"
                      // Mobile <tbody><tr className>
                      tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative border-2 border-cs-off-white font-sm font-normal text-black rounded-xl p-3 bg-white"
                      // <tbody><tr><td className>
                      tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
                      // Empty <tbody><tr><td className>
                      tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
                      // Mobile <tbody><tr><td className>
                      tableTbodyTrEmptyTdMobileClassNames="h-2"
                      // Hide Columns On Mobile
                      hideColumnOnMobile={[]}
                      fullWidthMobileTd={[]}
                      spacedMobileTd={[]}
                      // react-table props
                      getTableProps={getTableProps}
                      getTableBodyProps={getTableBodyProps}
                      headerGroups={headerGroups}
                      page={page}
                      pages={tableData?.total}
                      prepareRow={prepareRow}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      pageOptions={pageOptions}
                      gotoPage={gotoPage}
                      setPageSize={setPageSize}
                      isFetching={isFetching}
                  />
              </div>
          </section>
        </DashboardContainer>
        <Footer />
      </div>
    )
}

export default Creators;