import {
  ScrollRestoration,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import {
  SearchClaimModal,
  SubmittedModal,
} from "../../components/global/www/modals/SearchClaimModal";
import { useAuthUser } from "../../hooks/useAuth";
import type { AttractionMinimal } from "../../components/global/www/modals/CreateClaimModal";
import { useAppSelector } from "../../store";
import { TwoColTemplate } from "../../components/global/TwoColTemplate";
import { apiSlice } from "../../store/apiSlice";
import { globalActions } from "../../store/globalSlice";
import { useDispatch } from "react-redux";
import { restartAnimation } from "../../components/global/amp/ToastNotification";

const SearchClaim: React.FC = () => {
  const { state } = useLocation();
  const { user } = useAppSelector((state) => state.global);
  const navigate = useNavigate();

  const { loggedInClaim } = (state as {
    loggedInClaim: AttractionMinimal | null;
  }) ?? { loggedInClaim: null };
  const { isLoggedIn } = useAuthUser();
  const [showSubmittedModal, setSubmittedModal] = useState<AttractionMinimal>({
    id: loggedInClaim?.id ?? "",
    title: loggedInClaim?.title ?? "",
    location: loggedInClaim?.location ?? "",
    image: loggedInClaim?.image ?? "",
    image_alt: loggedInClaim?.image_alt ?? "",

    phone: loggedInClaim?.phone ?? undefined,
    website: loggedInClaim?.website ?? undefined,
    postcode: loggedInClaim?.postcode ?? undefined,
    location_name: loggedInClaim?.location_name ?? undefined,
  });

  const [getSingleAttraction] = apiSlice.useLazySearchSingleAttractionQuery();
  const [searchParams] = useSearchParams();
  const attractionQuery = searchParams.get("attraction");
  const { toastNotification } = useAppSelector((state) => state.global);
  const [isFetchingAttractionData, setIFetchingAttractionData] =
    useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (attractionQuery) {
        setIFetchingAttractionData(true);
        const { data, isError } = await getSingleAttraction(attractionQuery);

        if (!data || !data.result || isError) {
          dispatch(
            globalActions.setToastNotifcation({
              ...toastNotification,
              type: "ERROR",
              message: "Attraction not found.",
              attractionApprovalStatus: "",
              attractionImage: "",
              attractionName: "",
              attractionAddress: "",
            })
          );
          restartAnimation();
        } else {
          const { result } = data;
          setSubmittedModal({
            id: result.short_id,
            title: result.name ?? "-",
            location: result.city ?? "-",
            image:
              result?.primary_image_media?.url || result?.media_1?.url || "",
            image_alt:
              result?.primary_image_media?.alt_text ||
              result?.media_1?.alt_text ||
              result?.name ||
              "-",

            phone: result?.phone ?? "-",
            website: result?.website ?? "-",
            postcode: result?.postcode ?? "-",
            location_name: result.city ?? "-",
          });
        }
        setIFetchingAttractionData(false);
      }
    })();
  }, [attractionQuery]);

  useEffect(() => {
    if (isLoggedIn && user?.role === "ATTRACTION_MANAGER") {
      navigate("/forbidden");
    }
  }, []);

  return (
    <>
      <ScrollRestoration />
      <TwoColTemplate
        sidebarText={
          showSubmittedModal.id && showSubmittedModal.id !== "-1"
            ? "Tailor your listing content, add images, and highlight what sets your business apart from competitors."
            : showSubmittedModal.id
            ? 'With over 500% more visitors than any other family-focused "things to do" website, capture the attention of your ideal audience.'
            : "Showcase the best of your brand to our family audience... with over 35 million annual visitors, searching for things to do."
        }
        sidebarImage={
          showSubmittedModal.id && showSubmittedModal.id !== "-1" ? (
            "/assets/www/activate-account-image.png"
          ) : (
            <>
              <img
                src={"/assets/www/login-image-2.png"}
                alt=""
                className={showSubmittedModal.id ? `hidden` : ""}
              />
              <img
                src={"/assets/www/login-image-2.1.png"}
                alt=""
                className={`${
                  !showSubmittedModal.id ? `hidden` : ""
                } scale-[1.3] translate-y-8 mb-16`}
              />
            </>
          )
        }
        imageClassName={
          showSubmittedModal.id && showSubmittedModal.id === "-1"
            ? "scale-[1.3] mb-[50px]"
            : ""
        }
      >
        <section className="flex flex-col items-center justify-center flex-1">
          {isLoggedIn && (
            <button
              className="flex items-center gap-2 text-sm mt-10 w-full mb-2"
              onClick={() => navigate("/dashboard")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M16 22L6 12L16 2l1.775 1.775L9.55 12l8.225 8.225L16 22Z"
                />
              </svg>
              Back to dashboard
            </button>
          )}
          {isFetchingAttractionData ? (
            <div className="py-10 min-w-[440px] min-h-[296px] flex justify-center items-center">
              <LoadingIndicator />
            </div>
          ) : (
            <>
              {showSubmittedModal.id ? (
                <SubmittedModal
                  attraction={showSubmittedModal}
                  setSubmittedModal={setSubmittedModal}
                />
              ) : (
                <SearchClaimModal setSubmittedModal={setSubmittedModal} />
              )}
            </>
          )}
        </section>
      </TwoColTemplate>
    </>
  );
};

export default SearchClaim;

const LoadingIndicator: React.FC = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-cs-pink"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
