import React, { useState } from "react";
import { Control, Controller, useController } from "react-hook-form";
import { TickIcon } from "../misc/Icons";

interface IRatingInput{
    label: string;
    subLabel?: string;
    name: string;
    control: Control<any>
}

const RatingInput: React.FC<IRatingInput> = ({ label, subLabel, name, control }) => {
    const [hover, setHover] = useState<number | null>(null);
    const { field: { onChange, value } } = useController({ name, control });


    const handleRatingClick = (ratingValue: number) => {
        if (ratingValue === value) {
          onChange(0);
        } else {
          onChange(ratingValue);
        }
      };

    return(
        <div className="w-full max-w-cs-400">
            <label className="block w-full text-sm font-bold text-center group-focus-within:text-cs-pink">
                {label}
            </label>
            <div className="text-sm font-normal text-center text-cs-gray my-1.5">{subLabel}</div>
            <div className="flex flex-wrap justify-center gap-2 mt-2">
                {[...Array(5)].map((_, index) => {
                    const ratingValue = index + 1;
                    return (
                        <label key={index}>
                            <input
                                type="radio"
                                name="rating"
                                value={ratingValue}
                                onClick={() => onChange(ratingValue)}
                                style={{ display: 'none' }}
                            />
                            <button className="" type="button" onMouseEnter={() => setHover(ratingValue)} onMouseLeave={() => setHover(null)} onClick={() => handleRatingClick(ratingValue)}>
                                <TickIcon svgPathClassName={ratingValue <= (hover || value) ? "fill-cs-pink" : ""}/>
                            </button>
                        </label>
                    );
                })}
            </div>
        </div>
    )
}

export default RatingInput;