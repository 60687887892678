import { components } from "react-select";

const Options: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  value,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    color: "#555",
    display: "flex",
    fontSize: 14,
    fontWeight: 400,
    height: 0,
    padding: 15,
    margin: 0
  };

  const props = {
    ...innerProps,
    style
  };

  return (
    <components.Option
    {...rest}
    isDisabled={isDisabled}
    isFocused={isFocused}
    isSelected={isSelected}
    getStyles={getStyles}
    innerProps={props}
    >
      <label className="checkbox-item" >
        <input className="!w-40 !h-3" type="checkbox" checked={isSelected || value.includes("true") ? true : false} readOnly/>
        <span className="checkmark"></span>
      </label>
      {children}
    </components.Option>
  );
};

export default Options;