import { useFormContext } from 'react-hook-form';

export function TicketDescription() {
  const { register } = useFormContext()
  return (
    <>
      <label htmlFor='description' className="block font-medium text-gray-700  rounded-md">
        Ticket Description
      </label>
      <textarea
        {...register('description')}
        className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
        rows={10} />
    </>);
}
