export function hasUrl(value: string) {
    const words = value.replace(/(<([^>]+)>)/gi, ' ').split(' ')
    let result = false
    
    words.forEach(w => {
        if (isValidUrl(w)) {
            result = true;
        }
    })

    return result
}

function isValidUrl(_string: string) {
    let url;
    try {
      url = new URL(_string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }