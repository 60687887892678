import React, { useEffect, useRef, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Table from "../../../lib/react-table/Table";
import Footer from "../../../components/global/amp/Footer";
import { apiSlice } from "../../../store/apiSlice";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { StepZeroCard } from "../ticketing/Onboarding";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { PrimaryButton } from "../../../components/global/PrimaryButton";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const AttractionTickets: React.FC<{
  isManagePage?: boolean;
}> = ({ isManagePage = false }) => {
  const { attractionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");

  const prevQuerySearch = useRef(getQuerySearchQuery);
  const { data: tableData, isFetching } = apiSlice.useGetProductListQuery({
    pageSize: getQueryPageSize,
    page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
    sortBy: "venue",
    query: getQuerySearchQuery,
    sortDesc: "false",
    filter: "all",
    venueShortId: attractionId as string,
  });

  const data = React.useMemo<TicketDetails[]>(
    () => tableData?.results ?? ([] as TicketDetails[]),
    [tableData]
  );

  const columns = React.useMemo<Column<TicketDetails>[]>(
    () => [
      {
        Header: "My Tickets",
        accessor: "name",
        id: "name",
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return <div className="text-sm font-normal">{original.name}</div>;
        },
        disableSortBy: true,
        sortType: (a: any, b: any) => {
          if (a.original?.name > b.original?.name) return 1;
          if (b.original?.name > a.original?.name) return -1;
          return 0;
        },
        width: "35%",
      },
      {
        Header: "Active",
        accessor: "status",
        id: "status",
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return (
            <div className="text-sm font-normal text-center">
              {original.status !== "pending" ? "Yes" : "No"}
            </div>
          );
        },
        disableSortBy: true,
        width: "12.5%",
      },
      {
        Header: "Adult",
        accessor: "default_price_formatted",
        id: "default_price_formatted",
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return (
            <div className="text-sm font-normal text-center">
              {original.tickets.find(t => t.category === 'adult')?.default_price_formatted || '-'}
            </div>
          );
        },
        disableSortBy: true,
        width: "12.5%",
      },
      {
        Header: "Child",
        accessor: "provider",
        id: "provider",
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return (
            <div className="text-sm font-normal text-center">
                 {original.tickets.find(t => t.category === 'child')?.default_price_formatted || '-'}
            </div>
          );
        },
        disableSortBy: true,
        width: "12.5%",
      },
      {
        Header: "Availability",
        accessor: "is_available",
        id: "is_available",
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          const capacity = original.tickets.find(t => t.category === 'child')?.capacity || 0
          return <div className="text-sm font-normal text-center">
            {(+capacity === 0 ? 'Unlimited' : capacity.toString()) || '-'}
          </div>;
        },
        disableSortBy: true,
        width: "12.5%",
      },
      {
        Header: "Total Sold",
        accessor: "pricing_options",
        id: "pricing_options",
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return <div className="text-sm font-normal text-center">{original.totalSold ?? '0'}</div>;
        },
        disableSortBy: true,
        width: "12.5%",
      },
      {
        Header: "",
        accessor: "id",
        id: "actions",
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<TicketDetails>) => {
          return (
            <div className="flex justify-center text-sm">
              <Link
                to={`/attractions/${attractionId}/ticketing/create/${original.id}`}
                className="flex flex-col items-center"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.1875 17.8125L6.04311 15.945C6.35368 15.8255 6.50897 15.7658 6.65425 15.6878C6.7833 15.6185 6.90632 15.5386 7.02204 15.4488C7.15231 15.3477 7.26995 15.2301 7.50524 14.9948L17.375 5.12502C18.3415 4.15852 18.3415 2.59152 17.375 1.62502C16.4085 0.658523 14.8415 0.658522 13.875 1.62502L4.00525 11.4948C3.76995 11.7301 3.65231 11.8477 3.55122 11.978C3.46143 12.0937 3.38148 12.2167 3.31221 12.3458C3.23422 12.4911 3.17449 12.6463 3.05504 12.9569L1.1875 17.8125ZM1.1875 17.8125L2.98836 13.1304C3.11722 12.7953 3.18166 12.6278 3.29217 12.551C3.38876 12.484 3.50827 12.4586 3.62376 12.4807C3.75592 12.5059 3.88284 12.6328 4.13667 12.8867L6.11338 14.8634C6.36721 15.1172 6.49413 15.2441 6.51937 15.3763C6.54142 15.4918 6.51606 15.6113 6.449 15.7079C6.37227 15.8184 6.20474 15.8828 5.86969 16.0117L1.1875 17.8125Z"
                    stroke="#565657"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="text-[8px] text-[#565657]">Edit</div>
              </Link>
            </div>
          );
        },
        disableGlobalFilter: true,
        width: "2%",
      },
    ],
    [attractionId]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageSize, pageIndex, sortBy, globalFilter },
  } = useTable<TicketDetails>(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: tableData?.total,
      disableSortRemove: true,
      initialState: {
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        pageIndex: parseInt(getQueryPage),
        globalFilter: searchParams.get("query") || getQuerySearchQuery,
        sortBy: [
          {
            id: searchParams.get("sortBy") ?? "venue",
            desc: searchParams.get("sortDesc") === "true",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<TicketDetails>;

  useEffect(() => {
    document.title = "Ticketing | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (getQuerySearchQuery !== prevQuerySearch.current) {
      setQueryPage("0");
      gotoPage(0);

      prevQuerySearch.current = getQuerySearchQuery;
    }
  }, [getQuerySearchQuery, gotoPage]);
  useEffect(() => {
    if (!isFetching) {
      setSearchParams(
        {
          pageSize: pageSize.toString(),
          page: pageIndex.toString(),
          query: globalFilter ? globalFilter : "",
          sortBy: sortBy[0].id.toLowerCase(),
          sortDesc: sortBy[0].desc!.toString(),
        },
        { replace: JSON.stringify(searchParams) === "{}" }
      );

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySearchQuery(globalFilter ? globalFilter : "");

      prevQuerySearch.current = getQuerySearchQuery;
    } else {
    }
  }, [
    pageSize,
    pageIndex,
    sortBy[0].id,
    globalFilter,
    sortBy[0].desc,
    isFetching,
    getQuerySearchQuery,
    searchParams,
    setSearchParams,
    sortBy,
  ]);

  const navigate = useNavigate();
  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA]">
      <DashboardHeaderContainer flexWrap={false} slim={true} >
        <div className="xl:mx-[50px] flex-grow flex flex-wrap justify-between gap-5 items-center">
          <h1 className="font-extrabold text-xl text-black mr-7 flex-grow">
            {isManagePage ? "Ticket Manager" : "Ticket Manager Dashboard"}
          </h1>

          <div className="flex ml-2.5 w-full xl:w-auto max-w-[210px]">
            <PrimaryButton
              scale="sm"
              className="mx-0 !leading-[10px] px-[31px] !bg-[#58CF12]"
              type="button"
              onClick={() => navigate(attractionId ? `/attractions/${attractionId}/ticketing/scan` : '/ticketing/scan')}
            >
              Scan / Redeem Ticket
            </PrimaryButton>
          </div>
        </div>
      </DashboardHeaderContainer>

      <DashboardContainer>
        {isManagePage ? (
          <div className="bg-white p-5 rounded-lg xl:mx-[70px]">
            <h1 className="font-extrabold text-[22px] text-[#212121]">
              🎟️ Manage Tickets
            </h1>
            <div className="flex gap-[50px] items-center sm:flex-nowrap flex-wrap">
              <p className="text-[#5F646D] text-sm font-normal mt-2.5">
                Here you can view all the tickets associated with your account.
                You can edit any ticket by clicking the pencil icon, and you can
                add new tickets as needed by click on the ‘ADD NEW TICKET’ button.
              </p>
              <PrimaryButton
                scale="sm"
                className="ml-auto !leading-[10px] px-[31px]"
                type="button"
                onClick={() => navigate(`/attractions/${attractionId}/ticketing/create`)}
              >
                ADD NEW TICKET
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <div className="mt-[20px] gap-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:mx-[70px] lg:px-0 px-5">
            <StepZeroCard
              title="Add New Ticket"
              desc="Easily add new attraction tickets here. You can specify type, price and availability."
              cta="Add Ticket"
              disabled={false}
              onClick={() =>
                navigate(`/attractions/${attractionId}/ticketing/create`)
              }
            />

            <StepZeroCard
              title="Manage Tickets"
              desc="Quickly edit or change existing tickets. Change prices, inventory, names & more."
              cta="View / Edit"
              disabled={false}
              onClick={() =>
                navigate(`/attractions/${attractionId}/ticketing/manage`)
              }
            />

            <StepZeroCard
              title="Ticket Reports"
              desc="View information on sales and bookings. Including volume, customer and value."
              cta="View"
              disabled={false}
              onClick={() => navigate(`/attractions/${attractionId}/ticketing/reporting`)}
            />
          </div>
        )}
      </DashboardContainer>

      <DashboardContainer>
        <section className="flex flex-col px-5 md:px-[70px]">
          <div id="attractionsTable" className="md:max-w-[calc(100vw-3rem)]">
            <Table<TicketDetails>
              // <table id>
              tableId="attractionsTable"
              // <table className>
              tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
              // <thead className>
              tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // <tbody className>
              tableTbodyClassNames={`relative ${
                page.length === 0 ? "h-[60px]" : ""
              }`}
              // Mobile <tbody className>
              tableTbodyMobileClassNames="block"
              // <tbody><tr className>
              tableTbodyTrClassNames="h-1 last-of-type:hidden"
              // Empty <tbody><tr className>
              tableTbodyTrEmptyMobileClassNames="border-none"
              // Mobile <tbody><tr className>
              tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative shadow-cs-admin-table-td overflow-hidden border-2 border-cs-off-white font-sm font-normal text-cs-gray rounded-xl p-3 bg-white"
              // <tbody><tr><td className>
              tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // Empty <tbody><tr><td className>
              tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
              // Mobile <tbody><tr><td className>
              tableTbodyTrEmptyTdMobileClassNames="h-2"
              // Hide Columns On Mobile
              hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
              // react-table props
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              pages={tableData?.total}
              prepareRow={prepareRow}
              pageIndex={pageIndex}
              pageSize={pageSize}
              pageOptions={pageOptions}
              gotoPage={gotoPage}
              setPageSize={setPageSize}
              isFetching={isFetching}
            />
          </div>
        </section>
      </DashboardContainer>
      <Footer />
    </div>
  );
};

export default AttractionTickets;
