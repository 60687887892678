import React from "react";
import { Control, Controller, useController, UseFormSetValue } from "react-hook-form";
import { ErrorMessage } from "../global/ErrorMessage";

interface ITownInput{
    filteredCities: {name: string, priority: number}[];
    control: Control<any>;
    name: string;
    handleCityInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setValue: UseFormSetValue<any>;
    clearErrors: (val: string) => void;
    setFilteredCities: (val: {name: string, priority: number}[]) => void;
    label: string;
    className?: string;
    isLoading: boolean
}

const TownInput: React.FC<ITownInput> = ({ filteredCities, control, name, handleCityInputChange, setValue, clearErrors, setFilteredCities, label, className, isLoading }) => {

    const {
        fieldState: { error },
      } = useController({
        name,
        control,
    });
    const { message: errorMessage } = error ?? {};

    return(
        <div className="relative group">
            <label className={`group-focus-within:text-cs-pink text-sm font-bold mb-2 block ${errorMessage && "text-cs-red"}`}>{label}<span className="text-cs-pink">*</span></label>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <div className="relative w-full max-w-[437px]">
                        <input
                            {...field}
                            onChange={handleCityInputChange}
                            className={`border-cs-1 border-cs-bright-lt-gray ${errorMessage && "border-cs-red"} rounded-md ${filteredCities?.length > 0 ? "rounded-bl-none rounded-br-none" : "rounded-md"} text-cs-gray py-2.5 px-5 pr-10 text-sm flex flex-col w-full max-w-[437px] focus:outline-cs-pink h-10`}
                            
                        />
                        {
                            isLoading && 
                            <div className="absolute -translate-y-1/2 right-1 top-1/2">
                                <LoadingIndicator />
                            </div>
                        }
                    </div>
                )}
            />
            {filteredCities.length > 0 && (
                <ul className="absolute top-[4.2rem] left-0 w-full rounded-tl-none rounded-tr-none max-w-[437px] bg-white border border-cs-bright-lt-gray rounded-md z-10">
                    {filteredCities.map((city, index) => (
                        <li
                            key={index}
                            className="px-5 py-2 text-sm cursor-pointer hover:bg-gray-200 text-cs-gray"
                            onClick={() => {
                                setValue(name, city?.name);
                                clearErrors(name);
                                setFilteredCities([]);
                            }}
                        >
                            {city?.name}
                        </li>
                    ))}
                </ul>
            )}
            {
                errorMessage &&
                <ErrorMessage>{"City is required"}</ErrorMessage>
            }
        </div>
    )
}

export default TownInput;

const LoadingIndicator: React.FC = () => {
    return (
      <svg
        className="w-5 h-5 mr-3 -ml-1 animate-spin text-cs-pink"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
};