import React, { useEffect, useRef } from "react";
import {
    Controller,
    useFieldArray,
    Control,
    UseFormSetValue,
} from "react-hook-form";
import Select from "react-select";
import SingleSelectOption from "../../lib/react-select/SingleSelectOption";
import { DeleteIcon } from "../misc/Icons";

interface IMultipleFieldArray{
    control: Control<any>;
    name: string;
    firstSelectName: string;
    firstSelectOptions: {label: string; value: string}[];
    secondSelectName: string;
    secondSelectOptions: {label: string; value: string}[];
    watch: any;
    setValue: any;
}

const MultipleFieldArray: React.FC<IMultipleFieldArray> = ({ control, name, firstSelectName, firstSelectOptions, secondSelectName, secondSelectOptions, watch, setValue }) => {

    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        rules: {
          minLength: 1,
        },
    });

    const returnVisitorIcon = (visitorType: string) => {
        switch(visitorType){
            case "Mum":
                return <img src="/assets/www/mum.png" alt="mum"/>;
            case "Dad":
                return <img src="/assets/www/dad.png" alt="dad"/>;
            case "Daughter":
                return <img src="/assets/www/daughter.png" alt="daughter"/>;
            case "Son":
                return <img src="/assets/www/son.png" alt="son"/>;
            default:
                return ""
        }
    }

    const isRowPopulated = (index: number) => {
        const field = fields[index] as {[key: string]: string};
        return field[firstSelectName] && field[secondSelectName];
    };

    const isRowEmpty = (index: number) => {
        const field = fields[index] as {[key: string]: string};
        return !field[firstSelectName] && !field[secondSelectName];
    };

    const initialized = useRef(false);

    useEffect(() => {
        if (initialized.current) {
            const lastIndex = fields.length - 1;
            if (fields.length === 0 || isRowPopulated(lastIndex)) {
                append({ [firstSelectName]: "", [secondSelectName]: "" });
            }
        } else {
            initialized.current = true;
        }
    }, [fields, append]);

    return (
        <div className="inline-flex flex-col flex-wrap w-full mb-4 md:w-3/5 xl:w-[56%] max-w-[550px]">
          <div className="flex">
            <label className="flex mt-4 mb-3 text-sm font-normal text-[#5F646D] pl-7 block w-[220px]">
                Visitor
            </label>
            <label className="flex mt-4 mb-3 text-sm font-normal text-[#5F646D]">
                Age
            </label>
          </div>
          {fields.map((field, index) => {
            return(
                <div key={field.id} className="flex flex-row gap-2 mb-2">
                    <div>
                        <Controller
                            control={control}
                            name={`${name}[${index}].${firstSelectName}`}
                            render={({ field }) => (
                                <div className="flex items-center gap-x-2">
                                    <div className="flex items-center w-7 h-7">
                                        {returnVisitorIcon(field?.value)}
                                    </div>
                                    <Select
                                        {...field}
                                        className="w-[220px]"
                                        options={firstSelectOptions}
                                        placeholder="Visitor"
                                        components={{ Option: SingleSelectOption, IndicatorSeparator: () => null }}
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption ? selectedOption.value : "");
                                        }}
                                        value={firstSelectOptions.find(option => option.value === field.value)}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderRadius: '6px',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                borderRadius: '6px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#CFDBD5',
                                            }),
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            control={control}
                            name={`${name}[${index}].${secondSelectName}`}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    className="min-w-[86px]"
                                    options={secondSelectOptions}
                                    placeholder="Age"
                                    components={{ Option: SingleSelectOption, IndicatorSeparator: () => null }}
                                    onChange={(selectedOption) => {
                                        field.onChange(selectedOption ? selectedOption.value : "");
                                    }}
                                    value={secondSelectOptions.find(option => option.value === field.value)}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderRadius: '6px',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '6px',
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: '#CFDBD5',
                                        }),
                                    }}
                                />
                            )}
                        />
                    </div>
                    <button
                        type="button"
                        className=""
                        onClick={() => {
                            if (isRowEmpty(index)) {
                                append({ [firstSelectName]: "", [secondSelectName]: "" });
                            } else {
                                remove(index);
                            }
                        }}
                    >
                        {isRowEmpty(index) ? "+" : <DeleteIcon />}
                    </button>
                </div>
            )}
        )}
        </div>
      );
}

export default MultipleFieldArray;