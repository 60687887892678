import {
  Control,
  useController,
} from "react-hook-form";
import { ErrorMessage } from "../global/ErrorMessage";
import { LightningIcon } from "../amp/LightBulbTooltip";

interface ITextInput {
  label?: string;
  showLightning?: boolean
  lightningTooltip?: string
  required?: boolean;
  readonly?: boolean;
  name: string;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  control: Control<any, any>;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  placeholder?: string;
  requirements?: string;
  afterStar?: string | React.ReactNode;
  afterStarClassName?: string;
  hideLabelMargin?: boolean;
}

const TextInput: React.FC<ITextInput> = ({
  label,
  name,
  required,
  readonly,
  endAdornment,
  startAdornment,
  inputProps,
  control,
  className,
  labelClassName,
  errorClassName,
  inputClassName,
  placeholder,
  requirements,
  afterStar,
  afterStarClassName,
  hideLabelMargin,
  showLightning,
  lightningTooltip
}) => {
  const {
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const { message: errorMessage } = error ?? {};

  const onClick: React.MouseEventHandler<HTMLInputElement> = (event) => {
    if (
      inputProps?.type === "date" &&
      "showPicker" in HTMLInputElement.prototype
    ) {
      (event.target as HTMLInputElement)?.showPicker();
    }
  };

  return (
    <div className={`${className ?? ""}`}>
      {label && (
        <label
          className={`${labelClassName ?? ""} ${
            errorMessage ? "text-cs-red" : "text-black"
          } text-sm font-bold`}
        >
          {label}
          {required && <span className="text-cs-pink"> *</span>}
          {showLightning && <LightningIcon tooltip={lightningTooltip || ''} className="ml-1" />}
        </label>
      )}
      {afterStar && (
        <div
          className={`${afterStarClassName} text-sm leading-[21px] mt-2.5 text-[#5F646D] font-normal`}
        >
          {afterStar}
        </div>
      )}
      <div className={`flex relative ${label && !hideLabelMargin && "mt-2"}`}>
        {startAdornment && startAdornment}
        <input
          className={`${inputClassName ?? ""} ${
            readonly ? "bg-[#F2F2F2]" : ""
          } ${
            errorMessage
              ? "border-cs-red hover:border-cs-cs-red "
              : "border-[#CFDBD5] hover:border-cs-pink "
          }`}
          readOnly={readonly}
          placeholder={placeholder}
          name={name}
          onClick={onClick}
          {...inputProps}
        />
        {endAdornment && endAdornment}
      </div>
      {errorMessage && (
        <ErrorMessage className={errorClassName}>{errorMessage}</ErrorMessage>
      )}
      {requirements && (
        <p className="text-xs font-normal text-cs-gray">{requirements}</p>
      )}
    </div>
  );
};

export default TextInput;
