import React, { useEffect, useState } from "react";
import { z } from "zod";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { useParams } from "react-router-dom";
import { apiSlice } from "../../../store/apiSlice";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import ButtonMultiSelect from "../../../components/form/ButtonMultiSelect";
import { dayOutEssentials } from "../../../helpers/constants";
import { useApiError } from "../../../hooks/useApiError";
import { FcnBlogContentRequestInterfaceKeys, filterProperties } from "../../../helpers/media";
import DiaryDetailsHeader from "./DiaryDetailsHeader";
import { FcnBlogContentRequest } from "../../../../../client-core/src/types/blog";

const schema = z.object({
    visit_plan: z.string().min(1, "Visit plan is required"),
    visit_essentials: z.array(z.string()).min(1, "Visit essentials are required"),
    visit_tip_1: z.string().optional(),
    visit_tip_2: z.string().optional(),
    visit_tip_3: z.string().optional(),
    visit_tip_4: z.string().optional(),
    visit_budget: z.string().min(1, "Visit budget is required")
});

type DiaryForm = z.infer<typeof schema>;

const DiaryPreparing: React.FC = () => {
    const { blogId } = useParams();
    const { data, isLoading } = apiSlice.useGetBlogDetailsQuery(blogId ?? "");
    const [uploadContent, { isLoading: isLoadingContent, error }] = apiSlice.useContentMutation();
    const [resolveContent, {isLoading: isLoadingResolve, error: resolveError}] = apiSlice.useResolveContentMutation();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const contentError = useApiError(error);
    const [resolveType, setResolveType] = useState<string>("");
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { isValid, isSubmitted, errors, isDirty },
        control,
        setValue,
        getValues,
        reset,
        trigger: triggerValidation,
        clearErrors,
        watch
    } = useForm<DiaryForm>({
        resolver: async (data, context, options) => {
        return zodResolver(schema)(data, context, options);
        },
        defaultValues: {
        },
    });

    const visitPlan = watch('visit_plan', '');
    const visitTip1 = watch('visit_tip_1', '') ?? "";
    const visitTip2 = watch('visit_tip_2', '') ?? "";
    const visitTip3 = watch('visit_tip_3', '') ?? "";
    const visitTip4 = watch('visit_tip_4', '') ?? "";
    const visitBudget = watch('visit_budget', '');

    const onSubmit: SubmitHandler<DiaryForm> = async () => {
        const formValues = { 
            ...getValues()
        };
        setIsDisabled(true);
        const contentUpdateRequest: any = {
            ...formValues
        }  
        const filteredPayload = filterProperties<FcnBlogContentRequest>(contentUpdateRequest, FcnBlogContentRequestInterfaceKeys);

        await uploadContent({
            ...filteredPayload,
            blog_id: data?.blog_id,
            content_id: data?.last_modified_content?.content_id,
        });
        setIsDisabled(false);
    };

    const clearErrorsOnChange = (fieldName: string) => {
		clearErrors(fieldName as any);
	};

    const handlePreviewContent = async () => {
        setIsLoadingPreview(true);
        try {
          const response = await fetch(`/api/amp/v1/fcn/blogs/content/${data?.last_modified_content?.content_id}/preview`);
          const url = await response.text();
          if(url){
            window.open(url, '_blank', 'noopener,noreferrer');
          }
        } catch (err) {
            console.log("Preview Error: ", err)
        } finally{
            setIsLoadingPreview(false);
        }
    };

    const countWords = (text: string) => {
        if(!text){
            return 0;
        }
        return text.trim().split(/\s+/).length;
    };

    const handleResolveContent = async (type: string) => {
        setResolveType(type);
        if(type === "approve"){
            await resolveContent({
                approve: true,
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else if(type === "deny"){
            await resolveContent({
                approve: false,
                reason: "",
                contentId: data?.last_modified_content?.content_id ?? ""
            });
        }else{
            console.log("No resolve type selected")
        }
    }

    useEffect(() => {
        async function fetchBlogDetails(){
            if(data){
                setValue("visit_plan", data?.last_modified_content?.visit_plan ?? "");
                setValue("visit_essentials", data?.last_modified_content?.visit_essentials ?? []);
                setValue("visit_tip_1", data?.last_modified_content?.visit_tip_1 ?? "");
                setValue("visit_tip_2", data?.last_modified_content?.visit_tip_2 ?? "");
                setValue("visit_tip_3", data?.last_modified_content?.visit_tip_3 ?? "");
                setValue("visit_tip_4", data?.last_modified_content?.visit_tip_4 ?? "");
                setValue("visit_budget", data?.last_modified_content?.visit_budget ?? "");
            }
        }
        fetchBlogDetails();
    }, [data]);

    return(
        <div className={`flex flex-col flex-1 bg-[#F5F5FA] doodle-bg ${isLoading && "blur-sm"}`}>
            <DashboardHeaderContainer slim={true}>
            {
                blogId &&
                <DiaryDetailsHeader
                    blogId={blogId}
                />
            }
            </DashboardHeaderContainer>
            <DashboardContainer>
                <form className={`z-10 ${false ? "blur-sm" : ""}`} onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-x-3 px-5 xl:px-[20px] w-full">
                        <div className="w-full p-6 mb-6 bg-white rounded-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/family.png" alt="family"/>
                                {"Preparing for your day out"}
                            </h1>
                            <div className="mt-2 text-sm font-normal text-cs-gray">Share your story of how you got ready for the day! Tell us about the planning, packing, and any fun preparations you made for your adventure.</div>
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className={`group-focus-within:text-cs-pink text-sm font-bold ${errors?.visit_plan ? "text-cs-red" : ""}`}>
                                    Share how you planned for your trip
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <Controller
                                    name="visit_plan"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 200 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="visit_plan"
                                            rows={2}
                                            className={`border-cs-1 ${errors?.visit_plan ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36`}
                                            placeholder="For example: We started by checking the weather forecast and packing accordingly. We made a checklist of essentials like snacks, sunscreen, and hats. We also looked up the attraction’s website for any special tips and packed a few extra items just in case"
                                            maxLength={200}
                                            onChange={(e) => {
                                                setValue('visit_plan', e?.target?.value);
                                                clearErrorsOnChange('visit_plan');
                                            }}
                                        />
                                    )}
                                />
                                <div className="flex justify-between gap-x-2 !mt-0">
                                    {
                                        errors?.visit_plan &&
                                        <ErrorMessage>{errors?.visit_plan?.message}</ErrorMessage>
                                    }
                                    <p className="mt-3 ml-auto text-sm text-cs-gray text-end">{countWords(visitPlan)} / 200 words</p>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-8 mb-6 bg-white rounded-b-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/letter.png" alt="letter"/>
                                {"Your day out checklist"}
                            </h1>
                            <div className="flex flex-wrap gap-8">
                                <div className="max-w-[60%] w-full">
                                    <div className="mt-2 text-sm font-normal text-cs-gray">List all the essentials you needed for your day out, including any extra items you brought along. Think of everything from tickets, snacks and suncream!</div>
                                    <ButtonMultiSelect 
                                        containerClassName="my-4 w-full max-w-[600px]"
                                        control={control}
                                        label="Day Out Essentials"
                                        subLabel=""
                                        options={dayOutEssentials}
                                        name="visit_essentials"
                                        selectOptionsContainerClassName="overflow-y-auto"
                                        isMulti={true}
                                        required
                                        clearErrors={clearErrorsOnChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-8 mb-6 bg-white rounded-b-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/tips.png" alt="tips"/>
                                {"Top tips for a great visit"}
                            </h1>
                            <div className="mt-2 mb-6 text-sm font-normal text-cs-gray">Share four practical tips that made your day out easier and more enjoyable. What advice would you give other parents to help them have a smoother visit?</div>
                            <div className="flex flex-wrap gap-8">
                                <div className="max-w-[60%] w-full">
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className="w-7 h-7 min-w-7 rounded-full border-2 border-[#979797] text-[#979797] flex items-center justify-center text-sm">1</div>
                                        <div className="w-full">
                                            <Controller
                                                name="visit_tip_1"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 200 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="visit_tip_1"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                                        placeholder="For example: We started by checking the weather forecast and packing accordingly. We made a checklist of essentials like snacks, sunscreen, and hats. We also looked up the attraction’s website for any special tips and packed a few extra items just in case"
                                                        maxLength={200}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{visitTip1.length} / 200 characters</p>
                                        </div>
                                    </div>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className="w-7 h-7 min-w-7 rounded-full border-2 border-[#979797] text-[#979797] flex items-center justify-center text-sm">2</div>
                                        <div className="w-full">
                                            <Controller
                                                name="visit_tip_2"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 200 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="visit_tip_2"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                                        placeholder="For example: We started by checking the weather forecast and packing accordingly. We made a checklist of essentials like snacks, sunscreen, and hats. We also looked up the attraction’s website for any special tips and packed a few extra items just in case"
                                                        maxLength={200}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{visitTip2.length} / 200 characters</p>
                                        </div>
                                    </div>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className="w-7 h-7 min-w-7 rounded-full border-2 border-[#979797] text-[#979797] flex items-center justify-center text-sm">3</div>
                                        <div className="w-full">
                                            <Controller
                                                name="visit_tip_3"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 200 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="visit_tip_3"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                                        placeholder="For example: We started by checking the weather forecast and packing accordingly. We made a checklist of essentials like snacks, sunscreen, and hats. We also looked up the attraction’s website for any special tips and packed a few extra items just in case"
                                                        maxLength={200}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{visitTip3.length} / 200 characters</p>
                                        </div>
                                    </div>
                                    <div className="flex w-full gap-2 mb-4">
                                        <div className="w-7 h-7 min-w-7 rounded-full border-2 border-[#979797] text-[#979797] flex items-center justify-center text-sm">4</div>
                                        <div className="w-full">
                                            <Controller
                                                name="visit_tip_4"
                                                control={control}
                                                defaultValue=""
                                                rules={{ maxLength: 200 }}
                                                render={({ field }) => (
                                                    <textarea
                                                        {...field}
                                                        id="visit_tip_4"
                                                        rows={2}
                                                        className="border-cs-1 border-cs-bright-lt-gray text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36"
                                                        placeholder="For example: We started by checking the weather forecast and packing accordingly. We made a checklist of essentials like snacks, sunscreen, and hats. We also looked up the attraction’s website for any special tips and packed a few extra items just in case"
                                                        maxLength={200}
                                                    />
                                                )}
                                            />
                                            <p className="mt-3 text-sm text-cs-gray text-end">{visitTip4.length} / 200 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-6 bg-white rounded-t-lg">
                            <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                                <img src="/assets/www/budget.png" alt="budget"/>
                                {"Budgeting for your day out"}
                            </h1>
                            <div className="mt-2 mb-6 text-sm font-normal text-cs-gray">Tell us how you planned and managed your budget for the trip. Share what expenses you considered and any tips for staying within budget.</div>
                            <div className="w-full mt-6 space-y-2 max-w-cs-574">
                                <label className={`group-focus-within:text-cs-pink text-sm font-bold ${errors?.visit_budget ? "text-cs-red" : ""}`}>
                                    Did you budget for your day out?
                                    <span className="text-cs-pink"> *</span>
                                </label>
                                <Controller
                                    name="visit_budget"
                                    control={control}
                                    defaultValue=""
                                    rules={{ maxLength: 200 }}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            id="visit_budget"
                                            rows={2}
                                            className={`border-cs-1 ${errors?.visit_budget ? "border-cs-red" : "border-cs-bright-lt-gray"} text-cs-gray resize-none placeholder:text-[#CFDBD5] rounded-md py-2 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-36`}
                                            placeholder="For example: We started by checking the weather forecast and packing accordingly. We made a checklist of essentials like snacks, sunscreen, and hats. We also looked up the attraction’s website for any special tips and packed a few extra items just in case"
                                            maxLength={200}
                                            onChange={(e) => {
                                                setValue('visit_budget', e?.target?.value);
                                                clearErrorsOnChange('visit_budget');
                                            }}
                                        />
                                    )}
                                />
                                <div className="flex justify-between gap-x-2 !mt-0">
                                    {
                                        errors?.visit_budget &&
                                        <ErrorMessage>{errors?.visit_budget?.message}</ErrorMessage>
                                    }
                                    <p className="mt-3 ml-auto text-sm text-cs-gray text-end">{countWords(visitBudget)} / 200 words</p>
                                </div>
                            </div>
                            <button 
                                className={`px-4 py-2 text-sm font-bold text-white rounded-lg bg-cs-pink ${isDisabled ? "opacity-50" : "opacity-100"}`} 
                                type="submit"
                                disabled={isDisabled}
                            >
                                Save Changes
                            </button>
                            {
                                contentError &&
                                <p className="mt-2 text-sm text-cs-red">{contentError}</p>
                            }
                        </div>
                    </div>
                </form>
            </DashboardContainer>
        </div>
    )
}

export default DiaryPreparing;