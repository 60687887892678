import { Link, useNavigate } from "react-router-dom";
import { Description, Heading } from "../../TwoColTemplate";
import { PrimaryButton } from "../../PrimaryButton";
import { OutlineButton } from "../../OutlineButton";

export default function SubmittedModal() {
  const navigate = useNavigate();

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate("/login", { replace: true });
  };

  return (
    <div className="flex flex-col w-full">
      <Heading>Check your email to reset your password</Heading>

      <Description>
        If you have an existing account with us, you will have received an email
        with instructions on how to reset your password.
      </Description>

      <div className="mt-5 flex flex-col gap-y-5">
        <PrimaryButton type="submit" onClick={onSubmit}>
          Return to log in
        </PrimaryButton>

        <Link to={"https://www.dayoutwiththekids.co.uk/"}>
          <OutlineButton>Return to main site</OutlineButton>
        </Link>
      </div>
    </div>
  );
}
