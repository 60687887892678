import AsyncSelect from "react-select/async";
import SingleSelectOption from "../../lib/react-select/SingleSelectOption";
import { apiSlice } from "../../store/apiSlice";

export default function CountiesSelect({
  onChange,
  defaultValue,
  defaultOptions,
}: {
  defaultOptions: { label: string; value: string };
  defaultValue: string | undefined,
  onChange: Function;
}) {
  const [trigger] = apiSlice.useLazySearchCountiesQuery();
  const { data } = apiSlice.useSearchCountiesQuery("");

  const filterCounties = (
    items: TownList[]
  ): { label: string; value: string }[] => {
    const options = [
      ...items.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    ];

    if (options.length === 0) {
      return [defaultOptions]
    }

    if (!options.find(o => o.value === defaultValue) && !!defaultValue) {
      options.push(defaultOptions)
    }
    return options
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: { label: string; value: string }[]) => void
  ) => {
    trigger(inputValue)
      .unwrap()
      .then((data) => {
        callback(filterCounties(data.results));
      });
  };

  return (
    <>
    <AsyncSelect
      components={{
        Option: SingleSelectOption,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        LoadingIndicator: () => <LoadingIndicator />,
      }}
      cacheOptions
      loadOptions={loadOptions}
      value={filterCounties(data?.results ?? []).find(v => v.value === defaultValue) ?? undefined}
      defaultOptions={filterCounties(data?.results ?? [])}
      isSearchable={true}
      placeholder=""
      styles={{
        menu: (styles) => ({
          ...styles,
          margin: 0,
          border: "none",
        }),
        menuList: (base, isSelected) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
          background: "white",

          "::-webkit-scrollbar": {
            width: 14,
          },
          "::-webkit-scrollbar-thumb": {
            background: "#c2c2c2",
            borderRadius: 10,
            border: "4px solid white",
          },
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
          let extra =
            data.value === "-1"
              ? {
                  background: "#eee",
                }
              : {
                  background: "white",
                };
          return {
            ...base,
            border: "1px solid #c2c2c2",
            color: isSelected ? "white" : "#555",
            "&:hover": {
              backgroundColor: "#6836D1",
              color: isSelected || isFocused ? "white" : "#555",
              border: isFocused ? "1px solid white" : "",
            },
            "&:active": {
              backgroundColor: "#6836D1",
              color: "white",
            },
            ...extra,
          };
        },
        singleValue: (base) => ({
          ...base,
          color: "#555",
          fontSize: 14,
        }),
        control: (styles, state) => ({
          ...styles,
          cursor: "pointer",
          outline: "none",
          border: state.isFocused ? "2px solid #CFDBD5" : "2px solid #CFDBD5",
          "&:hover": {
            border: state.isFocused ? "2px solid #CFDBD5" : "2px solid #CFDBD5",
          },
          boxShadow: "none",
          paddingTop: 3,
          paddingBottom: 3,
          margin: 0,
          minHeight: 50,
          borderRadius: 8,
        }),
      }}
      onChange={(value) => {
        const val = value?.value;

        if (val) {
          onChange(val);
        }
      }}
    />
    </>
  );
}
const LoadingIndicator: React.FC = () => {
  return (
    <svg
      className="w-5 h-5 mr-3 -ml-1 animate-spin text-cs-pink"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
