import type { UseFieldArrayReturn, UseFormGetValues } from "react-hook-form";
import { z } from "zod";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { globalActions } from "../store/globalSlice";
import { restartAnimation } from "../components/global/amp/ToastNotification";
import { Cropper, type CropperRef } from "react-advanced-cropper";

export const singleMediaSchema = z.object({
  url: z.instanceof(File),
  urlString: z.string(),
  rawFile: z.any(),
  filename: z.string(),
  size: z.number(),
  createdAt: z.date(),
  isPrimary: z.boolean(),
  persisted: z.boolean().or(z.string()),
  image_db_id: z.string().optional(),
});
export const mediaSchema = singleMediaSchema.array();

interface Props {
  getValues: UseFormGetValues<{
    media: z.infer<typeof mediaSchema>;
  }>;
  controls: UseFieldArrayReturn<
    {
      media: z.infer<typeof mediaSchema>;
    },
    "media",
    "id"
  >;
  croppingImage: number | undefined;
  setCroppingImage: React.Dispatch<React.SetStateAction<number | undefined>>;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCropModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomCroppingImage?: any;
}

export function useMediaUpload({
  controls,
  croppingImage,
  setCroppingImage,
  setCustomCroppingImage,
  getValues,
  setShowCropModal,
}: Props) {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const { fields, update, remove, append } = controls;

  const [deletedPersisted, setDeletedPersisted] = useState<string[]>([]);

  const removeSingle = (index: number) => {
    const field = fields[index];

    if (field.persisted) {
      setDeletedPersisted([...deletedPersisted, field.image_db_id as string]);
    }

    remove(index);
  };

  const createMedia = async (
    event: React.ChangeEvent<HTMLInputElement>,
    customAppend?: any,
    customAppendIndex?: number
  ) => {
    const files = (event.target as HTMLInputElement).files;
    let length = fields.length;

    if (files && files[0] && length < 12) {
      const correctType = ["image/jpeg", "image/png"].includes(files[0].type);
      const correctSize = files[0].size <= 5 * 1024 * 1024;
      if (correctType && correctSize) {
        const newMediaData = {
          url: files[0],
          urlString: (await fileToDataString(files[0])) as string,
          rawFile: files[0],
          filename: files[0].name,
          size: files[0].size,
          isPrimary: false,
          createdAt: new Date(),
          persisted: false,
        };
        if (customAppend) {
          customAppend(newMediaData);
        } else {
          append(newMediaData);
        }

        if (customAppend) {
          setCustomCroppingImage(customAppendIndex);
        } else {
          setCroppingImage(length);
        }
        setShowCropModal(true);
      } else {
        dispatch(
          globalActions.setToastNotifcation({
            ...toastNotification,
            type: "ERROR",
            message: correctType
              ? "Unsupported file type"
              : correctSize
              ? "File is too large"
              : "Invalid file",
            attractionApprovalStatus: "",
            attractionImage: "",
            attractionName: "",
            attractionAddress: "",
          })
        );
        restartAnimation();
      }
    }

    event.target.value = "";
  };

  const cropImage = async (value: File) => {
    const index = croppingImage ?? 0;
    update(index, {
      ...getValues(`media.${index}`),
      url: value,
    });
    setShowCropModal(false);
  };

  const cancelCrop = () => {
    const index = croppingImage ?? 0;
    remove(index);
    setShowCropModal(false);
  };

  return {
    deletedPersisted,
    setDeletedPersisted,

    cropImage,
    cancelCrop,
    removeSingle,
    createMedia,
  };
}

export function fileToDataString(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      resolve(event.target?.result as string);
    };
    reader.onerror = reject;
  });
}
export function dataStringToBlob(base64String: string) {
  const binaryString = atob(base64String.split(",")[1]); // Binary data string
  const blob = new Blob([binaryString], { type: "image/png" }); // Create a BLOB object
  return blob;
}

export const ImageCropper: React.FC<{
  file: File;
  stencilAspectRatio?: number;
  onAccept: (value: File) => void;
  onCancel: () => void;
}> = ({ file, stencilAspectRatio = 1 / 1, onAccept, onCancel }) => {
  const cropperRef = useRef<CropperRef>(null);
  const [cropperImage, setCropperImage] = useState<string>("");

  useEffect(() => {
    const objectUrl = URL.createObjectURL(file);
    setCropperImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl); // Clean up the object URL
  }, [file]);

  const accepted = async () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.getCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob) {
            const newFile = new File([blob], file.name, {
              type: blob.type,
              lastModified: Date.now(),
            });
            onAccept(newFile);
          }
        }, file.type);
      }
    }
  };

  return (
    <>
      <div className="w-full sm:w-[80%] max-w-full aspect-square overflow-none">
        {cropperImage && (
          <Cropper
            className="w-full max-w-full aspect-square"
            ref={cropperRef}
            src={cropperImage}
            resize-image={{
              adjustStencil: true,
            }}
            stencilProps={{
              aspectRatio: stencilAspectRatio,
              movable: true,
              resizable: true,
              handlers: true,
            }}
          />
        )}
      </div>

      <div className="flex flex-row mt-10">
        <button
          type="button"
          className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
          onClick={accepted}
        >
          Save
        </button>

        <button
          type="button"
          className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export async function fetchFileData(
  url: string
): Promise<{ size: number; data: File }> {
  const response = await fetch(url);
  const contentLength = response.headers.get("Content-Length");
  const fileSize = contentLength
    ? Number.parseInt(contentLength, 10)
    : (await response.body
        ?.getReader()
        ?.read()
        .then((result) =>
          result.done ? result.value?.length || 0 : Infinity
        )) || 0;

  if (!response.ok) {
    throw new Error("Failed to fetch the file.");
  }

  const blob = await response.blob();
  const file = new File([blob], `file-${generateUUIDv4()}`, {
    type: blob.type,
    lastModified: Date.now(),
  });

  return { size: fileSize, data: file };
}

function generateUUIDv4(): string {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: number) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
  );
}
