import React, { useEffect, useRef, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import Table from "../../../../lib/react-table/Table";
import Footer from "../../../../components/global/amp/Footer";
import moment from "moment";
import { apiSlice } from "../../../../store/apiSlice";
import DashboardHeaderContainer from "../../../../components/amp/DashboardHeaderContainer";
import { Button } from "../../../../components/global/Button";
import { TableText } from "../../../../components/global/TableText";
import Header from "../../../../components/global/amp/Header";
import { SingleAttractionTemplate } from "../../../../components/global/SingleAttractionTemplate";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const AvailabilityPeriods: React.FC = () => {
  const { attractionId } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [getQuerySortBy, setQuerySortBy] = useState<string>("venue");
  const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
  const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");
  const [getFilter, setFilter] = useState<'all' | 'active' | 'inactive' | 'mapped' | 'unmapped'>('all')

  const prevQuerySearch = useRef(getQuerySearchQuery);
  const { data: tableData, isFetching } = apiSlice.useGetAvailabilityPeriodListQuery({
    pageSize: getQueryPageSize,
    page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
    sortBy: getQuerySortBy,
    query: getQuerySearchQuery,
    sortDesc: getQuerySortDesc,
    filter: getFilter,
    venueShortId: attractionId!
  });

  const data = React.useMemo<DOWTKTicketingAvailabilityPeriod[]>(
    () => tableData?.results ?? ([] as DOWTKTicketingAvailabilityPeriod[]),
    [tableData]
  );

  const { data: attractionQuery } = apiSlice.useGetAttractionsListQuery({
    pageSize: "9999999",
    page: "0",
    labelsOnly: "true"
  }) as any;

  const [attractions, setAttractions] = useState<AttractionDetails[]>([]);
  useEffect(() => {
    if (attractionQuery?.results?.length) {
      setAttractions([...attractions, ...attractionQuery.results]);
    }
  }, [attractionQuery]);

  const columns = React.useMemo<Column<DOWTKTicketingAvailabilityPeriod>[]>(
    () => [
      {
        Header: "Attraction Name",
        accessor: "name",
        id: "venue",
        Cell: ({ row: { original } }: CellProps<DOWTKTicketingAvailabilityPeriod>) => {
          return (
            <>
              <Link
                to={`/attractions/${attractionId}/ticketing/availability-periods/create/${original.id}`}
                state={{ from: "/dashboard/attractions" + location.search }}
                className="block w-auto xl:w-64 truncate font-bold text-sm text-black font-roboto-flex"
              >
                {original?.name || "Not Assigned"}
              </Link>
            </>
          );
        },
        disableSortBy: true,
        width: "65%",
      },
      {
        Header: "Updated at",
        accessor: "updated_at",
        id: "updated_at",
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<DOWTKTicketingAvailabilityPeriod>) => {
          return (
            <>
              <TableText className="min-w-[8rem]">
                {moment(original.updated_at).format("DD MMM YYYY")}
              </TableText>
            </>
          );
        },
        width: "10%",
      },
      {
        Header: "",
        accessor: "id",
        id: "actions",
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<DOWTKTicketingAvailabilityPeriod>) => {
          return (
            <div className="flex justify-center text-sm">
              <Link to={`/attractions/${attractionId}/ticketing/availability-periods/${original.id}/configure`}>
                <Button>Edit</Button>
              </Link>
            </div>
          );
        },
        disableGlobalFilter: true,
        width: "25%",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    gotoPage,
    setPageSize,
    setSortBy,
    state: { pageSize, pageIndex, sortBy, globalFilter },
  } = useTable<DOWTKTicketingAvailabilityPeriod>(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: tableData?.total,
      disableSortRemove: true,
      initialState: {
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        pageIndex: parseInt(getQueryPage),
        globalFilter: searchParams.get("query") || getQuerySearchQuery,
        sortBy: [
          {
            id: searchParams.get("sortBy") || getQuerySortBy,
            desc:
              searchParams.get("sortDesc") === "true" ||
              getQuerySortDesc !== "false",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<DOWTKTicketingAvailabilityPeriod>;

  useEffect(() => {
    document.title = "Ticketing | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (getQuerySearchQuery !== prevQuerySearch.current) {
      setQueryPage("0");
      gotoPage(0);

      prevQuerySearch.current = getQuerySearchQuery;
    }
  }, [getQuerySearchQuery]);
  useEffect(() => {
    if (!isFetching) {
      setSearchParams(
        {
          pageSize: pageSize.toString(),
          page: pageIndex.toString(),
          query: globalFilter ? globalFilter : "",
          sortBy: sortBy[0].id.toLowerCase(),
          sortDesc: sortBy[0].desc!.toString(),
        },
        { replace: JSON.stringify(searchParams) === "{}" }
      );

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySearchQuery(globalFilter ? globalFilter : "");

      prevQuerySearch.current = getQuerySearchQuery;
    } else {
    }
  }, [
    pageSize,
    pageIndex,
    sortBy[0].id,
    globalFilter,
    sortBy[0].desc,
    isFetching,
  ]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA]">
      <Header
        title="Availability"
        mobilePublishButtons={false}
        showPublishingButtons={false}
      />
      <SingleAttractionTemplate name={""}>
        <DashboardHeaderContainer>
          <div className="mx-auto flex mr-0">
            <Link to={`/attractions/${attractionId}/ticketing/availability-periods/create`} className="ml-auto">
              <div className="ml-auto">
                <Button>New Period</Button>
              </div>
            </Link>
          </div>
        </DashboardHeaderContainer>

        <section className="flex flex-col px-[70px]">
          <div id="attractionsTable" className="mt-5 max-w-[calc(100vw-3rem)]">
            <Table<DOWTKTicketingAvailabilityPeriod>
              tableId="attractionsTable"
              tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
              tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              tableTbodyClassNames={`relative ${page.length === 0 ? 'h-[60px]' : ''}`}
              tableTbodyMobileClassNames="block"
              tableTbodyTrClassNames="h-1 last-of-type:hidden"
              tableTbodyTrEmptyMobileClassNames="border-none"
              tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative shadow-cs-admin-table-td overflow-hidden border-2 border-cs-off-white font-sm font-normal text-cs-gray rounded-xl p-3"
              tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
              tableTbodyTrEmptyTdMobileClassNames="h-2"
              hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              pages={tableData?.total}
              prepareRow={prepareRow}
              pageIndex={pageIndex}
              pageSize={pageSize}
              pageOptions={pageOptions}
              gotoPage={gotoPage}
              setPageSize={setPageSize}
              isFetching={isFetching}
            />
          </div>
        </section>

      </SingleAttractionTemplate>
      <Footer />
    </div>
  );
};

export default AvailabilityPeriods;
