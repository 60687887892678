import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import TextInput from "../../../form/TextInput";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  type CreateAttractionArgs,
  apiSlice,
} from "../../../../store/apiSlice";
import { restartAnimation } from "../../amp/ToastNotification";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { globalActions } from "../../../../store/globalSlice";
import { VerifyEmailBox } from "./VerifyEmailModal";
import Select from "react-select";
import SingleSelectOption from "../../../../lib/react-select/SingleSelectOption";
import { useAuthUser, useLogin } from "../../../../hooks/useAuth";
import { Description, Heading } from "../../TwoColTemplate";
import { ErrorMessage } from "../../ErrorMessage";
import { PrimaryButton } from "../../PrimaryButton";

export type AttractionMinimal = {
  id: string;
  title: string;
  location: string;
  image: string;
  image_alt: string;

  website?: string;
  postcode?: string;
  location_name?: string;
  phone?: string;

  // listing_type?: CreateAttractionArgs["listing_type"];
  duration_type?: CreateAttractionArgs["duration_type"];
  // start_working?: string;
  // end_working?: string;
};
type Props = {
  setSubmittedModal: (showModal: boolean) => void;
  setNewUserEmail: (value: string) => void;
  attraction?: AttractionMinimal;
};

export const ATTRACTION_DATA_LOCAL_STORAGE_KEY = 'ongoingPaymentAttraction'

export const CreateClaimModal: React.FC<Props> = ({
  attraction,
  setSubmittedModal,
  setNewUserEmail,
}: Props) => {
  const { user } = useAppSelector((state) => state.global);
  const { isLoggedIn } = useAuthUser();
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [userId, setUserId] = useState<number | null>(isLoggedIn ? user?.id as number : null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [authorizedCheck, setAuthorizedCheck] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);

  const mustBe: string =
    "Must be at least 8 characters, with at least one number and one special character";

  const isNew = attraction?.id == "new";

  const schema = z.object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    businessName: z.string().min(1, "Company name is required"),
    businessEmail: z
      .string()
      .min(1, "Business email is required")
      .email("Enter a valid email address"),
    phoneNumber: z
      .string()
      .min(10, "Phone number is required")
      .refine(
        (val) =>
          new RegExp(
            /^(\+44\s?|\(0\d{4}\)\s?|\(0\d{3}\)\s?|\(0\d{2}\)\s?|\d{4}\s?|\d{3}\s?|\d{2}\s?)\d{3}\s?\d{4}(\s?\#\d{3,4})?$/
          ).test(val),
        "Invalid phone number. It should be at least 10 digits with no spaces"
      ),
    password: z
      .string()
      .min(1, "Password is required")
      .min(8, mustBe)
      .refine(
        (passwordText) =>
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$£%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$£%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(
            passwordText
          ),
        mustBe
      ),
    businessType: z.enum(["individual", "multiple", "agency"]),
  });

  type CreateClaimForm = z.infer<typeof schema>;

  const [registerRequest, registerRequestData] = apiSlice.useRegisterMutation();
  const [claimRequest, claimRequestData] = apiSlice.useCreateClaimMutation();
  const [createRequest, createRequestData] =
    apiSlice.useCreateAttractionMutation();

  const [checkoutUrl, checkoutUrlRequestData] =
    apiSlice.useCreatePaymentUrlMutation();

  const {
    isError: isRegisterError,
    isSuccess: isRegisterSuccess,
    isLoading: isRegisterLoading,
    isUninitialized: isRegisterUninitialized,
  } = registerRequestData;
  const {
    isError: isClaimedError,
    isSuccess: isClaimedSuccess,
    isLoading: isClaimedLoading,
    isUninitialized: isClaimedUninitialized,
  } = claimRequestData;
  const {
    isError: isCreateError,
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    isUninitialized: isCreateUninitialized,
    error: createError,
  } = createRequestData;

  const rawSavedUserData = localStorage.getItem(ATTRACTION_DATA_LOCAL_STORAGE_KEY)
  const savedUserData = rawSavedUserData ? JSON.parse(rawSavedUserData) : null
  const dummyPassword = 'p@ssword1sEncrypted'
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    control,
    getValues,
    setError,
  } = useForm<CreateClaimForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      businessEmail: user?.email ?? savedUserData?.user?.email,
      firstName: user?.first_name ?? savedUserData?.user?.first_name,
      lastName: user?.last_name ?? savedUserData?.user?.last_name,
      businessName: user?.operator?.title ?? savedUserData?.user?.operator_name,
      phoneNumber: user?.phone ?? savedUserData?.user?.phone,
      businessType: (user?.business_type ?? savedUserData?.user?.business_type) || undefined,
      password: savedUserData?.user?.password ?? (user?.email ? dummyPassword : undefined)
    }
  });

  function getNewUserData() {
    return {
      email: getValues("businessEmail"),
      password: getValues("password"),
      first_name: getValues("firstName"),
      last_name: getValues("lastName"),
      operator_name: getValues("businessName"),
      phone: getValues("phoneNumber"),
      business_type: getValues("businessType"),
    }
  }

  const createAccount = async (): Promise<number> => {
    if (userId) {
      return userId;
    }
    if (savedUserData?.user?.id) {
      return savedUserData.user.id;
    }
    const newUserData = getNewUserData()
    const newUser = await registerRequest(newUserData);

    if (newUser && "data" in newUser) {
      setUserId(!!newUser.data?.alreadyExist ? null : newUser.data?.id ?? null);
      setNewUserEmail(getValues("businessEmail"));
      return !!newUser.data?.alreadyExist ? -1 : newUser.data?.id ?? -1;
    }

    return (newUser.error as any)?.data?.message.startsWith(
      "EmailSendFailedError"
    )
      ? -2
      : -1;
  };

  const [callLogin] = useLogin();
  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = useState(false);
  const [isGettingCheckoutUrl, setIsGettingCheckoutUrl] = useState(false);

  async function startPayment(id: string, forcedIsPayment = false, newUserId = 1) {
    if (isPayment || forcedIsPayment) {
      setIsGettingCheckoutUrl(true)
      const checkoutPage = await checkoutUrl({
        id,
        planId: 'basic-plan-monthly'
      });
      setIsGettingCheckoutUrl(false)

      if ("data" in checkoutPage) {
        const savedUserData = {
          ...getNewUserData(),
          id: newUserId
        };
        // savedUserData.password = dummyPassword;
        localStorage.setItem(ATTRACTION_DATA_LOCAL_STORAGE_KEY, JSON.stringify({
          id,
          attraction,
          user: savedUserData
        }))
        window.location.href = new URL(
          checkoutPage.data?.sessionUrl ?? ""
        ).toString();
        return;
      }
    }
    navigate({
      pathname: "/skip-payment",
      search: createSearchParams(attraction).toString(),
    });
  }

  const claimAttraction = async (newUserId: number) => {
    let attractionId = attraction?.id ?? "";
    if (attractionId === "new") {
      if (savedUserData && savedUserData.id && JSON.stringify(savedUserData.attraction) === JSON.stringify(attraction)) {
        await startPayment(savedUserData.id, false, newUserId);
        return;
      }

      const results = await createRequest({
        name: attraction?.title ?? "",
        website: attraction?.website ?? "",
        postcode: attraction?.postcode ?? "",
        town_id: attraction?.location ?? "",
        userId: newUserId,
        phone: attraction?.phone ?? "",
        no_phone_number: false,
        // listing_type: attraction?.listing_type ?? "attraction",
        duration_type: attraction?.duration_type ?? "permanent",
        // start_working: attraction?.start_working ?? undefined,
        // end_working: attraction?.start_working ?? undefined,
      });

      if ("data" in results) {
        const id = (results.data.id as string) ?? ""

        setLoggingIn(true);

        if (!isLoggedIn || !user?.id) {
          callLogin({
            email: getValues("businessEmail"),
            password: getValues("password"),
          })
          .unwrap()
          .then(async () => {
            setLoggingIn(false);
            await startPayment(id, false, newUserId)
          });
          setLoggingIn(false);
        } else {
          await startPayment(id, false, newUserId)
        }
      }
    } else {
      await claimRequest({
        attractionId,
        userId: newUserId,
      });
    }
  };

  const [isPayment, setIsPayment] = useState(false);
  const onSubmit: SubmitHandler<CreateClaimForm> = async (e) => {
    const newUserId = (await createAccount()) as string | number;

    if (typeof newUserId === "string") {
      await claimAttraction(newUserId as unknown as number);
    } else if (newUserId === -1) {
      setError("businessEmail", {
        type: "custom",
        message:
          "This email address is already registered. Log in to your account to continue.",
      });
    } else if (newUserId === -2) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message:
            (createError as { data: { message: string } })?.data?.message ??
            "Failed to send verification email",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
    restartAnimation();
  };

  const businessTypeOptions = [
    { label: "Individual Attraction Owner / Employee", value: "individual" },
    { label: "Multiple Attraction Owner / Employee", value: "multiple" },
    { label: "Agency Managed on behalf of Attraction", value: "agency" },
  ];

  useEffect(() => {
    isCreateError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message:
            (createError as { data: { message: string } })?.data?.message ??
            "Cannot create attraction",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isCreateError]);

  useEffect(() => {
    isClaimedError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot create claim",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isClaimedError]);

  useEffect(() => {
    if (isRegisterSuccess && (isClaimedSuccess || isCreateSuccess)) {
      if (!(isCreateSuccess && !user && !isClaimedError)) {
        setSubmittedModal(true);
      }
    }
  }, [
    isRegisterError,
    isRegisterSuccess,
    isClaimedError,
    isClaimedSuccess,
    isCreateError,
    isCreateSuccess,
  ]);

  return (
    <div className="flex flex-col gap-5 mt-5">
      <Heading>
        {isNew
          ? "Add an attraction to "
          : "Claim Existing Attraction featured on"}{" "}
        <br></br>Day Out With The Kids
      </Heading>

      <AttractionCard
        className="w-full"
        title={attraction?.title ?? ""}
        location={attraction?.location_name ?? attraction?.location ?? ""}
        image={attraction?.image ?? ""}
        imageAlt={attraction?.image_alt ?? ""}
      />

      <Description>
        Not your attraction?{" "}
        <Link to="/search-claim" className="text-sm font-bold text-[#6836D1]">
          Search again
        </Link>
      </Description>

      <form
        autoComplete="off"
        className="pt-5 border-t border-[#F1F1F1]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Heading>Create your admin profile</Heading>
        <Description>
          Or{" "}
          <Link
            to="/login"
            state={{ claim: attraction }}
            className="text-sm font-bold text-[#6836D1]"
          >
            Login
          </Link>
        </Description>

        {isLoggedIn && <p className="text-xs font-normal text-[#5F646D] mt-2 bg-[#F1F1F1] rounded-md p-2.5">
            You already created you're account. You can continue creating your listing below.  
        </p>}

        <div className="flex flex-col gap-6 mt-6 sm:flex-row">
          <TextInput
            className="w-full group md:w-1/2"
            labelClassName="group-focus-within:text-cs-pink"
            inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
            control={control}
            name={"firstName"}
            required
            label="First Name"
            readonly={!!isLoggedIn}
            inputProps={{ ...register("firstName"), autoComplete: "off" }}
          />
          <TextInput
            className="w-full group md:w-1/2"
            labelClassName="group-focus-within:text-cs-pink"
            inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
            control={control}
            name={"lastName"}
            required
            label="Last Name"
            readonly={!!isLoggedIn}
            inputProps={{ ...register("lastName"), autoComplete: "off" }}
          />
        </div>

        <TextInput
          className="mt-6 group"
          labelClassName="group-focus-within:text-cs-pink"
          inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
          control={control}
          name={"businessName"}
          required
          label="Business Name"
          readonly={!!isLoggedIn}
          inputProps={{ ...register("businessName"), autoComplete: "off" }}
        />
        <p className="text-xs font-normal text-cs-gray">
          * This can be the name of your attraction, or the agency you work for.
        </p>
        <TextInput
          className="mt-6 group"
          labelClassName="group-focus-within:text-cs-pink"
          inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
          control={control}
          name={"businessEmail"}
          required
          label="Email Address' (this will be your login)"
          readonly={!!isLoggedIn}
          inputProps={{ ...register("businessEmail"), autoComplete: "off" }}
        />
        <p className="text-xs font-normal text-cs-gray">
          * You'll need to verify this email address before your listing can be approved...so make sure you have access to it
        </p>
        <TextInput
          className="mt-6 group"
          labelClassName="group-focus-within:text-cs-pink"
          inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
          control={control}
          name={"phoneNumber"}
          required
          label="Contact Phone Number"
          readonly={!!isLoggedIn}
          inputProps={{ ...register("phoneNumber"), autoComplete: "off" }}
        />
        <p className="text-xs font-normal text-cs-gray">
          * This will NOT be visible to website users.
        </p>
        <TextInput
          className="mt-6 group"
          labelClassName="group-focus-within:text-cs-pink"
          inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
          control={control}
          name={"password"}
          label={"Password"}
          required
          inputProps={{
            type: `${passwordShown ? "text" : "password"}`,
            ...register("password"),
            autoComplete: "off",
          }}
          readonly={!!isLoggedIn}
          endAdornment={
            isLoggedIn ? <></> : (<span
              onClick={() => setPasswordShown(passwordShown ? false : true)}
              className={`leading-snug underline absolute right-5 top-4 border-cs-red cursor-pointer hover:text-cs-pink ${
                errors.password ? "text-cs-red" : "text-cs-gray"
              }`}
            >
              {passwordShown ? "Hide" : "Show"}
            </span>)
          }
          requirements={
            "* Minimum of 8 characters with at least one number and one special character"
          }
        />

        <div className="mt-6">
          <label className={`block text-black font-bold text-sm mb-2`}>
            Confirm Your Attraction Role
            <span className="text-cs-pink"> *</span>
          </label>
          <Controller
            defaultValue="individual"
            control={control}
            name={`businessType`}
            render={({
              field: { ref, value, onChange },
              fieldState: { error },
            }) => (
              <Select
                isDisabled={!!isLoggedIn}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: () => (
                    <div className="px-2.5">
                      <svg
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ),
                  IndicatorSeparator: () => null,
                }}
                value={businessTypeOptions.find((o) => o.value === value)}
                options={businessTypeOptions}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                    zIndex: 15,
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CED3CF"
                      : `1px solid ${error ? "red" : "#999999"}`,
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CED3CF"
                        : "1px solid #CED3CF",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 50,
                    width: "100%",
                    borderRadius: 8,
                  }),
                }}
                onChange={(v) => onChange(v?.value)}
              />
            )}
          />
          <p className="text-xs font-normal text-cs-gray mt-2">
            * This will help us verify your claim easier
          </p>
        </div>

        <label className="w-full checkbox-item mt-6 items-start flex gap-2 text-sm text-cs-gray font-normal cursor-pointer pb-0.5 h-auto">
          I verify that I am an authorised representative of the business and
          that the information I have entered is true and correct to the best of my knowledge.
          <input
            className="!w-20 !h-5"
            type="checkbox"
            checked={authorizedCheck}
            onChange={() => setAuthorizedCheck((s) => !s)}
          />
          <span className="mt-1 checkmark border-cs-bright-lt-gray"></span>
        </label>

        <label className="w-full checkbox-item mt-6 items-start flex gap-2 text-sm text-cs-gray font-normal cursor-pointer pb-0.5 h-auto">
          <p className="text-sm font-normal">
            I have read and accept the{" "}
            <a
              href="/terms-of-service"
              target="__blank"
              className="text-sm underline text-cs-pink"
            >
              Terms of service
            </a>
          </p>

          <input
            className="!w-20 !h-5"
            type="checkbox"
            checked={termsCheck}
            onChange={() => setTermsCheck((s) => !s)}
          />
          <span className="mt-1 checkmark border-cs-bright-lt-gray"></span>
        </label>

        <div className="mt-5">
          {isNew ? (
            <>
              <PrimaryButton
                type="submit"
                loading={
                  isRegisterLoading ||
                  isClaimedLoading ||
                  isCreateLoading ||
                  !authorizedCheck ||
                  !termsCheck ||
                  loggingIn || 
                  isGettingCheckoutUrl
                }
                onClick={() => setIsPayment(true)}
              >
                Continue to payment
              </PrimaryButton>
              {/* <button
                className="mt-2.5 w-full mb-5 text-center underline text-sm font-light text-black"
                type="submit"
                disabled={
                  isRegisterLoading ||
                  isClaimedLoading ||
                  isCreateLoading ||
                  !authorizedCheck ||
                  !termsCheck ||
                  loggingIn ||
                  isGettingCheckoutUrl
                }
                onClick={() => setIsPayment(false)}
              >
                Skip now, pay later
              </button> */}
            </>
          ) : (
            <PrimaryButton
              type="submit"
              loading={
                isRegisterLoading ||
                isClaimedLoading ||
                isCreateLoading ||
                !authorizedCheck ||
                !termsCheck ||
                loggingIn ||
                isGettingCheckoutUrl
              }
              onClick={() => setIsPayment(false)}
            >
              Create account
            </PrimaryButton>
          )}
        </div>
        {isSubmitted && !isValid && (
          <ErrorMessage>
            Please fix the errors indicated above to continue
          </ErrorMessage>
        )}

        <Description>
          <div className="flex gap-2">
            Already have an account?
            <Link
              to="/login"
              state={{ claim: attraction }}
              className="block text-sm font-bold text-[#6836D1]"
            >
              Log in here
            </Link>
          </div>
        </Description>
      </form>
    </div>
  );
};

export const SubmittedModal: React.FC<{
  attraction?: AttractionMinimal;
  newUserEmail: string;
}> = ({ attraction, newUserEmail }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col my-[74px]">
      <Heading>
        <div className="text-[28px]">
        Please check your inbox for a verification email
        </div>
      </Heading>

      <VerifyEmailBox email={newUserEmail} desc="Follow the instructions on the verification email we have sent you. You'll need to complete that step before your listing can be approved." />

      <div className="w-full mx-auto max-w-cs-616">
        <AttractionCard
          className="w-full"
          title={attraction?.title ?? ""}
          location={attraction?.location_name ?? attraction?.location ?? ""}
          image={attraction?.image ?? ""}
          imageAlt={attraction?.image_alt ?? ""}
        />
      </div>

      <PrimaryButton className="mt-5">
        <Link to="/dashboard">View Dashboard</Link>
      </PrimaryButton>

      {/* <div className="mt-6">
        <Heading>What happens now?</Heading>

        <Description>
          Once you've successfully verified your email address, our team will
          proceed to approve your attraction. This step ensures that the correct
          owner can effectively manage content and access analytics.
        </Description>
        <Description>
          Please allow up to 3 working days for us to approve your attraction.
        </Description>
      </div> */}
    </div>
  );
};

export const AttractionCard: React.FC<{
  title: string;
  location: string;
  image: string;
  imageAlt?: string;
  className?: string;
}> = ({ title, location, image, imageAlt, className }) => {
  return (
    <div
      className={`grid grid-cols-4 items-center border border-[#CFDBD5] rounded-lg mx-auto max-w-[700px] h-[100px] overflow-hidden ${
        className ? className : "w-full sm:w-5/6"
      }`}
    >
      <div className="col-span-1 max-w-full min-w-[75px] h-full bg-[#D9D9D9] rounded-l-md aspect-square">
        {image && (
          <img
            className="object-cover w-full h-full aspect-square rounded-l-md"
            src={image}
            alt={imageAlt ? imageAlt : title}
          />
        )}
      </div>
      <div className="col-span-3 max-w-full [@media(max-width:358px)]:max-w-[200px] p-4">
        <h3 className={`font-extrabold text-black ${title.length > 17 ? 'text-xl' : 'text-xl'}`}>{title}</h3>
        <p className="mt-1 text-sm font-normal text-black">{location}</p>
      </div>
    </div>
  );
};
