import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link, type LinkProps, type To } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function ContextMenu({
  options,
}: {
  options: { name: string; linkProps: LinkProps }[];
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex ml-auto">
          <svg
            width="19"
            height="5"
            viewBox="0 0 19 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.001 2.41995C4.00093 2.68266 3.94913 2.94278 3.84853 3.18547C3.74793 3.42815 3.60052 3.64865 3.41471 3.83437C3.2289 4.02008 3.00833 4.16738 2.7656 4.26786C2.52286 4.36833 2.26271 4.42001 2 4.41995C1.73729 4.41988 1.47717 4.36807 1.23448 4.26748C0.991793 4.16688 0.771296 4.01947 0.585579 3.83366C0.399862 3.64785 0.252562 3.42728 0.152088 3.18454C0.0516139 2.9418 -6.56023e-05 2.68165 6.24975e-08 2.41895C0.000132679 1.88838 0.211026 1.3796 0.586286 1.00453C0.961547 0.629452 1.47043 0.418813 2.001 0.418945C2.53157 0.419078 3.04035 0.629972 3.41542 1.00523C3.79049 1.38049 4.00113 1.88938 4.001 2.41995ZM16.001 0.419946C15.7383 0.420011 15.4782 0.471821 15.2355 0.572416C14.9928 0.673012 14.7723 0.820422 14.5866 1.00623C14.4009 1.19204 14.2536 1.41261 14.1531 1.65535C14.0526 1.89809 14.0009 2.15824 14.001 2.42095C14.0011 2.68366 14.0529 2.94378 14.1535 3.18647C14.2541 3.42915 14.4015 3.64965 14.5873 3.83537C14.7731 4.02108 14.9937 4.16838 15.2364 4.26886C15.4791 4.36933 15.7393 4.42101 16.002 4.42095C16.5326 4.42081 17.0413 4.20992 17.4164 3.83466C17.7915 3.4594 18.0021 2.95051 18.002 2.41995C18.0019 1.88938 17.791 1.3806 17.4157 1.00552C17.0405 0.630452 16.5316 0.419813 16.001 0.419946ZM9.001 0.419946C8.73829 0.420011 8.47817 0.471821 8.23548 0.572416C7.99279 0.673012 7.7723 0.820422 7.58658 1.00623C7.40086 1.19204 7.25356 1.41261 7.15309 1.65535C7.05261 1.89809 7.00093 2.15824 7.001 2.42095C7.00107 2.68366 7.05288 2.94378 7.15347 3.18647C7.25407 3.42915 7.40148 3.64965 7.58729 3.83537C7.7731 4.02108 7.99367 4.16838 8.2364 4.26886C8.47914 4.36933 8.73929 4.42101 9.002 4.42095C9.53257 4.42081 10.0413 4.20992 10.4164 3.83466C10.7915 3.4594 11.0021 2.95051 11.002 2.41995C11.0019 1.88938 10.791 1.3806 10.4157 1.00552C10.0405 0.630452 9.53157 0.419813 9.001 0.419946Z"
              fill="black"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-auto xl:right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((o, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <Link
                    className={classNames(
                      active ? "bg-gray-100 text-black" : "text-gray-700",
                      "block px-4 py-2 text-sm font-roboto-flex"
                    )}
                    {...o.linkProps}
                  >
                    {o.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
