import { useEffect, useState } from 'react'
import { apiSlice } from '../../../../store/apiSlice';
import { useParams } from 'react-router-dom';
import DashboardContainer from '../../../../components/amp/DashboardContainer';
import DashboardHeaderContainer from '../../../../components/amp/DashboardHeaderContainer';
import AvailabilityDayConfiguration from './AvailabilityDayConfiguration';
import CreateTimeslot from './CreateTimeslot';

const AvailabilityPeriodConfiguration: React.FC = () => {
    const { availabilityPeriodId } = useParams();
    const { data: availabilityPeriodData } = apiSlice.useGetAvailabilityPeriodQuery(availabilityPeriodId || '', { skip: !availabilityPeriodId })
    const [showCreateTimeslot, setShowCreateTimeslot] = useState(false)
    const [availabilityPeriod, setAvailabilityPeriod] = useState<DOWTKTicketingAvailabilityPeriod | null>(null)
    const [createAvailabilityTimeslots] = apiSlice.endpoints.createAvailabilityTimeslots.useMutation()
    const [updateAvailabilityTimeslot] = apiSlice.endpoints.updateAvailabilityTimeslot.useMutation()

    useEffect(() => {
        if (!availabilityPeriodData) return
        setAvailabilityPeriod(availabilityPeriodData)
    }, [availabilityPeriodData])

    const createTimeslot = (timeslotData: DOWTKTicketingAvailabilityTimeSlot) => {
        const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        const availabilityPeriodCopy: DOWTKTicketingAvailabilityPeriod = JSON.parse(JSON.stringify(availabilityPeriod)) as DOWTKTicketingAvailabilityPeriod
        const timeslots: DOWTKTicketingAvailabilityTimeSlot[] = []
        days.forEach((day: string) => {
            const timeslot = {
                start_time: timeslotData.start_time,
                end_time: timeslotData.end_time,
                capacity: timeslotData.capacity ? +timeslotData.capacity : undefined,
                availability_day_id: ((availabilityPeriodCopy as any)[day] as DOWTKTicketingAvailabilityDay).id,
                availability_period_id: availabilityPeriodCopy.id,
            } as DOWTKTicketingAvailabilityTimeSlot

            timeslots.push(timeslot);

            const timeslotArray = ((availabilityPeriodCopy as any)[day] as DOWTKTicketingAvailabilityDay).timeslots || []

                ; (availabilityPeriodCopy as any)[day].timeslots = [...timeslotArray, timeslot]
        })

        createAvailabilityTimeslots({ timeslots, availabilityPeriodId: availabilityPeriodCopy.id })
        setAvailabilityPeriod(availabilityPeriodCopy)
        setShowCreateTimeslot(false)
    }

    const updateTimeslot = (timeslotData: DOWTKTicketingAvailabilityTimeSlot) => {
        // find the specific timeslot and update it
        const availabilityPeriodCopy: DOWTKTicketingAvailabilityPeriod = JSON.parse(JSON.stringify(availabilityPeriod)) as DOWTKTicketingAvailabilityPeriod

        const availabilityDay = [
            availabilityPeriodCopy?.monday,
            availabilityPeriodCopy?.tuesday,
            availabilityPeriodCopy?.wednesday,
            availabilityPeriodCopy?.thursday,
            availabilityPeriodCopy?.friday,
            availabilityPeriodCopy?.saturday,
            availabilityPeriodCopy?.sunday,
        ].find(day => day?.id === timeslotData.availability_day_id)

        const timeslot = availabilityDay?.timeslots?.find(ts => ts.id === timeslotData.id)

        if (!timeslot) {
            console.error('timeslot not found')
            return
        }

        const timeslotUpdate = {
            id: timeslotData.id,
            capacity: timeslotData.capacity ? +timeslotData.capacity : undefined,
            start_time: timeslotData.start_time,
            end_time: timeslotData.end_time,
        }

        Object.assign(timeslot, timeslotUpdate)

        updateAvailabilityTimeslot({ availabilityPeriodId: availabilityPeriod?.id as string, timeslot: timeslotUpdate })
        setAvailabilityPeriod(availabilityPeriodCopy)
    }

    return (
        <>
            <div className="flex flex-col flex-1 bg-cs-off-white">
                <DashboardHeaderContainer>
                    <h1 className="font-extrabold text-xl text-black font-roboto-flex ">Viewing: {availabilityPeriod?.name}</h1>
                </DashboardHeaderContainer>
                <DashboardContainer>
                    <section className={`flex flex-col px-6 pt-4 pb-8 bg-white xl:pt-6 xl:rounded-lg xl:mx-[70px]`}>
                        {availabilityPeriod && <AvailabilityDayConfiguration onTimeslotUpdate={updateTimeslot} availabilityPeriod={availabilityPeriod} availabilityDay={availabilityPeriod.monday} />}
                        {availabilityPeriod && <AvailabilityDayConfiguration onTimeslotUpdate={updateTimeslot} availabilityPeriod={availabilityPeriod} availabilityDay={availabilityPeriod.tuesday} />}
                        {availabilityPeriod && <AvailabilityDayConfiguration onTimeslotUpdate={updateTimeslot} availabilityPeriod={availabilityPeriod} availabilityDay={availabilityPeriod.wednesday} />}
                        {availabilityPeriod && <AvailabilityDayConfiguration onTimeslotUpdate={updateTimeslot} availabilityPeriod={availabilityPeriod} availabilityDay={availabilityPeriod.thursday} />}
                        {availabilityPeriod && <AvailabilityDayConfiguration onTimeslotUpdate={updateTimeslot} availabilityPeriod={availabilityPeriod} availabilityDay={availabilityPeriod.friday} />}
                        {availabilityPeriod && <AvailabilityDayConfiguration onTimeslotUpdate={updateTimeslot} availabilityPeriod={availabilityPeriod} availabilityDay={availabilityPeriod.saturday} />}
                        {availabilityPeriod && <AvailabilityDayConfiguration onTimeslotUpdate={updateTimeslot} availabilityPeriod={availabilityPeriod} availabilityDay={availabilityPeriod.sunday} />}
                    </section>
                    <button onClick={() => setShowCreateTimeslot(!showCreateTimeslot)}>Add time slot</button>

                    {showCreateTimeslot && availabilityPeriod && <CreateTimeslot callback={createTimeslot} availabilityPeriod={availabilityPeriod} />}
                </DashboardContainer>
            </div>
        </>
    )
}

export default AvailabilityPeriodConfiguration;