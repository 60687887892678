import { SubmitHandler, useForm } from "react-hook-form";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import TextInput from "../../../form/TextInput";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiSlice } from "../../../../store/apiSlice";
import { useLogin } from "../../../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { globalActions } from "../../../../store/globalSlice";
import { restartAnimation } from "../../amp/ToastNotification";
import type { AttractionMinimal } from "./CreateClaimModal";
import { Description, Heading } from "../../TwoColTemplate";
import { PrimaryButton } from "../../PrimaryButton";
import { ErrorMessage } from "../../ErrorMessage";

interface LoginForm {
  email: string;
  password: string;
}

export default function LoginModal({ className = "" }: { className?: string }) {
  const { state } = useLocation();
  const { claim } = (state as { claim: AttractionMinimal | null }) ?? {
    claim: null,
  };
  const navigate = useNavigate();
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const mustBe: string =
    "Must be at least 8 characters, with at least one number and one special character";

  const schema = z.object({
    email: z
      .string()
      .min(1, "Email is required")
      .email("Enter a valid email address"),
    password: z
      .string()
      .min(1, "Password is required")
      .min(8, mustBe)
      .refine(
        (passwordText) =>
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$£%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$£%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(
            passwordText
          ),
        mustBe
      ),
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, isSubmitted, errors },
    control,
  } = useForm<LoginForm>({
    resolver: zodResolver(schema),
  });

  const [callLogin, loginData] = useLogin();
  const { isError: isLoginError, isLoading, error } = loginData;

  const onSubmit: SubmitHandler<LoginForm> = (e) => {
    callLogin(e)
      .unwrap()
      .then(() => {
        if (claim) {
          navigate("/search-claim", {
            state: {
              loggedInClaim: claim,
            },
          });
        } else {
          navigate("/");
        }
      });
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [searchParams] = useSearchParams();
  const [verifyEmail, verifyEmailData] = apiSlice.useVerifyEmailMutation();
  const {
    isSuccess: isEmailVerificationSuccess,
    isError: isEmailVerificationError,
    isLoading: isEmailVerificationLoading,
    error: emailVerificationError,
  } = verifyEmailData;
  const verifyEmailCallback = async () => {
    const userId = searchParams.get("u");
    const token = searchParams.get("t");

    if (userId && token) {
      await verifyEmail({
        userId,
        token,
      });

      restartAnimation();
    }
  };
  useEffect(() => {
    verifyEmailCallback();
  }, [searchParams]);

  useEffect(() => {
    isEmailVerificationSuccess &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Email verified",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isEmailVerificationSuccess]);

  useEffect(() => {
    isEmailVerificationError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message:
            (emailVerificationError as { data: { message: string } })?.data
              ?.message ?? "Cannot verify email",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isEmailVerificationError]);

  useEffect(() => {
    if (
      error &&
      (error as unknown as { data: { status: string } })?.data?.status ===
        "UNVERIFIED"
    ) {
      navigate({
        pathname: "/verify-email",
        search: createSearchParams({
          email: getValues("email"),
        }).toString(),
      });
    }
  }, [error]);

  useEffect(() => {
    document.title = "Log in | Day Out With The Kids";
  }, []);

  return (
    <div className={`flex flex-col w-full ${className}`}>
      <Heading>Log in to your account</Heading>

      <Description>
        These accounts are designed for attractions to manage their listings on
        our site. If you’re looking to book tickets or browse our attractions,{" "}
        <Link
          to={"https://www.dayoutwiththekids.co.uk/"}
          className="underline text-[#6836D1]"
        >
          head to the main site!
        </Link>
      </Description>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-5 py-5">
          <TextInput
            className="group"
            labelClassName=""
            inputClassName="border text-black rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
            control={control}
            name={"email"}
            label="Email address"
            required
            inputProps={{ ...register("email") }}
          />
          <TextInput
            className="group"
            labelClassName=""
            inputClassName="border text-black rounded-md py-3 px-2 flex flex-col w-full focus:outline-cs-pink"
            control={control}
            name={"password"}
            label={"Password"}
            required
            inputProps={{
              type: `${passwordShown ? "text" : "password"}`,
              ...register("password"),
            }}
            endAdornment={
              <span
                onClick={togglePasswordVisiblity}
                className={`leading-snug underline absolute right-5 top-4 border-cs-red cursor-pointer hover:text-cs-pink ${
                  errors.password ? "text-cs-red" : "text-cs-gray"
                }`}
              >
                {passwordShown ? <ShowIcon /> : <HideIcon />}
              </span>
            }
          />
        </div>

        <PrimaryButton type="submit" loading={isLoading}>
          Log in
        </PrimaryButton>

        {isSubmitted && !isValid && (
          <ErrorMessage className="mt-3">
            Please fix the errors indicated above to continue
          </ErrorMessage>
        )}
        {isLoginError && (
          <ErrorMessage className="mt-3">
            The email and password combination you entered does not exist in our
            system
          </ErrorMessage>
        )}
      </form>

      <Link
        to={"/forgotten-password"}
        className="mt-4 text-sm underline cursor-pointer text-black hover:text-[#6836D1] font-roboto-flex font-normal"
      >
        Forgotten your password?
      </Link>

      <Description>
        Looking to claim or add a new listing?{" "}
        <Link
          to={"/search-claim"}
          className="font-bold underline text-[#6836D1]"
        >
          Click here
        </Link>
      </Description>
    </div>
  );
}

export function HideIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_4723)">
        <path
          d="M20.5455 10.7127C18.486 6.90545 14.8744 4.60156 10.8777 4.60156C6.88103 4.60156 3.26325 6.90545 1.22214 10.7127L1.05103 10.9999L1.20991 11.2932C3.26936 15.1005 6.88103 17.4043 10.8777 17.4043C14.8744 17.4043 18.4921 15.131 20.5455 11.2932L20.7044 10.9999L20.5455 10.7127ZM10.8777 16.1516C7.43714 16.1516 4.27769 14.2327 2.44436 10.9999C4.27769 7.76712 7.43714 5.84823 10.8777 5.84823C14.3182 5.84823 17.441 7.77323 19.3049 10.9999C17.441 14.2327 14.3121 16.1516 10.8777 16.1516Z"
          fill="black"
        />
        <path
          d="M11.055 15.2106C13.3703 15.2106 15.2472 13.3337 15.2472 11.0184C15.2472 8.70309 13.3703 6.82617 11.055 6.82617C8.73972 6.82617 6.86279 8.70309 6.86279 11.0184C6.86279 13.3337 8.73972 15.2106 11.055 15.2106Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4723">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ShowIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_1919)">
        <path
          d="M18.37 11.17C17.5607 11.171 16.7581 11.3166 16 11.6L24.8 20.4C25.0872 19.6326 25.2329 18.8194 25.23 18C25.2221 16.1858 24.4958 14.4487 23.2102 13.1686C21.9245 11.8886 20.1842 11.17 18.37 11.17Z"
          fill="black"
        />
        <path
          d="M34.29 17.53C30.92 11.3 25.01 7.53003 18.47 7.53003C16.6893 7.53421 14.9206 7.82112 13.23 8.38003L14.84 10C16.0258 9.69356 17.2452 9.53568 18.47 9.53003C24.1 9.53003 29.22 12.67 32.27 17.96C31.1511 19.9226 29.6679 21.6536 27.9 23.06L29.32 24.48C31.3661 22.8294 33.0653 20.7902 34.32 18.48L34.58 18L34.29 17.53Z"
          fill="black"
        />
        <path
          d="M4.86988 5.77998L9.32988 10.24C6.5108 12.0551 4.20678 14.5657 2.63988 17.53L2.37988 18L2.63988 18.48C6.00988 24.71 11.9199 28.48 18.4599 28.48C21.0125 28.4795 23.532 27.9017 25.8299 26.79L30.8299 31.79L32.5799 30.29L6.57988 4.28998L4.86988 5.77998ZM13.1699 14.08C12.2343 15.3994 11.7958 17.0073 11.932 18.619C12.0682 20.2307 12.7703 21.7422 13.914 22.8859C15.0577 24.0296 16.5692 24.7317 18.1809 24.8679C19.7926 25.0041 21.4005 24.5655 22.7199 23.63L24.3199 25.23C22.4677 26.0217 20.4742 26.4299 18.4599 26.43C12.8299 26.43 7.70988 23.29 4.65988 18C6.12359 15.4082 8.23161 13.2382 10.7799 11.7L13.1699 14.08Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1919">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
