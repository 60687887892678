import { Controller, useFormContext } from 'react-hook-form';
import Select from "react-select";

export function TicketingProduct({ products }: { products: DOWTKTicketingProduct[] }) {
  const { control } = useFormContext()
  const options = products.map((p) => ({ value: p.id, label: p.name }))

  return (
    <>
      <label htmlFor='product' className="block font-medium text-gray-700  rounded-md">
        Ticket Product
      </label>

      <Controller
        name="product_id"
        control={control}
        render={({ field }) => (
          <div className='border border-cs-gray rounded-md p-2'>
            <Select
              required={true}
              {...field}
              value={options.find((option: any) => option.value === field.value)}
              className="w-full mt-2 mb-2 text-sm text-cs-gray"
              options={options}
              onChange={(v) => {
                console.log('v', v)
                field.onChange((v as any)?.value)
              }}
            />
          </div>
        )} />
    </>);

}
