import { Link } from "react-router-dom";
import { apiSlice } from "../../../store/apiSlice";

const Footer: React.FC = () => {

    const { data } = apiSlice.useGetVersionNumberQuery();
    const { version } = data ?? {}; 

    // to be deleted and remodified once we know how to get www data in
    const bottomNavAbout = [
        ["About", "https://www.dayoutwiththekids.co.uk/about-us"],
        ["Management Centre terms of service", "/terms-of-service"]
    ]

    const bottomNavContact = [
        ["Contact Us", "https://www.dayoutwiththekids.co.uk/contact-us"],
        ["List a day out", "https://www.dayoutwiththekids.co.uk/listing-options"]
    ]

    const bottomNavSocial = [
        ["/assets/www/facebook.svg", "https://www.facebook.com/DayOutWithTheKids/"],
        ["/assets/www/twitter.svg", "https://twitter.com/dowtkids/"],
        ["/assets/www/instagram.svg", "https://www.instagram.com/dayoutwiththekids/"],
        ["/assets/www/pinterest.svg", "https://www.pinterest.co.uk/dayoutwiththekids/"],
    ]

    return(
        <footer className="flex flex-col mt-auto bg-cs-gray">
            <section className="flex flex-col flex-wrap justify-center w-full px-5 pt-12 pb-6 mx-auto lg:flex-row lg:max-w-cs-1024 xl:max-w-cs-1280 2xl:max-w-cs-1536">
                <nav className="flex flex-col">
                    <h3 className="mb-2 text-lg font-bold text-white xl:mx-40">About</h3>
                    <ul className="flex flex-col xl:mx-40">
                        { 
                            bottomNavAbout.map((item, i) => <li key={item[0]+i} className="my-1 text-white underline"><Link to={item[1]} target="_blank" className="font-normal text-cs-13">{item[0]}</Link></li>) 
                        }
                    </ul>
                </nav>
                <nav className="flex flex-col mt-7 xl:mt-0">
                    <h3 className="mb-2 text-lg font-bold text-white xl:mx-40">Contact</h3>
                    <ul className="flex flex-col xl:mx-40">
                        { 
                            bottomNavContact.map((item, i) => <li key={item[0]+i} className="my-1 text-white underline"><Link to={item[1]} target="_blank" className="font-normal text-cs-13">{item[0]}</Link></li>) 
                        }
                    </ul>
                </nav>
                <nav className="flex justify-center mt-20 mb-6 xl:flex-cs-full">
                    <ul className="flex">
                        { 
                            bottomNavSocial.map((item, i) => <li key={item[0]+i} className="mx-4 w-cs-30"><Link to={item[1]} target="_blank"><img src={item[0]} /></Link></li>) 
                        }
                    </ul>
                </nav>
            </section>
            
            <section className="flex justify-center py-10 bg-white">
                <div className="px-5 mx-auto text-center xl:max-w-cs-1280 2xl:max-w-cs-1536">
                    <p className="mb-3 text-lg text-cs-gray">{version} | © Copyright Day Out With The Kids Ltd. All rights reserved.</p>
                    <p className="mb-3 text-lg text-cs-gray">Registered in England and Wales, company number: 05813603.</p>
                    <p className="mb-3 text-lg text-cs-gray">Registered address: Day Out With The Kids, First Floor, 1 The Courtyard, 707 Warwick Road, Solihull, B91 3DA.</p>
                </div>
            </section>
        </footer>
    )
}

export default Footer;