import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from "zod";
import { PrimaryButton } from '../../../../components/global/PrimaryButton';
import { apiSlice } from '../../../../store/apiSlice';
import { TicketDescription } from './TicketDescription';
import { TicketTitle } from './TicketTitle';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardContainer from '../../../../components/amp/DashboardContainer';
import DashboardHeaderContainer from '../../../../components/amp/DashboardHeaderContainer';
import { TicketCategory } from './TicketCategory';
import { TicketPrice } from './TicketPrice';
import { TicketingProduct } from './TicketingProduct';
import { TicketAge } from './TicketAge';

const CreateTicketOld: React.FC = () => {
  const navigate = useNavigate();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { attractionId } = useParams();
  const isEditing = !!ticketId
  const [products, setProducts] = useState<DOWTKTicketingProduct[]>([]);

  const [createTicket] = apiSlice.useCreateTicketMutation()
  const [updateTicket] = apiSlice.useUpdateTicketMutation()
  const { data: ticketQuery } = apiSlice.useGetTicketQuery(ticketId || '', { skip: !isEditing })
  const { data: productsQuery } = apiSlice.useGetTicketingProductListQuery({
    venueShortId: attractionId!
  });

  const formMethods = useForm<EditTicketFormType>({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (!ticketQuery) return
    formMethods.reset({})
  }, [ticketQuery])

  useEffect(() => {
    if (!productsQuery) return
    setProducts(productsQuery.results)
  }, [productsQuery])

  const onSubmit = () => {
    const fn: SubmitHandler<EditTicketFormType> = async (e) => {

      if (isEditing) {
        await updateTicket(e as any).unwrap().then((res) => {
          navigate(-1);
        })
      } else {
        await createTicket(e as any).unwrap().then((res) => {
          navigate(-1);
        })
      }
    };

    return fn;
  }

  return (
    <>
      <div className="flex flex-col flex-1 bg-cs-off-white">
        <DashboardHeaderContainer>
          <h1 className="font-extrabold text-xl text-black font-roboto-flex ">Ticketing</h1>
        </DashboardHeaderContainer>
        <DashboardContainer>
          <section className={`flex flex-col px-6 pt-4 pb-8 bg-white xl:pt-6 xl:rounded-lg xl:mx-[70px]`}>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit())}>
                <legend className="mb-3 text-lg font-bold text-cs-gray">
                  {isEditing ? 'Edit Ticket' : 'Create Ticket'}
                </legend>
                <TicketTitle />
                <TicketingProduct products={products} />
                {/* <TicketCategory index={0} /> */}
                <TicketAge />
                <TicketDescription />
                <TicketPrice />
                <div className="flex items-center gap-5 max-w-[350px] mt-5">
                  <PrimaryButton scale="sm" type="submit">
                    {isEditing ? 'Edit Ticket' : 'Create Ticket'}
                  </PrimaryButton>
                </div>
              </form>
            </FormProvider>
          </section>
        </DashboardContainer>
      </div>

    </>
  )
}

export default CreateTicketOld;

export type EditTicketFormType = z.infer<typeof schema>;

export const categories: { value: string; label: string }[] = [
  { value: "3_and_under", label: "Babies and Toddlers" },
  { value: "younger_children", label: "Child" },
  { value: "adult", label: "Adult" },
  { value: "senior", label: "Senior" },
  { value: "student", label: "Student" },
  { value: "family", label: "Family" },
  { value: "carer", label: "Carer" },
  { value: "group", label: "Group" },
];

export const ages: { value: string; label: string }[] = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18+", label: "18+" },
];

const schema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: "Please enter a name." }),
  default_price: z.coerce.number().positive({ message: "Please enter a price." }).or(z.literal('0')),
  product_id: z.string(),
  category: z.string().nonempty({ message: "Please select a category." }),
  description: z.string(),
  min_age: z.coerce.number().positive({ message: "Please enter a minimum age." }).or(z.literal('0')),
  max_age: z.coerce.number().positive({ message: "Please enter a maximum age." }),
});

export const getCategory = (value: string) => {
  return categories.find((c) => c.value === value);
};
export const getAge = (
  value: string,
  additional: { label: string; value: string }[] = []
) => {
  return [...additional, ...ages].find((c) => c.value === value);
};
export const defaultPrice = {
  category: "3_and_under",
  age: {
    from: "0",
    to: "3",
  },
  price: null,
};



