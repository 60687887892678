import { PrimaryButton } from "../global/PrimaryButton";

export function SaveButtonsEditAttraction({
    isLoading,
    label = "Save changes"
}: {
    isLoading: boolean,
    label?: string
}) {
  return (
    <div className="flex items-center gap-5 max-w-[350px] mt-[30px]">
      <PrimaryButton
        scale="sm"
        type="submit"
        loading={isLoading}
        className="px-[25px]"
      >
        {label}
      </PrimaryButton>
      {/* <OutlineButton scale="sm" type="button" onClick={revertToSave}>
          Cancel
        </OutlineButton> */}
    </div>
  );
}
