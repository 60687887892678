import TimeslotConfiguration from './TimeslotConfiguration';

const AvailabilityDayConfiguration = ({ availabilityDay, availabilityPeriod, onTimeslotUpdate }: {
  availabilityDay: DOWTKTicketingAvailabilityDay, availabilityPeriod: DOWTKTicketingAvailabilityPeriod,
  onTimeslotUpdate: (timeslot: DOWTKTicketingAvailabilityTimeSlot) => void
}) => {

  return (
    <>
      <div className="flex flex-col flex-1 bg-cs-off-white">
        <div>{availabilityDay?.day_of_week}</div>
        {availabilityDay?.timeslots?.map(timeslot => <TimeslotConfiguration onUpdate={onTimeslotUpdate} availabilityPeriod={availabilityPeriod} timeslot={timeslot} />)}
      </div>
    </>
  )
}

export default AvailabilityDayConfiguration;