import { useFormContext } from "react-hook-form";
import TextInput from "../../../../components/form/TextInput";
import { Label } from "./CreateTicket";

export function TicketTitle() {
  const { register, control } = useFormContext();

  return (
    <div>
      <Label
        forText={"name"}
        title={"Ticket Name"}
        desc="Example: Standard Entry, General Admission etc."
        required={true}
      />
      <TextInput
        name={"name"}
        className="max-w-[437px] group"
        labelClassName="group-focus-within:text-cs-pink font-bold"
        inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
        label=""
        control={control}
        required
        inputProps={{
          ...register("name"),
          type: "text",
        }}
      />
    </div>
  );
}
