import { components } from "react-select";

const singleSelectedOptionWithMultiSelect: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  value,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    fontSize: 12,
    fontWeight: 400,
    margin: 0,
    padding: 0
  };

  const props = {
    ...innerProps,
    style
  };

  return (
    <components.Option
    {...rest}
    isFocused={isFocused}
    getStyles={getStyles}
    innerProps={props}
    >
       <div className="py-4 flex w-full items-center border-cs-1 border-x-0 h-full border-b-0 border-[#F2F2F2] text-sm hover:bg-[#F2F2F2]" key={`${value}`}>
        <label className="checkbox-item mb-1 ml-2" >
          <input className="!w-40 !h-3" type="checkbox" checked={isSelected} readOnly/>
          <span className="checkmark"></span>
        </label>
        {children}
      </div>
    </components.Option>
  );
};

export default singleSelectedOptionWithMultiSelect;