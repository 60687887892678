
interface RTKSuccess {
    data: any
}

interface RTKError {
    error: any
}

export const isSuccessResponse = (obj: RTKSuccess | RTKError): obj is RTKSuccess => {
    return (obj as any).data != undefined;
}