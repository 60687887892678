import { Outlet, ScrollRestoration } from "react-router-dom";
import { useAppSelector } from "../../store";
import { Walkthrough } from "../amp/Walkthrough";

interface IRouteShell {
  isAmp: boolean;
  attractionId?: string;
}

const RouteShell: React.FC<IRouteShell> = ({ isAmp }) => {
  const { appLoaded } = useAppSelector((state) => state.global);
  const {
    walkthroughStatus,
    walkthroughType,
    attractionId: attractionIdState,
  } = useAppSelector((state) => state.walkthrough);

  return (
    <>
      <div className={`flex flex-1 ${isAmp ? "flex-col" : "flex-col"}`}>
        {appLoaded && (
          <>
            <ScrollRestoration />
            <Outlet />
            <Walkthrough
              key={walkthroughStatus + walkthroughType + attractionIdState}
            />
          </>
        )}
      </div>
    </>
  );
};

export default RouteShell;
