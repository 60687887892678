import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { PrimaryButton } from "../../../../components/global/PrimaryButton";
import TextInput from "../../../../components/form/TextInput";

const CreateTimeslot = ({ availabilityPeriod, timeslot, callback }: {
    availabilityPeriod: DOWTKTicketingAvailabilityPeriod,
    timeslot?: DOWTKTicketingAvailabilityTimeSlot,
    callback: (data: DOWTKTicketingAvailabilityTimeSlot) => void

}) => {

    const editingCapacity = !!timeslot
    const formSchema = z.object({
        id: z.string().optional(),
        availability_period_id: z.string(),
        availability_day_id: z.string().optional(),
        start_time: z.string().nonempty({ message: "Please enter a start date for this period." }),
        capacity: z.preprocess((val) => val ? val : undefined, z.coerce.number().int().positive().optional()),
    });

    const formMethods = useForm<DOWTKTicketingAvailabilityTimeSlot>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            id: timeslot?.id || undefined,
            availability_period_id: availabilityPeriod.id,
            availability_day_id: timeslot?.availability_day_id || undefined,
            start_time: timeslot?.start_time || '',
            capacity: timeslot?.capacity || undefined,
        }
    })

    const onSubmit = () => {
        return () => { callback(formMethods.getValues()) }
    }

    return (
        <div className="fixed w-full h-full top-0 left-0 z-[101] flex flex-col before:content-[''] before:bg-opacity-50 before:bg-black before:w-full before:h-full before:fixed">
            <div className="rounded-lg p-6 absolute top-2/4 right-2/4 -translate-y-2/4 translate-x-2/4 flex flex-col min-w-full md:min-w-cs-787 min-h-cs-394 items-center bg-white">
                <h3 className="text-2xl font-bold text-cs-gray">
                    Create timeslot
                </h3>
                <p className="font-normal my-3 text-base text-cs-gray">
                    Choose settings
                </p>

                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onSubmit())}>
                        <legend className="mb-3 text-lg font-bold text-cs-gray">
                        </legend>
                        {!editingCapacity &&
                            <TextInput
                                name={"start_time"}
                                className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
                                labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
                                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink"
                                label="Start Date"
                                control={formMethods.control}
                                required
                                inputProps={{
                                    ...formMethods.register('start_time'),
                                    type: "time",
                                }} />
                        }
                        <TextInput
                            name={"capacity"}
                            className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
                            labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
                            inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink"
                            label="Capacity"
                            control={formMethods.control}
                            inputProps={{
                                ...formMethods.register('capacity'),
                                type: "number",
                            }} />
                        <div className="flex items-center gap-5 max-w-[350px] mt-5">
                            <PrimaryButton scale="sm" type="submit">
                                OK
                            </PrimaryButton>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
}

export default CreateTimeslot