import { type ReactNode } from "react";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  children: ReactNode;
  scale?: 'sm' | 'base'
}

export function PrimaryButton({
  type = "button",
  children,
  className = "",
  loading = false,
  scale = 'base',
  ...args
}: ButtonProps) {
  const loadingStyle =
    "bg-cs-bright-lt-gray border-cs-bright-lt-gray hover:bg-cs-bright-lt-gray hover:border-cs-bright-lt-gray hover:text-white";
  const normalStyle = "bg-[#6836D1]";

  const size = scale === 'base' ? 'h-[52px] text-base p-4 w-full' : 'h-9 text-sm py-2.5 px-10 leading-[16px]'
  return (
    <>
      <button
        type={type}
        className={`rounded-lg text-white font-bold whitespace-nowrap ${size} ${
          (loading || args.disabled) ? loadingStyle : normalStyle
        } ${className}`}
        {...args}
        disabled={loading || args.disabled}
      >
        {children}
      </button>
    </>
  );
}
