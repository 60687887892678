import { Controller, useFormContext, useWatch } from "react-hook-form";
import Select from "react-select";
import TextInput from "../../../../components/form/TextInput";
import { useEffect } from "react";

const options = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16+", label: "16+" },
];
export function TicketMinAge({ index }: { index: number }) {
  const { control } = useFormContext();
  const watched = useWatch({
    control,
    name: `ticketType.${index}.min_age`
  })
  
  return (
    <TextInput
      name={`ticketType.${index}.min_age`}
      className=""
      labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
      inputClassName="flex flex-col w-[81px] h-[31px] flex justify-center items-center text-center rounded-full bg-[#F1F1F1]"
      label=""
      control={control}
      required
      inputProps={{
        ...control.register(`ticketType.${index}.min_age`, {
          setValueAs(val) {
            const num = parseInt(val);

            if (Number.isNaN(num)) return val;
            return num;
          },
        }),
        value: watched
      }}
    />
  );
}

export function TicketMaxAge({ index }: { index: number }) {
  const { control } = useFormContext();
  const watched = useWatch({
    control,
    name: `ticketType.${index}.max_age`
  })
  
  return (
    <TextInput
      name={`ticketType.${index}.max_age`}
      className=""
      labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
      inputClassName="flex flex-col w-[81px] h-[31px] flex justify-center items-center text-center rounded-full bg-[#F1F1F1]"
      label=""
      control={control}
      required
      inputProps={{
        ...control.register(`ticketType.${index}.max_age`, {
          setValueAs(val) {
            const num = parseInt(val);

            if (Number.isNaN(num)) return val;
            return num;
          },
        }),
        value: watched
      }}
    />
  );
}

export function TicketAge() {
  return null;
}
