import { useState, useEffect, memo } from "react";
import { usePaginationPages } from "../../../hooks/usePaginationPages";

const Pagination = ({
  pageIndex,
  gotoPage,
  length,
  pageSize,
  setPageSize,
}: {
  pageIndex: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  length: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
}) => {
  const { canGo, currentPage, pages, goTo, goNext, goPrev } =
    usePaginationPages({
      pageIndex,
      gotoPage,
      length,
      pageSize,
    });

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);

  return (
    <div className="ml-2 flex items-center justify-center">
      {canGo.previous && (
        <button onClick={goPrev} disabled={!canGo.previous}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3438 5.26367V6.57617H3.21875L6.9375 10.3262L6 11.2637L0.65625 5.91992L6 0.576172L6.9375 1.51367L3.21875 5.26367H11.3438Z"
              fill="black"
            />
          </svg>
        </button>
      )}
      {pages.map((page: number, i: number) => (
        <button
          onClick={() => goTo(page - 1)}
          key={i}
          className={`text-md w-10 h-10 flex justify-center items-center rounded-lg xl:text-sm ${
            currentPage == page - 1
              ? "font-bold bg-[#6836D1] text-white"
              : "text-black"
          }`}
        >
          {page}
        </button>
      ))}
      {canGo.next && (
        <button onClick={goNext} disabled={!canGo.next}>
          <svg
          className="rotate-180"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3438 5.26367V6.57617H3.21875L6.9375 10.3262L6 11.2637L0.65625 5.91992L6 0.576172L6.9375 1.51367L3.21875 5.26367H11.3438Z"
              fill="black"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default memo(Pagination);
