import {
  Link,
  ScrollRestoration,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect } from "react";
import {
  ATTRACTION_DATA_LOCAL_STORAGE_KEY,
  AttractionCard,
} from "../../components/global/www/modals/CreateClaimModal";
import { useAppDispatch, useAppSelector } from "../../store";
import { useAuthUser } from "../../hooks/useAuth";
import {
  Description,
  Heading,
  TwoColTemplate,
} from "../../components/global/TwoColTemplate";
import { apiSlice } from "../../store/apiSlice";
import { restartAnimation } from "../../components/global/amp/ToastNotification";
import { globalActions } from "../../store/globalSlice";
import { EmailVerificationButton } from "../../components/global/www/modals/VerifyEmailModal";

const PaymentSuccess: React.FC = () => {
  const { user } = useAppSelector((state) => state.global);
  const { isLoggedIn } = useAuthUser();
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const attraction = {
    id: searchParams.get("id") ?? "",
    title: searchParams.get("title") ?? "",
    location: searchParams.get("location") ?? "",
    location_name: searchParams.get("location_name") ?? undefined,
    image: searchParams.get("image") ?? "",
    image_alt: searchParams.get("image_alt") ?? "",
  };
  const cbHostedPageId = searchParams.get("id") || "";
  const [completePayment] = apiSlice.useCompletePaymentMutation();
  const [submitApproval] = apiSlice.useUpdateSubmitClaimMutation();

  useEffect(() => {
    localStorage.removeItem(ATTRACTION_DATA_LOCAL_STORAGE_KEY);

    if(!isLoggedIn) {
      navigate("/login");
      return
    }

    if (isLoggedIn && user?.role === "ATTRACTION_MANAGER") {
      navigate("/forbidden");
    }

    (async () => {
      const result = await completePayment({
        cbHostedPageId,
      });

      if ("error" in result) {
        dispatch(
          globalActions.setToastNotifcation({
            ...toastNotification,
            type: "ERROR",
            message: "Failed completing the payment.",
          })
        );
      } else {
        dispatch(
          globalActions.setToastNotifcation({
            ...toastNotification,
            type: "SUCCESS",
            message: "Successful payment",
          })
        );
      }

      restartAnimation();

      const claimId = searchParams.get('submit_approval');
      if (claimId) {
        await submitApproval(claimId)
      }
    })()
  }, []);

  return (
    <>
      <ScrollRestoration />

      <TwoColTemplate
        sidebarText={"Discover bespoke marketing packages, designed to enhance the performance of your listing on our platform."}
        sidebarImage={"/assets/www/login-image-3.png"}
      >
        <div className="flex flex-col my-[74px]">
          <Heading>
            <div className="text-[28px]">
              Your payment was successful.
            </div>
          </Heading>

          <Description>
            We've sent your payment receipt to your email address.
            You will have also received an email with a link to verify your email address.
          </Description>

          {isLoggedIn && <div className="mt-2.5">
            <EmailVerificationButton
              label="Didn't receive the email?"
              className="text-sm text-[#5F646D] font-normal underline ml-0.5"
              email={user?.email ?? ''}
            />
          </div>}

          <div className="w-full mx-auto max-w-cs-616 mt-5">
            <AttractionCard
              className="w-full"
              title={attraction?.title ?? ""}
              location={attraction?.location_name ?? attraction?.location ?? ""}
              image={attraction?.image ?? ""}
              imageAlt={attraction?.image_alt ?? ""}
            />
          </div>

          <div className="mt-6">
            <Heading className="!text-[22px]">What happens now?</Heading>

            <Description>
            Once you have verified your email address, you can head to your dashboard to upload your content and publish your listing.
            </Description>

            {isLoggedIn && <Link
              to="/dashboard"
              className="inline-block mt-5 rounded-lg text-white font-bold whitespace-nowrap h-9 text-sm py-2.5 px-10 leading-[16px] bg-[#6836D1] ml-auto !px-2.5"
            >
              Go to your dashboard{" "}
            </Link>}
          </div>
        </div>
      </TwoColTemplate>
    </>
  );
};

export default PaymentSuccess;
