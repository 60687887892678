import { ErrorPage } from "../components/global/amp/PermissionError";

const NotFound: React.FC = () => {

    return(
        <>
        <ErrorPage type="NOT_FOUND" />
        </>
    )
}

export default NotFound;